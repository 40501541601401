import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { GGlobalTagListFragment } from "src/generated/graphql";

export type Tag = GGlobalTagListFragment & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TagListData {
  //
}

export class TagListStore extends Store2<TagListData, Tag> {
  protected data(): TagListData {
    return {};
  }

  protected async load() {
    //
  }

  protected setupSubscribe() {
    //
  }

  protected setupEmitter() {
    //
  }
}

export const tagListStore = new TagListStore();
