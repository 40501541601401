<template>
  <q-dialog ref="dialogRef" transition-duration="0" position="top" @hide="onDialogHide">
    <div
      class="mx-auto w-full mt-24 min-h-[400px] max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
    >
      <Combobox @update:modelValue="onSelect">
        <div class="relative border-b-gray-100 border flex flex-nowrap">
          <FormKit
            type="datepicker"
            name="cater_date"
            inner-class="$reset flex flex-row-reverse flex-nowrap"
            outer-class="$reset w-full mx-2"
            panel-wrapper-class="foo left-4"
            icon-class="text-gray-400 hover:text-gray-900"
            :placeholder="$t('conversation.actions.snooze.timeInputPlaceholder')"
            :sequence="['day', 'time']"
            popover
            :min-date="new Date()"
            overlay
            value-locale="en-US"
            @input="onDatePickerUpdate"
          >
            <template #input>
              <ComboboxInput
                autofocus
                class="h-12 w-full border-0 outline-none bg-transparent pl-3 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                :placeholder="$t('conversation.actions.snooze.timeInputPlaceholder')"
                @change="search = $event.target.value"
              />
            </template>
          </FormKit>
        </div>

        <ComboboxOptions
          static
          class="max-h-96 transform-gpu scroll-py-4 overflow-y-auto p-3 option-container space-y-1"
        >
          <ComboboxOption
            v-for="item in list"
            :key="`palette--${item.ui.index}`"
            :value="item.ui.index"
            as="template"
            v-slot="{ active }"
          >
            <li
              :class="[
                'flex flex-col flex-nowrap justify-between items-start cursor-pointer select-none rounded-xl p-3',
                active && 'bg-gray-100',
              ]"
            >
              <div class="flex flex-grow justify-between font-semibold">{{ item.label }}</div>

              <div class="muted text-xs">{{ quasarDate.formatDate(item.date, "DD MMM YYYY HH:mm") }}</div>
            </li>
          </ComboboxOption>
        </ComboboxOptions>

        <div v-if="search !== '' && !list.length" class="px-6 py-14 text-center text-sm sm:px-14">
          <q-icon name="fa-light fa-exclamation-circle" size="md" class="mx-auto text-gray-400" />
          <p class="mt-4 font-semibold text-gray-900">{{ $t("search.noResultsHeader") }}</p>
          <p class="mt-2 text-gray-500">{{ $t("search.noResultsDescription") }}</p>
        </div>
      </Combobox>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { date as quasarDate, useDialogPluginComponent, useQuasar } from "quasar";
import { parseDateInput } from "src/shared/multi-lingual-time-parser";
import {
  ConversationSnooze,
  ConversationSnoozeStore,
} from "src/features/conversation/conversation-snooze/conversation-snooze-store";
import { humanFriendlyDateDifference } from "src/shared/human-friendly-date-difference";
import { conversationSnoozeCommand } from "src/features/conversation/conversation-snooze/api-commands";
import { useStore2 } from "src/composables/useStore2";
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from "@headlessui/vue";
import { useI18n } from "vue-i18n";
import { useShortcut } from "src/composables/useShortcut";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },
});

const { store } = useStore2(ConversationSnoozeStore);

const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
defineEmits([...useDialogPluginComponent.emits]);

const { t } = useI18n();
const $q = useQuasar();
const search = ref("");
const isSaving = ref(false);
const maxYear = new Date().getUTCFullYear() + 5;

const onSelect = async (uiIndex: number) => {
  if (isSaving.value) {
    console.log("Snooze already in progress");
    return;
  }

  const selected = list.find(i => i.ui.index == uiIndex);

  if (!selected) {
    console.log("Could not find uiIndex", uiIndex);
    $q.notify({
      message: t("conversation.actions.snooze.errorMessage"),
      type: "negative",
    });

    return;
  }

  try {
    isSaving.value = true;

    await conversationSnoozeCommand(props.conversationId, selected.date);

    $q.notify({
      message: t("conversation.actions.snooze.successMessage"),
      type: "positive",
    });
    onDialogOK();
  } catch (err) {
    console.warn(err);
    $q.notify({
      message: t("conversation.actions.snooze.errorMessage"),
      type: "negative",
    });
  } finally {
    isSaving.value = false;
  }
};

const list = store.getList();

const getDefaultOptions = () => {
  const dates = [];
  const currentDate = new Date();

  // Function to add days to a date
  function addDays(date: Date, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Function to set time to 9 AM
  function setToNineAM(date: Date) {
    date.setHours(9, 0, 0, 0);
    return date;
  }

  // Add tomorrow, in 2 days, and in 3 days at 9 AM
  for (let i = 1; i <= 3; i++) {
    dates.push(setToNineAM(addDays(currentDate, i)));
  }

  // Find next Monday
  let nextMonday = new Date(currentDate);
  nextMonday.setDate(nextMonday.getDate() + ((7 - nextMonday.getDay() + 1) % 7 || 7));
  dates.push(setToNineAM(nextMonday));

  // In one week at 9 AM
  dates.push(setToNineAM(addDays(currentDate, 7)));

  return dates;
};

watch(search, onSearchChange);

const compileResult = (dates: Date[]) => {
  const res: Omit<ConversationSnooze, "ui">[] = [];

  for (const date of dates) {
    const label = humanFriendlyDateDifference(date);

    if (!label) {
      continue;
    }

    if (res.some(i => i.id === date.getTime().toString())) {
      continue;
    }

    res.push({
      id: date.getTime().toString(),
      date: date,
      label: label,
    });
  }

  return res;
};

watch(
  [list, search],
  () => {
    if (list.length === 0 && search.value.trim() === "") {
      store.loadWithData(compileResult(getDefaultOptions()));
      store.selectFirst();
    }
  },
  { immediate: true },
);

function onSearchChange() {
  const matches = parseDateInput(search.value);

  const data = compileResult(matches).filter(i => i.label && i.date.getUTCFullYear() < maxYear);

  store.loadWithData(data);
  store.selectFirst();
}

function onDatePickerUpdate(dateISO8601: string | Date | undefined) {
  const date = new Date(dateISO8601 as string);

  store.loadWithData(compileResult([date]));
  store.selectFirst();
}

useShortcut("conversation-snooze", [], { suspendShortcuts: true });
</script>

<style lang="sass" scoped></style>
