<template>
  <slot />
</template>

<script setup lang="ts">
import { useIntervalFn } from "@vueuse/core";

const threshold = 60_000; // 60 seconds threshold
const interval = 5_000; // 5 second interval
let lastTime = Date.now();

useIntervalFn(() => {
  const currentTime = Date.now();
  const deltaTime = currentTime - lastTime;

  if (deltaTime > interval + threshold) {
    // The tab was probably inactive or throttled, reload or update the app
    console.log("Drift was detected -- tab may be throttled. Reloading app...");
    window.location.reload();
    // Reload the app or perform any necessary actions
  }

  // Update lastTime for the next tick
  lastTime = currentTime;
}, interval);
</script>
