<template>
  <sa-editor
    ref="editor"
    class="bg-yellow-600 text-white"
    :content="content"
    autofocus="start"
    @escape="onEscape"
    @mod+enter="onCtrlCenter(false)"
    @mod+shift+enter="onCtrlCenter(true)"
    @upload="onUpload"
    text-bubble-menu
    file-upload
    paragraph-as-div
    agent-mention
    image-paste
    emoji
    body-scroll-lock
  >
  </sa-editor>

  <div class="flex justify-between">
    <div class="flex flex-row items-center pt-1.5">
      <AttachmentThumbnail
        v-for="(attachment, index) in attachments"
        :key="`attachment-${index}`"
        :filename="attachment.filename"
        :content-type="attachment.contentType"
        :preview-url="attachment.previewUrl"
        :download-url="attachment.downloadUrl"
        show-delete
        dense
        @delete="onAttachmentDelete(index)"
      />
    </div>
    <MessageSubmitButton :loading="isSending" @send="onCtrlCenter(false)" @send-and-mark-done="onCtrlCenter(true)" />
  </div>
</template>

<script setup lang="ts">
import SaEditor, { ISaEditor } from "src/features/tip-tap-editor/SaEditor.vue";
import { createConversationAgentCommentCommand } from "src/features/conversation/agent-comment-editor/api-commands";
import { ref } from "vue";
import { UploadedFile } from "src/features/tip-tap-editor/SaEditorDropTarget.vue";
import AttachmentThumbnail from "src/features/attachment/attachment-thumbnail/AttachmentThumbnail.vue";
import { TrackedEvent, useTracking } from "../../../composables/useTracking";
import emitShortcut from "src/shared/emit-shortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import MessageSubmitButton from "../shared/MessageSubmitButton.vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },

  linkedToTimelineId: {
    type: String,
    required: false,
    default: () => null,
  },
});

const emits = defineEmits(["close"]);

const { trackEvent } = useTracking();
const $q = useQuasar();
const { t } = useI18n();

const editor = ref<ISaEditor>();
const attachments = ref<UploadedFile[]>([]);
const isSending = ref(false);

const content = "";

function onUpload(attachment: UploadedFile) {
  attachments.value.push(attachment);
}

function onEscape() {
  emits("close");
}

async function onCtrlCenter(shift: boolean) {
  if (isSending.value) {
    console.log("A message is already sending, skipping...");
    return;
  }

  const attachmentList = attachments.value.map(i => i.downloadUrl);

  if (editor.value?.isEmpty() && attachmentList.length === 0) {
    $q.notify({
      type: "warning",
      icon: "fa-regular fa-face-confused",
      message: t("conversation.actions.send.nothingToSendNotification"),
    });
    return;
  }

  isSending.value = true;

  createConversationAgentCommentCommand(
    props.conversationId,
    editor.value?.getHTML() ?? "",
    attachmentList,
    props.linkedToTimelineId,
  )
    .then(() => {
      if (shift) {
        emitShortcut(ShortcutItem.ConversationArchive);
      }

      emits("close");
      trackEvent(TrackedEvent.CreatedComment);
    })
    .catch(() => {
      console.warn("Error");
    })
    .finally(() => {
      isSending.value = false;
    });
}

function onAttachmentDelete(index: number) {
  attachments.value.splice(index, 1);
}
</script>

<style lang="sass"></style>
