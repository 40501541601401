<template>
  <main-nav-btn
    icon="fa-bell"
    @click="onClick"
    :tooltip="$t('agentNotificationList.drawer.header')"
    size="small"
    :badge="state.count > 9 ? ' ' : state.count?.toString()"
    :shortcuts="['G', 'N']"
  />
</template>

<script setup lang="ts">
import { useDrawer } from "src/composables/useDrawers";
import { useI18n } from "vue-i18n";
import { unseenAgentNotificationCountStore } from "src/features/agent-notification/unseen-agent-notification-count/unseen-agent-notification-count-global-store";
import { computed } from "vue";
import MainNavBtn from "components/MainNavBtn.vue";

const { t } = useI18n();
const { showAgentNotificationListDrawer } = useDrawer(t);

const state = computed(() => unseenAgentNotificationCountStore.getState());

function onClick() {
  showAgentNotificationListDrawer();
}
</script>

<style lang="sass"></style>
