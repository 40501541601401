<template>
  <transition name="fade-skeleton" mode="out-in">
    <div v-if="isLoaded">
      <template v-if="sortedList.length">
        <div v-for="notification in sortedList" :key="notification.id">
          <div
            :id="`agent-notification-${notification.ui.index}`"
            class="sa-list__row cursor-pointer py-2 px-4 text-sm"
            :class="{
              'sa-list__row--hover': notification.ui.hover && !notification.ui.selected,
              'sa-list__row--selected': notification.ui.selected,
              'sa-agent-notification-list__row--unseen': !notification.seen,
            }"
            @mouseover="onMouseOver(notification.ui.index)"
            @mouseleave="onMouseLeave(notification.ui.index)"
            @click="onClick(notification)"
          >
            <template
              v-if="
                notification.eventType === GNotificationEvent.ConversationUnassignedUnSnoozed ||
                notification.eventType === GNotificationEvent.ConversationAssignedAgentUnSnoozed ||
                notification.eventType === GNotificationEvent.ConversationAssignedTeamUnSnoozed
              "
            >
              <agent-notification-list-item>
                <template #icon>
                  <q-icon name="fa-light fa-clock" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">{{ $t("notifications.conversationReminder") }}</div>
                  <div>{{ notification.conversation?.description ?? `<${$t("no-description")}>` }}</div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>

            <template v-if="notification.eventType === GNotificationEvent.ConversationAssignedToAgent">
              <agent-notification-list-item>
                <template #icon>
                  <agent-avatar-with-status
                    v-if="!isNullOrUndefined(notification.generatedByAgent?.id)"
                    :agent-id="notification.generatedByAgent!.id"
                  />
                  <q-icon v-else name="fa-light fa-user" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">{{ $t("notifications.conversationAssignedToYou") }}</div>
                  <div>{{ notification.conversation?.description ?? `<${$t("no-description")}>` }}</div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>

            <template v-if="notification.eventType === GNotificationEvent.ConversationAssignedToTeam">
              <agent-notification-list-item>
                <template #icon>
                  <q-icon name="fa-light fa-user-plus" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">
                    {{ $t("notifications.conversationAssignedToTeam", [notification.conversation?.team?.name]) }}
                  </div>
                  <div>{{ notification.conversation?.description ?? `<${$t("no-description")}>` }}</div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>

            <template
              v-if="
                notification.eventType === GNotificationEvent.ConversationUnassignedCreatedByContact ||
                notification.eventType === GNotificationEvent.ConversationAssignedAgentCreatedByContact ||
                notification.eventType === GNotificationEvent.ConversationAssignedTeamCreatedByContact
              "
            >
              <agent-notification-list-item>
                <template #icon>
                  <q-icon name="fa-light fa-message-plus" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">{{ $t("notifications.newConversation") }}</div>
                  <div>{{ notification.conversation?.description ?? `<${$t("no-description")}>` }}</div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>

            <template
              v-if="
                notification.eventType === GNotificationEvent.ConversationUnassignedContactMessageCreated ||
                notification.eventType === GNotificationEvent.ConversationAssignedAgentContactMessageCreated ||
                notification.eventType === GNotificationEvent.ConversationAssignedTeamContactMessageCreated
              "
            >
              <agent-notification-list-item>
                <template #icon>
                  <q-icon name="fa-light fa-message" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">{{ $t("notifications.newReplyInConversation") }}</div>
                  <div>{{ notification.conversation?.description ?? `<${$t("no-description")}>` }}</div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>

            <template v-if="notification.eventType === GNotificationEvent.ConversationAgentMentioned">
              <agent-notification-list-item>
                <template #icon>
                  <q-icon name="fa-light fa-user-plus" size="24px" />
                </template>
                <template #body>
                  <div class="font-bold">
                    {{ $t("notifications.youWereMentionedByAgent", [notification.generatedByAgent?.name]) }}
                  </div>
                  <div>
                    {{
                      (notification.conversationTimeline?.data as GConversationAgentComment)?.content ??
                      `<${$t("no-description")}>`
                    }}
                  </div>
                </template>
                <template #ago>
                  <updated-ago :updated="notification.timestamp" />
                </template>
              </agent-notification-list-item>
            </template>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="py-4 px-4">{{ t("agentNotificationList.noNotifications") }}</div>
      </template>
    </div>
    <div v-else class="flex flex-col space-y-2 my-2">
      <q-skeleton v-for="(i, idx) of [1, 2, 3, 4, 5]" :key="idx" class="rounded-xl" :type="'QInput'" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import {
  AgentNotification,
  AgentNotificationListStore,
} from "src/features/agent-notification/agent-notification-list/agent-notification-list-store";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { GConversationAgentComment, GNotificationEvent } from "src/generated/graphql";
import { useShortcut } from "src/composables/useShortcut";
import { useSaveSelectionToStore } from "src/composables/useSaveMouseOverToStore";
import AgentNotificationListItem from "src/features/agent-notification/agent-notification-list/AgentNotificationListItem.vue";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import { useRouter } from "vue-router";
import { emitter } from "boot/mitt";
import { ShortcutItem } from "src/shared/shortcut-item";
import { useScrollToStoreSelected } from "src/composables/useScrollToStoreSelected";
import { closeDrawer } from "src/composables/useGlobalDrawer";
import { useI18n } from "vue-i18n";
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { useScrollBottomListener } from "src/composables/useScrollBottomListeners";
import { useStore2 } from "src/composables/useStore2";
import { computed, watch } from "vue";

const { t } = useI18n();
const $router = useRouter();

const emit = defineEmits<{
  (e: "unreadCount", value: number): void;
}>();

const { store, isLoaded } = useStore2(AgentNotificationListStore);
void store.load();
const list = store.getList();

const sortedList = computed(() => {
  return [...list].sort((a, b) => {
    return a.timestamp - b.timestamp;
  });
});

const unreadCount = computed(() => {
  return list.filter(i => i.seen === false).length;
});

watch(
  unreadCount,
  () => {
    console.log("emitting", unreadCount.value);
    emit("unreadCount", unreadCount.value);
  },
  {
    immediate: true,
  },
);

const { onMouseOver, onMouseLeave } = useSaveSelectionToStore(store);

useScrollToStoreSelected(store, "agent-notification");

useScrollBottomListener(false, scrollToPreviousLocation => {
  void store.loadMore().then(() => {
    scrollToPreviousLocation();
  });
});

useShortcut(
  "agent-notification-list",
  [
    {
      key: "down",
      callback: () => selectNext(),
      sendToCommandPalette: false,
      allowRepeat: true,
    },
    {
      key: "up",
      callback: () => selectPrevious(),
      sendToCommandPalette: false,
      allowRepeat: true,
    },
    {
      key: "enter",
      callback: () => onEnter(),
      sendToCommandPalette: false,
      shortcut: ShortcutItem.AgentNotificationSelect,
    },
    {
      key: "escape",
      callback: () => onEscape(),
      description: t("agentNotificationList.goBack"),
      sendToCommandPalette: false, // TODO: closeDrawer() doesn't work when triggered from global palette
      shortcut: ShortcutItem.AgentNotificationGoBack,
    },
    {
      key: "r",
      description: t("agentNotificationList.markAllAsRead"),
      callback: () => onMarkAllAsRead(),
      shortcut: ShortcutItem.AgentNotificationMarkAllAsRead,
    },
  ],
  {
    newShortcutStack: true,
    includeGlobal: true,
  },
);

function selectPrevious() {
  store.selectPrevious();
}

function selectNext() {
  store.selectNext();
}

async function onEnter() {
  if (store.selectedCount()) {
    const selected = store.getSelectedItem();
    void onClick(selected);
  }
}

function onEscape() {
  closeDrawer();
}

// function onSelect(uiIndex: number) {
//   agentNotificationListStore.select(uiIndex);
// }

async function onMarkAllAsRead() {
  const sdk = useGraphqlSdk();
  await sdk.MarkAllAgentNotificationsAsSeen();
}

async function onClick(agentNotification: AgentNotification) {
  if (!agentNotification.seen) {
    const sdk = useGraphqlSdk();

    void sdk.MarkAgentNotificationAsSeen({
      agentNotificationId: agentNotification.id,
    });

    emitter.emit("agent-notification-marked-as-seen", agentNotification.id);
  }

  await $router.push({ name: "conversation", params: { id: agentNotification.conversation?.id } });
}

// onMounted(() => {
//   const sdk = useGraphqlSdk();
//   sdk
//     .GetAgentNotifications({
//       take: 50,
//       // continuationToken: this.settings.continuationToken,
//     })
//     .then(res => {
//       agentNotificationListStore.loadWithData(res.agentNotifications.items as AgentNotification[]);
//     });
// });
</script>

<style lang="sass"></style>
