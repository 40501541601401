<template>
  <div class="sa-editor-popup">
    <q-list>
      <template v-if="items.length">
        <q-item
          v-for="(item, index) in items"
          :key="index"
          v-ripple
          clickable
          class="sa-editor-popup__item"
          :class="{ 'sa-editor-popup__item--selected': index === selectedIndex }"
          @click="selectItem(index)"
          @mouseenter="selectedIndex = index"
        >
          <q-item-section>
            <agent-avatar-with-status :agent-id="item.id" include-name size="sm" />
          </q-item-section>
        </q-item>
      </template>
      <template v-else>
        <q-item>No matching agent found</q-item>
      </template>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import { agentWithStatusStore } from "src/features/agent/agent-avatar-with-status/agent-with-status-global-store";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { PropType, toRefs } from "vue";
import { usePopup } from "src/features/tip-tap-editor/composables/usePopup";

const props = defineProps({
  items: {
    // eslint-disable-next-line
    type: Object as PropType<any[]>,
    required: true,
  },

  command: {
    type: Function,
    required: true,
  },
});

const { items: popupItems } = toRefs(props);

const { onKeyDown, selectedIndex } = usePopup(popupItems, selectItem);

defineExpose({ onKeyDown });

function selectItem(index: number) {
  const item = popupItems?.value[index];

  if (item) {
    let list = agentWithStatusStore.getList();
    const index = list.findIndex(i => i.id === item.id);
    if (index !== -1) {
      const agent = list[index];
      props.command({ id: agent.id, label: resolveDisplayName(agent.firstName, agent.lastName) });
    }
  }
}
</script>
