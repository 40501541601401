<template>
  <template v-if="activeList.length">
    <div class="space-y-4 w-full flex justify-start">
      <div
        v-for="contactIsTyping in activeList"
        :key="contactIsTyping.contactId"
        class="w-2/3"
        :id="`contact-typing-${contactIsTyping.contactId}`"
      >
        <div
          class="sa-conversation-timeline-contact-typing-message__name mb-1 text-sm flex flex-row items-end space-x-2"
        >
          Kunde skriver
          <q-spinner-dots size="xs" />
        </div>

        <div
          class="sa-conversation-timeline-contact-typing-message__block cursor-pointer pl-5 pr-2 py-3 rounded-lg w-full flex justify-between items-center"
        >
          <div class="sa-conversation-timeline-contact-typing-message__block-content leading-6 text-sm pr-4 space-y-1">
            <template v-if="contactIsTyping.content === ''">
              <q-spinner-dots />
            </template>
            <div v-else v-html="contactIsTyping.content" />
          </div>
        </div>

        <placeholder-component @mounted="onContactTypingMounted(contactIsTyping.contactId)" />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import {
  GOnContactStoppedTypingInConversationByIdSubscription,
  GOnContactStoppedTypingInConversationByIdSubscriptionVariables,
  GOnContactTypingInConversationByIdSubscription,
  GOnContactTypingInConversationByIdSubscriptionVariables,
  OnContactStoppedTypingInConversationByIdDocument,
  OnContactTypingInConversationByIdDocument,
} from "src/generated/graphql";
import { useSubscribe } from "src/composables/useSubscribe";
import { TextSynchronizationClient } from "src/shared/text-synchronization-client";
import { ref } from "vue";
import { useInterval } from "src/composables/useInterval";
import PlaceholderComponent from "components/PlaceholderComponent.vue";
import { isPageNearBottom, scrollToBottomSmooth } from "src/shared/dom-utils";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },
});

interface ContactIsTypingInConversation {
  contactId: string;
  content: string;
  text: TextSynchronizationClient;
  lastUpdated: Date;
}

const list: ContactIsTypingInConversation[] = [];
const activeList = ref<ContactIsTypingInConversation[]>([]);

function onContactTypingMounted(contactId: string) {
  const elementId = `contact-typing-${contactId}`;
  const el = document.getElementById(elementId);

  if (el) {
    // The element is already visible, so we need to include the height when checking if we are near the bottom.
    if (isPageNearBottom(el.clientHeight + 50)) {
      scrollToBottomSmooth();
    }
  } else {
    console.log("Element", elementId, "not found");
  }
}

useInterval(() => {
  const delta = new Date().getTime() - 30 * 1000; // 30 seconds
  activeList.value = list.filter(
    i => i.lastUpdated.getTime() > delta && i.content !== "" && i.content !== "<div></div>"
  );
}, 1);

useSubscribe<GOnContactTypingInConversationByIdSubscription, GOnContactTypingInConversationByIdSubscriptionVariables>(
  OnContactTypingInConversationByIdDocument,
  {
    conversationId: props.conversationId ?? "",
  },
  res => {
    let contactIsTyping: ContactIsTypingInConversation;
    const isNearBottom = isPageNearBottom();

    const contactIsTypingIndex = list.findIndex(i => i.contactId === res.onContactTypingInConversationById.contactId);
    if (contactIsTypingIndex !== -1) {
      contactIsTyping = list[contactIsTypingIndex] as ContactIsTypingInConversation;
    } else {
      contactIsTyping = {
        contactId: res.onContactTypingInConversationById.contactId,
        content: "",
        text: new TextSynchronizationClient(),
      } as ContactIsTypingInConversation;

      list.push(contactIsTyping);
    }

    contactIsTyping.content = contactIsTyping.text.applyPatch(
      res.onContactTypingInConversationById.content,
      res.onContactTypingInConversationById.isFullContent,
      res.onContactTypingInConversationById.sequence
    );

    contactIsTyping.lastUpdated = new Date();

    if (isNearBottom) {
      scrollToBottomSmooth(500);
    }
  }
);

useSubscribe<
  GOnContactStoppedTypingInConversationByIdSubscription,
  GOnContactStoppedTypingInConversationByIdSubscriptionVariables
>(
  OnContactStoppedTypingInConversationByIdDocument,
  {
    conversationId: props.conversationId ?? "",
  },
  res => {
    const contactIsTypingIndex = list.findIndex(
      i => i.contactId === res.onContactStoppedTypingInConversationById.contactId
    );
    if (contactIsTypingIndex !== -1) {
      list.splice(contactIsTypingIndex, 1);
    }
  }
);
</script>
