import { Extensions, generateJSON } from "@tiptap/core";

export function useFormattingHelper() {
  const prepareEditorJsonFromStoredHtml = (html: string, extensions: Extensions) => {
    if (!html) {
      return html;
    }

    const cleanedJson = generateJSON(html, extensions);

    if (!cleanedJson.content) {
      return cleanedJson;
    }

    try {
      // <div><br/></div> will result in two empty lines once inserted to the editor. This is a workaround to remove the extra line.
      /* eslint-disable @typescript-eslint/no-explicit-any */
      cleanedJson.content.forEach((item: any) => {
        if (item.type === "paragraph" && item.content?.length === 1 && item.content[0].type === "hardBreak") {
          delete item.content;
        }
      });
    } catch (err) {
      console.warn("Error cleaning editor json", err);
    }

    return cleanedJson;
  };

  return {
    /** Transforms stored HTML to JSON for the editor, with some massaging to avoid excessive line breaks */
    prepareEditorJsonFromStoredHtml,
  };
}
