<template>
  <s-btn-with-tooltip
    icon="arrow_back"
    :unelevated="unelevated"
    :glossy="glossy"
    :round="round"
    :rounded="rounded"
    :ripple="ripple"
    :push="push"
    :dense="dense"
    size="lg"
    class="sa-toolbar-button"
    :tooltip="tooltip"
    :shortcuts="shortcuts"
    :active="active"
  >
    <slot />
  </s-btn-with-tooltip>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import SBtnWithTooltip from "components/SBtnWithTooltip.vue";

defineProps({
  tooltip: {
    type: String,
    required: true,
  },
  shortcuts: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [],
  },
  active: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const unelevated = ref(true);
const glossy = ref(false);
const round = ref(false);
const rounded = ref(false);
const ripple = ref(false);
const push = ref(false);
const dense = ref(false);
</script>

<style lang="sass"></style>
