import { Node } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";
import { useFormattingHelper } from "../../composables/useFormattingHelper";
import { TrackedEvent, useTracking } from "src/composables/useTracking";

export type MentionOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>;
  // renderLabel: (props: { options: MentionOptions; node: ProseMirrorNode }) => string;
  suggestion: Omit<SuggestionOptions, "editor">;
};

export const ArticlePluginKey = new PluginKey("knowledge-base-article");

const char = "??";

export const KnowledgeBaseArticleNode = Node.create<MentionOptions>({
  name: "knowledge-base-article",

  addOptions() {
    const { prepareEditorJsonFromStoredHtml } = useFormattingHelper();
    const { trackEvent } = useTracking();

    return {
      HTMLAttributes: {},
      // renderLabel({ options, node }) {
      //   return `TEST: ${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      // },
      suggestion: {
        char: char,
        allowSpaces: true,
        pluginKey: ArticlePluginKey,
        command: ({ editor, range, props }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          if (range.from > 0) {
            range.from -= 1;
          }

          const preparedJson = prepareEditorJsonFromStoredHtml(props.content, editor.extensionManager.extensions);

          editor.chain().focus().insertContentAt(range, preparedJson).run();

          editor.commands.scrollIntoView();

          trackEvent(TrackedEvent.InsertedKbInReply);
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          return !!$from.parent.type.contentMatch.matchType(type);
        },
      },
    };
  },

  group: "inline",
  inline: true,

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
