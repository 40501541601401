<template>
  <s-form @submit="onSubmit" class="space-y-1">
    <FormKit type="form" ref="form" :actions="false">
      <q-banner v-if="error" class="bg-red text-white">{{ errorMessage }}</q-banner>

      <div>
        <FormKit
          type="text"
          :label="$t('kb.shared.titleLabel')"
          validation="required"
          v-model="title"
          :help="$t('kb.shared.titleHelp')"
        />
      </div>

      <div class="mb-5">
        <div class="sa-form__label">{{ $t("kb.shared.solutionLabel") }}</div>
        <kb-editor ref="solutionEditor" :body="solution" :autofocus="false" @update="val => (solution = val)" />
        <div class="text-xs text-gray-500">{{ $t("kb.shared.theMainContentsOfTheArticle") }}</div>
      </div>

      <div class="space-y-1 mb-5" ref="questionsGroup">
        <div class="sa-form__label">{{ $t("kb.shared.questionsLabel") }}</div>

        <FormKit id="questions" type="group" :help="$t('kb.shared.questionsHelp')">
          <ActionBtn v-if="!questions.length" :kind="'secondary'" @click="onAddQuestionClick">{{
            $t("kb.shared.addAQuestion")
          }}</ActionBtn>

          <FormKit
            v-for="(values, index) in questions"
            :key="index"
            :id="`article-question-${index}`"
            type="text"
            v-model="questions[index]"
            :placeholder="$t('kb.shared.questionInputPlaceholder')"
            inner-class="mt-1 flex rounded-md shadow-sm"
            input-class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-wh-blue focus:ring-wh-blue sm:text-sm fdfsd"
            :help="index === 0 ? $t('kb.shared.questionsHelp') : undefined"
          >
            <template #suffix>
              <SBtnWithTooltip
                v-if="index === questions.length - 1"
                dense
                class="muted px-2 shadow-none"
                size="sm"
                @click="onAddQuestionClick"
                icon="fa-light fa-plus"
                :tooltip="$t('kb.shared.addAnotherQuestion')"
              />
              <SBtnWithTooltip
                dense
                class="muted px-2 shadow-none"
                size="sm"
                @click="removeQuestion(index)"
                icon="fa-light fa-trash"
                :tooltip="$t('kb.shared.removeThisQuestion')"
              />
            </template>
          </FormKit>
        </FormKit>
      </div>

      <div class="space-y-1">
        <FormKit
          :label="$t('kb.shared.audience')"
          type="radio"
          v-model="audience"
          :options="{
            [GKnowledgeBaseArticleAudienceItem.Internal]: $t('kb.audience.internal'),
            [GKnowledgeBaseArticleAudienceItem.Public]: $t('kb.audience.public'),
          }"
          :help="$t('kb.shared.audienceHelp')"
        />
      </div>

      <div class="space-y-1">
        <FormKit
          :label="$t('kb.shared.draft')"
          alt-label-position
          v-model="draft"
          type="toggle"
          :help="$t('kb.shared.draftHelp')"
          value-label-display="inner"
        />
      </div>

      <div class="space-y-1 pb-4">
        <div class="flex flex-row space-x-2 items-center pt-2">
          <ActionBtn @click="onSubmit" :loading="saving" :kind="'primary'">{{
            $t("kb.create.saveButtonLabel")
          }}</ActionBtn>
          <!-- <div class="muted" v-if="state.unsavedChanged">({{ $t("kb.shared.unsavedChanges") }})</div> -->
        </div>
      </div>
    </FormKit>
  </s-form>
</template>

<script setup lang="ts">
import SForm from "components/SForm.vue";
import { nextTick, ref } from "vue";
import { useFormSave } from "src/composables/useFormSave";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import KbEditor from "src/features/knowledgebase/kb-editor/KbEditor.vue";
import { Editor } from "@tiptap/vue-3";
import { scrollToId } from "src/shared/dom-utils";
import ActionBtn from "src/components/ActionBtn.vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import SBtnWithTooltip from "src/components/SBtnWithTooltip.vue";
import { useRouter } from "vue-router";
import { GKnowledgeBaseArticleAudienceItem } from "../../../generated/graphql";

const props = defineProps({
  // store: {
  //   type: Object as PropType<KnowledgeBaseArticleCreateStore>,
  //   required: true,
  // },
  title: {
    type: String,
    required: false,
  },
  solution: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["update:saving", "created"]);
const $q = useQuasar();
const { t } = useI18n();
const router = useRouter();

const { save, error, errorMessage, setError } = useFormSave();
defineExpose({ save });

//const state = props.store.getState();
const solutionEditor = ref<Editor>();
const questionsGroup = ref<Element>();
const title = ref(props.title ?? "");
const solution = ref(props.solution ?? "");
const questions = ref<string[]>([""]);

const audience = ref<GKnowledgeBaseArticleAudienceItem>(GKnowledgeBaseArticleAudienceItem.Public);
const draft = ref(false);
const saving = ref(false);

async function onSubmit() {
  const progress = $q.notify({
    type: "ongoing",
    message: t("kb.create.saveToastProgress"),
  });

  emit("update:saving", true);
  saving.value = true;
  solution.value = solutionEditor.value?.getHTML() ?? "";

  const sdk = useGraphqlSdk();
  sdk
    .CreateKnowledgeBaseArticle({
      title: title.value,
      solution: solution.value,
      questions: questions.value.filter(i => i !== ""),
      draft: draft.value,
      audience: audience.value,
    })
    .then(data => {
      if (!data.createKnowledgeBaseArticle.knowledgeBaseArticle?.id) {
        throw new Error("API response did not contain an article ID");
      }

      progress({
        type: "positive",
        message: t("kb.create.saveToastSuccessful"),
        actions: [
          {
            label: t("knowledgeBase.showArticle"),
            color: "white",
            handler: async () => {
              await router.push({
                name: "kb-modify",
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                params: { id: data.createKnowledgeBaseArticle.knowledgeBaseArticle!.id },
              });
            },
          },
          {
            label: t("common.createAnother"),
            color: "white",
            handler: async () => {
              await router.push({ name: "kb-create" });
            },
          },
        ],
      });

      emit("created", data.createKnowledgeBaseArticle.knowledgeBaseArticle.id);
    })
    .catch(err => {
      setError(err.message);
      progress({
        type: "negative",
        message: t("kb.create.saveToastError"),
      });
    })
    .finally(() => {
      emit("update:saving", false);
      saving.value = false;
    });
}

async function onAddQuestionClick() {
  questions.value.push("");

  await nextTick(() => {
    const inputs = questionsGroup.value?.querySelectorAll("input");
    if (inputs) {
      inputs[inputs.length - 1].focus();
      scrollToId(`article-question-${inputs.length - 1}`);
    }
  });
}

function removeQuestion(index: number) {
  questions.value.splice(index, 1);
}
</script>

<style lang="sass"></style>
