<template>
  <s-form @submit="onSubmit" ref="form" class="max-w-2xl">
    <q-banner v-if="error" class="bg-red text-white">{{ errorMessage }}</q-banner>

    <div class="space-y-1">
      <div class="sa-form__label">{{ $t("settings.channels.sms.smsPrefixHeader") }}</div>
      <div class="text-xs muted">
        {{ $t("settings.channels.sms.prefixDescription") }} {{ $t("settings.channels.sms.keepItShort") }}
      </div>
      <q-input
        v-model="smsPrefix"
        outlined
        :placeholder="`${$t('settings.channels.sms.suggestion')}: ${prefixSuggestion}:`"
        dense
        class="sa-form__input"
        type="text"
        autofocus
        :rules="[val => (val?.length ?? 0) < 30 || $t('common.form.useMaxNCharacters', 30)]"
      >
      </q-input>
    </div>

    <div class="space-y-1">
      <div class="sa-form__label">{{ $t("settings.channels.sms.smsSuffixHeader") }}</div>
      <div class="text-xs muted">
        {{ $t("settings.channels.sms.suffixDescription") }} {{ $t("settings.channels.sms.keepItShort") }}
      </div>
      <q-input
        v-model="smsSuffix"
        outlined
        class="sa-form__input"
        :placeholder="`${$t('settings.channels.sms.suggestion')}: ${$t(
          'settings.channels.sms.youMayRespondToThisMessage',
        )}`"
        type="text"
        autofocus
        :rules="[val => (val?.length ?? 0) < 30 || $t('common.form.useMaxNCharacters', 30)]"
      />
    </div>
  </s-form>
</template>

<script setup lang="ts">
import SForm from "components/SForm.vue";
import { ref, computed } from "vue";
import { useFormSave } from "src/composables/useFormSave";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { isNullOrUndefined } from "src/shared/object-utils";
import { tenantInfoStore } from "stores/tenant-info-global-store";

const prefixSuggestion = computed(() => tenantInfoStore.getState().name);

const emit = defineEmits(["update:saving", "created"]);

const { form, save, error, errorMessage, setError } = useFormSave();
defineExpose({ save });

const smsPrefix = ref<string>();
const smsSuffix = ref<string>();

async function onSubmit() {
  const sdk = useGraphqlSdk();
  emit("update:saving", true);

  sdk
    .CreateSmsSubNumberChannel({
      input: {
        smsPrefix: smsPrefix.value,
        smsSuffix: smsSuffix.value,
      },
    })
    .then(res => {
      if (!isNullOrUndefined(res.createSmsSubNumberChannel.channel)) {
        emit("created", res.createSmsSubNumberChannel.channel);
      }
    })
    .catch(err => {
      setError(err.message);
    })
    .finally(() => {
      emit("update:saving", false);
    });
}
</script>

<style lang="sass"></style>
