import { GUserError, GUserErrorCode } from "src/generated/graphql";

export class UserError extends Error {
  public errors: GUserError[];
  public code: GUserErrorCode;

  constructor(errors: GUserError[]) {
    super(errors[0].message);
    this.code = errors[0].code;
    this.errors = errors;
  }
}

export class InternalError extends UserError {
  constructor(error: Error) {
    super([{ message: error.message, code: GUserErrorCode.Error }]);
  }
}
