import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { Store2 } from "stores/store-2";
import { GConversationBotAssistantModeItem } from "src/generated/graphql";

export interface ConversationBotAssistantModeItem {
  id: string;
  mode: GConversationBotAssistantModeItem;
}

export type ConversationBotAssistantMode = ConversationBotAssistantModeItem & StoreWithSelectListData;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationBotAssistantData {}

export class ConversationBotAssistantModeStore extends Store2<
  ConversationBotAssistantData,
  ConversationBotAssistantMode
> {
  protected data(): ConversationBotAssistantData {
    return {};
  }

  public load() {
    this.push({
      id: GConversationBotAssistantModeItem.Disconnected,
      mode: GConversationBotAssistantModeItem.Disconnected,
    });

    this.push({
      id: GConversationBotAssistantModeItem.SuggestReply,
      mode: GConversationBotAssistantModeItem.SuggestReply,
    });

    this.push({
      id: GConversationBotAssistantModeItem.CreateReply,
      mode: GConversationBotAssistantModeItem.CreateReply,
    });

    this.selectFirst();
  }
}

// export const conversationBotAssistantModeStore = new ConversationBotAssistantModeStore();
