<template>
  <template v-if="error">
    <q-banner inline-actions rounded class="bg-orange text-white">
      An error occured while processing your request. Please try again.
      <div v-if="correlationId" class="text-xs font-extralight">Request ID: {{ correlationId }}</div>

      <template v-slot:action>
        <ActionBtn :kind="'secondary'" @click="$router.go(0)">Try again</ActionBtn>
      </template>
    </q-banner>
  </template>
  <slot v-else />
</template>

<script setup lang="ts">
import { ClientError } from "graphql-request";
import { onErrorCaptured, ref, watch } from "vue";
import ActionBtn from "src/components/ActionBtn.vue";
import { useRoute } from "vue-router";

const error = ref(false);
const correlationId = ref<string>();
const route = useRoute();

onErrorCaptured(err => {
  console.warn("Captured error in ErrorBoundary", err);
  error.value = true;

  if (err instanceof ClientError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const correlationId = (err.response.headers as any)?.map?.["x-correlation-id"];

    if (correlationId) {
      correlationId.value = correlationId;
    }
  }

  return false;
});

watch(route, () => {
  error.value = false;
});
</script>

<style scoped lang="scss"></style>
