import { useQuasar } from "quasar";
import SearchDialog from "src/features/search/SearchDialog.vue";
import ConversationAssignDialog from "src/features/conversation/conversation-assign/ConversationAssignDialog.vue";

export function useDialog() {
  const $q = useQuasar();

  const showSearchDialog = () => {
    $q.dialog({
      component: SearchDialog,
    });
  };

  const showAssignDialog = (conversationIds: string[], currentAgentId?: string, currentTeamId?: string) => {
    $q.dialog({
      component: ConversationAssignDialog,
      componentProps: {
        conversationIds: conversationIds,
        currentAgentId: currentAgentId,
        currentTeamId: currentTeamId,
      },
    });
  };

  return {
    showSearchDialog,
    showAssignDialog,
  };
}
