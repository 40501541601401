<template>
  <div>
    <div class="space-y-2 px-4 py-4">
      <div class="sa-kb-display text-sm leading-5" v-html="solution" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <ActionBtn :label="$t('common.cancel')" @click="onCancel" />
      <ActionBtn :label="$t('common.delete')" @click="onDelete" />
      <ActionBtn :label="$t('common.edit')" primary @click="onEdit" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import { useGraphqlSdk, GraphQlUserError } from "src/graphql/graphql-client";
import { useRouter } from "vue-router";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import ActionBtn from "src/components/ActionBtn.vue";
import { useI18n } from "vue-i18n";
import { useQuasar } from "quasar";
import { GKnowledgeBaseArticleStatusItem, GUserErrorCode } from "src/generated/graphql";
import { emitter } from "boot/mitt";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const $router = useRouter();
const sdk = useGraphqlSdk();
const title = ref("");
const objective = ref<string | null | undefined>("");
const solution = ref("");
const { t } = useI18n();
const $q = useQuasar();

useShortcut(
  "knowledgeBaseDisplayDrawer",
  [
    {
      key: "esc",
      callback: () => onCancel(),
      description: t("common.closeDrawer"),
      shortcut: ShortcutItem.Escape,
    },
  ],
  {
    newShortcutStack: true,
    includeGlobal: true,
  },
);

function load() {
  sdk
    .GetKnowledgeBaseArticleDisplayById({
      id: props.id,
    })
    .then(res => {
      title.value = res.knowledgeBaseArticleById.title;
      objective.value = res.knowledgeBaseArticleById.objective;
      solution.value = res.knowledgeBaseArticleById.solution;
    })
    .catch(err => {
      console.error(err);
    });
}

function onEdit() {
  onHide();
  void $router.push({ name: "kb-modify", params: { id: props.id } });
}

function onDelete() {
  $q.dialog({
    title: t("common.confirm"),
    message: t("kb.shared.confirmDelete"),
    cancel: true,
  }).onOk(async () => {
    const progress = $q.notify({
      type: "ongoing",
      message: t("kb.shared.deleteToastProgress"),
    });

    try {
      const deleteResult = await sdk.DeleteKnowledgeBaseArticle({
        input: {
          id: props.id,
        },
      });

      if (deleteResult.deleteKnowledgeBaseArticle.article?.status === GKnowledgeBaseArticleStatusItem.Deleted) {
        progress({
          type: "positive",
          message: t("kb.shared.deleteToastSuccessful"),
        });

        emitter.emit("knowledgebase-article-deleted", props.id);

        closeDrawer();
        return;
      }

      throw new Error("Updated article was not deleted in mutation response");
    } catch (ex) {
      if (ex instanceof GraphQlUserError) {
        if (ex.error.code === GUserErrorCode.KnowledgeBaseArticleIsPublishing) {
          progress({
            type: "negative",
            message: t("kb.modify.isPublishingError"),
          });
          return;
        }
      }

      progress({
        type: "negative",
        message: t("kb.shared.deleteToastError"),
      });
    }
  });
}

function onCancel() {
  closeDrawer();
}

// function onShow() {
//   bind();
// }

function onHide() {
  // closeDrawer();
}

load();
</script>

<style lang="sass"></style>
