import { Ref, ref, watch } from "vue";

export function usePopup(
  items: Ref<unknown[]>,
  enterHandlerCallback: (selected: number) => void,
  otherKeyHandlerCallback?: (event: KeyboardEvent) => void
) {
  const selectedIndex = ref(0);

  watch(items, () => {
    selectedIndex.value = 0;
  });

  function onKeyDown({ event }: { event: KeyboardEvent }) {
    if (event.key === "ArrowUp") {
      upHandler();
      return true;
    }

    if (event.key === "ArrowDown") {
      downHandler();
      return true;
    }

    if (event.key === "Enter") {
      enterHandler();
      return true;
    }

    if (otherKeyHandlerCallback) {
      otherKeyHandlerCallback(event);
    }

    return false;
  }

  function upHandler() {
    selectedIndex.value = (selectedIndex.value + items.value.length - 1) % items.value.length;
  }

  function downHandler() {
    selectedIndex.value = (selectedIndex.value + 1) % items.value.length;
  }

  function enterHandler() {
    // selectItem(selectedIndex.value);
    enterHandlerCallback(selectedIndex.value);
  }

  return { selectedIndex, onKeyDown };
}
