<template>
  <q-list bordered>
    <q-item
      v-for="option in options"
      :key="option.id"
      class="q-my-sm"
      clickable
      v-ripple
      :active="option.ui.selected"
      @click="onClick(option.ui.index)"
    >
      <template v-if="option.id === 'new'">
        <q-item-section id="create-address-section">
          <q-item-label>
            <div v-if="!option.ui.selected">{{ $t("common.addNew") }}</div>
            <div v-else>
              <FormKit
                v-if="option.addressType === GInputContactAddressTypeItem.EmailAddress"
                type="email"
                v-model="newAddressValue"
                :label="$t('conversation.actions.transfer.addEmailInputLabel')"
                :help="$t('conversation.actions.transfer.addEmailInputHelp')"
                validation="required|email"
                :placeholder="$t('conversation.actions.transfer.addEmailInputPlaceholder')"
                id="new"
                ref="input"
                autofocus
                validation-visibility="dirty"
              />
              <FormKit
                v-else-if="option.addressType === GInputContactAddressTypeItem.PhoneNumber"
                type="tel"
                v-model="newAddressValue"
                id="new"
                :label="$t('conversation.actions.transfer.addPhoneInputLabel')"
                validation="required|phone"
                :help="$t('conversation.actions.transfer.addPhoneInputHelp')"
                ref="input"
                validation-visibility="dirty"
                autofocus
              />
            </div>
          </q-item-label>
        </q-item-section>
      </template>
      <template v-else>
        <q-item-section>
          <q-item-label>{{ option.address }}</q-item-label>
        </q-item-section>

        <q-item-section side>
          <div v-if="option.id === currentAddressId">{{ $t("common.current") }}</div>
        </q-item-section>
      </template>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from "vue";
import { useShortcut } from "src/composables/useShortcut";
import { useI18n } from "vue-i18n";
import { ConversationTransferAddressStore } from "./conversation-transfer-address-store";
import { GInputContactAddressTypeItem, GUserErrorCode } from "src/generated/graphql";
import { FormKitNode } from "@formkit/core";
import { getNode } from "@formkit/core";
import { GraphQlUserError, useGraphqlSdk } from "src/graphql/graphql-client";
import { useQuasar } from "quasar";

const props = defineProps({
  store: {
    type: Object as PropType<ConversationTransferAddressStore>,
    required: true,
  },
  contactId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "selected", contactAddressId: string): void;
}>();

const { t } = useI18n();
const $q = useQuasar();

const options = props.store.getList();
const newAddressValue = ref<string>("");
const input = ref<FormKitNode>();
const sdk = useGraphqlSdk();

const currentAddressId = computed(() => props.store.getState().currentAddressId);

const onClick = async (index: number) => {
  props.store.select(index);
  await selectAddress();
};

const selectAddress = async () => {
  console.log("onEnter");
  const selected = props.store.getSelectedItem();

  if (selected) {
    if (selected.id === "new") {
      const node = getNode("new");

      document.getElementById("create-address-section")?.getElementsByTagName("input")?.[0]?.focus();

      if (node?.context?.state.valid) {
        await createContactAddress(node.value as string, selected.addressType);
      } else {
        console.log("Not valid");
      }
    } else {
      complete(selected.id);
    }
  }
};

const createContactAddress = async (address: string, type: GInputContactAddressTypeItem) => {
  const progress = $q.notify({
    type: "ongoing",
    message: t("conversations.transferDialog.creatingContactProgressText"),
  });

  try {
    const res = await sdk.CreateContactAddress({
      input: {
        address,
        type,
        contactId: props.contactId,
      },
    });

    if (!res.createContactAddress.contactAddress?.id) {
      throw new Error("Got no id from created contact address");
    }

    progress({
      type: "positive",
      message: t("conversations.transferDialog.contactAddressCreatedSuccess"),
    });

    complete(res.createContactAddress.contactAddress?.id);
  } catch (ex) {
    if (ex instanceof GraphQlUserError) {
      if (ex.error.code === GUserErrorCode.ContactAddressConflict) {
        //const contactAddressConflict = ex.error as GContactAddressConflictError;
        progress({
          type: "negative",
          message: t("conversations.transferDialog.contactAddressConflictError"),
        });
        return;
      }
    }

    progress({
      type: "negative",
      message: t("conversations.transferDialog.contactAddressCreateError"),
    });
    console.warn("error creating address", ex);
  }
};

const complete = (contactAddressId: string) => {
  emit("selected", contactAddressId);
};

useShortcut(
  "conversationTransferAddressSelect",
  [
    {
      key: "down",
      callback: () => props.store.selectNext(),
      sendToCommandPalette: false,
    },
    {
      key: "up",
      callback: () => props.store.selectPrevious(),
      sendToCommandPalette: false,
    },
    {
      key: "enter",
      callback: async () => selectAddress(),
      sendToCommandPalette: false,
    },
    {
      key: "esc",
      callback: async () => emit("cancel"),
      sendToCommandPalette: false,
    },
  ],
  {
    newShortcutStack: true,
  },
);
</script>

<style scoped lang="scss"></style>
