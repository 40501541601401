<template>
  <div class="px-2">
    <agent-notification-list @unread-count="unreadCount = $event" />
  </div>

  <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`" v-if="unreadCount > 0">
    <s-drawer-btn
      :label="$t('agentNotificationList.markAllAsRead')"
      primary
      @click="markAllAsRead"
      :loading="markingAllAsRead"
    />
  </Teleport>
</template>

<script setup lang="ts">
import AgentNotificationList from "src/features/agent-notification/agent-notification-list/AgentNotificationList.vue";
import { drawerRef } from "src/composables/useGlobalDrawer";
import { ref } from "vue";
import SDrawerBtn from "src/components/SDrawerBtn.vue";
import emitShortcut from "src/shared/emit-shortcut";
import { ShortcutItem } from "src/shared/shortcut-item";

const markingAllAsRead = ref(false);
const unreadCount = ref(0);

const markAllAsRead = () => {
  markingAllAsRead.value = true;
  emitShortcut(ShortcutItem.AgentNotificationMarkAllAsRead);

  setTimeout(() => {
    markingAllAsRead.value = false;
  }, 1000);
};
</script>

<style lang="sass"></style>
