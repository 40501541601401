<template>
  <q-dialog ref="dialogRef" position="top" @hide="onDialogHide">
    <div
      class="mx-auto w-full mt-24 max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
    >
      <FocusTrap>
        <Combobox @update:modelValue="onSelect">
          <div class="relative border-b-gray-100 border flex flex-nowrap">
            <q-icon
              size="sm"
              :name="searching ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-search'"
              class="pointer-events-none absolute left-4 top-3 text-gray-400"
            />
            <ComboboxInput
              class="h-12 w-full border-0 outline-none bg-transparent pl-14 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              :placeholder="t('search.inputPlaceholder')"
              @change="query = $event.target.value"
            />

            <!-- <Listbox>
              <ListboxButton>
                <div
                  class="rounded-md border-gray-200 my-2 mx-2 px-2 py-1 bg-gray-100 flex flex-nowrap items-center space-x-1"
                >
                  <span>Filter</span>
                  <q-icon class="text-gray-400" name="fa-solid fa-caret-down" />
                </div>
              </ListboxButton>
              <ListboxOptions>
                <ListboxOption :value="'contacts'"> Contacts </ListboxOption>
                <ListboxOption :value="'messages'"> Messages </ListboxOption>
              </ListboxOptions>
            </Listbox>-->
          </div>

          <ComboboxOptions
            v-if="hasResults"
            static
            class="max-h-96 transform-gpu scroll-py-12 overflow-y-auto p-3 option-container space-y-4"
          >
            <li v-if="contactResults.length > 0">
              <SearchResultsList :categoryName="$t('search.contacts')" :results="contactResults" />
            </li>
            <li v-if="conversationResults.length > 0">
              <SearchResultsList :categoryName="$t('search.conversations')" :results="conversationResults" />
            </li>
            <li v-if="timelineResults.length > 0">
              <SearchResultsList :categoryName="$t('search.messages')" :results="timelineResults" />
            </li>
            <li v-if="kbResults.length > 0">
              <SearchResultsList :categoryName="$t('search.kbArticle')" :results="kbResults" />
            </li>
          </ComboboxOptions>

          <div
            v-if="query !== '' && !hasResults && !searching && hasFirstResult"
            class="px-6 py-14 text-center text-sm sm:px-14"
          >
            <!-- <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" /> -->
            <q-icon name="fa-light fa-exclamation-circle" size="md" class="mx-auto text-gray-400" />
            <p class="mt-4 font-semibold text-gray-900">{{ $t("search.noResultsHeader") }}</p>
            <p class="mt-2 text-gray-500">{{ $t("search.noResultsDescription") }}</p>
          </div>
        </Combobox>
      </FocusTrap>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Combobox, ComboboxInput, ComboboxOptions, FocusTrap } from "@headlessui/vue";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { watchDebounced } from "@vueuse/core";
import { useRouter } from "vue-router";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { useI18n } from "vue-i18n";
import { useDrawer } from "src/composables/useDrawers";
import { SearchResultItem } from "./SearchResultItem";
import SearchResultsList from "./SearchResultsList.vue";
import { stripHtml } from "src/shared/html-utils";

defineEmits([...useDialogPluginComponent.emits]);
const sdk = useGraphqlSdk();

const { dialogRef, onDialogCancel, onDialogHide } = useDialogPluginComponent();
const router = useRouter();
const $q = useQuasar();
const { t } = useI18n();
const { showContactDisplayDrawer } = useDrawer(t);

const hasFirstResult = ref(false);

const contactResults = ref<SearchResultItem[]>([]);
const conversationResults = ref<SearchResultItem[]>([]);
const timelineResults = ref<SearchResultItem[]>([]);
const kbResults = ref<SearchResultItem[]>([]);

const query = ref("");
const searching = ref(false);
let prevQuery = "";

const resetResults = () => {
  contactResults.value = [];
  conversationResults.value = [];
  timelineResults.value = [];
  kbResults.value = [];
};

const hasResults = computed(() => {
  return (
    contactResults.value.length > 0 ||
    conversationResults.value.length > 0 ||
    timelineResults.value.length > 0 ||
    kbResults.value.length > 0
  );
});

watchDebounced(
  query,
  async () => {
    if (!query.value.trim()) {
      prevQuery = "";
      resetResults();
      return;
    }

    if (query.value.trim() === prevQuery) {
      return;
    }

    prevQuery = query.value.trim();

    searching.value = true;

    try {
      // TODO allow fetching more
      const [timelineResult, conversationResult, contactResult, kbResult] = await Promise.all([
        sdk.ConversationTimelineSearch({
          query: query.value,
          take: 20,
        }),
        sdk.ConversationSearch({
          query: query.value,
          take: 20,
        }),
        sdk.ContactSearch({
          query: query.value,
          take: 20,
        }),
        sdk.KnowledgeBaseSearch({
          query: query.value,
          take: 20,
        }),
      ]);

      hasFirstResult.value = true;
      searching.value = false;
      resetResults();

      for (const res of kbResult.searchKnowledgeBase.results ?? []) {
        if (!res.document?.id) {
          continue;
        }

        kbResults.value.push({
          id: res.document?.id,
          name: res.document.title ?? "",
          description: stripHtml(res.document.solution) ?? "",
          url: router.resolve({
            name: "kb-modify",
            params: {
              id: res.document.id,
            },
          }),
        });
      }

      for (const res of timelineResult.searchConversationTimeline.results ?? []) {
        if (!res.document?.id) {
          continue;
        }

        timelineResults.value.push({
          id: res.document?.id,
          name: t("search.message"),
          description: res.document.textPlain ?? "",
          url: router.resolve({
            name: "conversation",
            params: {
              id: res.document.conversationId,
            },
          }),
        });
      }

      for (const res of conversationResult.searchConversation.results ?? []) {
        if (!res.document?.id) {
          continue;
        }

        console.log(res.document.contact);

        let resolvedName = resolveDisplayName(res.document.contact?.firstName, res.document.contact?.lastName);

        if (!resolvedName || resolvedName === "") {
          resolvedName = `<${t("contacts.unknownContact").toLowerCase()}>`;
        }

        conversationResults.value.push({
          id: res.document?.id,
          name: res.document.description ?? `<${t("conversation.common.noSubject").toLowerCase()}>`,
          description: resolvedName,
          url: router.resolve({
            name: "conversation",
            params: {
              id: res.document.id,
            },
          }),
        });
      }

      for (const res of contactResult.searchContact.results ?? []) {
        if (!res.document?.id) {
          continue;
        }

        let description = "";

        if (res.document.addresses?.length) {
          res.document.addresses.sort((a, b) => (a.addressType > b.addressType ? 1 : -1));

          description = res.document.addresses
            .slice(0, 2)
            .map(i => i.address)
            .join(", ");

          const diff = res.document.addresses.length - 2;

          if (diff > 1) {
            description += ` ${t("common.andNOthers", diff)}`;
          }
        }

        if (!description) {
          description = `<${t("search.noAddresses")}>`;
        }

        contactResults.value.push({
          id: res.document?.id,
          name:
            resolveDisplayName(res.document.firstName, res.document.lastName) ??
            `<${t("contacts.unknownContact").toLowerCase()}>`,
          description,
          onClick: () => {
            if (res.document?.id) {
              showContactDisplayDrawer(res.document.id);
            }
          },
        });
      }
    } catch (err) {
      console.error(err);
      $q.notify({
        type: "negative",
        message: t("common.genericErrorOccured"),
      });
      searching.value = false;
      resetResults();
    }
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
);

const onSelect = async (item: SearchResultItem | undefined) => {
  if (item) {
    if (item.url) {
      await router.push(item.url);
      onDialogCancel();
    } else if (item.onClick) {
      item.onClick();
      onDialogCancel();
    }
  }
};
</script>

<style lang="scss"></style>
