<template>
  <div>
    <div v-if="showName" class="sa-conversation-timeline-contact-message__name mb-1 text-sm">
      {{ contactName }}
    </div>

    <div
      class="sa-conversation-timeline-contact-message__block px-5 py-3 rounded-lg w-full flex justify-between items-center"
      :class="{
        'ring-2 ring-offset-2': selected,
      }"
    >
      <div class="sa-conversation-timeline-contact-message__block-content leading-6 max-w-full text-sm pr-4 space-y-1">
        <div v-html="contactMessage.content"></div>

        <div v-if="contactMessage.contentIsTruncated" class="sa-conversation-timeline-contact-message__block-more">
          {{ $t("conversation.display.textIsTruncated") }}
        </div>
      </div>

      <div class="sa-conversation-timeline-contact-message__block-timestamp ml-auto text-sm place-self-end">
        <updated-ago :updated="timestamp" />
      </div>
    </div>

    <conversation-attachment-grid :attachments="contactMessage.attachments" />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { GConversationContactMessage } from "src/generated/graphql";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import ConversationAttachmentGrid from "src/features/conversation/conversation-display/ConversationAttachmentGrid.vue";
import { GInputContactAddressTypeItem } from "src/generated/graphql";
import { resolveDisplayName } from "../../../shared/display-name-helper";

const showAddressForContactAddressTypes: Array<GInputContactAddressTypeItem> = [
  GInputContactAddressTypeItem.EmailAddress,
  GInputContactAddressTypeItem.PhoneNumber,
];

const props = defineProps({
  timestamp: {
    type: String,
    required: true,
  },

  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },

  showName: {
    type: Boolean,
    required: true,
    default: () => true,
  },

  contactMessage: {
    type: Object as PropType<GConversationContactMessage>,
    required: true,
  },
});

const contactName = computed(() => {
  if (!props.showName) {
    return null;
  }

  if (showAddressForContactAddressTypes.includes(props.contactMessage.contactAddress.addressType)) {
    return props.contactMessage.contactAddress.address;
  }

  return resolveDisplayName(props.contactMessage.contact.firstName, props.contactMessage.contact.lastName);
});
</script>
