<template>
  <template v-if="isLoaded">
    <header class="sticky top-0 z-10 sa-conversation-display__toolbar pb-4">
      <conversation-toolbar :drawer-mode="drawerMode" :conversation-store="conversationStore" />
    </header>

    <main class="py-4 space-y-6">
      <conversation-timeline :drawer-mode="drawerMode" :conversation-store="conversationStore" />

      <conversation-agent-typing-message :conversation-id="id" />
      <conversation-contact-typing-message :conversation-id="id" />
    </main>

    <footer class="sticky bottom-0 pt-1 pb-2 sa-conversation-display__footer" v-if="showAddMessage">
      <agent-message-editor
        :conversation-id="id"
        @close="showAddMessage = false"
        :content="agentMessageEditorContent"
        :linked-to-timeline-id="agentMessageEditorLinkedToTimelineId"
        :intro-template-placeholder-content="introTemplatePlaceholderContent"
        :current-channel="conversationStore.getState().conversation?.currentChannel"
      />
    </footer>

    <footer ref="footer" class="sticky bottom-0 pt-1 pb-2 sa-conversation-display__footer" v-if="showAddComment">
      <agent-comment-editor
        :conversation-id="id"
        @close="showAddComment = false"
        :linked-to-timeline-id="agentCommentEditorLinkedToTimelineId"
      />
    </footer>
  </template>
</template>

<script async setup lang="ts">
import { Shortcut, useShortcut } from "src/composables/useShortcut";
import { useI18n } from "vue-i18n";
import { nextTick, ref, toRefs, computed } from "vue";
import AgentCommentEditor from "src/features/conversation/agent-comment-editor/AgentCommentEditor.vue";
// import { conversationStore } from "src/features/conversation/conversation-display/conversation-store";
import ConversationToolbar from "src/features/conversation/conversation-display/ConversationToolbar.vue";
import ConversationTimeline from "src/features/conversation/conversation-display/ConversationTimeline.vue";
import { ShortcutItem } from "src/shared/shortcut-item";
import AgentMessageEditor from "src/features/conversation/agent-message-editor/AgentMessageEditor.vue";
import { useQuasar } from "quasar";
import ConversationBotAssistantModeDialog from "src/features/conversation/bot-assistant-mode/ConversationBotAssistantModeDialog.vue";
import { useRoute, useRouter } from "vue-router";
import { modifyConversationStatusCommand } from "src/features/conversation/conversation-display/api-commands";
import { GConversationStatusItem } from "src/generated/graphql";
import ConversationAgentTypingMessage from "src/features/conversation/conversation-display/ConversationAgentTypingMessage.vue";
import { useWatchConversationHandler } from "src/features/conversation/conversation-display/useWatchConversationHandler";
import { closeDrawer } from "src/composables/useGlobalDrawer";
import { useDrawer } from "src/composables/useDrawers";
import { isPageNearBottom, scrollToBottomSmooth } from "src/shared/dom-utils";
import ConversationContactTypingMessage from "src/features/conversation/conversation-display/ConversationContactTypingMessage.vue";
import ConversationSnoozeDialog from "src/features/conversation/conversation-snooze/ConversationSnoozeDialog.vue";
import { useClipboard } from "src/composables/useClipboard";
import { useStore2 } from "src/composables/useStore2";
import { ConversationStore } from "src/features/conversation/conversation-display/conversation-store";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { useEmitterOn } from "src/composables/useEmitterOn";
import ConversationTransferDialog from "../conversation-transfer/ConversationTransferDialog.vue";
import { PlaceholderReplacementData } from "src/features/tip-tap-editor/extensions/template/template-intro-suggestion";
import { TemplatePlaceholder } from "src/features/tip-tap-editor/extensions/template/template-placeholder";
import { agentSettingsStore } from "src/stores/agent-settings-global-store";
import { useDialog } from "src/composables/useDialog";
import { useFeatureEntitlements } from "@frontegg/vue";
import { Entitlements } from "src/shared/entitlements";
import { TrackedEvent } from "src/composables/useTracking";
import { useTracking } from "../../../composables/useTracking";
import { GAutoAdvanceOptionItem } from "../../../generated/graphql";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  drawerMode: {
    type: Boolean,
    required: false,
  },
});

const { id, drawerMode } = toRefs(props);

const { store: conversationStore, isLoaded } = useStore2(ConversationStore);
const { trackEvent } = useTracking();
const agentSettingsStoreState = agentSettingsStore.getState();
const aiEntitlement = useFeatureEntitlements(Entitlements.AiAssistant);

const { t } = useI18n();
const $q = useQuasar();
const router = useRouter();
const route = useRoute();
const footer = ref<HTMLElement>();
const { showContactDisplayDrawer } = useDrawer(t);
const { copyToClipboard } = useClipboard(t);
const sdk = useGraphqlSdk();
const { showAssignDialog: extShowAssignDialog } = useDialog();

const showAddComment = ref(false);
const showAddMessage = ref(false);
const agentMessageEditorContent = ref("");
const agentMessageEditorLinkedToTimelineId = ref();
const agentCommentEditorLinkedToTimelineId = ref();

useWatchConversationHandler(id.value);

const introTemplatePlaceholderContent = computed<PlaceholderReplacementData>(() => {
  return {
    [TemplatePlaceholder.AgentName]: agentSettingsStoreState.firstName,
    [TemplatePlaceholder.ContactName]: conversationStore.getState().conversation?.contact.firstName ?? "",
  };
});

function onEscape() {
  if (drawerMode.value) {
    closeDrawer();
  } else {
    void router.push({ name: "conversation-list" });
  }
}

const shortcuts = computed(() => {
  const temp: Shortcut[] = [
    {
      key: "down",
      callback: () => conversationStore.selectNext(true),
      description: t("conversations.singleView.timeline.goToNextMessage"),
      allowRepeat: true,
    },
    {
      key: "up",
      callback: () => conversationStore.selectPrevious(true),
      description: t("conversations.singleView.timeline.goToPreviousMessage"),
      allowRepeat: true,
    },
    {
      key: "escape",
      callback: () => onEscape(),
      description: t("conversations.singleView.goBack"),
      sendToCommandPalette: true,
      shortcut: ShortcutItem.ConversationGoBack,
    },
    {
      key: "c",
      callback: () => addComment(),
      description: t("conversations.singleView.addComment"),
      shortcut: ShortcutItem.CreateConversationAgentComment,
    },
    {
      key: "enter",
      callback: () => addMessage(),
      description: t("conversations.singleView.reply"),
      shortcut: ShortcutItem.CreateConversationAgentMessage,
    },
    {
      key: undefined,
      callback: () => suggestKnowledgebaseArticle(),
      description: t("conversations.singleView.suggestKnowledgeBaseArticle"),
      shortcut: ShortcutItem.SuggestKnowledgeBaseArticleFromConversation,
    },
    {
      key: undefined,
      callback: () => createSummary(),
      description: t("conversations.summary.commandPaletteText"),
      shortcut: ShortcutItem.SummarizeConversation,
    },
  ];

  if (aiEntitlement.value.isEntitled) {
    temp.push({
      key: "b",
      callback: () => showBotAssistantModeDialog(),
      description: t("conversations.singleView.botAssistantMode"),
      shortcut: ShortcutItem.ConversationShowBotAssistantMode,
    });
  }

  if (!drawerMode.value) {
    temp.push({
      key: "n",
      callback: () => placeholder,
      description: t("conversations.singleView.newConversation"),
      shortcut: ShortcutItem.CreateNewConversation,
    });
  }

  temp.push(
    {
      key: "z",
      callback: () => showSnoozeDialog(),
      description: t("conversations.singleView.snooze"),
      shortcut: ShortcutItem.ConversationSnooze,
    },
    {
      key: "a",
      callback: () => showAssignDialog(),
      description: t("conversations.singleView.assign"),
      shortcut: ShortcutItem.ConversationAssign,
    },
    {
      key: "f",
      callback: () => showTransferDialog(),
      description: t("conversations.singleView.transfer"),
      shortcut: ShortcutItem.ConversationChangeChannel,
      sendToCommandPalette: true,
    },
  );

  if (!drawerMode.value) {
    temp.push({
      key: "i",
      callback: () => showContact(),
      description: t("conversations.singleView.showContact"),
      shortcut: ShortcutItem.ConversationShowContact,
    });
  }

  temp.push({
    key: "mod+/",
    callback: async () => await copyToClipboard(window.location.href),
    description: t("conversations.singleView.copyUrl"),
    shortcut: ShortcutItem.ConversationCopyUrl,
  });

  if (conversationStore.getState().conversation) {
    temp.push(
      conversationStore.getState().conversation?.status === GConversationStatusItem.Archived
        ? {
            key: "d",
            callback: () => unArchiveConversation(),
            description: t("conversations.singleView.markAsNotDone"),
            shortcut: ShortcutItem.ConversationUnArchive,
          }
        : {
            key: "d",
            callback: () => archiveConversation(),
            description: t("conversations.singleView.markAsDone"),
            shortcut: ShortcutItem.ConversationArchive,
          },
    );
  }

  return temp;
});

useShortcut("conversation-display", shortcuts, {
  newShortcutStack: drawerMode.value,
  includeGlobal: true,
});

useEmitterOn("bot-suggestion-agent-edit", async data => {
  if (data.conversationId === props.id) {
    agentMessageEditorContent.value = data.content;
    agentMessageEditorLinkedToTimelineId.value = data.linkedToTimelineId;
    await addMessage();
  }
});

useEmitterOn("conversation-timeline-item-comment", async timelineId => {
  await addComment(timelineId);
});

useEmitterOn("conversation-timeline-created", async data => {
  if (
    agentMessageEditorLinkedToTimelineId.value &&
    data.timeline.linkedToTimelineId === agentMessageEditorLinkedToTimelineId.value
  ) {
    agentMessageEditorLinkedToTimelineId.value = null;
  }

  if (
    agentCommentEditorLinkedToTimelineId.value &&
    data.timeline.linkedToTimelineId === agentCommentEditorLinkedToTimelineId.value
  ) {
    agentCommentEditorLinkedToTimelineId.value = null;
  }
});

const addComment = async (linkedToTimelineId?: string) => {
  if (linkedToTimelineId) {
    agentCommentEditorLinkedToTimelineId.value = linkedToTimelineId;
  }

  const nearBottom = isPageNearBottom();
  showAddMessage.value = false;
  showAddComment.value = true;

  await nextTick(() => {
    if (nearBottom) {
      scrollToBottomSmooth();
    }
  });
};

const addMessage = async () => {
  const nearBottom = isPageNearBottom();
  showAddComment.value = false;
  showAddMessage.value = true;
  await nextTick(() => {
    if (nearBottom) {
      scrollToBottomSmooth();
    }
  });
};

const createSummary = async () => {
  const progress = $q.notify({
    type: "ongoing",
    message: t("conversations.summary.generatingSummaryProgressText"),
  });

  try {
    await sdk.CreateConversationSummary({
      conversationId: id.value,
    });

    progress({
      type: "positive",
      message: t("conversations.summary.summaryCreatedText"),
    });

    trackEvent(TrackedEvent.RequestedConversationSummary);
  } catch (err) {
    console.warn(err);
    progress({
      type: "negative",
      message: t("conversations.summary.summaryErrorText"),
    });
  }
};

const suggestKnowledgebaseArticle = async () => {
  const progress = $q.notify({
    type: "ongoing",
    message: t("kb.conversationSuggestion.progressToastMessage"),
  });

  try {
    const result = await sdk.CreateKnowledgeBaseArticleSuggestionsFromConversation({
      input: {
        conversationId: id.value,
      },
    });

    const suggestionsCount = result.createKnowledgeBaseArticleSuggestionsFromConversation.articles?.length ?? 0;
    const suggestions = result.createKnowledgeBaseArticleSuggestionsFromConversation.articles ?? [];

    progress({
      type: suggestionsCount > 0 ? "positive" : "info",
      message: t("kb.conversationSuggestion.successToastMessage", suggestionsCount),
      timeout: 10_000,
      actions:
        suggestionsCount > 0
          ? [
              {
                label: "See suggestions",
                color: "white",
                handler: async () => {
                  await router.push({ name: "kb-list-suggestions" });
                },
              },
            ]
          : [],
    });

    for (const article of suggestions) {
      $q.notify({
        type: "info",
        message: `<div class="max-w-[600px]">
          <div><b>${article.title}</b></div>
        </div>
        `,
        multiLine: true,
        group: false,
        timeout: 0,
        html: true,
        actions: [
          {
            label: "Edit article",
            color: "white",
            handler: async () => {
              await router.push({
                name: "kb-modify",
                params: {
                  id: article.id,
                },
              });
            },
          },
        ],
      });
    }
  } catch (err) {
    console.warn(err);
    progress({
      type: "negative",
      message: t("kb.conversationSuggestion.errorToastMessage"),
    });
  }
};

function placeholder() {
  //
}

const archiveConversation = async () => {
  const currentRoute = { ...route };
  let wasRedirected = false;

  const shouldRedirectTo = workflowShouldRedirectTo();

  // Optimistic redirect -- redirect back if the update fails
  if (shouldRedirectTo) {
    wasRedirected = true;
    await router.push(shouldRedirectTo);
  }

  const progress = $q.notify({
    type: "ongoing",
    message: t("conversation.actions.archive.progressNotification"),
  });

  try {
    await modifyConversationStatusCommand(id.value, GConversationStatusItem.Archived);
    progress({
      type: "positive",
      message: t("conversation.actions.archive.successNotification"),
      // actions: [
      //   {
      //     label: 'Undo',
      //     color: 'yellow',
      //     handler: () => {
      //       modifyConversationStatusCommand(
      //         props.id,
      //         GConversationStatusItem.Active
      //       )
      //     }
      //   }
      // ]
    });
  } catch (err) {
    progress({
      type: "negative",
      message: t("conversations.singleView.couldNotArchiveTitle"),
    });

    if (wasRedirected) {
      await router.push(currentRoute);
    }
  }
};

const unArchiveConversation = () => {
  modifyConversationStatusCommand(id.value, GConversationStatusItem.Active)
    .then(() => {
      $q.notify({
        progress: true,
        message: t("conversation.actions.unArchive.successNotification"),
        // actions: [
        //   {
        //     label: 'Undo',
        //     color: 'yellow',
        //     handler: () => {
        //       modifyConversationStatusCommand(
        //         props.id,
        //         GConversationStatusItem.Active
        //       )
        //     }
        //   }
        // ]
      });
    })
    .catch(err => {
      $q.dialog({
        title: t("conversations.singleView.couldNotUnArchiveTitle"),
        message: err.message,
      });
    });
};

const showContact = () => {
  if (isLoaded) {
    showContactDisplayDrawer(conversationStore.getState().conversation?.contact.id as string);
  }
};

const showBotAssistantModeDialog = () => {
  $q.dialog({
    component: ConversationBotAssistantModeDialog,
    componentProps: {
      conversationId: id.value,
      currentMode: conversationStore.getState().conversation?.botAssistantMode,
    },
  });
};

const workflowShouldRedirectTo = () => {
  if (props.drawerMode) {
    return null;
  }

  if (agentSettingsStore.getState().settings?.autoAdvance?.action === GAutoAdvanceOptionItem.ReturnToConversationList) {
    return router.resolve({ name: "conversation-list-active" });
  }

  return null;
};

const showSnoozeDialog = () => {
  $q.dialog({
    component: ConversationSnoozeDialog,
    componentProps: {
      conversationId: id.value,
    },
  }).onOk(async () => {
    const shouldRedirectTo = workflowShouldRedirectTo();
    if (shouldRedirectTo) {
      await router.push(shouldRedirectTo);
    }
    trackEvent(TrackedEvent.SnoozedConversation);
  });
};

const showAssignDialog = () => {
  const state = conversationStore.getState();

  if (!state.conversationId) {
    console.warn("Missing conversation id in state");
    return;
  }

  extShowAssignDialog([state.conversationId], state.conversation?.assignee?.id, state.conversation?.team?.id);
};

const showTransferDialog = () => {
  const state = conversationStore.getState();

  $q.dialog({
    component: ConversationTransferDialog,
    componentProps: {
      conversationId: state.conversationId,
      currentChannel: state.conversation?.currentChannel,
      currentAddressId: state.conversation?.currentContactAddress?.id,
      contactId: state.conversation?.contact.id,
    },
  });
};

// onUnmounted(async () => {
//   conversationStore.unmount();
// });

try {
  await conversationStore.load(id.value);
} catch (e) {
  $q.notify({
    type: "negative",
    message:
      "An error occured while trying to fetch the requested conversation. Please make sure you have access to it.",
  });

  await router.push({ name: "conversation-list" });
}
</script>
