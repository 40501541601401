// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useGlobalDrawer } from "./useGlobalDrawer";
import AgentCreateDrawer from "src/features/settings/agents/agent-create/AgentCreateDrawer.vue";
import TeamCreateDrawer from "src/features/settings/teams/team-create/TeamCreateDrawer.vue";
import AgentModifyDrawer from "src/features/settings/agents/agent-modify/AgentModifyDrawer.vue";
import TeamModifyDrawer from "src/features/settings/teams/team-modify/TeamModifyDrawer.vue";
import EmailCreateDrawer from "src/features/settings/channels/email/email-create/EmailCreateDrawer.vue";
import SmsSubNumberChannelCreateDrawer from "src/features/settings/channels/sms/sms-subnumber-create/SmsSubnumberCreateDrawer.vue";
import SmsVonageChannelCreateDrawer from "src/features/settings/channels/sms/sms-vonage-create/SmsVonageCreateDrawer.vue";
import KnowledgeBaseArticleDisplayDrawer from "src/features/knowledgebase/kb-article-display/KnowledgeBaseArticleDisplayDrawer.vue";
import ContactDisplayDrawer from "src/features/contact/contact-display/ContactDisplayDrawer.vue";
import ContactCreateDrawer from "src/features/contact/contact-create/ContactCreateDrawer.vue";
import ConversationDisplayDrawer from "src/features/conversation/conversation-display/ConversationDisplayDrawer.vue";
import { Composer } from "vue-i18n";
import AgentNotificationListDrawer from "src/features/agent-notification/agent-notification-list/AgentNotificationListDrawer.vue";
import KnowledgeBaseArticleCreateDrawer from "src/features/knowledgebase/kb-article-create/KnowledgeBaseArticleCreateDrawer.vue";
import SnippetDisplayDrawer from "src/features/conversation-snippets/snippet-display/SnippetDisplayDrawer.vue";

export function useDrawer($t: Composer["t"]) {
  const { openDrawer } = useGlobalDrawer();

  const showCreateAgentDrawer = () => {
    openDrawer($t("settings.agents.inviteHeader"), AgentCreateDrawer, {}, "sm");
  };

  const showModifyAgentDrawer = (id: string) => {
    openDrawer($t("settings.agents.modifyAgent"), AgentModifyDrawer, { id }, "sm");
  };

  const showCreateTeamDrawer = () => {
    openDrawer($t("settings.teams.createTeam"), TeamCreateDrawer, {}, "sm");
  };

  const showModifyTeamDrawer = (id: string) => {
    openDrawer($t("settings.teams.editTeam"), TeamModifyDrawer, { id }, "sm");
  };

  const showCreateEmailChannelDrawer = () => {
    openDrawer($t("settings.channels.email.createHeader"), EmailCreateDrawer, {}, "md");
  };

  const showCreateSmsSubNumberChannelDrawer = () => {
    openDrawer($t("settings.channels.sms.createHeader"), SmsSubNumberChannelCreateDrawer, {}, "md");
  };

  const showCreateSmsVonageChannelDrawer = () => {
    openDrawer($t("settings.channels.sms.createHeader"), SmsVonageChannelCreateDrawer, {}, "md");
  };

  const showCreateContactDrawer = () => {
    openDrawer($t("contactSelect.createContact"), ContactCreateDrawer, {}, "md");
  };

  const showContactDisplayDrawer = (contactId: string) => {
    openDrawer($t("contacts.display.header"), ContactDisplayDrawer, { contactId }, "md");
  };

  const showConversationDisplayDrawer = (conversationId: string) => {
    openDrawer(null, ConversationDisplayDrawer, { id: conversationId }, "xl");
  };

  const showKnowledgeBaseArticleDisplayDrawer = (articleId: string) => {
    openDrawer($t("kb.previewArticle"), KnowledgeBaseArticleDisplayDrawer, { id: articleId }, "xl");
  };

  const showSnippetDisplayDrawer = (snippetId: string) => {
    openDrawer($t("conversation.snippets.previewSnippet"), SnippetDisplayDrawer, { id: snippetId }, "md");
  };

  const showAgentNotificationListDrawer = () => {
    openDrawer($t("agentNotificationList.drawer.header"), AgentNotificationListDrawer, {}, "md");
  };

  const showKnowledgeBaseCreateArticleDrawer = (title = "", solution = "") => {
    openDrawer(
      null,
      KnowledgeBaseArticleCreateDrawer,
      {
        drawerMode: true,
        title,
        solution,
      },
      "md",
    );
  };

  return {
    showCreateAgentDrawer,
    showModifyAgentDrawer,
    showCreateTeamDrawer,
    showModifyTeamDrawer,
    showCreateEmailChannelDrawer,
    showCreateContactDrawer,
    showContactDisplayDrawer,
    showConversationDisplayDrawer,
    showKnowledgeBaseArticleDisplayDrawer,
    showAgentNotificationListDrawer,
    showCreateSmsSubNumberChannelDrawer,
    showCreateSmsVonageChannelDrawer,
    showKnowledgeBaseCreateArticleDrawer,
    showSnippetDisplayDrawer,
  };
}
