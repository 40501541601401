<template>
  <div>
    <div class="cursor-pointer">
      <AgentAvatarWithStatus :agent-id="agentId" size="lg" />
    </div>
    <q-menu
      transition-show="jump-down"
      transition-hide="jump-up"
      ref="menu"
      anchor="bottom right"
      self="top right"
      :offset="[0, 8]"
    >
      <q-list class="whitespace-nowrap">
        <q-item>
          <q-item-section>
            <q-item-label class="muted text-xs font-semibold"> {{ authState.user?.name }} </q-item-label>
            <q-item-label caption class="text-xs muted">{{ authState.user?.email }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item clickable v-close-popup @click="setAwayStatus(true)" v-if="isAway === false">
          <q-item-section>
            <q-item-label>{{ $t("agent.awayStatus.setYourselfAsAway") }}</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="fa-thin fa-hand-wave" />
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="setAwayStatus(false)" v-if="isAway === true">
          <q-item-section>
            <q-item-label>{{ $t("agent.awayStatus.setYourselfAsAvailable") }}</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="fa-thin fa-hand-wave" />
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="logout">
          <q-item-section>
            <q-item-label>{{ $t("common.logOut") }}</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-icon name="fa-thin fa-arrow-right-from-bracket" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import AgentAvatarWithStatus from "src/features/conversation/team-or-agent-avatar/TeamOrAgentAvatar.vue";
import { agentSettingsStore } from "src/stores/agent-settings-global-store";
import { computed, ref } from "vue";
import { QMenu, useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { useAuthState } from "@frontegg/vue";
import { useI18n } from "vue-i18n";
import { useGraphqlSdk } from "../graphql/graphql-client";
import { agentWithStatusStore } from "../features/agent/agent-avatar-with-status/agent-with-status-global-store";

const menu = ref<QMenu>();
const agentSettingsStoreState = agentSettingsStore.getState();
const agentWithStatusStoreState = agentWithStatusStore.getList();
const router = useRouter();
const authState = useAuthState();
const { t } = useI18n();
const $q = useQuasar();

const agentId = computed(() => agentSettingsStoreState.id);
const isAway = computed(() => agentWithStatusStoreState.find(i => i.id === agentId.value)?.away ?? undefined);

const logout = async () => {
  await router.push({
    path: authState.routes.logoutUrl,
  });
};

const setAwayStatus = async (value: boolean) => {
  const progress = $q.notify({
    type: "ongoing",
    message: value
      ? t("agent.awayStatus.isSettingAwayProgressText")
      : t("agent.awayStatus.isSettingAvailableProgressText"),
  });

  try {
    const sdk = useGraphqlSdk();
    await sdk.SetAgentAwayStatus({
      input: {
        input: {
          agentId: agentId.value,
          away: value,
        },
      },
    });

    progress({
      type: "positive",
      message: t("common.changesSavedLong"),
    });
  } catch (err) {
    console.warn(err);

    progress({
      type: "negative",
      message: t("common.saveChangesGenericError"),
    });
  }
};
</script>

<style scoped lang="scss"></style>
