/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import ArticleSuggestionList from "src/features/tip-tap-editor/extensions/knowledge-base-article-suggestion/ArticleSuggestionList.vue";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { SuggestionOptions } from "@tiptap/suggestion";
import { useDebounceFn } from "@vueuse/core";

export default function (): Omit<SuggestionOptions<any>, "editor"> {
  const sdk = useGraphqlSdk();

  const debouncedSearch = useDebounceFn(async (query: string) => {
    const res = await sdk.KnowledgeBaseSearch({
      query: query,
      take: 5,
    });

    return res.searchKnowledgeBase.results;
  }, 250);

  return {
    //char: "!!",

    items: async ({ query }: { query: string }) => {
      if (!query?.trim()) {
        return [null];
      }

      return debouncedSearch(query);
    },

    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          component = new VueRenderer(ArticleSuggestionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            maxWidth: 520,
          });
        },

        onUpdate(props: any) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return false;
          }

          return component.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
}
