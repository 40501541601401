import { Store } from "stores/store";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import {
  GConversationStatusItem,
  GOnActiveConversationCountConversationCreatedSubscription,
  GOnActiveConversationCountConversationCreatedSubscriptionVariables,
  GOnActiveConversationCountConversationStatusModifiedSubscription,
  GOnActiveConversationCountConversationStatusModifiedSubscriptionVariables,
  OnActiveConversationCountConversationCreatedDocument,
  OnActiveConversationCountConversationStatusModifiedDocument,
} from "src/generated/graphql";
import { isNullOrUndefined } from "src/shared/object-utils";

export interface ActiveConversationCountData {
  [key: string]: number;
}

export class ActiveConversationCountGlobalStore extends Store<ActiveConversationCountData> {
  protected data(): ActiveConversationCountData {
    return {};
  }

  protected setupSubscribe() {
    const me = agentSettingsStore.getState().id;
    this.addSubscription<
      GOnActiveConversationCountConversationStatusModifiedSubscription,
      GOnActiveConversationCountConversationStatusModifiedSubscriptionVariables
    >(OnActiveConversationCountConversationStatusModifiedDocument, {}, res => {
      const data = res.onMultiTenantConversationStatusModified;

      const toActive =
        data.fromStatus !== GConversationStatusItem.Active && data.toStatus === GConversationStatusItem.Active;
      const fromActive =
        data.fromStatus === GConversationStatusItem.Active && data.toStatus !== GConversationStatusItem.Active;
      const activeUnchanged =
        data.fromStatus === GConversationStatusItem.Active && data.toStatus === GConversationStatusItem.Active;
      const wasInList = data.fromAssignedAgentId === null || data.fromAssignedAgentId === me;
      const isInList = data.toAssignedAgentId === null || data.toAssignedAgentId === me;
      const wasNotInList = data.fromAssignedAgentId !== null && data.fromAssignedAgentId !== me;
      const isNotInList = data.toAssignedAgentId !== null && data.toAssignedAgentId !== me;

      if ((toActive && isInList) || (activeUnchanged && wasNotInList && isInList)) {
        if (data.tenantId in this.state) {
          this.state[data.tenantId]++;
        } else {
          console.log("Could not find tenant", data.tenantId, "in list of active conversation count");
        }
      }

      if ((fromActive && wasInList) || (activeUnchanged && wasInList && isNotInList)) {
        if (data.tenantId in this.state) {
          this.state[data.tenantId]--;
        } else {
          console.log("Could not find tenant", data.tenantId, "in list of active conversation count");
        }
      }
    });

    this.addSubscription<
      GOnActiveConversationCountConversationCreatedSubscription,
      GOnActiveConversationCountConversationCreatedSubscriptionVariables
    >(OnActiveConversationCountConversationCreatedDocument, {}, res => {
      const data = res.onMultiTenantConversationCreated;

      const toActive = data.status === GConversationStatusItem.Active;
      const isInList = isNullOrUndefined(data.assignee?.id) || data.assignee?.id === me;

      if (toActive && isInList) {
        if (data.tenantId in this.state) {
          this.state[data.tenantId]++;
        } else {
          console.log("Could not find tenant", data.tenantId, "in list of active conversation count");
        }
      }
    });
  }

  // protected setupEmitter() {
  //   emitter.on("active-conversation-count", count => {
  //     this.state.count = count;
  //   });
  // }

  countForTenant(tenantId: string) {
    if (!isNullOrUndefined(tenantId) && tenantId in this.state) {
      return this.state[tenantId];
    }

    return undefined;
  }
}

export const activeConversationCountStore = new ActiveConversationCountGlobalStore();
