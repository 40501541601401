<template>
  <div class="sa-editor-popup">
    <q-list>
      <template v-if="items.length">
        <q-item
          v-for="(item, index) in items"
          :key="index"
          v-ripple
          clickable
          class="sa-editor-popup__item"
          :class="{ 'sa-editor-popup__item--selected': index === selectedIndex }"
          @click="selectItem(index)"
          @mouseenter="selectedIndex = index"
        >
          <q-item-section side>{{ item.emoji }}</q-item-section>
          <q-item-section>:{{ item.name }}:</q-item-section>
        </q-item>
      </template>
      <template v-else>
        <q-item>No matching emojis found</q-item>
      </template>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { PropType, toRefs } from "vue";
import { usePopup } from "src/features/tip-tap-editor/composables/usePopup";

const props = defineProps({
  items: {
    // eslint-disable-next-line
    type: Object as PropType<any[]>,
    required: true,
  },

  command: {
    type: Function,
    required: true,
  },
});

const { items: popupItems } = toRefs(props);

const { onKeyDown, selectedIndex } = usePopup(popupItems, selectItem);

defineExpose({ onKeyDown });

function selectItem(index: number) {
  const item = popupItems?.value[index];

  if (item) {
    props.command({ name: item.name });
  }
}
</script>
