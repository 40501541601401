import { computed, reactive, readonly } from "vue";
import { DocumentNode } from "graphql/language";
import { subscribe } from "src/graphql/subscription-client";
import { emitter } from "boot/mitt";
import { MittEvents } from "src/shared/mitt-events";

interface StoreSettings {
  loaded: boolean;
}

type EventType = keyof MittEvents;
type Callback = () => void;

interface RegisteredMittEvent<T extends EventType> {
  type: T;
  callback: (data: MittEvents[T]) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class Store<T extends Object> {
  protected readonly state: T;
  protected readonly settings: StoreSettings;
  private subscriptions: Callback[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private registeredMittEvents: RegisteredMittEvent<any>[];

  constructor() {
    this.settings = reactive(this.settingsData()) as StoreSettings;
    const data = this.data();
    this.mittEvents();
    this.state = reactive(data) as T;
    this.subscriptions = [];
    this.registeredMittEvents = [];
    this.setup();
    this.load();
  }

  protected abstract data(): T;

  protected setup(): void {
    //
  }

  public getState(): T {
    return readonly(this.state) as T;
  }

  public setData(data: T) {
    // Breaks reactivity
    // this.state = reactive(data) as T;

    Object.assign(this.state, data);
    this.settings.loaded = true;
  }

  public clearState() {
    //const data = this.data();
    // const stateCopy = { ...this.state };

    // Breaks reactivity
    //this.state = reactive(data) as T;
    //this.settings = reactive(this.settingsData()) as StoreSettings;

    // Instead:
    Object.assign(this.state, this.data());
    Object.assign(this.settings, this.settingsData());
    // await this.onClearedState(stateCopy);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // protected async onClearedState(data: T): Promise<void> {
  //   //
  // }

  protected mittEvents(): void {
    //
  }

  protected setupSubscribe(): void {
    //
  }

  protected setupEmitter(): void {
    //
  }

  protected load(): void {
    //
  }

  private settingsData(): StoreSettings {
    return {
      loaded: false,
    };
  }

  protected addSubscription<T, TVar extends Record<string, unknown> | undefined>(
    query: DocumentNode,
    variables: TVar,
    callback: (data: T) => void,
    onReconnected?: () => void,
  ) {
    console.log("Adding subscription");

    const unsubscribe = subscribe<T, TVar>(query, variables, callback, onReconnected);

    this.subscriptions.push(unsubscribe);
  }

  protected addMittEvent<T extends keyof MittEvents>(type: T, callback: (data: MittEvents[T]) => void) {
    emitter.on(type, callback);
    this.registeredMittEvents.push({
      type: type,
      callback: callback,
    });
  }

  protected unsubscribeAll() {
    this.subscriptions.forEach(callback => {
      console.log("Removing subscription", callback);
      callback();
    });

    this.subscriptions = [];

    this.registeredMittEvents.forEach(i => {
      console.log("Removing mitt event", i.type, i.callback);
      emitter.off(i.type, i.callback);
    });

    this.registeredMittEvents = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public update(key: keyof T, value: any) {
    this.state[key] = value;
  }

  public isLoaded() {
    return computed(() => this.settings.loaded);
  }

  public unmount() {
    void this.clearState();
    this.unsubscribeAll();
  }

  loadWithData(data: T): void {
    this.unsubscribeAll();
    this.clearState();
    // Breaks reactivity
    //this.state = reactive(data) as T;
    // Instead:
    Object.assign(this.state, data);
    this.setupSubscribe();
    this.setupEmitter();
    this.settings.loaded = true;
  }

  noop() {
    //
  }
}
