<template>
  <span>
    {{ agoText }}
    <STooltip>
      {{ parsedDateHuman }}
    </STooltip>
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useInterval } from "src/composables/useInterval";
import { useI18n } from "vue-i18n";
import STooltip from "components/STooltip.vue";

const { t } = useI18n();

const props = defineProps({
  updated: {
    type: String,
    required: true,
  },
});

const agoText = ref("");
const parsedDate = Date.parse(props.updated);

const parsedDateHuman = new Date(parsedDate).toLocaleString();

function ago(diff: number): string {
  let ago: string;
  if (diff >= 31536000) {
    const years: number = Math.floor(diff / 31536000);
    ago = t("relativeTime.yearAgo", { count: years });
  } else if (diff >= 2592000) {
    const months: number = Math.floor(diff / 2592000);
    ago = t("relativeTime.monthAgo", { count: months });
  } else if (diff >= 86400) {
    const days: number = Math.floor(diff / 86400);
    ago = t("relativeTime.dayAgo", { count: days });
  } else if (diff >= 3600) {
    const hours = Math.floor(diff / 3600);
    ago = t("relativeTime.hourAgo", { count: hours });
  } else if (diff >= 60) {
    const minutes = Math.floor(diff / 60);
    ago = t("relativeTime.minuteAgo", { count: minutes });
  } else {
    ago = t("relativeTime.rightNow");
  }

  return ago;
}

function setAgo() {
  // if (parsedDate === null) {
  //   agoText.value = ''
  // }

  agoText.value = ago((new Date().getTime() - parsedDate) / 1000);
}

setAgo();

useInterval(() => setAgo(), 60);
</script>
