import Image from "@tiptap/extension-image";

export const PastedImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      class: {
        default: null,
      },
      style: {
        default: null,
      },
      azureblob: {
        default: null,
      },
    };
  },
});
