import { nextTick, onMounted, onBeforeUnmount } from "vue";
import { debounce } from "quasar";
import {
  getInnerHeight,
  getPageElement,
  getScrollHeight,
  getScrollY,
  isDialogVisible,
  isDrawerVisible,
} from "src/shared/dom-utils";

export function useScrollBottomListener(drawerMode: boolean, callback: (scrollToPreviousLocation: () => void) => void) {
  const debouncedHandleScroll = debounce(handleScroll, 100);
  let distanceFromTop = 0;
  let pageElement: HTMLElement | Window;

  async function handleScroll() {
    const scrollY = getScrollY(pageElement);

    if (getInnerHeight(pageElement) + scrollY >= getScrollHeight(pageElement) - 20) {
      console.log("User is at the bottom of the page");

      // Not the best Vue way, but it works...
      // If a q-dialog is showing then we don't trigger the callback
      if (!drawerMode && (isDrawerVisible() || isDialogVisible())) {
        console.log("A q-dialog is showing. Skipping");
        return;
      }

      distanceFromTop = scrollY;
      callback(scrollToPreviousLocation);
    }
  }

  async function scrollToPreviousLocation() {
    await nextTick(() => {
      console.log("scrolling to ", distanceFromTop);
      pageElement.scrollTo(0, distanceFromTop);
    });
  }

  onMounted(() => {
    pageElement = getPageElement();
    pageElement.addEventListener("scroll", debouncedHandleScroll);
  });

  onBeforeUnmount(() => {
    pageElement.removeEventListener("scroll", debouncedHandleScroll);
    debouncedHandleScroll.cancel();
  });
}
