<template>
  <FormKit id="team-form" type="form" @submit="onSubmit" :actions="false">
    <FormKit
      type="text"
      v-model="name"
      :help="$t('settings.teams.shared.teamNameInputHelp')"
      validation="required"
      :label="$t('settings.teams.shared.teamNameInputLabel')"
      :placeholder="$t('settings.teams.shared.teamNameInputPlaceholder')"
    >
      <template #prefixIcon>
        <EmojiPickerButton :current-emoji="emoji" @select="emoji = $event" />
      </template>
    </FormKit>
    <FormKit
      type="autocomplete"
      clear-search-on-open
      :close-on-select="false"
      open-on-focus
      v-model="memberAgentIds"
      :help="$t('settings.teams.shared.teamAgentsInputHelp')"
      :label="$t('settings.teams.shared.teamAgentsInputLabel')"
      :options="agentOptions"
      multiple
    >
      <template #option="{ option, classes }">
        <div :class="classes.option" class="space-x-2">
          <AgentAvatarWithStatus :agent-id="option.value" />
          <span>
            {{ option.label }}
          </span>
        </div>
      </template>
    </FormKit>
  </FormKit>
</template>

<script setup lang="ts">
import { agentWithStatusStore } from "src/features/agent/agent-avatar-with-status/agent-with-status-global-store";
import { ref, computed } from "vue";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { GAgentStatusItem } from "src/generated/graphql";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { FormKitGroupValue, FormKitNode, getNode } from "@formkit/core";
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import EmojiPickerButton from "src/shared/components/EmojiPickerButton.vue";

const emit = defineEmits(["update:saving", "created"]);

const name = ref("");
const emoji = ref("");
const memberAgentIds = ref<string[]>([]);

const submitForm = () => {
  getNode("team-form")?.submit();
};

defineExpose({ submitForm });

const agentOptions = computed(() =>
  agentWithStatusStore
    .getList()
    .filter(i => [GAgentStatusItem.Active, GAgentStatusItem.Invited].includes(i.perTenant.status))
    .map(i => {
      return {
        label: resolveDisplayName(i.firstName, i.lastName),
        value: i.id,
      };
    }),
);

async function onSubmit(form: FormKitGroupValue, node?: FormKitNode) {
  try {
    const sdk = useGraphqlSdk();
    emit("update:saving", true);

    await sdk.CreateTeam({
      input: {
        name: name.value,
        memberAgentIds: memberAgentIds.value,
        emoji: emoji.value.trim(),
      },
    });

    emit("created");
  } catch (err) {
    node?.setErrors([`Sorry, the team could not be created.`]);
  } finally {
    emit("update:saving", false);
  }
}
</script>

<style lang="sass"></style>
