<template>
  <div class="flex flex-row flex-nowrap">
    <!-- Static sidebar for desktop -->
    <SafeTeleport to="#left-sidebar">
      <nav class="flex flex-1 flex-col sa-sidebar-navigation shadow-md w-full">
        <SettingsSidebarMenu />
      </nav>
    </SafeTeleport>

    <main class="flex-grow sa-sidebar-layout-body rounded-none lg:rounded-r-md lg:-mx-2">
      <SettingsSidebarMenu v-if="showNavigationInMainContainer" class="block lg:hidden" />
      <div class="px-4 sm:px-6 lg:px-8">
        <ErrorBoundary>
          <Suspense>
            <RouterView />

            <template #fallback>
              <q-spinner size="50px" />
            </template>
          </Suspense>
        </ErrorBoundary>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import SettingsSidebarMenu from "src/features/settings/SettingsSidebarMenu.vue";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import ErrorBoundary from "src/components/ErrorBoundary.vue";

const $q = useQuasar();

const route = useRoute();
const router = useRouter();

if ($q.screen.gt.sm && route.name === "settings-index") {
  // for some reason, vue crashes if we await this
  void router.push({ name: "settings-profile" });
}

const showNavigationInMainContainer = computed(() => {
  return route.name === "settings-index";
});
</script>

<style lang="sass"></style>
