/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, shallowRef, DefineComponent } from "vue";

export interface DrawerInstance {
  comp?: any;
  title: string | null;
  width: "xl" | "lg" | "md" | "sm";
  drawer: Component;
  wrapper: string;
  props: any;
}

export const drawerRef = shallowRef<DrawerInstance | null>();

export const globalDrawerProps = {
  isVisible: {
    type: Boolean,
  },
};

/**
 * Closes the currently opened drawer
 */
export function closeDrawer() {
  drawerRef.value = null;
}

/**
 * Extracts the type of props from a component definition.
 */
type PropsType<C extends DefineComponent<any, any, any>> = InstanceType<C>["$props"];

export function useGlobalDrawer() {
  /**
   * Opens a dialog.
   * @param dialog The dialog you want to open.
   * @param props The props to be passed to the dialog.
   * @param wrapper The dialog wrapper you want the dialog to open into.
   */

  function openDrawer<C extends DefineComponent<any, any, any, any, any>>(
    title: string | null,
    drawer: C,
    props?: PropsType<C>,
    width: DrawerInstance["width"] = "xl",
    wrapper = "default"
  ) {
    drawerRef.value = {
      title,
      drawer,
      props,
      wrapper,
      width,
    };
  }

  return {
    openDrawer,
  };
}
