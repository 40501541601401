import { createClient } from "graphql-sse";
import { DocumentNode } from "graphql/language";
import { print } from "graphql";
import { ContextHolder } from "@frontegg/vue";
import { emitter } from "src/boot/mitt";
import { useDebounceFn } from "@vueuse/core";

const API_ENDPOINT = process.env.API;

if (!API_ENDPOINT) {
  throw new Error("Missing API in env");
}

const onReconnected = useDebounceFn(() => {
  console.log("Subscriptions reconnected");
  emitter.emit("subscription-reconnected");
}, 1000);

const subscriptionClient = createClient({
  // singleConnection: true, preferred for HTTP/1 enabled servers and subscription heavy apps
  url: API_ENDPOINT,
  retryAttempts: 1000,
  //onNonLazyError: console.warn,
  headers: () => {
    const jwt = ContextHolder.getAccessToken();

    if (!jwt) {
      throw new Error("Cannot use subscription client without a jwt");
    }

    return { Authorization: `Bearer ${jwt}` };
  },
  retry: async () => {
    await new Promise(resolve => {
      console.log("Attempting subscription reconnect");
      setTimeout(resolve, 1000 + Math.random() * 3000);
    });
  },
  onMessage: data => console.debug("Subscription message:", data),
  on: {
    connected: async (reconnected: boolean) => {
      if (reconnected) {
        await onReconnected();
      }
    },
  },
});

export function subscribe<T, TVar extends Record<string, unknown> | undefined>(
  query: DocumentNode,
  variables: TVar,
  callback: (data: T) => void,
  onReconnected?: () => void,
) {
  return subscriptionClient.subscribe<T>(
    {
      query: print(query),
      variables: variables,
    },
    {
      next: data => {
        if (data.data !== undefined && data.data !== null) {
          const result = data.data as T;
          callback(result);
        }
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
        // console.log("completed");
      },
    },
    {
      connected(reconnected) {
        if (reconnected && onReconnected) {
          onReconnected();
        }
      },
    },
  );
}
