<template>
  <q-list bordered>
    <q-item
      v-for="option in options"
      :key="option.connectedServiceId"
      class="q-my-sm"
      clickable
      v-ripple
      :active="option.ui.selected"
      @click="onClick(option.ui.index)"
    >
      <q-item-section avatar>
        <q-icon :name="channelIcons[option.channel]" />
      </q-item-section>

      <q-item-section>
        <q-item-label><ConversationChannelDisplay :channel="option.channel" /></q-item-label>
        <q-item-label caption lines="1">
          {{
            $t(
              "conversations.transferDialog.addressesOnFile",
              {
                count: option.addressOptions.length.toString(),
                noun: getNounString(option.channel, option.addressOptions.length),
              },
              option.addressOptions.length,
            )
          }}
        </q-item-label>
      </q-item-section>

      <q-item-section side>
        <div v-if="option.channel === currentChannel">{{ $t("conversations.transferDialog.currentChannel") }}</div>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { ConversationTransferChannelStore } from "./conversation-transfer-channel-store";
import { useShortcut } from "src/composables/useShortcut";
import { GConversationChannelItem } from "src/generated/graphql";
import { useI18n } from "vue-i18n";
import ConversationChannelDisplay from "../conversation-display/ConversationChannelDisplay.vue";
import { channelIcons } from "src/shared/channel-display-helper";

const props = defineProps({
  store: {
    type: Object as PropType<ConversationTransferChannelStore>,
    required: true,
  },
});

const { t } = useI18n();

const options = props.store.getList();

const currentChannel = computed(() => props.store.getState().currentChannel);

const onClick = (index: number) => {
  props.store.select(index);
  selectChannel();
};

const getNounString = (channel: GConversationChannelItem, count: number) => {
  if (channel === GConversationChannelItem.Email) {
    return t("conversations.transferDialog.noun.emailAddress", count);
  }

  if (
    channel === GConversationChannelItem.Sms ||
    channel === GConversationChannelItem.WhatsApp ||
    channel === GConversationChannelItem.VoiceCall
  ) {
    return t("conversations.transferDialog.noun.phoneNumber", count);
  }

  return t("conversations.transferDialog.noun.address", count);
};

const selectChannel = () => {
  const selected = props.store.getSelectedItem();

  if (selected) {
    props.store.selectChannel(selected.channel);
  }
};

useShortcut(
  "conversationTransferChannelSelect",
  [
    {
      key: "down",
      callback: () => props.store.selectNext(),
      sendToCommandPalette: false,
    },
    {
      key: "up",
      callback: () => props.store.selectPrevious(),
      sendToCommandPalette: false,
    },
    {
      key: "enter",
      callback: async () => selectChannel(),
      sendToCommandPalette: false,
    },
  ],
  {
    newShortcutStack: true,
  },
);
</script>

<style scoped lang="scss"></style>
