<template>
  <slot v-if="isReady"></slot>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useQuasar } from "quasar";
import { initializeApp } from "src/app-init/initializer";
import { useEmitterOn } from "src/composables/useEmitterOn";
import { useAuthState } from "@frontegg/vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const authState = useAuthState();
const { t } = useI18n();

const q = useQuasar();
const router = useRouter();

const isReady = ref(false);
const hasInitialized = ref(false);

const initialize = () => {
  isReady.value = false;
  hasInitialized.value = true;

  initializeApp(t)
    .then(() => {
      isReady.value = true;
    })
    .catch(e => {
      console.error(e, "Error initializing app");
      q.loading.hide();

      setTimeout(() => {
        // Is there a better way than setTimeout?
        q.dialog({
          title: t("appInit.errorTitle"),
          message: t("appInit.errorMessage"),
          ok: t("common.tryAgain"),
          cancel: t("common.logOut"),
          persistent: true,
        })
          .onOk(() => {
            window.location.reload();
          })
          .onCancel(() => {
            void router.push({
              path: authState.routes.logoutUrl,
            });
          });
      }, 250);
    });
};

watch(
  isReady,
  () => {
    if (isReady.value) {
      q.loading.hide();
    } else {
      if (!hasInitialized.value) {
        initialize();
      }

      q.loading.show({
        //delay: 400,
        message: t("appInit.loadingMessage"),
      });
    }
  },
  {
    immediate: true,
  },
);

// useEmitterOn("auth-tenant-changed", async () => {
//   console.info("Switched tenants, re-initializing app data");
//   await initializeApp(t);
// });

useEmitterOn(
  [
    "sms-channel-created-in-drawer",
    "email-channel-created-in-drawer",
    "chat-channel-created",
    "conversation-snippet-created",
    "conversation-snippet-deleted",
    "conversation-snippet-updated",
    "subscription-reconnected",
  ],
  async () => {
    console.info("Global data changed, re-initializing app data");
    await initializeApp(t);
  },
);
</script>
