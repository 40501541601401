<template>
  <slot />
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { useShortcut, Shortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { useDrawer } from "../composables/useDrawers";
import { useI18n } from "vue-i18n";
import { useDialog } from "src/composables/useDialog";

const router = useRouter();
const $q = useQuasar();
const { t } = useI18n();

const { showAgentNotificationListDrawer } = useDrawer(t);
const { showSearchDialog } = useDialog();

const shortcuts: ComputedRef<Shortcut[]> = computed(() => [
  {
    description: t("appShortcuts.goToActiveConversations"),
    key: "g i",
    callback: async () => {
      await router.push({ name: "conversation-list-active" });
    },
    shortcut: ShortcutItem.GoToActiveConversations,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToSnoozedConversations"),
    key: "g z",
    callback: async () => {
      await router.push({ name: "conversation-list-snoozed" });
    },
    shortcut: ShortcutItem.GoToActiveConversations,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToDoneConversations"),
    key: "g d",
    callback: async () => {
      await router.push({ name: "conversation-list-done" });
    },
    shortcut: ShortcutItem.GoToArchivedConversations,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToActivityFeed"),
    key: "g f",
    callback: async () => {
      await router.push({ name: "app-event-list" });
    },
    shortcut: ShortcutItem.GoToActivityFeed,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToKnowledgeBase"),
    key: "g k",
    callback: async () => {
      await router.push({ name: "kb-list" });
    },
    shortcut: ShortcutItem.GoToKnowledgeBase,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToSettings"),
    key: $q.platform.is.mac ? "mod+," : "g s",
    callback: async () => {
      await router.push({ name: "settings" });
    },
    shortcut: ShortcutItem.GoToSettings,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    description: t("appShortcuts.goToNotifications"),
    key: "g n",
    callback: () => {
      showAgentNotificationListDrawer();
    },
    shortcut: ShortcutItem.AgentNotificationOpenDrawer,
    sendToCommandPalette: true,
    globalShortcut: true,
  },
  {
    key: "/",
    description: t("common.search"),
    callback: () => {
      showSearchDialog();
    },
    sendToCommandPalette: true,
    shortcut: ShortcutItem.Search,
    globalShortcut: true,
  },
]);

useShortcut("app", shortcuts, {
  includeGlobal: true,
});
</script>

<style scoped lang="scss"></style>
