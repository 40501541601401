<template>
  <div class="sa-editor-popup">
    <q-list>
      <template v-if="items.length">
        <q-item
          v-for="(item, index) in items"
          :key="index"
          clickable
          class="sa-editor-popup__item"
          :class="{ 'sa-editor-popup__item--selected': index === selectedIndex }"
          @mouseenter="selectedIndex = index"
          @click="selectItem(index)"
        >
          <template-suggestion-item :title="item.title" :body="item.content" />
          <!--          <q-item-section>-->
          <!--            <q-item-label overline>{{ item.title }}</q-item-label>-->
          <!--            <q-item-label>{{ item.body }}</q-item-label>-->
          <!--            <q-tooltip anchor="center left" self="center end" :offset="[30, 0]" max-width="500px" class="text-sm">-->
          <!--              {{ item.body }}-->
          <!--            </q-tooltip>-->
          <!--          </q-item-section>-->
        </q-item>
      </template>
      <template v-else>
        <q-item>{{ $t("conversation.snippets.editorPlugin.noSnippetFound") }}</q-item>
      </template>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, toRefs } from "vue";
import { usePopup } from "src/features/tip-tap-editor/composables/usePopup";
import TemplateSuggestionItem from "src/features/tip-tap-editor/extensions/template/TemplateSuggestionItem.vue";
import { GGlobalSnippetListFragment } from "src/generated/graphql";

const props = defineProps({
  items: {
    // eslint-disable-next-line
    type: Object as PropType<GGlobalSnippetListFragment[]>,
    required: true,
  },

  command: {
    type: Function,
    required: true,
  },
});

const lastExpand = ref<number | null>(null);
const shiftKey = ref(false);
const showFull = ref(false);
const { items: popupItems } = toRefs(props);
const { onKeyDown, selectedIndex } = usePopup(popupItems, selectItem, otherKeyCallback);

defineExpose({ onKeyDown });

function selectItem(index: number) {
  const item = popupItems?.value[index];

  if (item) {
    const index = props.items.findIndex(i => i.id === item.id);
    if (index !== -1) {
      const template = props.items[index];
      props.command({ id: template.id, content: template.content });
    }
  }
}

function otherKeyCallback(event: KeyboardEvent) {
  if (event.shiftKey && selectedIndex.value !== lastExpand.value) {
    lastExpand.value = selectedIndex.value;
    shiftKey.value = true;
    showFull.value = true;
  }
}
</script>
