import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { Store2 } from "stores/store-2";
import { GTransferOption, GConversationChannelItem } from "src/generated/graphql";

export interface ConversationTransferOption extends GTransferOption {
  id: string;
}

export type ConversationTransfer = ConversationTransferOption & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationTransferData {
  currentChannel: GConversationChannelItem;
  selectedChannel?: GConversationChannelItem;
}

export class ConversationTransferChannelStore extends Store2<ConversationTransferData, ConversationTransfer> {
  protected data(): ConversationTransferData {
    return {
      currentChannel: GConversationChannelItem.Unknown,
    };
  }

  public setState(state: ConversationTransferData) {
    Object.assign(this.state, state);
  }

  public selectChannel(value: GConversationChannelItem) {
    this.state.selectedChannel = value;
  }
}

//export const conversationAssignStore = new ConversationAssignGlobalStore();
