<template>
  <q-dialog transition-duration="0" ref="dialogRef" @hide="onDialogHide">
    <div class="sa-dialog w-full">
      <div class="sa-dialog__header px-4 py-4 flex justify-between items-center">
        <div class="sa-dialog__header-title">
          {{ $t("conversations.transferDialog.transferConversation") }}
        </div>
        <div>
          <q-btn icon="fa-light fa-close" dense @click="onDialogCancel"></q-btn>
        </div>
      </div>

      <div>
        <div class="sa-dialog__content scroll" style="max-height: 50vh">
          <transition name="fade-skeleton" mode="out-in">
            <div v-if="!isLoaded" class="flex flex-col space-y-2 mb-4 px-2">
              <q-skeleton v-for="(i, idx) of [1, 2]" :key="idx" :animation="'fade'" :type="'QSlider'" />
            </div>
            <ChannelSelect :store="channelStore" v-else-if="!hasSelectedChannel" />
            <AddressSelect
              :store="addressStore"
              :contact-id="contactId"
              v-else
              @cancel="onAddressSelectCancelled"
              @selected="onAddressSelected"
            />
          </transition>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { GConversationChannelItem } from "src/generated/graphql";
import { useStore2 } from "src/composables/useStore2";
import { ConversationTransferChannelStore, ConversationTransferOption } from "./conversation-transfer-channel-store";
import ChannelSelect from "./ChannelSelect.vue";
import AddressSelect from "./AddressSelect.vue";
import { ConversationTransferAddressStore } from "./conversation-transfer-address-store";
import { channelTypeToContactAddressTypeMap } from "src/shared/constants";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },
  currentChannel: {
    type: String as PropType<GConversationChannelItem>,
  },
  currentAddressId: {
    type: String,
  },
  contactId: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const sdk = useGraphqlSdk();
const $q = useQuasar();
const { store: channelStore } = useStore2(ConversationTransferChannelStore);
const { store: addressStore } = useStore2(ConversationTransferAddressStore);
const { dialogRef, onDialogCancel, onDialogOK, onDialogHide } = useDialogPluginComponent();

const isLoaded = channelStore.isLoaded();

defineEmits([...useDialogPluginComponent.emits]);

onMounted(async () => {
  // Cannot fetch in outer scope due to missing suspense in dialogs
  const res = await sdk.TransferConversationOptions({
    conversationId: props.conversationId,
  });

  channelStore.loadWithData(
    res.conversationTransferOptions.options.map<ConversationTransferOption>(i => {
      return {
        ...i,
        id: i.channel,
      };
    })
  );

  channelStore.setState({
    currentChannel: props.currentChannel ?? GConversationChannelItem.Unknown,
  });

  channelStore.selectFirst();
});

const onAddressSelectCancelled = () => {
  channelStore.selectChannel(GConversationChannelItem.Unknown);
};

const onAddressSelected = async (contactAddressId: string) => {
  const selectedChannel = channelStore.getState().selectedChannel;

  if (!selectedChannel) {
    throw new Error("Channel was not set in store");
  }

  const progress = $q.notify({
    type: "ongoing",
    message: t("conversations.transferDialog.transferProgressText"),
  });

  try {
    await sdk.ModifyConversation({
      input: {
        conversationId: props.conversationId,
        channelType: channelStore.getSelectedItem().channel,
        contactAddressId: contactAddressId,
        connectedServiceId: channelStore.getSelectedItem().connectedServiceId,
      },
    });

    progress({
      type: "positive",
      message: t("conversations.transferDialog.transferSuccessText"),
    });
    onDialogOK();
  } catch (err) {
    console.warn(err);
    progress({
      type: "negative",
      message: t("conversations.transferDialog.transferErrorText"),
    });
    onDialogCancel();
  }
};

const hasSelectedChannel = computed(() => {
  const state = channelStore.getState();
  return !!state.selectedChannel && state.selectedChannel !== GConversationChannelItem.Unknown;
});

watch(hasSelectedChannel, () => {
  if (hasSelectedChannel.value) {
    const selectedChannel = channelStore.getState().selectedChannel;

    if (!selectedChannel) {
      console.error("no selected channel found");
      return;
    }

    const addressType = channelTypeToContactAddressTypeMap[selectedChannel];

    if (!addressType) {
      console.log("No address type mapping for channel", selectedChannel);
      return;
    }

    const addressData = channelStore.getList().find(i => i.channel == selectedChannel);

    if (addressData) {
      addressStore.loadWithData([
        ...addressData.addressOptions,
        {
          id: "new",
          address: "",
          addressType: addressType,
        },
      ]);
      addressStore.setState({
        currentAddressId: props.currentAddressId,
      });
      addressStore.selectFirst();
    }
  } else {
    addressStore.unmount();
  }
});
</script>

<style lang="sass" scoped></style>
