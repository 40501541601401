import { TextPatch } from "src/shared/models/text-patch";

export interface TextSequencePatch {
  sequence: number;
  patchData: TextPatch[];
}

export class TextSynchronizationClient {
  private clientText = "";
  private lastAppliedSeqNumber = 0;
  private pendingPatches: TextSequencePatch[] = [];

  public applyPatch(text: string, isFullContent: boolean, sequence: number) {
    if (isFullContent) {
      return this.applyFullText(text, sequence);
    } else {
      const patchData: TextPatch[] = JSON.parse(text);
      return this.applySequentialPatch({
        sequence: sequence,
        patchData: patchData,
      });
    }
  }

  // Call this when a full text update is received
  private applyFullText(text: string, sequence: number): string {
    this.clientText = text;
    this.lastAppliedSeqNumber = sequence;

    // Remove patches that are outdated based on the new sequence number
    this.pendingPatches = this.pendingPatches.filter(patch => patch.sequence > sequence);

    this.applyDiffPatches();

    return this.clientText;
  }

  // Call this when a patch is received
  private applySequentialPatch(textPatch: TextSequencePatch): string {
    // Discard old or duplicate patches
    if (textPatch.sequence <= this.lastAppliedSeqNumber) {
      console.log("Discarded patch as", textPatch.sequence, "<=", this.lastAppliedSeqNumber);
      return this.clientText;
    }

    // Apply the patch immediately if it's the next in sequence
    if (textPatch.sequence === this.lastAppliedSeqNumber + 1) {
      console.log("Will apply patch", textPatch.sequence, "now");
      this.applyDifferences(textPatch);
    }

    // Store patch in the pending patches array
    else {
      console.log(
        "Storing patch",
        textPatch.sequence,
        "in pending patches since last applied sequence is",
        this.lastAppliedSeqNumber
      );
      this.pendingPatches.push(textPatch);
      this.pendingPatches.sort((a, b) => a.sequence - b.sequence);
      this.applyDiffPatches();
    }

    return this.clientText;
  }

  private applyDiffPatches() {
    let i = 0;
    while (i < this.pendingPatches.length) {
      const nextPatch = this.pendingPatches[i];
      if (nextPatch.sequence === this.lastAppliedSeqNumber + 1) {
        this.applyDifferences(nextPatch);
        this.pendingPatches.splice(i, 1); // remove the applied patch
      } else {
        i++;
      }
    }
  }

  private applyDifferences(textPatch: TextSequencePatch) {
    for (const diff of textPatch.patchData) {
      this.clientText =
        this.clientText.slice(0, diff.start) + diff.insert + this.clientText.slice(diff.start + diff.del);
    }

    this.lastAppliedSeqNumber = textPatch.sequence;
  }
}
