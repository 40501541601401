<template>
  <q-layout view="lHr lpR lFr">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel ref="dialogPanel" class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-40 top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 w-40">
                <nav class="flex flex-1 flex-col justify-between py-4 overflow-y-auto space-y-4">
                  <div class="flex flex-col space-y-4">
                    <TenantSwitcher />
                    <MainNavigation />
                  </div>
                  <div class="flex flex-col items-center content-center w-full">
                    <UserMenu class="" />
                  </div>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-40 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-50 px-6 lg:pt-4">
        <nav class="flex flex-1 flex-col justify-between space-y-4">
          <div class="flex flex-col space-y-16">
            <TenantSwitcher />
            <MainNavigation />
          </div>
          <div class="flex flex-col items-center content-center w-full pb-4">
            <!-- <global-command-palette-btn fab />

            <agent-notification-btn /> -->

            <UserMenu class="" />
          </div>
        </nav>
      </div>
    </div>

    <!-- Static header bar for desktop -->
    <div
      :class="[
        'hidden lg:fixed lg:left-40 lg:right-0 lg:w-[calc(100%-theme(space.40))] lg:top-0 lg:h-10 lg:z-50 lg:overflow-hidden lg:flex lg:flex-col title-bar',
      ]"
      id="title-bar"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="bg-gray-50 pr-6 h-full">
        <nav :class="['flex flex-1 flex-row justify-between py-1 space-x-4']">
          <div class="flex flex-col space-y-4">
            <AppNavigation />
          </div>
          <div class="flex flex-row items-start content-center">
            <global-command-palette-btn />

            <agent-notification-btn />

            <!-- <UserMenu class="scale-75 ml-2 h-4" /> -->
          </div>
        </nav>
      </div>
    </div>

    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden h-14">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">{{ title }}</div>
      <div class="flex flex-row items-center">
        <global-command-palette-btn fab />
        <agent-notification-btn />
        <main-nav-btn v-if="$q.platform.is.mobile" icon="fa-square-question" @click="showGleapWidget" size="small" />
      </div>
    </div>

    <main
      :class="'lg:ml-40 lg:mt-10 lg:border-t lg:border-l border-gray-200 fixed top-[theme(space.16)] lg:top-0 bottom-0 w-full lg:w-[calc(100%-theme(space.40))] overflow-auto'"
    >
      <div :class="[showSidebar ? 'lg:pl-96' : 'lg:pl-0', 'transition-all']">
        <div class="px-4 py-0 sm:px-6 lg:px-8 lg:py-6">
          <suspense>
            <ErrorBoundary>
              <router-view />
            </ErrorBoundary>
          </suspense>
        </div>
      </div>
    </main>

    <aside
      :class="[
        !showSidebar
          ? 'w-0 opacity-0'
          : 'opacity-100 fixed top-[theme(space.10)] bottom-0 left-40 hidden w-96 overflow-y-auto border-r border-t border-l border-gray-200 lg:block',
        'transition-all',
      ]"
    >
      <div ref="buttonContainer" class="space-x-2 flex justify-end h-full" id="left-sidebar"></div>
    </aside>

    <global-drawer />
    <global-shortcuts />
  </q-layout>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ErrorBoundary from "src/components/ErrorBoundary.vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useEventListener, useMutationObserver } from "@vueuse/core";
import MainNavigation from "./components/MainNavigation.vue";
import TenantSwitcher from "src/features/auth/TenantSwitcher.vue";
import GlobalDrawer from "src/components/GlobalDrawer.vue";
import GlobalShortcuts from "components/GlobalShortcuts.vue";
import UserMenu from "src/components/UserMenu.vue";
import GlobalCommandPaletteBtn from "src/features/palette-global-command/GlobalCommandPaletteBtn.vue";
import AgentNotificationBtn from "src/features/agent-notification/agent-notification-btn/AgentNotificationBtn.vue";
import AppNavigation from "./components/AppNavigation.vue";
import MainNavBtn from "components/MainNavBtn.vue";
import Gleap from "gleap";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import { useLocaleHelpers } from "src/composables/useLocaleHelpers";
import { loadLocaleMessages } from "src/boot/i18n";

const buttonContainer = ref<HTMLDivElement>();
const showSidebar = ref(false);
const titleBarHidden = ref(false);
const { locale } = useI18n();
const { localeItemToLocale } = useLocaleHelpers();

/* eslint-disable @typescript-eslint/no-explicit-any */
if ("windowControlsOverlay" in navigator) {
  useEventListener((navigator as any).windowControlsOverlay, "geometrychange", () => {
    titleBarHidden.value = (navigator as any).windowControlsOverlay.visible;
  });
}

useMutationObserver(
  buttonContainer,
  () => {
    if (buttonContainer.value?.hasChildNodes() ?? false) {
      showSidebar.value = true;
    } else {
      showSidebar.value = false;
    }
  },
  {
    childList: true,
    subtree: true,
    characterData: true,
  },
);

const sidebarOpen = ref(false);
const gleapToggle = ref(false);

const route = useRoute();
const { t } = useI18n();

watch(route, () => {
  sidebarOpen.value = false;
});

const showGleapWidget = () => {
  gleapToggle.value = !gleapToggle.value;
  Gleap.showFeedbackButton(gleapToggle.value);
};

const title = computed(() => {
  if (route.meta?.titleI18nKey) {
    return t(route.meta.titleI18nKey as string);
  }

  return null;
});

watch(
  () => agentSettingsStore.getState().locale,
  async newLocale => {
    if (newLocale) {
      // Update locale
      const i18nLocale = localeItemToLocale[newLocale];
      await loadLocaleMessages(i18nLocale);
      locale.value = i18nLocale;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss">
#title-bar {
  left: env(titlebar-area-x, 0);
  right: env(titlebar-area-x, 0);
  width: env(titlebar-area-width, 100%);
}
</style>
