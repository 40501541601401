import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { Store2 } from "stores/store-2";

export interface ConversationSnoozeItem {
  id: string;
  date: Date;
  label: string;
}

export type ConversationSnooze = ConversationSnoozeItem & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationSnoozeData {}

export class ConversationSnoozeStore extends Store2<ConversationSnoozeData, ConversationSnooze> {
  protected data(): ConversationSnoozeData {
    return {};
  }
}

// export const conversationSnoozeStore = new ConversationSnoozeStore();
