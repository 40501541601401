import { Store } from "./store";
import { ref, watch, Ref } from "vue";
import { set, get } from "idb-keyval";

// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class StorePersistent<T extends Object> extends Store<T> {
  private isInitialized = ref(false);
  private storeName = ref("");

  constructor(readonly name: string) {
    super();
    this.storeName.value = name;
  }

  async init() {
    console.log(`Initing ${this.storeName.value}`);

    if (this.isInitialized.value === false) {
      console.log("Getting state from Indexed DB");
      const stateFromIndexedDB: string | null | undefined = await get(this.storeName.value);

      if (stateFromIndexedDB) {
        Object.assign(this.state, JSON.parse(stateFromIndexedDB));
      }

      watch(
        () => this.state,
        val => set(this.storeName.value, JSON.stringify(val)),
        {
          deep: true,
        }
      );

      this.isInitialized.value = true;
    }
    console.log("Init done");
  }

  getIsInitialized(): Ref<boolean> {
    return this.isInitialized;
  }
}
