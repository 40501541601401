import { Extension } from "@tiptap/core";
import { imageUploader } from "./image-uploader";
import { randomString } from "src/shared/random";

export interface ImageUploaderPluginOptions {
  acceptMimes: string[];
  id: string;
  uploadUrl: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageUploadExtension: {
      uploadImage: (options: { file: File }) => ReturnType;
    };
  }
}

export const ImageUploadExtension = Extension.create<ImageUploaderPluginOptions>({
  name: "image-uploader",

  addOptions() {
    return {
      id: randomString(32),
      acceptMimes: ["image/jpeg", "image/gif", "image/png", "image/jpg"],
      uploadUrl: "",
    };
  },

  addProseMirrorPlugins() {
    const options = this.options;
    return [imageUploader(options)];
  },
});
