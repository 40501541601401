<template>
  <div :class="['flex flex-col items-center space-y-1 transition-all group', !disabled && 'cursor-pointer']">
    <div
      :class="[
        'icon-container h-10 w-10 rounded-xl transition-all flex flex-col items-center justify-center relative',
        active && 'active',
        disabled && 'disabled opacity-40 pointer-events-none',
        dimensions.containerClasses,
      ]"
    >
      <q-badge v-if="badge" transparent floating rounded class="sa-main-nav__button--badge">
        {{ badge }}
      </q-badge>
      <q-icon :name="`${active ? 'fa-solid' : 'fa-light'} ${icon}`" :size="dimensions.iconSize"> </q-icon>
    </div>

    <span v-if="label" class="text-xs font-medium">{{ label }}</span>

    <s-tooltip v-if="tooltip && !$q.platform.has.touch" :position="'bottom'">
      <div>{{ tooltip }}</div>
      <div v-if="shortcuts.length > 0">
        <KeyboardShortcutDisplay :keys="shortcuts.join(' ')" />
      </div>
    </s-tooltip>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import STooltip from "components/STooltip.vue";
import KeyboardShortcutDisplay from "./KeyboardShortcutDisplay.vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  tooltip: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: true,
  },
  shortcuts: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [],
  },
  active: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  badge: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    default: "normal",
  },
});

const dimensions = computed(() => {
  switch (props.size) {
    case "small": {
      return {
        containerClasses: "h-8 w-8",
        iconSize: "15px",
      };
    }

    default: {
      return {
        containerClasses: "h-10 w-10",
        iconSize: "20px",
      };
    }
  }
});
</script>

<style lang="scss" scoped>
.group {
  &:hover {
    .icon-container:not(.disabled) {
      color: var(--q-sa-main-navigation--buttonTextColor);
      background: var(--q-sa-main-navigation--buttonBackground);

      .q-icon {
        @apply scale-110 transition-all transform-gpu;
      }
    }
  }

  .icon-container {
    &.active {
      color: var(--q-sa-main-navigation--activeButtonTextColor);
      background: var(--q-sa-main-navigation--activeButtonBackground);
    }
  }
}
</style>
