import { Store } from "stores/store";
import { emitter } from "boot/mitt";
import {
  GOnAgentNotificationCreatedSubscription,
  GOnAgentNotificationCreatedSubscriptionVariables,
  GOnAgentNotificationSeenModifiedSubscription,
  GOnAgentNotificationSeenModifiedSubscriptionVariables,
  OnAgentNotificationCreatedDocument,
  OnAgentNotificationSeenModifiedDocument,
} from "src/generated/graphql";

export interface UnseenAgentNotificationCountData {
  count: number;
}

export class UnseenAgentNotificationCountGlobalStore extends Store<UnseenAgentNotificationCountData> {
  protected data(): UnseenAgentNotificationCountData {
    return {
      count: 0,
    };
  }

  protected setupSubscribe() {
    this.addSubscription<GOnAgentNotificationCreatedSubscription, GOnAgentNotificationCreatedSubscriptionVariables>(
      OnAgentNotificationCreatedDocument,
      {},
      res => {
        if (!res.onAgentNotificationCreated.seen) {
          this.state.count++;
        }
      }
    );

    this.addSubscription<
      GOnAgentNotificationSeenModifiedSubscription,
      GOnAgentNotificationSeenModifiedSubscriptionVariables
    >(OnAgentNotificationSeenModifiedDocument, {}, res => {
      if (!res.onAgentNotificationSeenModified.fromSeen && res.onAgentNotificationSeenModified.toSeen) {
        this.state.count--;
      } else if (res.onAgentNotificationSeenModified.fromSeen && !res.onAgentNotificationSeenModified.toSeen) {
        this.state.count++;
      }
    });
  }

  protected setupEmitter() {
    emitter.on("unseen-agent-notification-count", count => {
      this.state.count = count;
    });

    // emitter.on("agent-notification-marked-as-seen", () => {
    //   this.state.count--;
    // });
  }
}

export const unseenAgentNotificationCountStore = new UnseenAgentNotificationCountGlobalStore();
