import { RouteLocationNormalizedLoaded, RouteRecordRaw, RouterView } from "vue-router";
import { GConversationStatusItem, GKnowledgeBaseArticleStatusItem } from "src/generated/graphql";
import MainLayout from "src/layouts/MainLayout.vue";
import SettingsLayout from "src/layouts/SettingsLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "",
    component: RouterView,
    children: [
      {
        path: "/conversation/:id",
        name: "legacy-conversation",
        component: RouterView,
      },
      {
        path: "/:tenantId",
        component: RouterView,
        children: [
          {
            path: "",
            // component: () => import("layouts/MainLayout.vue"),
            redirect: {
              name: "conversation-list",
            },
            children: [
              {
                path: "contact",
                meta: {
                  titleI18nKey: "mainNavigation.contacts",
                },
                component: MainLayout,
                children: [
                  {
                    path: "create",
                    name: "contact-create",
                    component: () => import("src/features/contact/contact-create/ContactCreatePage.vue"),
                  },
                ],
              },

              {
                path: "conversations",
                component: MainLayout,
                meta: {
                  titleI18nKey: "mainNavigation.conversations",
                },
                children: [
                  {
                    name: "conversation-list",
                    path: "",
                    redirect: {
                      name: "conversation-list-active",
                    },
                  },

                  {
                    name: "conversation-list-active",
                    path: "active",
                    component: () =>
                      import("src/features/conversation/conversation-list/ConversationListFilterProvider.vue"),
                    props: () => {
                      return {
                        status: GConversationStatusItem.Active,
                      };
                    },
                  },

                  {
                    name: "conversation-list-snoozed",
                    path: "snoozed",
                    component: () =>
                      import("src/features/conversation/conversation-list/ConversationListFilterProvider.vue"),
                    props: () => {
                      return {
                        status: GConversationStatusItem.Snoozed,
                      };
                    },
                  },

                  {
                    name: "conversation-list-done",
                    path: "done",
                    component: () =>
                      import("src/features/conversation/conversation-list/ConversationListFilterProvider.vue"),
                    props: () => {
                      return {
                        status: GConversationStatusItem.Archived,
                      };
                    },
                  },

                  // {
                  //   name: "conversation-list-filter",
                  //   path: "filter",
                  //   component: () =>
                  //     import("src/features/conversation/conversation-list/ConversationListRoutePage.vue"),
                  //   props: (route: RouteLocationNormalizedLoaded) => {
                  //     return {
                  //       status: "s" in route.query ? route.query.s : GConversationStatusItem.Active,
                  //       unassigned: "u" in route.query ? route.query.u : null,
                  //       agentId: "a" in route.query ? route.query.a : null,
                  //       teamId: "t" in route.query ? route.query.t : null,
                  //     };
                  //   },
                  // },
                  // {
                  //   name: "conversation-list-snoozed",
                  //   path: "snoozed",
                  //   component: () => import("src/features/conversation/conversation-list/ConversationListPage.vue"),
                  //   props: (route: RouteLocationNormalizedLoaded) => {
                  //     return {
                  //       status: GConversationStatusItem.Snoozed,
                  //       unassigned: "u" in route.query ? route.query.u === "true" : false,
                  //       agentId: "a" in route.query ? route.query.a : null,
                  //       teamId: "t" in route.query ? route.query.t : null,
                  //     };
                  //   },
                  // },
                  // {
                  //   name: "conversation-list-archived",
                  //   path: "archived",
                  //   component: () => import("src/features/conversation/conversation-list/ConversationListPage.vue"),
                  //   props: (route: RouteLocationNormalizedLoaded) => {
                  //     return {
                  //       status: GConversationStatusItem.Archived,
                  //       unassigned: "u" in route.query ? route.query.u === "true" : false,
                  //       agentId: "a" in route.query ? route.query.a : null,
                  //       teamId: "t" in route.query ? route.query.t : null,
                  //     };
                  //   },
                  // },
                ],
              },

              {
                path: "conversation/:id",
                component: MainLayout,
                meta: {
                  titleI18nKey: "mainNavigation.conversation",
                },
                children: [
                  {
                    path: "",
                    name: "conversation",
                    component: () =>
                      import("src/features/conversation/conversation-display/ConversationDisplayPage.vue"),
                    props: (route: RouteLocationNormalizedLoaded) => {
                      return {
                        id: route.params.id,
                      };
                    },
                  },
                ],
              },

              {
                component: MainLayout,
                path: "conversation/create",
                children: [
                  {
                    path: "",
                    name: "conversation-create",
                    component: () => import("src/features/conversation/conversation-create/CreateConversationPage.vue"),
                  },
                ],
              },

              {
                component: MainLayout,
                path: "kbs",
                meta: {
                  titleI18nKey: "mainNavigation.knowledgeBase",
                },
                children: [
                  {
                    path: "",
                    name: "kb-list",
                    redirect: {
                      name: "kb-list-published",
                    },
                  },

                  {
                    path: "published",
                    name: "kb-list-published",
                    component: () =>
                      import("src/features/knowledgebase/kb-article-list/KnowledgeBaseArticleListPage.vue"),
                    props: () => {
                      return { status: GKnowledgeBaseArticleStatusItem.Published };
                    },
                  },
                  {
                    path: "drafts",
                    name: "kb-list-drafts",
                    component: () =>
                      import("src/features/knowledgebase/kb-article-list/KnowledgeBaseArticleListPage.vue"),
                    props: () => {
                      return { status: GKnowledgeBaseArticleStatusItem.Draft };
                    },
                  },
                  {
                    path: "suggestions",
                    name: "kb-list-suggestions",
                    component: () =>
                      import("src/features/knowledgebase/kb-article-list/KnowledgeBaseArticleListPage.vue"),
                    props: () => {
                      return { status: GKnowledgeBaseArticleStatusItem.SuggestedByBot };
                    },
                  },
                ],
              },

              {
                path: "kb/create",
                component: MainLayout,
                children: [
                  {
                    path: "",
                    name: "kb-create",
                    component: () =>
                      import("src/features/knowledgebase/kb-article-create/KnowledgeBaseArticleCreate.vue"),
                  },
                ],
              },

              // {
              //   path: "/kb/bot-create",
              //   component: MainLayout,
              //   children: [
              //     {
              //       path: "",
              //       name: "kb-bot-create",
              //       component: () =>
              //         import("src/features/knowledgebase/kb-bot-article-create-with-bot/KnowledgeBaseArticleBotCreatePage.vue"),
              //     },
              //   ],
              // },

              {
                path: "kb/modify/:id",
                component: MainLayout,
                children: [
                  {
                    path: "",
                    name: "kb-modify",
                    component: () =>
                      import("src/features/knowledgebase/kb-article-modify/KnowledgeBaseArticleModifyPage.vue"),
                    props: (route: RouteLocationNormalizedLoaded) => {
                      return {
                        id: route.params.id,
                      };
                    },
                  },
                ],
              },

              {
                path: "contacts",
                component: MainLayout,
                meta: {
                  titleI18nKey: "mainNavigation.contacts",
                },
                children: [
                  {
                    path: "",
                    name: "contact-list",
                    component: () => import("src/features/contact/contact-list/ContactListPage.vue"),
                  },
                ],
              },

              {
                path: "feed",
                component: MainLayout,
                meta: {
                  titleI18nKey: "mainNavigation.activityFeed",
                },
                //component: () => import("layouts/MainLayout.vue"),
                children: [
                  {
                    path: "",
                    name: "app-event-list",
                    component: () => import("src/features/app-event/app-event-list/AppEventListPage.vue"),
                    props: (route: RouteLocationNormalizedLoaded) => {
                      return {
                        showContactId: "showContactId" in route.query ? route.query.showContactId : null,
                        showAgentId: "showAgentId" in route.query ? route.query.showAgentId : null,
                        showConversationId: "showConversationId" in route.query ? route.query.showConversationId : null,
                        filterAgentId: "filterAgentId" in route.query ? route.query.filterAgentId : null,
                      };
                    },
                  },
                ],
              },

              // {
              //   path: "/kbs",
              //   meta: {
              //     titleI18nKey: "mainNavigation.knowledgeBase",
              //   },
              //   //component: () => import("layouts/MainLayout.vue"),
              //   children: [
              //     {
              //       path: "",
              //       name: "kb-list",
              //       component: () => import("src/features/knowledgebase/kb-article-list/KnowledgeBaseArticleListPage.vue"),
              //     },
              //   ],
              // },

              // {
              //   path: "/kb/create",
              //   //component: () => import("layouts/MainLayout.vue"),
              //   children: [
              //     {
              //       path: "",
              //       name: "kb-create",
              //       component: () => import("src/features/knowledgebase/kb-article-create/KnowledgeBaseArticleCreatePage.vue"),
              //     },
              //   ],
              // },
              //
              // {
              //   path: "/kb/modify/:id",
              //   //component: () => import("layouts/MainLayout.vue"),
              //   children: [
              //     {
              //       path: "",
              //       name: "kb-modify",
              //       component: () => import("src/features/knowledgebase/kb-article-modify/KnowledgeBaseArticleModifyPage.vue"),
              //       props: (route: RouteLocationNormalizedLoaded) => {
              //         return {
              //           id: route.params.id,
              //         };
              //       },
              //     },
              //   ],
              // },

              {
                path: "settings",
                name: "settings",
                component: MainLayout,
                meta: {
                  titleI18nKey: "mainNavigation.settings",
                },
                redirect: {
                  name: "settings-profile",
                },
                children: [
                  {
                    path: "",
                    name: "settings-index",
                    component: SettingsLayout,

                    children: [
                      {
                        path: "profile",
                        name: "settings-profile",
                        component: () => import("src/features/settings/profile/ProfileEditor.vue"),
                      },
                      {
                        path: "snippets",
                        component: RouterView,
                        children: [
                          {
                            path: "",
                            name: "settings-snippets",
                            component: () =>
                              import("src/features/conversation-snippets/snippet-list/SnippetListPage.vue"),
                          },
                          {
                            path: "create",
                            name: "settings-snippet-create",
                            component: () =>
                              import("src/features/conversation-snippets/snippet-create/SnippetCreate.vue"),
                          },
                          {
                            path: "modify/:id",
                            name: "settings-snippet-modify",
                            component: () =>
                              import("src/features/conversation-snippets/snippet-modify/SnippetModifyPage.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                        ],
                      },
                      {
                        path: "agents",
                        name: "settings-agents",
                        component: () => import("src/features/settings/agents/agent-list/AgentList.vue"),
                      },
                      {
                        path: "agent-notifications",
                        name: "settings-agent-notifications",
                        component: () => import("src/features/settings/notifications/AgentNotificationSettings.vue"),
                      },
                      {
                        path: "teams",
                        name: "settings-teams",
                        component: () => import("src/features/settings/teams/team-list/TeamList.vue"),
                      },
                      {
                        path: "bot-assistant",
                        name: "settings-bot-assistant",
                        component: () => import("src/features/settings/bot-assistant/BotAssistantSettings.vue"),
                      },
                      {
                        path: "knowledgebase",
                        name: "settings-kb",
                        component: () => import("src/features/settings/knowledgebase/KbSettings.vue"),
                      },
                      {
                        path: "channels/chat",
                        name: "settings-channels-chat",
                        component: () => import("src/features/settings/channels/chat/ChatPage.vue"),
                        children: [
                          {
                            path: ":id/installation",
                            name: "settings-channels-chat-installation",
                            component: () => import("src/features/settings/channels/chat/ChatWidgetInstallation.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                          {
                            path: ":id/behaviour",
                            name: "settings-channels-chat-behaviour",
                            component: () => import("src/features/settings/channels/chat/ChatWidgetBehaviour.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                          {
                            path: ":id/appearance",
                            name: "settings-channels-chat-appearance",
                            component: () => import("src/features/settings/channels/chat/ChatWidgetAppearance.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                          {
                            path: ":id/text",
                            name: "settings-channels-chat-text",
                            component: () => import("src/features/settings/channels/chat/ChatWidgetText.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                          {
                            path: ":id/pre-chat-survey",
                            name: "settings-channels-chat-pre-chat-survey",
                            component: () => import("src/features/settings/channels/chat/ChatPreChatSurvey.vue"),
                            props: (route: RouteLocationNormalizedLoaded) => {
                              return {
                                id: route.params.id,
                              };
                            },
                          },
                        ],
                      },
                      {
                        path: "channels/email",
                        name: "settings-channels-email",
                        component: () => import("src/features/settings/channels/email/email-list/EmailList.vue"),
                      },
                      {
                        path: "channels/messenger",
                        name: "settings-channels-messenger",
                        component: () =>
                          import("src/features/settings/channels/messenger/messenger-list/MessengerList.vue"),
                      },
                      {
                        path: "channels/sms",
                        name: "settings-channels-sms",
                        component: () => import("src/features/settings/channels/sms/sms-status/SmsStatus.vue"),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  // {
  //   path: "/:catchAll(.*)*",
  //   component: () => import("pages/ErrorNotFound.vue"),
  // },
];

export default routes;
