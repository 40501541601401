<template>
  <q-dialog v-model="enabled" @hide="onDialogHide" no-esc-dismiss transition-duration="0">
    <div class="flex flex-col w-full h-full border border-gray-600">
      <div class="flex flex-row w-full px-2 py-2 space-x-2 items-center sa-dialog__header-with-buttons">
        <q-btn icon="keyboard_backspace" flat @click="closeDialog" />
        <div class="flex-1 text-lg ellipsis">
          {{ filename }}
        </div>
        <div>
          <q-btn icon="get_app" flat @click="onDownloadClick" />
          <q-btn icon="close" flat @click="closeDialog" />
        </div>
      </div>

      <div class="flex-1 overflow-auto flex items-center justify-center p-4">
        <template v-if="attachmentTypeStore.isImage(contentType)">
          <img :src="previewUrl" :alt="filename" />
        </template>

        <template v-else-if="attachmentTypeStore.isMedia(contentType)">
          <video-player
            :src="previewUrl"
            controls
            :volume="0.5"
            class="w-full h-full"
            disable-picture-in-picture
            autoplay
          />
        </template>

        <template v-else>
          <div @click="$event.stopPropagation()" class="flex flex-col items-center justify-center space-y-4">
            <div class="text-xl">{{ $t("conversation.display.attachment.noPreviewAvailable") }}</div>
            <div>
              <q-btn
                icon="get_app"
                no-caps
                :label="$t('conversation.display.attachment.download')"
                @click="onDownloadClick"
              />
            </div>
          </div>
        </template>

        <!--        <template v-if="attachmentTypeStore.isText(contentType)">-->
        <!--          <attachment-preview-text @click="$event.stopPropagation()" :src="previewUrl" />-->
        <!--        </template>-->
      </div>
    </div>
  </q-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { attachmentTypeStore } from "stores/attachment-type-global-store";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import { useI18n } from "vue-i18n";

const props = defineProps({
  filename: {
    type: String,
    required: true,
  },

  contentType: {
    type: String,
    required: true,
  },

  previewUrl: {
    type: String,
    required: true,
  },

  downloadUrl: {
    type: String,
    required: true,
  },
});

const enabled = ref(true);
const { t } = useI18n();

const emits = defineEmits(["close"]);

useShortcut(
  "attachment-preview-dialog",
  [
    {
      key: "escape",
      shortcut: ShortcutItem.AttachmentPreviewDialogClose,
      description: t("common.closeDialog"),
      callback: closeDialog,
    },
    {
      key: "d",
      shortcut: ShortcutItem.AttachmentPreviewDialogDownload,
      description: t("conversation.display.attachment.downloadFile"),
      callback: onDownloadClick,
    },
  ],
  {
    newShortcutStack: true,
    includeGlobal: true,
  },
);

function onDownloadClick() {
  window.location.href = props.downloadUrl;
}

function closeDialog() {
  enabled.value = false;
}

function onDialogHide() {
  emits("close");
}
</script>
