<template>
  <slot />
</template>

<script setup lang="ts">
import { useAuthState } from "@frontegg/vue";
import { onMounted, onBeforeUnmount } from "vue";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import { tenantInfoStore } from "stores/tenant-info-global-store";

const authState = useAuthState();

const identifyUser = async () => {
  if (!authState.user) {
    console.error("No user data, skipping June identify");
    return;
  }

  const agentState = agentSettingsStore.getState();
  const tenantState = tenantInfoStore.getState();

  await window.juneAnalytics?.identify(authState.user.id, {
    email: authState.user.email,
    // Optional
    name: agentState.name,
    createdAt: authState.user.createdAt,
  });

  await window.juneAnalytics?.group(authState.user.tenantId, {
    id: authState.user.tenantId,
    name: authState.tenantsState.activeTenant?.name,
    hasAtLeastOneConversation: tenantState.onboardingStatus.hasAtLeastOneConversation,
    hasInstalledWidgetOnWebsite: tenantState.onboardingStatus.hasInstalledWidgetOnWebsite,
  });
};

onMounted(async () => {
  if (!authState.user) {
    console.error("No user data, skipping Gleap init");
    return;
  }

  await identifyUser();
});

onBeforeUnmount(async () => {
  await window.juneAnalytics?.reset();
});
</script>

<style scoped lang="scss"></style>
