<template>
  <div>
    <div class="flex lg:hidden">
      <ConversationToolbarBtn icon="fa-light fa-ellipsis-vertical" @click="onMobileClick" />
    </div>
    <div class="hidden lg:flex space-x-2">
      <template v-for="(action, idx) in actions" :key="idx">
        <router-link v-if="action.route" :to="action.route">
          <ConversationToolbarBtn
            :icon="action.icon"
            @click="action.action"
            :tooltip="action.description"
            :shortcuts="action.shortcut"
          />
        </router-link>
        <ConversationToolbarBtn
          v-else
          :icon="action.icon"
          @click="action.action"
          :tooltip="action.description"
          :shortcuts="action.shortcut"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import ConversationToolbarBtn from "src/features/conversation/conversation-display/ConversationToolbarBtn.vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { ActionButtonCollectionActionProp } from "./props";

const $q = useQuasar();
const { t } = useI18n();

const props = defineProps({
  actions: {
    type: Array as PropType<ActionButtonCollectionActionProp[]>,
    required: true,
  },
});

const onMobileClick = () => {
  let i = 0;
  $q.bottomSheet({
    message: t("common.actions"),
    grid: true,
    actions: props.actions.map(action => {
      return {
        label: action.description,
        id: i++,
        icon: action.icon,
      };
    }),
  }).onOk(action => {
    console.log("Action chosen:", action.id);
    const actionCallback = props.actions[action.id].action;

    if (actionCallback) {
      actionCallback();
    }
  });
};
</script>

<style scoped lang="scss"></style>
