import { emitter } from "src/boot/mitt";
import { ref, computed } from "vue";

const currentTenantId = ref<string>();

export function useCurrentTenant() {
  return {
    currentTenantId: computed(() => currentTenantId.value),
    setTenantId: (tenantId: string) => {
      const hadPreviousTenant = !!currentTenantId.value;

      currentTenantId.value = tenantId;

      if (hadPreviousTenant) {
        emitter.emit("auth-tenant-changed");
      }
    },
    reset: () => {
      currentTenantId.value = undefined;
    },
  };
}
