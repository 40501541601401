<template>
  <q-avatar dense color="grey-4" round :size="size" text-color="black" class="select-none">
    {{ teamData?.emoji ?? initials }}
    <s-tooltip v-if="tooltip" class="text-base">{{ teamData?.name }}</s-tooltip>
  </q-avatar>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { resolveTeamInitials } from "src/shared/display-name-helper";
import STooltip from "components/STooltip.vue";
import { teamListGlobalStore } from "stores/team-list-global-store";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator: (value: string) => {
      return !!teamListGlobalStore.getById(value);
    },
  },

  size: {
    type: String,
    required: false,
    default: () => "md",
  },

  tooltip: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const teamData = teamListGlobalStore.getById(props.id);

const initials = ref("");
initials.value = resolveTeamInitials(teamData.value?.name);
</script>

<style lang="sass"></style>
