export const KeyIds: (isWindows: boolean) => { [index: string]: number } = (isWindows: boolean) => {
  return {
    alt: 0b1_00000000,
    option: 0b1_00000000,
    command: 0b10_00000000,
    mod: isWindows ? 0b100_00000000 : 0b10_00000000,
    meta: 0b10_00000000,
    ctrl: 0b100_00000000,
    control: 0b100_00000000,
    shift: 0b1000_00000000,

    /* SPECIAL CHARACTERS */
    backspace: 1,
    capslock: 2,
    del: 3,
    delete: 3,
    down: 4,
    end: 5,
    enter: 13,
    return: 6,
    home: 8,
    insert: 9,
    left: 100,
    pagedown: 101,
    pageup: 102,
    right: 103,
    space: 14,
    spacebar: 14,
    tab: 15,
    up: 16,
    esc: 27,
    z: 90,
    comma: 188,
  };
};

export const KeySymbols: (isWindows: boolean) => { [index: number]: string } = (isWindows: boolean) => {
  return {
    0b1_00000000: "⌥", // alt
    0b10_00000000: "⌘", // cmd
    0b100_00000000: isWindows ? "ctrl" : "⌃", // ctrl
    0b1000_00000000: isWindows ? "shift" : "⇧", // shift
    /* SPECIAL CHARACTERS */
    1: "⌫", // backspace
    2: "⇪", // capslock
    3: "⌦", // delete
    4: "↓", // down
    5: "↘", // end
    13: isWindows ? "enter" : "⏎", // enter
    27: "esc", // escape
    8: "↖", // home
    9: "⎀", // insert
    100: "←", // left
    101: "⇟", // pagedown
    102: "⇞", // pageup
    103: "→", // right
    14: "␣", // space
    15: "⇥", // tab
    16: "↑", // up
    90: "Z",
    188: ",",
  };
};

export function getSymbolIcon(symbol: string, isWindows: boolean) {
  const keyId = KeyIds(isWindows)[symbol];
  return keyId ? KeySymbols(isWindows)[keyId] : symbol.toUpperCase();
}
