import { onUnmounted, watch } from "vue";
import {
  startWatchConversation,
  stopWatchConversation,
} from "src/features/conversation/conversation-display/api-commands";
import { useTimeout } from "src/composables/useTimeout";
import { useInterval } from "src/composables/useInterval";
import { useIdle } from "@vueuse/core";

export function useWatchConversationHandler(conversationId: string) {
  const { idle } = useIdle(15 * 60 * 1000); // 15 minutes
  let hasSentWatchCommand = false;

  useTimeout(async () => {
    await startWatchConversation(conversationId);
    hasSentWatchCommand = true;
  }, 5);

  useInterval(async () => {
    if (idle.value) {
      console.log("Will not send watch command as agent is idle");
      return;
    }

    await startWatchConversation(conversationId);
  }, 300);

  onUnmounted(async () => {
    if (hasSentWatchCommand) {
      await stopWatchConversation(conversationId);
    }
  });

  watch(idle, async idleValue => {
    if (idleValue) {
      await stopWatchConversation(conversationId);
    } else {
      await startWatchConversation(conversationId);
    }
  });
}
