import Mousetrap from "mousetrap";
import { onMounted, onBeforeUnmount, ref, computed, nextTick, isRef, watch, unref, ComputedRef } from "vue";
import { emitter } from "boot/mitt";
import { TriggerShortcut } from "src/shared/mitt-events";
import { ShortcutItem } from "src/shared/shortcut-item";
import { isNullOrUndefined } from "src/shared/object-utils";
import "mousetrap-global-bind";
import { TrackedEvent, useTracking } from "./useTracking";

export interface ShortcutOptions {
  /** Handle bind/unbind manually */
  ignoreMount?: boolean;

  /** Create a new shortcut stack, used by dialogs/drawers */
  newShortcutStack?: boolean;

  /** Should the new stack include global shortcuts? */
  includeGlobal?: boolean;

  /** Suspend the shortcuts for current stack - used by command palette dialog */
  suspendShortcuts?: boolean;
}

export interface Shortcut {
  /** The keyboard key to trigger shortcut, i.e 'a', 'mod+k' */
  key?: string;

  /** The callback when shortcut is triggered */
  callback: () => void;

  /** The description in global command palette */
  description?: string;

  /** The shortcut item, to be able to trigger shortcuts without a key */
  shortcut?: ShortcutItem;

  /** Show in global comand palette? */
  sendToCommandPalette?: boolean;

  /** Use mousetrap global bind that overrides bindings in textarea etc */
  useGlobalBind?: boolean;

  /** Allow holding down the key and trigger multiple events? */
  allowRepeat?: boolean;

  /** Is the shortcut global, meaning it should persist between stacks */
  globalShortcut?: boolean;
}

interface StackedShortcut extends Shortcut {
  caller: string;
}

interface ShortcutContext {
  options: ShortcutOptions;
  shortcuts: StackedShortcut[];
}

// A stack of shortcuts. The ones currently in effect are on top.
const shortcutStack = ref<ShortcutContext[]>([]);

export function useShortcut(
  caller: string,
  shortcuts: Shortcut[] | ComputedRef<Shortcut[]>,
  options?: ShortcutOptions,
) {
  const { trackEvent } = useTracking();

  let actualShortcuts: Shortcut[];
  let isBound = false;

  if (isRef(shortcuts)) {
    actualShortcuts = shortcuts.value;
    watch(shortcuts, newShortcuts => {
      setShortcuts(newShortcuts);
    });
  } else {
    actualShortcuts = shortcuts;
  }

  const bindShortcuts = (newShortcuts: Shortcut[]) => {
    // const newShortcutStr = newShortcuts
    //   .filter(i => !isNullOrUndefined(i.key))
    //   .map(i => i.key)
    //   .join(", ");

    //console.debug("useShortcut", caller, "Binding shortcuts:", newShortcutStr, "with options", options);

    for (const { key, callback, useGlobalBind, allowRepeat } of newShortcuts) {
      if (!isNullOrUndefined(key)) {
        const action = (e: KeyboardEvent) => {
          if ((allowRepeat === undefined || !allowRepeat) && e.repeat) {
            console.debug("useShortcut", caller, "Ignoring repeated", e.code);
            return;
          }

          if (e.preventDefault) {
            e.preventDefault();
          }

          callback();

          if (key) {
            trackEvent(TrackedEvent.UsedKeyboardShortcut, {
              shortcut: key,
            });
          }
        };

        const keyTriggerEvent =
          allowRepeat === true
            ? "keydown" // For proper 'repeat' detection across Win/Mac. "keypress" will always have `repeat: false` under Windows.
            : undefined; // Let mousetrap decide

        if (useGlobalBind !== undefined && useGlobalBind) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Mousetrap.bindGlobal(key, action, keyTriggerEvent);
        } else {
          Mousetrap.bind(key, action, keyTriggerEvent);
        }
      }
    }
  };

  const unbindShortcuts = (newShortcuts: Shortcut[]) => {
    if (newShortcuts.length === 0) {
      return;
    }

    // const shortcutStr = newShortcuts
    //   .filter(i => !isNullOrUndefined(i.key))
    //   .map(i => i.key)
    //   .join(", ");

    // console.debug("useShortcut", caller, "Unbinding shortcuts:", shortcutStr);

    // for (const { key } of newShortcuts) {
    //   if (!isNullOrUndefined(key)) {
    //     Mousetrap.unbind(key);
    //   }
    // }
  };

  const triggerShortcut = (data: TriggerShortcut) => {
    unref(shortcuts).forEach(shortcut => {
      if (data.shortcut === shortcut.shortcut) {
        shortcut.callback();
      }
    });
  };

  const listenToTriggers = () => {
    emitter.on("trigger-shortcut", triggerShortcut);
  };

  const removeListenToTriggers = () => {
    emitter.off("trigger-shortcut", triggerShortcut);
  };

  const emitShortcut = (shortcut: ShortcutItem) => {
    emitter.emit("trigger-shortcut", {
      shortcut: shortcut,
    });
  };

  const setShortcuts = (newShortcuts: Shortcut[]) => {
    // console.debug(
    //   "New shortcuts",
    //   newShortcuts.map(i => i.description),
    // );

    // Might not be bound because of onMounted not firing yet
    if (!isBound) {
      actualShortcuts = newShortcuts;
      return;
    }

    unbind();
    actualShortcuts = newShortcuts;
    bind();
  };

  const bind = () => {
    if (isBound) {
      return;
    }

    isBound = true;
    listenToTriggers();
    createNewShortcutStack();
    bindShortcuts(unref(actualShortcuts));
  };

  const unbind = () => {
    removeListenToTriggers();
    unbindShortcuts(unref(actualShortcuts));
    removeShortcutsFromCurrentShortcutStack();

    if (isCurrentShortcutStackEmpty()) {
      applyLastShortcutStack();
    }

    isBound = false;
  };

  const createNewShortcutStack = () => {
    // A new stack requested, or the first stack. Unbind the shortcuts for the previous stack, if it exists.
    if (options?.newShortcutStack || shortcutStack.value.length === 0) {
      if (shortcutStack.value.length) {
        const currentStack = shortcutStack.value[shortcutStack.value.length - 1];
        const oldShortcuts = options?.includeGlobal
          ? currentStack.shortcuts.filter(i => !i.globalShortcut)
          : currentStack.shortcuts;
        unbindShortcuts(oldShortcuts);
      }

      console.debug("useShortcut", caller, "Adding new shortcut stack");

      const newStack = {
        caller: caller,
        options: options ?? {},
        shortcuts: actualShortcuts.map(s => {
          return { ...s, caller: caller };
        }),
      };

      if (options?.includeGlobal) {
        console.debug("useShortcut", caller, "Including global shortcuts in new stack");
        shortcutStack.value.forEach(stack => {
          stack.shortcuts.forEach(shortcut => {
            if (shortcut.globalShortcut) {
              newStack.shortcuts.push(shortcut);
            }
          });
        });
      }

      shortcutStack.value.push(newStack);
    }

    // The shortcuts are being added to the current stack
    else {
      //console.debug("useShortcut", caller, "Adding shortcuts to current stack");
      const lastStack = shortcutStack.value[shortcutStack.value.length - 1];

      actualShortcuts.forEach(s => {
        if (
          lastStack.shortcuts.findIndex(i => i.caller === caller && i.shortcut === s.shortcut && i.key === s.key) !== -1
        ) {
          console.debug("useShortcut", caller, "Shortcut", s.shortcut, "Key", s.key, "already added");
        } else {
          // console.debug("useShortcut", caller, "Adding", s.key);
          lastStack.shortcuts.push({ ...s, caller: caller });
        }
      });
    }
  };

  const applyLastShortcutStack = () => {
    if (shortcutStack.value.length === 0) {
      return;
    }

    console.debug("useShortcut", caller, "Removing last shortcut stack");
    shortcutStack.value.pop();

    if (shortcutStack.value.length > 0) {
      bindShortcuts(shortcutStack.value[shortcutStack.value.length - 1].shortcuts);
    }
  };

  const removeShortcutsFromCurrentShortcutStack = () => {
    if (shortcutStack.value.length === 0) {
      return;
    }

    const lastStack = shortcutStack.value[shortcutStack.value.length - 1];

    actualShortcuts.forEach(s => {
      const index = lastStack.shortcuts.findIndex(l => l.shortcut === s.shortcut && l.caller === caller);

      if (index !== -1) {
        // console.debug("useShortcut", caller, "Removing", lastStack.shortcuts[index].key);
        lastStack.shortcuts.splice(index, 1);
      }
    });
  };

  const isCurrentShortcutStackEmpty = () => {
    if (shortcutStack.value.length === 0) {
      return false;
    }

    const lastStack = shortcutStack.value[shortcutStack.value.length - 1];
    const isNewStack = lastStack.options.newShortcutStack !== undefined && lastStack.options.newShortcutStack;
    const isEmpty = isNewStack && lastStack.shortcuts.filter(i => !i.globalShortcut).length === 0;
    //console.debug("useShortcut", caller, "Is current stack empty?", isEmpty);
    return isEmpty;
  };

  const suspendShortcuts = () => {
    if (shortcutStack.value.length) {
      const currentStack = shortcutStack.value[shortcutStack.value.length - 1];
      unbindShortcuts(currentStack.shortcuts);
    }
  };

  const resumeShortcuts = () => {
    if (shortcutStack.value.length) {
      const currentStack = shortcutStack.value[shortcutStack.value.length - 1];
      bindShortcuts(currentStack.shortcuts);
    }
  };

  if (options?.ignoreMount === undefined || options?.ignoreMount === false) {
    onMounted(() => {
      void nextTick(() => {
        bind();

        if (options?.suspendShortcuts === true) {
          suspendShortcuts();
        }
      });
    });

    onBeforeUnmount(() => {
      unbind();

      if (options?.suspendShortcuts === true) {
        resumeShortcuts();
      }
    });
  }

  const activeShortcuts = computed(() => {
    const list: StackedShortcut[] = [];

    if (shortcutStack.value.length) {
      const lastStack = shortcutStack.value[shortcutStack.value.length - 1];

      lastStack.shortcuts.forEach(s => {
        list.push(s);
      });
    }

    return list;
  });

  return {
    bind,
    unbind,
    emitShortcut,
    setShortcuts,
    activeShortcuts,
    shortcutStack,
  };
}
