<template>
  <div>
    <div class="flex flex-row items-center px-6 space-x-4 mt-4">
      <q-avatar size="64px" color="white" text-color="black">{{ contactInitials }}</q-avatar>
      <h3 class="font-bold text-2xl">{{ contactName }}</h3>
    </div>

    <div class="mt-4 mx-4 px-4 py-2 border rounded-md">
      <h3 class="font-medium py-2 text-xs uppercase flex items-center">
        <q-icon name="fa-solid fa-at" size="xs" class="mr-3" />
        <!-- <AtSymbolIcon class="h-5 w-5 mr-2 text-gray-500 dark:text-zinc-400" /> -->
        <span>{{ $t("contact.contactInfoPanel.addresses") }}</span>
      </h3>

      <dl v-if="contactAddresses" class="border-t divide-y divide-gray-200">
        <div v-for="(value, key) in contactAddresses" :key="key" class="text-sm py-5">
          <template v-if="value.length">
            <p class="space-y-6 text-xs font-medium mb-2 flex items-center">
              <q-icon :name="contactAddressTypeIconMap[key as GInputContactAddressTypeItem]" size="xs" />
              {{ contactAddressTypeDescriptions[key as GInputContactAddressTypeItem] }}
            </p>

            <div v-for="address in value" :key="address" class="py-1.5 flex justify-between items-center text-sm">
              <dt class="max-w-xs overflow-hidden overflow-ellipsis">
                {{ address.address }}
              </dt>
              <dd class="text-gray-900 whitespace-nowrap">
                <!-- <Tooltip placement="top" text="New conversation">
                  <button
                    type="button"
                    @click.prevent="navigateToNewConversation(address.address)"
                    class="custom-focus non-initial-focus rounded-full p-1.5 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-wh-black"
                  >
                    <IconBase
                      class="h-4 w-4 text-gray-500 dark:text-zinc-400"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                    >
                      <NewMessage />
                    </IconBase>
                  </button>
                </Tooltip> -->

                <!-- <CopyToClipboardButton
                  icon-class-list="h-4 w-4 text-gray-500 dark:text-zinc-400"
                  :value-to-copy="address.address"
                /> -->
              </dd>
            </div>
          </template>
        </div>
      </dl>

      <p
        v-else
        class="text-gray-500 dark:text-zinc-400 text-sm mt-2 border-t border-gray-200 dark:border-zinc-500 py-3 pr-2"
      >
        {{ $t("contact.contactInfoPanel.noKnownAddresses") }}
      </p>
    </div>

    <div class="mt-6 mx-4 px-4 py-2 border rounded-md border-gray-200">
      <h3 class="font-medium py-2 text-xs uppercase flex items-center">
        <q-icon name="fa-light fa-comments" size="xs" class="mr-3" />
        <!-- <ChatAlt2Icon class="h-5 w-5 mr-2 text-gray-500 dark:text-zinc-400" /> -->
        <span>{{ $t("contact.contactInfoPanel.latestConversations") }}</span>

        <!-- <a
          href="#"
          tabindex="0"
          v-if="contactConversations?.length"
          class="custom-focus text-gray-500 dark:text-zinc-400 ml-auto justify-end flex hover:underline justify-self-end"
          @click.prevent="navigateToContactConversations"
        >
          {{ $t("common.showAll") }}
        </a> -->
      </h3>
      <dl
        v-if="contactConversations?.length"
        class="border-t border-gray-200 divide-y divide-gray-300 dark:border-zinc-500 dark:divide-zinc-500"
      >
        <a
          v-for="conversation in contactConversations"
          :key="conversation.id"
          class="py-3 text-sm cursor-pointer mb-1 flex flex-col items-start"
          @click.prevent="navigateToConversation(conversation.id)"
          :href="resolveConversationUrl(conversation.id)"
        >
          <p class="text-xs">
            <q-icon name="fa-light fa-clock" size="14px" />
            <!-- <CalendarIcon class="h-4 w-5 text-gray-300 mr-1" /> -->
            <UpdatedAgo :updated="conversation.started" />
          </p>

          <div class="flex justify-between items-center">
            <dt class="flex items-center">
              {{ resolveDescription(conversation.description) }}
              <ConversationStatusChipDisplay :status="conversation.status" />
            </dt>
            <dd class="text-gray-900">
              <button
                type="button"
                class="custom-focus rounded-full p-1.5 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-wh-black"
              >
                <q-icon name="fa-light fa-arrow-up-right-from-square" size="xs" />
                <!-- <ExternalLinkIcon class="h-4 w-4 text-gray-500 dark:text-zinc-400" /> -->
              </button>
            </dd>
          </div>
        </a>
      </dl>
      <p v-else class="text-sm mt-2 border-t border-gray-200 dark:border-zinc-500 py-3 pr-2">
        {{ $t("contact.contactInfoPanel.noRecentConversations") }}
      </p>
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <s-drawer-btn @click="onEscape" label="Lukk" />
      <!-- <s-drawer-btn label="Slå sammen" tooltip="test" />
      <s-drawer-btn label="Vis hele profilen" primary tooltip="test" /> -->
    </Teleport>
  </div>
</template>

<script async setup lang="ts">
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import SDrawerBtn from "components/SDrawerBtn.vue";
import { computed, ref, watch } from "vue";
import { closeDrawer, drawerRef, globalDrawerProps } from "src/composables/useGlobalDrawer";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { resolveDisplayName, resolveNameInitials } from "src/shared/display-name-helper";
import { contactAddressTypeIconMap, showAddressForContactAddressTypes } from "src/shared/constants";
import { groupArrayByKey } from "src/shared/object-utils";
import { GInputContactAddressTypeItem } from "src/generated/graphql";
import { useRoute, useRouter } from "vue-router";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import { useI18n } from "vue-i18n";
import ConversationStatusChipDisplay from "src/features/conversation/conversation-display/ConversationStatusChipDisplay.vue";

const props = defineProps({
  contactId: {
    type: String,
    required: true,
  },
  ...globalDrawerProps,
});

const sdk = useGraphqlSdk();
const router = useRouter();
const { t } = useI18n();
const route = useRoute();

const contactData = ref(
  await sdk.GetContactById({
    id: props.contactId,
  })
);

const contactAddressTypeDescriptions: Record<GInputContactAddressTypeItem, string> = {
  [GInputContactAddressTypeItem.EmailAddress]: "E-mail addresses",
  [GInputContactAddressTypeItem.FacebookUserId]: "Facebook",
  [GInputContactAddressTypeItem.PhoneNumber]: "Phone numbers",
  [GInputContactAddressTypeItem.ChatVisitorId]: "Chat Visitor IDs",
  [GInputContactAddressTypeItem.Unknown]: "Unknown",
};

const resolveConversationUrl = (id: string) => {
  return router.resolve({
    name: "conversation",
    params: {
      id,
    },
  })?.fullPath;
};

const currentConversationId = computed(() => {
  return route.params.id;
});

const resolveDescription = (description: string | null | undefined) => {
  return description || `<${t("conversation.common.noSubject")}>`;
};

const navigateToConversation = async (conversationId: string) => {
  if (conversationId === currentConversationId.value) {
    onEscape();
  }

  await router.push({
    name: "conversation",
    params: {
      id: conversationId,
    },
  });
};

const contactName = computed(() =>
  resolveDisplayName(contactData.value.contactById.firstName, contactData.value.contactById.lastName)
);

const contactInitials = computed(() =>
  resolveNameInitials(contactData.value.contactById.firstName, contactData.value.contactById.lastName)
);

const contactAddresses = computed(() => {
  const visibleAddresses = contactData.value?.contactById.address.filter(
    address => address?.addressType && showAddressForContactAddressTypes.includes(address?.addressType)
  );

  const grouped = groupArrayByKey(visibleAddresses, "addressType");
  return Object.keys(grouped)?.length ? grouped : null;
});

// TODO Remove/highlight current conversation
const conversations = await sdk.GetContactConversations({
  take: 6,
  // TODO: fix me
  contactId: props.contactId,
  includeTotalCount: false,
});

const contactConversations = computed(() =>
  conversations.contactConversations.items.filter(i =>
    currentConversationId.value ? i.id !== currentConversationId.value : true
  )
);

watch(
  () => props.contactId,
  async () => {
    contactData.value = await sdk.GetContactById({
      id: props.contactId,
    });
  }
);

useShortcut(
  "contact-drawer",
  [
    {
      key: "esc",
      callback: () => onEscape(),
      description: "Close contact drawer",
      shortcut: ShortcutItem.ConversationHideContact,
    },
    {
      key: "i",
      callback: () => onEscape(),
      description: "Close contact drawer",
      shortcut: ShortcutItem.ConversationHideContact,
    },
    { key: "m", callback: () => placeholder(), description: "Merge" },
    {
      key: "enter",
      callback: () => placeholder(),
      description: "Show profile",
    },
  ],
  {
    newShortcutStack: true,
    includeGlobal: true,
  }
);

// watch(
//   () => props.isVisible,
//   () => {
//     if (!props.isVisible) {
//       shortcut.unbind();
//     } else {
//       shortcut.bind();
//     }
//   }
// );

function onEscape() {
  closeDrawer();
}

function placeholder() {
  //
}
</script>

<style lang="scss" scoped>
.q-icon {
  margin-right: 6px;
}
</style>
