<template>
  <q-tooltip class="sa-tooltip" v-bind="tooltipProps">
    <span class="text-sm">
      <slot />
    </span>
  </q-tooltip>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { QTooltipProps } from "quasar";

const props = defineProps({
  position: {
    type: String as PropType<"left" | "right" | "top" | "bottom">,
    default: "bottom",
  },
});

const tooltipProps = computed<QTooltipProps>(() => {
  switch (props.position) {
    case "right": {
      return {
        anchor: "center right",
        self: "center left",
        offset: [10, 10],
      };
    }

    case "left": {
      return {
        anchor: "center left",
        self: "center right",
        offset: [10, 10],
      };
    }

    case "top": {
      return {
        anchor: "top middle",
        self: "bottom middle",
        offset: [10, 10],
      };
    }

    // bottom
    default: {
      return {
        anchor: "bottom middle",
        self: "top middle",
        offset: [10, 10],
      };
    }
  }
});
</script>

<style lang="sass"></style>

<i18n>
{
  "en": {
  },
  "no": {
  }
}
</i18n>
