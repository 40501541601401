<template>
  <div :class="`flex flex-row relative w-full rounded-md ${className}`">
    <div class="py-1 flex-1">
      <div @click="onContainerClick" class="relative" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <editor-content
          ref="editorEl"
          class="sa-editor"
          :name="name"
          :disabled="disabled || undefined"
          :editor="editor as Editor"
          :spellcheck="spellcheck"
        />

        <sa-editor-drop-target v-if="fileUpload" @upload="onUpload" />
      </div>
    </div>

    <div
      v-if="isWorking"
      class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 flex flex-row space-x-2 items-center justify-center rounded-lg"
    >
      <q-spinner />
      <div>Please wait..</div>
    </div>
  </div>

  <div>
    <slot name="bottom-buttons" />
  </div>

  <sa-editor-text-bubble-menu :editor="editor as Editor" v-if="editor && textBubbleMenu" />
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, PropType, ref } from "vue";
import {
  AnyExtension,
  Editor,
  EditorContent,
  EditorOptions,
  FocusPosition,
  mergeAttributes,
  useEditor,
} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { ImageUploadExtension } from "./extensions/image-upload/image-upload-extension";
import { Link } from "@tiptap/extension-link";
import { isNullOrUndefined } from "src/shared/object-utils";
import agentSuggestion from "src/features/tip-tap-editor/extensions/agent-mention/agent-suggestion";
import bodyIntroSuggestion, {
  PlaceholderReplacementData,
} from "src/features/tip-tap-editor/extensions/template/template-intro-suggestion";
import knowledgeBaseArticleSuggestion from "src/features/tip-tap-editor/extensions/knowledge-base-article-suggestion/knowledge-base-article-suggestion";
import { TemplateIntro } from "src/features/tip-tap-editor/extensions/template/template-intro";
import { Mention } from "@tiptap/extension-mention";
import SaEditorDropTarget, { UploadedFile } from "src/features/tip-tap-editor/SaEditorDropTarget.vue";
import SaEditorTextBubbleMenu from "src/features/tip-tap-editor/SaEditorTextBubbleMenu.vue";
import { Paragraph } from "@tiptap/extension-paragraph";
import type { BodyScrollOptions } from "body-scroll-lock";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { PastedImage } from "src/features/tip-tap-editor/extensions/pasted-image/pasted-image";
import { useChangeWatcher } from "src/features/tip-tap-editor/composables/useChangeWatcher";
import Emoji from "@tiptap-pro/extension-emoji";
import emojiSuggestion from "src/features/tip-tap-editor/extensions/emoji/emoji-suggestion";
import { useQuasar } from "quasar";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { Slice, Fragment, Node, ResolvedPos } from "@tiptap/pm/model";
import { KnowledgeBaseArticleNode } from "./extensions/knowledge-base-article-suggestion/knowledge-base-article-plugin";
import { TrackedEvent, useTracking } from "../../composables/useTracking";

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

/**
 * Prosemirror will inject a `<br class="ProseMirror-trailingBreak"/>` automatically into empty divs,
 * so if we already have a `<br />`, we'll remove our own to prevent double linebreaks
 */
const removeExtraBr = (data: string) => {
  if (!data) {
    return data;
  }

  const regexBr = new RegExp("<div><br /></div>", "gi");
  return data.replaceAll(regexBr, "<div></div>");
};

export interface ISaEditor {
  getHTML: () => string;
  focus: () => void;
  insertContent: (content: string) => void;
  translate: (language: string) => void;
  improveLanguage: () => void;
  fixGrammar: () => void;
  showAskTranslateLanguageDialog: () => void;
  isEmpty: () => boolean;
}

const props = defineProps({
  // height: {
  //   type: Number,
  //   required: true,
  // },

  content: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    required: false,
  },

  disabled: {
    type: Boolean,
    required: false,
  },

  imagePaste: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  fileUpload: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  agentMention: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  introTemplate: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  knowledgeBaseSuggestions: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  emoji: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  emojiEmoticons: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  textBubbleMenu: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  imgBubbleMenu: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  paragraphAsDiv: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  bodyScrollLock: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  autofocus: {
    type: String as PropType<FocusPosition>,
    required: false,
  },

  changeWatch: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  spellcheck: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  class: {
    type: String,
    required: false,
    default: () => "",
  },

  introTemplatePlaceholderContent: {
    type: Object as PropType<PlaceholderReplacementData>,
    required: false,
  },
});

const emit = defineEmits([
  "focus",
  "blur",
  "escape",
  "mod+enter",
  "mod+shift+enter",
  "upload",
  "change-diff",
  "change-snapshot",
  "update",
]);
const $q = useQuasar();
const editorEl = ref<HTMLElement>();
const { trackEvent } = useTracking();

if (isNullOrUndefined(process.env.REST_API)) {
  throw new Error("REST_API is not defined in config");
}

const uploadUrl = `${process.env.REST_API}/upload`;
console.log("Upload URL is", uploadUrl);

const isWorking = ref(false);

// const editorHeight = computed(() => props.height - 30);

// useShortcut(
//   "saEditor",
//   [
//     {
//       shortcut: ShortcutItem.SaEditorTranslate,
//       callback: () => showAskTranslateLanguageDialog(),
//       sendToCommandPalette: true,
//       useGlobalBind: true,
//       description: "Translate text in editor",
//     },
//   ],
//   {}
// );

const className = props.class.toString();

const extensions = getExtensions();

let editorOptions: Partial<EditorOptions> = {
  content: removeExtraBr(props.content),

  editorProps: {
    handleKeyDown(view, event: KeyboardEvent) {
      if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
        emit(event.shiftKey ? "mod+shift+enter" : "mod+enter");
        return true;
      }

      if (event.key === "PageDown" || event.key === "PageUp") {
        return true;
      }

      if (event.key === "Escape") {
        const element = document.querySelector("[data-tippy-root]");
        if (document.getElementsByClassName("sa-editor-popup").length === 0 || !element) {
          // if (editor.value?.isFocused) {
          //   editor.value?.commands.blur();
          // } else {
          //   emit("escape");
          // }

          emit("escape");
        }

        return true;
      }

      return false;
    },

    // Prosemirror cuts extra newlines when pasting plain text.
    // https://github.com/ueberdosis/tiptap/issues/775
    clipboardTextParser(text: string, context: ResolvedPos) {
      const blocks = text.split(/(?:\r\n?|\n)/);
      const nodes: Node[] = [];

      blocks.forEach(line => {
        let nodeJson: { type: string; content?: Array<unknown> } = { type: "paragraph" };
        if (line.length > 0) {
          nodeJson.content = [{ type: "text", text: line }];
        }
        let node = Node.fromJSON(context.doc.type.schema, nodeJson);
        nodes.push(node);
      });

      const fragment = Fragment.fromArray(nodes);
      return Slice.maxOpen(fragment);
    },
  },

  onUpdate() {
    emit("update");
  },

  onFocus() {
    // bind();
    emit("focus");
  },

  onBlur() {
    // unbind();
    emit("blur");
  },

  extensions: extensions,
};

if (props.autofocus) {
  editorOptions["autofocus"] = props.autofocus;
}

const editor = useEditor(editorOptions);

function getHTML() {
  if (isNullOrUndefined(editor)) {
    throw new Error("Editor not defined");
  }

  // Prevent empty divs collapsing
  // https://github.com/ueberdosis/tiptap/issues/412
  const regexBr = new RegExp("<div></div>", "gi");
  return editor.value?.getHTML().replaceAll(regexBr, "<div><br /></div>") ?? "";
}

function isEmpty() {
  if (isNullOrUndefined(editor.value)) {
    throw new Error("Editor not defined");
  }

  return editor.value.isEmpty || editor.value.getText().trim() === "";
}

function focus() {
  if (isNullOrUndefined(editor)) {
    throw new Error("Editor not defined");
  }

  editor.value?.chain().focus().run();
}

function onContainerClick() {
  focus();
}

function onUpload(attachment: UploadedFile) {
  const isImage = ["image/jpeg", "image/gif", "image/png", "image/jpg"].includes(attachment.contentType);

  if (isImage) {
    insertContent(`<img src="${attachment.previewUrl}" alt="${attachment.filename}" />`);
  } else {
    emit("upload", attachment);
  }
}

function insertContent(content: string) {
  editor.value?.commands.insertContent(content);
}

function showAskTranslateLanguageDialog() {
  $q.dialog({
    title: "Translate",
    message: "Which language do you want to translate the text to?",
    prompt: {
      model: "",
      type: "text", // optional
    },
    cancel: true,
  }).onOk(async data => {
    await translate(data);
  });
}

async function translate(language: string) {
  const { hasContent, content } = editorHasContent();

  if (!hasContent) {
    return;
  }

  isWorking.value = true;

  const sdk = useGraphqlSdk();

  try {
    const res = await sdk.TranslateTextWithHtml({
      text: content,
      language: language,
    });

    if (res.translateTextWithHtml.translation) {
      editor.value?.commands.setContent(res.translateTextWithHtml.translation);
    }

    $q.notify({
      type: "positive",
      message: "The text has been translated",
    });

    trackEvent(TrackedEvent.AssistantTranslatedText, {
      language: language,
    });
  } catch (ex) {
    console.error(ex, "Failed to translate text");
    $q.notify({
      type: "negative",
      message: "Failed to translate text",
    });
  } finally {
    isWorking.value = false;
  }
}

async function improveLanguage() {
  const { hasContent, content } = editorHasContent();

  if (!hasContent) {
    return;
  }

  isWorking.value = true;

  const sdk = useGraphqlSdk();

  try {
    const res = await sdk.ImproveTextWithHtml({
      text: content,
    });

    if (res.improveTextWithHtml.text) {
      editor.value?.commands.setContent(res.improveTextWithHtml.text);
    }

    $q.notify({
      type: "positive",
      message: "The writing has been improved",
    });

    trackEvent(TrackedEvent.AssistantImprovedText);
  } catch (ex) {
    console.error(ex, "Failed to improve writing");
    $q.notify({
      type: "negative",
      message: "Failed to improve writing",
    });
  } finally {
    isWorking.value = false;
  }
}

async function fixGrammar() {
  const { hasContent, content } = editorHasContent();

  if (!hasContent) {
    return;
  }

  isWorking.value = true;

  const sdk = useGraphqlSdk();

  try {
    const res = await sdk.FixGrammarTextWithHtml({
      text: content,
    });

    if (res.fixGrammarTextWithHtml.text) {
      editor.value?.commands.setContent(res.fixGrammarTextWithHtml.text);
    }

    $q.notify({
      type: "positive",
      message: "Spelling and grammar updated",
    });

    trackEvent(TrackedEvent.AssistantFixedGrammar);
  } catch (ex) {
    console.error(ex, "Failed to check grammar and spelling");
    $q.notify({
      type: "negative",
      message: "Failed to check grammar and spelling",
    });
  } finally {
    isWorking.value = false;
  }
}

function editorHasContent() {
  if (!editor.value) {
    return { hasContent: false, content: "" };
  }

  const html = editor.value.getHTML();
  if (isNullOrUndefined(html) || html === "" || html === "<div></div>" || html === "<p></p>") {
    return { hasContent: false, content: "" };
  }

  return { hasContent: true, content: html };
}

// function onTranslate(language: string) {
//   isWorking.value = true;
//
//   const selectedText = getHTMLContentBetween();
//   let allText = editor.value?.getHTML();
//
//   translateTextWithHtml(selectedText, language)
//     .then(res => {
//       allText = allText?.replace(selectedText, res.translation);
//
//       if (!isNullOrUndefined(allText)) {
//         editor.value?.commands.setContent(allText);
//       }
//     })
//     .catch(err => {
//       throw new Error(err);
//     })
//     .finally(() => {
//       isWorking.value = false;
//     });
// }

// function getHTMLContentBetween() {
//   if (isNullOrUndefined(editor.value)) {
//     return "";
//   }
//
//   const { from, to } = editor.value.state.selection;
//   console.log("From", from, "to", to);
//   const { state } = editor.value;
//   const nodesArray: string[] = [];
//
//   state.doc.nodesBetween(from, to, (node, pos, parent) => {
//     if (parent === state.doc) {
//       if (!isNullOrUndefined(editor.value)) {
//         const serializer = DOMSerializer.fromSchema(editor.value.schema);
//         const dom = serializer.serializeNode(node);
//         const tempDiv = document.createElement("div");
//         tempDiv.appendChild(dom);
//         nodesArray.push(tempDiv.innerHTML);
//       }
//     }
//   });
//
//   return nodesArray.join("");
// }

function getExtensions() {
  const extensions: AnyExtension[] = [];

  extensions.push(
    StarterKit.configure({
      paragraph: props.paragraphAsDiv ? false : undefined,
      bulletList: {
        HTMLAttributes: {
          class: "custom-list-disc",
        },
      },
    }),
  );

  extensions.push(
    Link.configure({
      protocols: [],
      autolink: false,
      openOnClick: false,
      HTMLAttributes: {
        rel: "noopener noreferrer",
        target: null,
      },
    }),
  );

  if (props.imagePaste) {
    extensions.push(PastedImage);

    extensions.push(
      ImageUploadExtension.configure({
        acceptMimes: ["image/jpeg", "image/gif", "image/png", "image/jpg"],
        uploadUrl: uploadUrl,
        id: "my-id",
      }),
    );
  }

  if (props.agentMention) {
    extensions.push(
      Mention.configure({
        HTMLAttributes: {
          class: "sa-editor-agent-mention",
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        suggestion: agentSuggestion,
      }),
    );
  }

  if (props.paragraphAsDiv) {
    extensions.push(
      Paragraph.extend({
        parseHTML() {
          return [{ tag: "div" }];
        },
        renderHTML({ HTMLAttributes }) {
          return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
        },
      }),
    );
  }

  if (props.introTemplate) {
    if (props.introTemplatePlaceholderContent) {
      extensions.push(
        TemplateIntro.configure({
          HTMLAttributes: {
            class: "sa-test",
          },
          suggestion: bodyIntroSuggestion(props.introTemplatePlaceholderContent),
        }),
      );
    } else {
      console.warn("When using introTemplate, introTemplatePlaceholderContent must also be provided");
    }
  }

  if (props.knowledgeBaseSuggestions) {
    extensions.push(
      KnowledgeBaseArticleNode.configure({
        HTMLAttributes: {
          class: "",
        },
        suggestion: knowledgeBaseArticleSuggestion(),
      }),
    );
  }

  if (props.emoji) {
    extensions.push(
      Emoji.configure({
        enableEmoticons: props.emojiEmoticons,
        suggestion: emojiSuggestion,
      }),
    );
  }

  return extensions;
}

function onMouseEnter() {
  if (props.bodyScrollLock && !isNullOrUndefined(editorEl.value)) {
    disableBodyScroll(editorEl.value, options);
  }
}

function onMouseLeave() {
  if (props.bodyScrollLock && !isNullOrUndefined(editorEl.value)) {
    enableBodyScroll(editorEl.value);
  }
}

onMounted(() => {
  if (props.changeWatch) {
    useChangeWatcher(
      editor.value as Editor,
      (diffs, sequence) => emit("change-diff", { diffs, sequence }),
      (text, sequence) => emit("change-snapshot", { text, sequence }),
    );
  }
});

onBeforeUnmount(() => {
  // unbind();

  if (props.bodyScrollLock && !isNullOrUndefined(editorEl.value)) {
    enableBodyScroll(editorEl.value);
    clearAllBodyScrollLocks();
  }
});

defineExpose<ISaEditor>({
  getHTML,
  focus,
  insertContent,
  translate,
  showAskTranslateLanguageDialog,
  improveLanguage,
  fixGrammar,
  isEmpty,
});
</script>

<style lang="sass"></style>
