import { boot } from "quasar/wrappers";
import { Locale, createI18n } from "vue-i18n";
import { nextTick } from "vue";
import en from "src/i18n/en.json";

const messages = {
  en: en,
};

export const SUPPORTED_LOCALES = ["en", "no"] as const;

export type MessageLanguages = keyof typeof messages;
// Type-define 'en' as the master schema for the resource
export type MessageSchema = (typeof messages)["en"];

const loadedLocales: string[] = [];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module "vue-i18n" {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}
/* eslint-enable @typescript-eslint/no-empty-interface */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r;

const defaultLocale = "en";

export const i18n = createI18n({
  locale: defaultLocale,
  legacy: false,
  globalInjection: true,
  messages,
});

export async function loadLocaleMessages(locale: Locale) {
  if (!SUPPORTED_LOCALES.some(i => i === locale)) {
    console.warn("Tried to load unsupported locale", locale);
    return;
  }

  if (locale === defaultLocale) {
    console.log("Skipping load of default locale");
    return;
  }

  if (loadedLocales.some(i => i === locale)) {
    console.log("Locale", locale, "is already loaded");
    return;
  }

  console.debug("Loading locale", locale);

  // load locale messages
  const messages = await import(`../i18n/${locale}.json`).then(getResourceMessages);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);
  loadedLocales.push(locale);

  console.debug("Successfully loaded locale", locale);

  return nextTick();
}

export default boot(({ app }) => {
  // Set i18n instance on app
  app.use(i18n);
});
