export enum ShortcutItem {
  Escape,
  CreateConversationAgentComment,
  CreateConversationAgentMessage,
  SuggestKnowledgeBaseArticleFromConversation,
  SummarizeConversation,
  CreateDemoConversations,
  CreateNewConversation,
  ConversationSnooze,
  ConversationArchive,
  ConversationUnArchive,
  ConversationAssign,
  ConversationShowContact,
  ConversationHideContact,
  ConversationCopyUrl,
  ConversationGoto,
  ConversationChangeView,
  ConversationTags,
  ConversationChangeChannel,
  ConversationGoBack,
  RemoveConversationFilter,
  ModifyKnowledgeBaseArticle,
  ConversationShowBotAssistantMode,
  ModifyConversationSnippet,
  DisplayKnowledgeBaseArticle,
  ConversationAgentCommentSend,
  EditorBold,
  EditorCursive,
  Search,
  ShowGlobalCommandPalette,
  NavigateToConversations,
  NavigateToContacts,
  AttachmentPreviewDialogClose,
  AttachmentPreviewDialogDownload,
  AgentNotificationOpenDrawer,
  AgentNotificationSelect,
  AgentNotificationMarkAllAsRead,
  AgentNotificationGoBack,
  ToggleShortcutDiag,
  SaEditorTranslate,
  GoToActiveConversations,
  GoToSnoozedConversations,
  GoToArchivedConversations,
  GoToActivityFeed,
  SwitchTenant,
  GoToKnowledgeBase,
  GoToSettings,
}
