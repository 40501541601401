<template>
  <div></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";

const emit = defineEmits(["mounted", "unmounted"]);

onMounted(() => {
  emit("mounted");
});

onUnmounted(() => {
  emit("unmounted");
});
</script>

<style lang="sass"></style>
