<template>
  <slot />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { appMode } from "src/shared/app";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  navigator.serviceWorker.controller?.postMessage({ type: "SET_APP_MODE", payload: appMode() });

  navigator.serviceWorker.addEventListener("message", async event => {
    if (event.data.action === "navigate") {
      console.log("Got navigate event from service worker:", event.data.url);
      await router.push(event.data.url);
    }
  });
});
</script>

<style lang="sass"></style>
