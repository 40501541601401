<template>
  <FormKit id="agent-form" type="form" @submit="onSubmit" :actions="false">
    <FormKit
      type="text"
      v-model="firstName"
      validation="required"
      :label="$t('settings.agents.shared.firstNameInputLabel')"
      :placeholder="$t('settings.agents.shared.firstNameInputLabel')"
    >
    </FormKit>
    <FormKit
      type="text"
      v-model="lastName"
      validation="required"
      :label="$t('settings.agents.shared.lastNameInputLabel')"
      :placeholder="$t('settings.agents.shared.lastNameInputLabel')"
    >
    </FormKit>

    <FormKit
      type="email"
      v-model="email"
      validation="required"
      :label="$t('settings.agents.shared.emailInputLabel')"
      :placeholder="$t('settings.agents.shared.emailInputLabel')"
    >
    </FormKit>

    <FormKit
      type="autocomplete"
      clear-search-on-open
      :close-on-select="true"
      :selection-removable="false"
      open-on-focus
      v-model="role"
      :label="$t('settings.agents.shared.roleInputLabel')"
      :options="roleOptions"
      validation="required"
    >
      <template #option="{ option, classes }">
        <div :class="classes.option">
          <div>
            {{ option.label }}
          </div>
          <div class="text-xs muted">
            {{ option.description }}
          </div>
        </div>
      </template>
    </FormKit>
  </FormKit>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { GTenantRoleItem } from "src/generated/graphql";
import { useI18n } from "vue-i18n";
import { getNode, FormKitNode, FormKitGroupValue } from "@formkit/core";

const { t } = useI18n();

const roleOptions = [
  {
    label: t("settings.agents.shared.roles.agentLabel"),
    value: GTenantRoleItem.Agent,
    description: t("settings.agents.shared.roles.agentDescription"),
  },
  {
    label: t("settings.agents.shared.roles.managerLabel"),
    value: GTenantRoleItem.Manager,
    description: t("settings.agents.shared.roles.managerDescription"),
  },
  {
    label: t("settings.agents.shared.roles.adminLabel"),
    value: GTenantRoleItem.Admin,
    description: t("settings.agents.shared.roles.adminDescription"),
  },
];

const emit = defineEmits(["update:saving", "created"]);
const sdk = useGraphqlSdk();

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const role = ref<GTenantRoleItem>(GTenantRoleItem.Admin);

const submitForm = () => {
  getNode("agent-form")?.submit();
};

defineExpose({ submitForm });

async function onSubmit(form: FormKitGroupValue, node?: FormKitNode) {
  emit("update:saving", true);

  try {
    emit("update:saving", true);

    await sdk.AgentCreate({
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      role: role.value,
      teamIds: [],
    });

    emit("created");
  } catch (err) {
    node?.setErrors([`Sorry, the agent could not be created.`]);
  } finally {
    emit("update:saving", false);
  }
}
</script>

<style lang="sass"></style>
