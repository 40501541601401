import { onMounted, ref } from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ISForm } from "components/SForm.vue";

export function useFormSave() {
  const form = ref<ISForm | null>(null);

  const error = ref(false);
  const errorMessage = ref("");

  const save = () => {
    error.value = false;
    errorMessage.value = "";

    if (form.value) {
      console.log("Saving in useFormSave");
      form.value.save();
    } else {
      console.log("Save in useFormSave not defined");
    }
  };

  const setError = (message: string) => {
    error.value = true;
    errorMessage.value = message;
  };

  onMounted(() => {
    error.value = false;
    errorMessage.value = "";
  });

  return {
    form,
    save,
    setError,
    error,
    errorMessage,
  };
}
