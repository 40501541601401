<template>
  <!-- <div class="fixed opacity-5 right-2 bottom-0 hover:opacity-50 transition-opacity">
    {{ appVersion }}
  </div> -->
  <slot />
</template>

<script lang="ts" setup>
import { gt } from "semver";
import { ref } from "vue";
import { useQuasar } from "quasar";

const $q = useQuasar();

const appVersion = process.env.appVersion;
const hasAvailableUpdate = ref(false);
const hasTriggeredNotification = ref(false);

const isVersionNewer = (version: string) => {
  if (!appVersion) {
    console.warn("app version was null");
    return false;
  }

  return gt(version, appVersion);
};

let timeout: number;

const versionCheck = async () => {
  try {
    const res = await fetch(window.location.origin + "/version.json?_=" + Math.round(new Date().getTime() / 1000));
    const { version } = await res.json();
    hasAvailableUpdate.value = isVersionNewer(version);

    if (hasAvailableUpdate.value && !hasTriggeredNotification.value) {
      console.log("New version available:", version);
      $q.notify({
        type: "info",
        message: "A new version is available",
        timeout: 0,
        actions: [
          {
            label: "Update now",
            color: "white",
            handler: async () => {
              try {
                if ("serviceWorker" in navigator) {
                  const registrations = await navigator.serviceWorker.getRegistrations();
                  console.log("registrations: ", registrations); // In production, results in an array: [ServiceWorkerRegistration]
                  for (const registration of registrations) {
                    await registration.update(); // Update the service worker
                    // See https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
                    //console.log("promise: ", promise);
                  }
                }
              } finally {
                window.location.reload();
              }
            },
          },
        ],
      });

      hasTriggeredNotification.value = true;
      clearInterval(timeout);
    }
  } catch (err) {
    console.log("Error getting latest version info", err);
  }
};

timeout = window.setInterval(versionCheck, 30_000);
</script>
