<template>
  <q-item-section>
    <q-item-label class="font-semibold">{{ title }}</q-item-label>
    <q-item-label class="line-clamp-2 truncate whitespace-normal">{{ strippedBody }} </q-item-label>
  </q-item-section>
  <q-item-section avatar>
    <q-btn icon="preview" flat>
      <q-tooltip
        anchor="center right"
        self="center left"
        :offset="[30, 0]"
        max-width="500px"
        class="text-sm"
        style="z-index: 99999 !important"
        v-html="body"
      />
    </q-btn>
  </q-item-section>
</template>

<script setup lang="ts">
import { stripHtml } from "src/shared/html-utils";
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  body: {
    type: String,
    required: true,
  },
});

const strippedBody = computed(() => {
  let contentToDisplay = `${stripHtml(props.body)}`;

  // if (contentToDisplay.length > 150) {
  //   contentToDisplay = contentToDisplay.substring(0, 150) + '..."';
  // }

  return contentToDisplay;
});
</script>
