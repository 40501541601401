import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { Store2 } from "stores/store-2";
import { TeamOrAgentType } from "src/shared/models/models";
import {
  GOnAgentModifiedSubscription,
  GOnAgentModifiedSubscriptionVariables,
  OnAgentModifiedDocument,
  GOnAgentCreatedSubscription,
  GOnAgentCreatedSubscriptionVariables,
  OnAgentCreatedDocument,
  OnTeamCreatedDocument,
  GOnTeamCreatedSubscription,
  GOnTeamCreatedSubscriptionVariables,
  GOnTeamModifiedSubscription,
  GOnTeamModifiedSubscriptionVariables,
  OnTeamModifiedDocument,
  GAgentStatusItem,
} from "src/generated/graphql";

export interface ConversationAssignItem {
  id: string;
  label: string;
  type: TeamOrAgentType;
}

export type ConversationAssign = ConversationAssignItem & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationAssignData {}

export class ConversationAssignGlobalStore extends Store2<ConversationAssignData, ConversationAssign> {
  protected data(): ConversationAssignData {
    return {};
  }

  protected setupSubscribe() {
    this.addSubscription<GOnAgentModifiedSubscription, GOnAgentModifiedSubscriptionVariables>(
      OnAgentModifiedDocument,
      {},
      res => {
        if (res.onAgentModified) {
          if (res.onAgentModified.perTenant.status !== GAgentStatusItem.Active) {
            this.removeByFilter(i => i.id === res.onAgentModified.id);
          } else {
            this.replaceById(res.onAgentModified.id, {
              id: res.onAgentModified.id,
              label: res.onAgentModified.name,
              type: TeamOrAgentType.Agent,
            });
          }
        }
      },
    );

    this.addSubscription<GOnAgentCreatedSubscription, GOnAgentCreatedSubscriptionVariables>(
      OnAgentCreatedDocument,
      {},
      res => {
        if (res.onAgentCreated) {
          this.push({
            id: res.onAgentCreated.id,
            label: res.onAgentCreated.name,
            type: TeamOrAgentType.Agent,
          });
        }
      },
    );

    this.addSubscription<GOnTeamModifiedSubscription, GOnTeamModifiedSubscriptionVariables>(
      OnTeamModifiedDocument,
      {},
      res => {
        if (res.onTeamModified) {
          if (res.onTeamModified.deleted) {
            this.removeByFilter(i => i.id === res.onTeamModified.id);
          } else {
            this.replaceById(res.onTeamModified.id, {
              id: res.onTeamModified.id,
              label: res.onTeamModified.name,
              type: TeamOrAgentType.Team,
            });
          }
        }
      },
    );

    this.addSubscription<GOnTeamCreatedSubscription, GOnTeamCreatedSubscriptionVariables>(
      OnTeamCreatedDocument,
      {},
      res => {
        if (res.onTeamCreated) {
          this.push({
            id: res.onTeamCreated.id,
            label: res.onTeamCreated.name,
            type: TeamOrAgentType.Team,
          });
        }
      },
    );
  }
}

export const conversationAssignStore = new ConversationAssignGlobalStore();
