<template>
  <div>
    <q-input
      v-if="!$q.platform.is.mobile"
      type="search"
      ref="filterInput"
      v-model="filter"
      outlined
      rounded
      :placeholder="$t('common.search')"
      dense
      class="mt-4 mx-1 mb-2"
      autofocus
      @keydown.esc="blurFilterInput"
    >
      <template v-slot:prepend>
        <q-icon name="fa-light fa-search" />
      </template>
      <template v-slot:append>
        <q-chip class="" label="/" square dense v-if="$q.platform.is.desktop" />
      </template>
    </q-input>
    <q-list ref="list" padding class="w-full" v-auto-animate :style="{ height: listHeight }">
      <template v-for="(item, idx) in filteredMenu" :key="idx">
        <q-separator v-if="item.type === 'separator'" class="q-my-md" />

        <q-item-label v-else-if="item.type === 'header'" header class="text-weight-bold text-uppercase">
          {{ item.text }}
        </q-item-label>

        <q-expansion-item
          v-else-if="item.type === 'item' && item.children && item.children.length"
          :icon="item.icon"
          :label="item.text"
          group="settings-nav"
          header-class="min-w-[275px]"
          :default-opened="(item.children?.some(i => i.to?.name === $route.name) ?? false) || !!filter?.trim()"
        >
          <template #header>
            <q-item-section side>
              <q-icon color="grey" :name="item.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ item.text }}</q-item-label>
            </q-item-section>
          </template>

          <q-item
            v-for="(subItem, idx) in item.children"
            :key="idx"
            clickable
            :to="subItem.to"
            @click="handleClick(subItem, $event)"
            class="ml-10"
          >
            <!-- <q-item-section side>
            <q-icon color="grey" :name="subItem.icon" />
          </q-item-section> -->
            <q-item-section>
              <q-item-label>{{ subItem.text }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-item clickable v-else :to="item.to" @click="handleClick(item, $event)">
          <q-item-section side>
            <q-icon color="grey" :name="item.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ item.text }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { AdminPortal } from "@frontegg/vue";
import { useIntervalFn } from "@vueuse/core";
import { computed, ref, unref, ComputedRef, watch } from "vue";
import { RouteLocationNamedRaw } from "vue-router";
import { watchDebounced } from "@vueuse/core";
import { useQuasar, QInput, QList } from "quasar";
import { useShortcut } from "src/composables/useShortcut";
import { vAutoAnimate } from "@formkit/auto-animate";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import { GTenantRoleItem } from "../../generated/graphql";
import { hasRequiredRole as hasRequiredRoleUtil } from "src/shared/auth-utils";
import { useFeatureEntitlements } from "@frontegg/vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { Entitlements } from "src/shared/entitlements";
import { useI18n } from "vue-i18n";

const aiEntitlement = useFeatureEntitlements(Entitlements.AiAssistant);

const $q = useQuasar();
const { t } = useI18n();
const agentState = agentSettingsStore.getState();

const handleClick = (item: MenuItem, e: Event) => {
  if (item.onClick) {
    e.preventDefault();
    item.onClick();
  }
};

const listHeight = ref("initial");
const filterInput = ref<QInput>();
const list = ref<QList>();
const intervalCounter = ref(0);
const filter = ref<string>();

const { pause: pauseInterval, resume: resumeInterval } = useIntervalFn(
  () => {
    console.debug("Running interval");
    intervalCounter.value++;

    const el: HTMLElement | undefined | null = document
      ?.getElementById("frontegg-admin-portal-container-default")
      ?.shadowRoot?.querySelector('[data-test-id="navigation"]');

    if (el) {
      el.style.display = "none";
      pauseInterval();
    } else {
      if (intervalCounter.value > 15) {
        pauseInterval();
      }
    }
  },
  100,
  {
    immediate: false,
  },
);

const openAdminPortal = (page: string) => {
  window.location.href = `#/admin-box/${page}`;
  AdminPortal.show();

  resumeInterval();
};

const hasRequiredRole = (item: MenuItem) => {
  if (!item.requiredRole) {
    return true;
  }

  return hasRequiredRoleUtil(agentState.perTenant.role, item.requiredRole);
};

const hasEntitlement = (item: MenuItem) => {
  if (isNullOrUndefined(item.isEntitled)) {
    return true;
  }

  return item.isEntitled() === true;
};

useShortcut("settingsNavigation", [
  {
    key: "/",
    callback: () => {
      filterInput.value?.focus();
    },
    sendToCommandPalette: false,
  },
]);

const blurFilterInput = () => {
  filterInput.value?.blur();
};

interface MenuItem {
  type: "header" | "separator" | "item";
  text?: string;
  icon?: string;
  children?: MenuItem[];
  to?: RouteLocationNamedRaw;
  onClick?: () => void;
  requiredRole?: GTenantRoleItem;
  isEntitled?: () => boolean;
}

const getFaIconName = (icon: string) => `fa-regular fa-${icon}`;

const menu: ComputedRef<MenuItem[]> = computed(() => [
  {
    type: "item",
    text: t("settings.navigation.preferences"),
    icon: getFaIconName("address-card"),
    to: { name: "settings-profile" },
  },
  {
    type: "item",
    text: t("settings.navigation.privacyAndSecurity"),
    icon: getFaIconName("fingerprint"),
    onClick: () => {
      openAdminPortal("privacy");
    },
  },
  {
    type: "item",
    text: t("settings.navigation.notifications"),
    icon: getFaIconName("bell"),
    to: { name: "settings-agent-notifications" },
  },
  {
    type: "separator",
  },
  {
    type: "header",
    text: t("settings.navigation.workspace"),
  },
  {
    type: "item",
    text: t("settings.navigation.snippets"),
    icon: getFaIconName("block-quote"),
    to: {
      name: "settings-snippets",
    },
  },
  {
    type: "item",
    text: t("settings.navigation.conversationAssist"),
    icon: getFaIconName("message-bot"),
    to: {
      name: "settings-bot-assistant",
    },
    requiredRole: GTenantRoleItem.Manager,
    // https://github.com/frontegg/frontegg-vue/issues/393
    isEntitled: () => unref(aiEntitlement).isEntitled,
  },
  {
    type: "item",
    text: t("settings.navigation.knowledgeBase"),
    icon: getFaIconName("book-open"),
    to: {
      name: "settings-kb",
    },
    requiredRole: GTenantRoleItem.Admin,
  },
  {
    type: "item",
    text: t("settings.navigation.channels"),
    icon: getFaIconName("message-smile"),
    children: [
      {
        type: "item",
        text: t("settings.navigation.webChat"),
        icon: getFaIconName("browser"),
        to: { name: "settings-channels-chat" },
      },
      {
        type: "item",
        text: t("settings.navigation.email"),
        icon: getFaIconName("envelope-open"),
        to: { name: "settings-channels-email" },
      },
      {
        type: "item",
        text: t("settings.navigation.sms"),
        icon: getFaIconName("message-sms"),
        to: { name: "settings-channels-sms" },
      },
      {
        type: "item",
        text: t("settings.navigation.facebookMessenger"),
        icon: "fa-brands fa-facebook-messenger",
        to: { name: "settings-channels-messenger" },
      },
      // {
      //   type: "item",
      //   text: "WhatsApp",
      //   icon: "fa-brands fa-whatsapp",
      //   to: { name: "foo" },
      // },
    ],
  },
  {
    type: "item",
    text: t("settings.navigation.agents"),
    icon: getFaIconName("people-group"),
    to: {
      name: "settings-agents",
    },
    requiredRole: GTenantRoleItem.Manager,
  },
  {
    type: "item",
    text: t("settings.navigation.teams"),
    icon: getFaIconName("screen-users"),
    to: {
      name: "settings-teams",
    },
    requiredRole: GTenantRoleItem.Admin,
  },
  {
    type: "item",
    text: t("settings.navigation.security"),
    icon: getFaIconName("key"),
    requiredRole: GTenantRoleItem.Admin,
    children: [
      {
        type: "item",
        text: t("settings.navigation.securitySettings"),
        icon: getFaIconName("shield-halved"),
        onClick: () => {
          openAdminPortal("security");
        },
      },
      {
        type: "item",
        text: t("settings.navigation.auditLogs"),
        icon: getFaIconName("file-magnifying-glass"),
        onClick: () => {
          openAdminPortal("audits");
        },
      },
      {
        type: "item",
        text: t("settings.navigation.apiTokens"),
        icon: getFaIconName("network-wired"),
        onClick: () => {
          openAdminPortal("api-tokens");
        },
      },
    ],
  },
  // {
  //   type: "item",
  //   text: t("settings.navigation.integrations"),
  //   icon: getFaIconName("chart-network"),
  //   children: [
  //     {
  //       type: "item",
  //       text: t("settings.navigation.notion"),
  //       icon: "svguse:/icons/notion.svg#notion",
  //     },
  //   ],
  // },
]);

const filteredMenu = ref(menu.value.filter(i => hasRequiredRole(i) && hasEntitlement(i)));

watch(menu, () => {
  filteredMenu.value = menu.value;
});

watchDebounced(
  filter,
  () => {
    if (!filter.value || !filter.value.trim()) {
      filteredMenu.value = menu.value.filter(i => hasRequiredRole(i) && hasEntitlement(i));
      return;
    }

    let hasMatchInGroup = false;
    const temp = [];
    const filterLowerCase = filter.value.toLowerCase();

    for (let i = menu.value.length - 1; i >= 0; i--) {
      const item = menu.value[i];

      if (item.type === "separator" && hasMatchInGroup) {
        hasMatchInGroup = false;
        temp.unshift(item);
        continue;
      }

      if (item.type === "header" && hasMatchInGroup) {
        temp.unshift(item);
        continue;
      }

      if (item.text && item.text.toLowerCase().indexOf(filterLowerCase) > -1) {
        temp.unshift(item);
        hasMatchInGroup = true;
      }

      if (item.children && item.children.some(c => c.text && c.text.toLowerCase().indexOf(filterLowerCase) > -1)) {
        if (temp.includes(item)) {
          continue;
        }

        temp.unshift({
          ...item,
          children: [...item.children.filter(c => c.text && c.text.toLowerCase().indexOf(filterLowerCase) > -1)],
        });
        hasMatchInGroup = true;
      }
    }

    if (temp.length && temp[0].type === "separator") {
      temp.shift();
    }

    filteredMenu.value = temp.filter(i => hasRequiredRole(i) && hasEntitlement(i));
  },
  { debounce: 100, maxWait: 100 },
);
</script>

<style scoped lang="scss"></style>
