<template>
  <Suspense>
    <AuthContext v-if="fronteggIsReady">
      <AppInit v-if="authState.isAuthenticated" :key="currentTenantId">
        <service-worker-setup />
        <AppShortcuts />
        <SwUpdateListener />
        <GleapContext />
        <SentryContext />
        <JuneContext />
        <ReportAgentActivity />
        <ThrottleDetection />
        <router-view v-slot="{ Component }">
          <!-- TODO: make this optional -->
          <!-- <transition name="fade-skeleton" mode="out-in">-->
          <component class="w-full" :is="Component" />
          <!-- </transition>-->
        </router-view>
      </AppInit>
    </AuthContext>
  </Suspense>

  <AppVersionCheck />
</template>

<script setup lang="ts">
import AppInit from "src/app-init/AppInit.vue";
import { useFrontegg, useAuthState } from "@frontegg/vue";
import { inject, watch } from "vue";
import ServiceWorkerSetup from "src/features/push-notification/ServiceWorkerSetup.vue";
import { useI18n } from "vue-i18n";
import AppShortcuts from "./components/AppShortcuts.vue";
import SwUpdateListener from "./components/UpdateListener.vue";
import AppVersionCheck from "./components/AppVersionCheck.vue";
import AuthContext from "./components/AuthContext.vue";
import { useRouter } from "vue-router";
import { useCurrentTenant } from "src/composables/useCurrentTenant";
import GleapContext from "src/components/GleapContext.vue";
import ReportAgentActivity from "src/features/agent/agent-status/ReportAgentActivity.vue";
import ThrottleDetection from "src/components/ThrottleDetection.vue";
import SentryContext from "./components/SentryContext.vue";
import JuneContext from "./components/JuneContext.vue";

const redirectKey = "SA_REDIRECT_URL";

const router = useRouter();

const fronteggReady = () => {
  return new Promise(resolve => {
    let intervals = 0;

    const interval = setInterval(() => {
      intervals++;
      if (authState.isLoading === false) {
        clearInterval(interval);
        resolve(true);
      }

      if (intervals >= 50) {
        clearInterval(interval);
        resolve(false);
        throw new Error("Frontegg failed to load in 5 seconds");
      }
    }, 100); // Check every 100ms
  });
};

router.beforeEach(async (to, _, next) => {
  await fronteggReady();

  if (isAuthRoute(to.path)) {
    next();
    return;
  }

  if (!authState.isAuthenticated) {
    console.debug("Not authorized for path", to.path, "- redirecting to login");
    trySetRedirectUrl(to.path);
    next(authState.routes.loginUrl);
    return;
  }

  const redirectUrl = tryGetRedirectUrl();

  if (redirectUrl) {
    trySetRedirectUrl(null);
    next({
      path: redirectUrl,
    });
    return;
  }

  next();
});

const isAuthRoute = (path: string) => {
  const { routes } = authState;
  return Object.values(routes)
    .filter(path => path !== routes.authenticatedUrl)
    .includes(path);
};

const trySetRedirectUrl = (path: string | null) => {
  try {
    if (!path) {
      sessionStorage.removeItem(redirectKey);
      return;
    }

    sessionStorage.setItem(redirectKey, path);
  } catch (err) {
    console.log("Error setting redirect url", err);
  }
};

const tryGetRedirectUrl = () => {
  try {
    return sessionStorage.getItem(redirectKey);
  } catch (err) {
    console.log("Error getting redirect url", err);
    return null;
  }
};

const authState = useAuthState();
const formkitConfig = inject(Symbol.for("FormKitConfig"));
const { locale } = useI18n();
const { fronteggLoaded: fronteggIsReady } = useFrontegg();
const { currentTenantId } = useCurrentTenant();

watch(
  locale,
  () => {
    if (locale.value === "en") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (formkitConfig as any).locale = "en";
    } else if (locale.value === "no") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (formkitConfig as any).locale = "nb";
    } else {
      console.warn("Missing Formkit i18n for locale", locale.value);
    }
  },
  {
    immediate: true,
  },
);
</script>
