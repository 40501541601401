import { GConversationChannelItem, GInputContactAddressTypeItem } from "src/generated/graphql";

export const showAddressForContactAddressTypes: Array<GInputContactAddressTypeItem> = [
  GInputContactAddressTypeItem.EmailAddress,
  GInputContactAddressTypeItem.PhoneNumber,
];

export const contactAddressTypeIconMap: Record<GInputContactAddressTypeItem, string> = {
  [GInputContactAddressTypeItem.EmailAddress]: "fa-light fa-envelope",
  [GInputContactAddressTypeItem.FacebookUserId]: "fa-brands fa-facebook-f",
  [GInputContactAddressTypeItem.PhoneNumber]: "fa-light fa-phone",
  [GInputContactAddressTypeItem.ChatVisitorId]: "",
  [GInputContactAddressTypeItem.Unknown]: "",
};

export const channelTypeToContactAddressTypeMap: Record<
  GConversationChannelItem,
  GInputContactAddressTypeItem | undefined
> = {
  [GConversationChannelItem.Email]: GInputContactAddressTypeItem.EmailAddress,
  [GConversationChannelItem.Sms]: GInputContactAddressTypeItem.PhoneNumber,
  [GConversationChannelItem.WhatsApp]: GInputContactAddressTypeItem.PhoneNumber,
  [GConversationChannelItem.FacebookMessenger]: undefined,
  [GConversationChannelItem.Unknown]: undefined,
  [GConversationChannelItem.VoiceCall]: undefined,
  [GConversationChannelItem.EmbeddedChat]: undefined,
};
