export enum SelectDirection {
  Up,
  Down,
}

// export interface StoreWithSelectData<T extends StoreWithSelectListData> {
//   list: T[]
//   // selectedIndex: number | null
//   loaded: boolean
//   hasNextPage: boolean
//   continuationToken: string | null
//   selectDirection: SelectDirection | null;
// }

export interface StoreWithSelectListData {
  ui: StoreWithSelectListDataItem;
}

export interface StoreWithIdField {
  id: string;
}

export interface StoreWithSelectListDataItem {
  index: number;
  selected: boolean;
  hover: boolean;
  key: number;
  // marked: boolean;
}
