{
  "success": "Action was successful",
  "common": {
    "form": {
      "globalInputRequired": "* Required",
      "useMaxNCharacters": "Please use no characters | Please use max one character | Please use max {count} characters"
    },
    "continue": "Continue",
    "reset": "Reset",
    "save": "Save",
    "cancel": "Cancel",
    "close": "Close",
    "send": "Send",
    "submit": "Submit",
    "undo": "Undo",
    "or": "or",
    "loadMore": "Load more",
    "delete": "Delete",
    "confirm": "Confirm",
    "justASecond": "Just a second",
    "checkYourInput": "This doesn't look quite right. Please double check your input.",
    "current": "Current",
    "select": "Select",
    "everyone": "Everyone",
    "finish": "Finish",
    "genericErrorTitle": "Oops 😳",
    "showAll": "Show all",
    "comingSoon": "Coming soon",
    "apply": "Apply",
    "changesSaving": "Your changes are being saved...",
    "changesSavedShort": "Changes saved",
    "changesSavedLong": "Your changes were saved successfully.",
    "genericErrorOccured": "An error occured. Please try again.",
    "saveChangesGenericError": "Unable to save your changes. Please try again!",
    "createNew": "Create new",
    "addNew": "Add new",
    "edit": "Edit",
    "create": "Create",
    "upgradeNow": "Upgrade now",
    "name": "Name",
    "upgradeSubscription": "Upgrade subscription",
    "andNOthers": "and {count} more | and {count} others",
    "now": "Now",
    "never": "Never",
    "nothingHereYet": "Nothing here... yet 😀",
    "goBack": "Go back",
    "learnMore": "Learn more",
    "copiedToClipboard": "Copied to clipboard",
    "copyToClipboardError": "Could not copy to clipboard",
    "keyboardShortcutSequenceSeparator": "then",
    "actions": "Actions",
    "search": "Search",
    "createAnother": "Create another",
    "closeDrawer": "Close drawer",
    "logOut": "Log out",
    "tryAgain": "Try again",
    "other": "Other",
    "closeDialog": "Close dialog",
    "copy": "Copy"
  },
  "auth": {},
  "mainNavigation": {
    "conversations": "Conversations",
    "conversation": "Conversation",
    "activityFeed": "Activity feed",
    "reports": "Reports",
    "contacts": "Contacts",
    "settings": "Settings",
    "knowledgeBase": "Knowledge base"
  },
  "appShortcuts": {
    "goToNotifications": "Open notifications drawer",
    "goToReports": "Go to reports",
    "goToActivityFeed": "Go to activity feed",
    "goToContacts": "Go to contacts",
    "goToKnowledgeBase": "Go to knowledge base",
    "goToSettings": "Go to settings",
    "goToActiveConversations": "Go to active conversations",
    "goToSnoozedConversations": "Go to snoozed conversations",
    "goToDoneConversations": "Go to done conversations",
    "toggleSupermode": "Toggle SuperMode",
    "restartKeyboardTutorial": "Restart keyboard tutorial"
  },
  "commandPalette": {
    "commandInputPlaceholder": "What do you want to do?",
    "title": "Command palette"
  },
  "relativeTime": {
    "yearAgo": "{count} year ago|{count} years ago",
    "monthAgo": "{count} month ago|{count} months ago",
    "dayAgo": "{count} day ago|{count} days ago",
    "hourAgo": "{count} hour ago|{count} hours ago",
    "minuteAgo": "{count} minute ago|{count} minutes ago",
    "rightNow": "right now"
  },
  "channelDisplay": {
    "email": "Email",
    "embeddedChat": "Chat",
    "facebookMessenger": "Messenger",
    "sms": "SMS",
    "unknown": "Unknown",
    "voiceCall": "Voice call",
    "whatsApp": "WhatsApp"
  },
  "contactCreate": {
    "firstName": "First name",
    "lastName": "Last name",
    "emailAddresses": "Email addresses",
    "phoneNumbers": "Phone numbers",
    "addressConflictError": "Address {address} already exists under another contact."
  },
  "contactSelect": {
    "contactNotFoundCreateNew": "Contact not found. Create new?",
    "createContact": "Create new contact",
    "andMore": "and {count} more"
  },
  "teamOrAgentSelect": {
    "noTeamOrAgent": "No agents or teams",
    "unassignedOptionLabel": "Unassigned"
  },
  "conversationAssignDialog": {},
  "contacts": {
    "contactsHeader": "Contacts",
    "goToContact": "Go to contact",
    "createContact": "Create contact",
    "unknownContact": "Unknown contact",
    "display": {
      "header": "Contact information"
    }
  },
  "conversations": {
    "list": {
      "goToNextConversation": "Go to next conversation",
      "goToPreviousConversation": "Go to previous conversation",
      "markAsDoneX": "Mark nothing as done | Mark conversation as done | Mark {count} conversations as done",
      "markAsNotDoneX": "Mark nothing as not done | Mark conversation as not done | Mark {count} conversations as not done",
      "goToConversation": "Go to conversation",
      "assignSelectedConversations": "Assign no conversations | Assign selected conversation | Assign {count} conversations",
      "changeView": "Change view...",
      "snoozeX": "Snooze nothing | Snooze conversation | Snooze {count} conversations",
      "changeTagsX": "Edit no tags | Edit conversation tags | Edit tags for {count} conversations",
      "copyUrl": "Copy conversation URL",
      "toggleSelect": "Select/deselect conversation",
      "selectAll": "Select all conversations",
      "newConversation": "Create new conversation",
      "removeFilter": "Remove conversation filter",
      "toolbar": {
        "activeTabLabel": "Active",
        "activeTabTooltip": "Go to active conversations",
        "snoozedTabTooltip": "Go to snoozed conversations",
        "snoozedTabLabel": "Snoozed",
        "doneTabLabel": "Done",
        "doneTabTooltip": "Go to done conversations"
      },
      "demoChipText": "Demo"
    },
    "statusChip": {
      "archived": "Archived",
      "snoozed": "Snoozed",
      "active": "Active"
    },
    "botAssistantMode": {
      "DISCONNECTED": "disconnected",
      "CREATE_SUGGESTION": "create suggestion",
      "CREATE_REPLY": "create reply"
    },
    "assignDialog": {
      "assignConversation": "Assign conversation",
      "inputPlaceholder": "Assign conversation to...",
      "findAgentOrTeam": "Type to find an agent or a team",
      "notAssigned": "Unassigned",
      "couldNotAssignConversationTitle": "Could not assign selected conversation"
    },
    "summary": {
      "commandPaletteText": "Summarize conversation",
      "generatingSummaryProgressText": "The conversation is being summarized...",
      "summaryCreatedText": "The summary was created successfully.",
      "summaryErrorText": "The conversation could not be summarized right now.",
      "summaryDisplayDescription": "{agent} requested a summary:"
    },
    "demo": {
      "action": "Insert demo content",
      "progressText": "The conversations are being created...",
      "createdText": "The demo conversations were created successfully.",
      "errorText": "The demo conversations could not be created right now."
    },
    "createForm": {
      "subject": "Subject",
      "subjectDescription": "What is the conversation about?",
      "contact": "Contact",
      "startTypingToFindContact": "Start typing to find a contact",
      "address": "Address",
      "assignment": "Assignment",
      "assignmentDescription": "Who should be in charge of the conversation?",
      "tags": "Tags",
      "tagsDescription": "If you wish, you may attach one or multiple tags.",
      "startSnoozed": "Start snoozed",
      "startSnoozedDescription": "Do you need to follow up in a few days? We'll remind you.",
      "markAsDone": "Mark as done",
      "markAsDoneDescription": "Nothing more to do? Enable this to set the conversation as done right now.",
      "message": "Message",
      "createButtonLabel": "Create conversation"
    },
    "changeViewDialog": {
      "changeConversationView": "Change conversation view",
      "findAgentOrTeam": "Type to find an agent or a team",
      "onlyUnassigned": "Only unassigned",
      "noFilter": "All agents / teams"
    },
    "singleView": {
      "addComment": "Add comment",
      "reply": "Reply",
      "markAsDone": "Mark as done",
      "markAsNotDone": "Mark as not done",
      "assign": "Assign to..",
      "snooze": "Snooze",
      "copyUrl": "Copy conversation URL",
      "newConversation": "Create new conversation",
      "showContact": "Show contact info",
      "botAssistantMode": "Assistant mode",
      "preview": {
        "openConversation": "Open conversation"
      },
      "goBack": "Go back to list",
      "couldNotArchiveTitle": "Could not mark the conversation as done",
      "couldNotUnArchiveTitle": "Could not mark conversation as active",
      "timeline": {
        "goToNextMessage": "Go to next message",
        "goToPreviousMessage": "Go to previous message"
      },
      "suggestKnowledgeBaseArticle": "Suggest knowledge base article",
      "transfer": "Transfer"
    },
    "transferDialog": {
      "transferConversation": "Transfer conversation",
      "addressesOnFile": "No {noun} on file | One {noun} on file | {count} {noun} on file",
      "noun": {
        "address": "address|addresses",
        "phoneNumber": "phone number|phone numbers",
        "emailAddress": "e-mail address|e-mail addresses"
      },
      "currentChannel": "Current",
      "creatingContactProgressText": "The contact is being updated.",
      "contactAddressCreatedSuccess": "The contact address was created successfully.",
      "contactAddressCreateError": "An error occured while adding the address.",
      "transferProgressText": "The conversation is being transfered...",
      "transferErrorText": "An error occured while transfering the conversation.",
      "transferSuccessText": "The conversation was transfered successfully.",
      "contactAddressConflictError": "Sorry, this address is already in use by another contact."
    },
    "customView": "You are using a custom view.",
    "demoContentBannerText": "This is a demo conversation. Feel free to play around with it - any replies you send will not be delivered anywhere."
  },
  "conversation": {
    "display": {
      "header": "Conversation",
      "textIsTruncated": "[The text has been truncated]",
      "systemAgentName": "System",
      "toolbar": {
        "assignedLabel": "Assigned:"
      },
      "attachment": {
        "noPreviewAvailable": "No preview available",
        "download": "Download",
        "downloadFile": "Download file"
      }
    },
    "event": {
      "archived": "The conversation was marked as done",
      "unArchived": "The conversation was marked as not done",
      "assignedSelf": "{agent} assigned the conversation to themselves",
      "assignedAgent": "Conversation was assigned to {agent}",
      "assignedTeam": "Conversation was assigned to {team}",
      "unassigned": "Conversation was unassigned",
      "botAssistantDisconnected": "The bot assistant was disconnected",
      "botAssistantSuggesting": "The bot assistant is suggesting answer to new replies",
      "botAssistantReplying": "The bot assistant is automatically answering new replies",
      "bySystem": "{ago}",
      "byAgent": "{ago} by {agent}",
      "snoozed": "The conversation was snoozed until {date}",
      "unSnoozed": "The conversation was reactivated",
      "transferredNewChannel": "Transferred from {fromChannel} to {toChannel} ({toAddress})",
      "transferredNewAddress": "Transferred from {fromAddress} to {toAddress}"
    },
    "common": {
      "noSubject": "No subject"
    },
    "snippets": {
      "featureName": "Snippets",
      "shared": {
        "titleLabel": "Title",
        "titleHelp": "Enter a suitable title for the snippet",
        "contentLabel": "Content",
        "contentHelp": "The main contents of the snippet.",
        "privateLabel": "Private snippet?",
        "privateHelp": "Do you want the snippet to only be available for yourself?",
        "confirmDelete": "Are you sure you wish to delete this snippet?",
        "deleteToastProgress": "The snippet is being deleted...",
        "deleteToastSuccessful": "The snippet was deleted successfully.",
        "deleteToastError": "An error occured while deleting the snippet. Please try again later.",
        "supportedPlaceholders": "Supported placeholders:",
        "showSnippet": "Show snippet"
      },
      "showArticle": "Show snippet",
      "previewSnippet": "Preview snippet",
      "snippetList": {
        "goToNextSnippet": "Go to next snippet",
        "goToPreviousSnippet": "Go to previous snippet",
        "selectAll": "Select all snippet",
        "newSnippet": "Create new snippet",
        "toggleSelect": "Select/deselect snippet"
      },
      "create": {
        "createSnippet": "Create snippet",
        "saveButtonLabel": "Save snippet",
        "saveToastProgress": "Your snippet is being created...",
        "saveToastSuccessful": "Your snippet was created successfully!",
        "saveToastError": "Sorry, an error occured while saving the snippet. Please try again."
      },
      "modify": {
        "modifySnippet": "Edit snippet",
        "saveButtonLabel": "Save snippet",
        "saveToastProgress": "Your snippet is being updated...",
        "saveToastSuccessful": "Your snippet was updated successfully!",
        "saveToastError": "Sorry, an error occured while updating the snippet. Please try again."
      },
      "editorPlugin": {
        "noSnippetFound": "No snippet found",
        "insertSnippet": "Insert snippet"
      },
      "featureDescription1": "Snippets are building blocks that you can use to write messages at ⚡️-speed."
    },
    "actions": {
      "snooze": {
        "title": "Snooze conversation",
        "timeInputPlaceholder": "Type to find a time",
        "successMessage": "The conversation was snoozed successfully.",
        "errorMessage": "The conversation could not be snoozed."
      },
      "assign": {
        "assignProgressMessage": "Assigning conversation",
        "successToastMessage": "Conversation was assigned"
      },
      "transfer": {
        "addEmailInputLabel": "Add new email address",
        "addEmailInputHelp": "Please enter a email address.",
        "addEmailInputPlaceholder": "email{'@'}domain.com",
        "addPhoneInputLabel": "Add new phone number",
        "addPhoneInputHelp": "Please enter a phone number."
      },
      "bulkStatusChange": {
        "unArchiveProgressText": "Changing status to not done",
        "conversationsUpdated": "Conversations updated",
        "archiveProgressText": "Changing status to done"
      },
      "send": {
        "sendOnly": "Send",
        "sendAndMarkDone": "Send and mark as done",
        "nothingToSendNotification": "There is nothing to send"
      },
      "archive": {
        "successNotification": "The conversation was marked as done.",
        "progressNotification": "Marking the conversation as done..."
      },
      "unArchive": {
        "successNotification": "The conversation was set to active."
      }
    },
    "assistant": {
      "sendSuggestionDirectly": "Send directly",
      "assistantIsRunning": "Assistant is running",
      "suggestedReply": "Suggested reply from assistant",
      "noSuitableResponseCouldBeFound": "The assistant did not find a suitable response",
      "error": "Error from the assistant",
      "hideAssistantDetails": "Hide assistant details",
      "showAssistantDetails": "Show assistant details",
      "mode": {
        "changeModeHeader": "Change assistant mode",
        "inputPlaceholder": "Choose assistant mode",
        "disconnected": "Disconnected",
        "disconnectedDescription": "The assistant will not suggest replies",
        "suggest": "Suggest replies",
        "suggestDescription": "The assistant will suggest replies, but they must be approved by an agent",
        "replyDirectly": "Create replies and send to customer",
        "replyDirectlyDescription": "The assistant will create replies and send them immediately to the customer",
        "modeChangeErrorMessage": "Could not set new bot assistant mode",
        "modeChangedSuccessMessage": "Assistant mode was updated"
      },
      "settings": {
        "header": "Assistant settings",
        "enabledHelp": "Globally enable or disable the assistant functionality",
        "enabledLabel": "Enable the bot assistant"
      }
    },
    "editor": {
      "assistant": {
        "improveWriting": "Improve writing",
        "fixSpellingAndGrammar": "Fix spelling and grammar",
        "translate": "Translate"
      }
    }
  },
  "appEvent": {
    "contactMessage": "{contact} replied in a {conversation} {ago}",
    "botSuggestion": "The bot assistant suggested a reply in a {conversation} with {contact} {ago}",
    "botMessage": "The bot assistant replied in a {conversation} with {contact} {ago}",
    "agentMessage": "{agent} replied in a {conversation} with {contact} {ago}",
    "agentComment": "{agent} commented in a {conversation} with {contact} {ago}",
    "conversationUnassigned": "{agent} unassigned a {conversation} {ago}",
    "conversationAssigned": "{agent} assigned a {conversation} to {assignee} {ago}",
    "conversationArchived": "{agent} marked a {conversation} with {contact} as done {ago}",
    "conversationUnArchived": "{agent} re-opened a {conversation} with {contact} {ago}",
    "conversationBotAssistantDisconnected": "{agent} disabled the bot assistant in a {conversation} {ago}",
    "conversationBotAssistantSuggesting": "{agent} changed the bot assistant to suggest replies in a {conversation} {ago}",
    "conversationBotAssistantReplying": "{agent} changed the bot assistant to reply automatically in a {conversation} {ago}",
    "conversationSnoozed": "{agent} snoozed a {conversation} {ago}",
    "conversationSnoozedRemind": "Will be reactivated at {remindAt}",
    "conversation": "conversation"
  },
  "agentNotificationList": {
    "drawer": {
      "header": "Notifications"
    },
    "goBack": "Close notifications",
    "markAllAsRead": "Mark all as read",
    "noNotifications": "You don't have any notifications... yet."
  },
  "settings": {
    "agents": {
      "inviteHeader": "Invite new agent",
      "shared": {
        "firstNameInputLabel": "First name",
        "lastNameInputLabel": "Last name",
        "emailInputLabel": "E-mail address",
        "roleInputLabel": "Role",
        "roles": {
          "agentLabel": "Agent",
          "agentDescription": "Agent access",
          "managerLabel": "Manager",
          "managerDescription": "Manager access",
          "adminLabel": "Admin",
          "adminDescription": "Full access"
        },
        "nameRowLabel": "Name"
      },
      "modifyAgent": "Edit agent",
      "agentsHeader": "Agents",
      "inviteNewAgentButtonLabel": "Invite new agent"
    },
    "teams": {
      "featureName": "Teams",
      "createTeam": "Create new team",
      "editTeam": "Edit team",
      "noTeamsYet": "You haven't created any teams yet.",
      "shared": {
        "teamNameInputHelp": "The name of the team.",
        "teamNameInputLabel": "Name",
        "teamAgentsInputHelp": "The agents who should be part of this team.",
        "teamAgentsInputLabel": "Agents",
        "teamNameInputPlaceholder": "Team name"
      }
    },
    "channels": {
      "email": {
        "createHeader": "Create new e-mail"
      },
      "messenger": {
        "subheader": "Keep in touch with your customers using Facebook Messenger.",
        "learnMore": "Learn more about Facebook Messenger",
        "connect": "Connect Facebook Messenger",
        "notIncludedInSubscription": "Your current subscription does not include Facebook Messenger",
        "selectAPage": "Select a page",
        "noPagesAvailable": "No pages were available. Please try again, and make sure you select at lest one page.",
        "pageChannelAlreadyExistsToastText": "You have already connected this Facebook Page.",
        "addingChannelProgressTitle": "Linking...",
        "addingChannelProgressText": "Your Facebook page is being linked. Please hang tight.",
        "linkingErrorToastText": "An error occurred while linking your Facebook Page.",
        "linkSuccessfulToastTitle": "Link Facebook Page",
        "linkSuccessfulToastText": "The Facebook Page was linked successfully.",
        "removeChannelConfirmationMessage": "Are you sure you wish to unlink this Facebook Page?",
        "removingChannelProgressText": "Your Facebook page is being unlinked. Please hang tight.",
        "unlinkingErrorToastText": "An error occurred while unlinking your Facebook Page.",
        "unlinkSuccessfulToastText": "The Facebook Page was unlinked successfully.",
        "reauthenticate": "Reauthenticate"
      },
      "sms": {
        "header": "SMS",
        "subheader": "Keep in touch with your customers using SMS.",
        "createHeader": "Enable SMS",
        "enableButtonText": "Enable SMS",
        "smsPrefixHeader": "SMS prefix text",
        "smsSuffixHeader": "SMS suffix text",
        "suggestion": "Suggestion",
        "youMayRespondToThisMessage": "You may respond to this message.",
        "prefixDescription": "Optionally you can start all outgoing SMS messages with some text, to help your customers recognize who the text is from (they most likely will not recognize the number).",
        "keepItShort": "Keep it short, remember that any message that exceeds 160 characters will count as two messages against your included SMS messages in your plan.",
        "suffixDescription": "Similarly, you may also finish all outgoing SMS messages with some text. For example, to ensure them that the SMS can be replied to (which might not be obvious to all users).",
        "enableDedicatedButtonText": "Enable SMS with dedicated number"
      },
      "chat": {
        "creatingChannelProgress": "The chat widget is being created...",
        "createError": "Unfortunately, we could not create the widget at this time. Please try again.",
        "createSuccessful": "The chat widget was created successfully!",
        "header": "Website chat",
        "subHeader": "Get started with chat on your website.",
        "createWidgetButtonText": "Create chat widget",
        "installation": {
          "successMessage": "You have successfully installed the chat widget on your website 🥳",
          "sendEmbedCodeEmailDescription": "If you want, you can click the button below and we'll e-mail you these instructions, either for your own use or to forward to a web designer.",
          "embedCodeHtmlPositionMessage": "The code should be installed at the end of the website body, right before its closing tag",
          "afterCodeCopyToastMessage": "Now you can simply paste the code into your website code.",
          "emailMe": "Email me!",
          "sendingEmailToastTitle": "Sending e-mail",
          "sendingEmailToastMessage": "The e-mail is being sent - please hang tight.",
          "emailSentToastTitle": "You've got mail 📫",
          "emailSentToastMessage": "The e-mail was sent successfully, and should reach you at {email} within a few moments.",
          "emailGenericErrorMessage": "Sorry, we could not send you the e-mail right now. Please try again later, or reach out to support."
        },
        "text": {
          "widgetLanguageLabel": "Widget language",
          "widgetLanguagePlaceholder": "Select a language",
          "widgetLanguageHelp": "The general language of the widget, such as buttons, menus etc.",
          "startMessageLabel": "First message",
          "startMessageHelp": "Message sent automatically to visitors when they start a chat",
          "noAgentsOnlineMessageLabel": "No agents online message",
          "noAgentsOnlineMessageHelp": "Message shown when there are no agents online",
          "splashHeaderLabel": "Conversation list header",
          "splashHeaderHelp": "Header shown on the conversation list",
          "splashSubHeaderLabel": "Conversation list subheader",
          "splashSubHeaderHelp": "Subheader shown on the conversation list",
          "showAgentName": "Show agent name",
          "agentNameVariation": "Agent name format",
          "showAgentNameHelp": "Visitors will see the name of the agent",
          "nameVariations": {
            "firstNameAndLastNameInitial": "First name + last name initial",
            "firstNameOnly": "First name only",
            "fullName": "Full name",
            "initialsOnly": "Initials only",
            "lastNameOnly": "Last name only"
          }
        },
        "behaviour": {
          "operationModeTitle": "Operation mode",
          "agentsAlwaysAvailableLabel": "Agents always available",
          "agentsAlwaysAvailableHelp": "Choose this mode if you are \"always on\" and handle customer requests any time of day",
          "agentsOnShiftLabel": "Agents on shift",
          "agentsOnShiftHelp": "Choose this mode if agents are available following a schedule"
        },
        "preChatSurvey": {
          "pageHeader": "This lets you ask your visitors to provide certain information before a conversation is started.",
          "publicFacingHeaderLabel": "Page header",
          "publicFacingHeaderHint": "A header shown above the form",
          "publicFacingSubHeaderLabel": "Page subheader",
          "publicFacingSubHeaderHint": "A subheader shown above the form",
          "fieldRepeaterLabel": "Survey fields",
          "fieldTitleLabel": "Field title",
          "fieldTitleHint": "The label of the field",
          "fieldPlaceholderLabel": "Placeholder",
          "fieldPlaceholderPlaceholder": "This is a placeholder",
          "fieldPlaceholderHint": "Optionally provide an example of a value for the field",
          "fieldHintLabel": "Hint",
          "fieldHintHint": "Optionally provide a longer description for the field (just like this sentence)",
          "fieldRequiredLabel": "Required",
          "fieldRequiredHint": "Require the field to be provided",
          "fieldAskForEveryConversationLabel": "Ask for every conversation",
          "fieldAskForEveryConversationHint": "If a returning visitor starts a new conversation, should we ask them again?",
          "fieldTypeLabel": "Field type",
          "fieldTypeHint": "What type of information are you asking for?",
          "addFieldButtonLabel": "Add field",
          "startBehaviour": {
            "dropdownLabel": "Conversation start mode",
            "dropdownHint": "When would you like the conversation to be started?",
            "startOnFirstContactMessageLabel": "Start on first message",
            "startOnFirstContactMessageHint": "Conversation will not be started until the visitor types a message into the chat window",
            "startOnSurveySubmissionLabel": "Start when survey is submitted",
            "startOnSurveySubmissionHint": "Conversation be started immediately when the survey is submitted"
          },
          "optionsLabel": "Options",
          "optionsAddTooltip": "Add another option",
          "optionsRemoveTooltip": "Remove this option",
          "moveDownTooltip": "Move down",
          "moveUpTooltip": "Move up",
          "typeRulesValidationError": "You can have no more than one field of this type.",
          "optionsRequiredValidationError": "This field cannot be empty.",
          "fieldTypes": {
            "email": "E-mail address",
            "name": "Name",
            "number": "Number",
            "phoneNumber": "Phone number",
            "text": "Single-line text",
            "multilineText": "Multi-line text",
            "date": "Date",
            "dateTime": "Date and time",
            "radio": "Radio buttons",
            "dropdown": "Dropdown list",
            "checkbox": "Checkboxes"
          }
        },
        "theme": {
          "fake-primary": "Primary color",
          "agentBubbleBackgroundColorHelp": "The background color for the agent's message bubbles within the chat interface.",
          "agentBubbleBackgroundColor": "Agent message bubble background color",
          "agentBubbleTextColorHelp": "The text color within the agent's message bubbles in the chat.",
          "agentBubbleTextColor": "Agent message bubble text color",
          "appBackgroundColorFromHelp": "The starting color for the background gradient of the chat window.",
          "appBackgroundColorFrom": "Background start color",
          "appBackgroundColorToHelp": "The ending color for the background gradient of the chat window.",
          "appBackgroundColorTo": "Background end color",
          "botBubbleBackgroundColorHelp": "The background color for the bot's message bubbles within the chat interface.",
          "botBubbleBackgroundColor": "Bot message bubble background color",
          "botBubbleTextColorHelp": "The text color within the bot's message bubbles in the chat.",
          "botBubbleTextColor": "Bot message bubble text color",
          "buttonBackgroundColorHelp": "The background color for buttons in the chat interface.",
          "buttonBackgroundColor": "Button background color",
          "buttonBorderColorHelp": "The border color for buttons in the chat interface.",
          "buttonBorderColor": "Button border color",
          "buttonTextColorHelp": "The color of the text on buttons in the chat interface.",
          "buttonTextColor": "Button text color",
          "contactBubbleBackgroundColorHelp": "The background color for the contact's message bubbles within the chat interface.",
          "contactBubbleBackgroundColor": "Contact message bubble background color",
          "contactBubbleTextColorHelp": "The text color within the contact's message bubbles in the chat.",
          "contactBubbleTextColor": "Contact message bubble text color",
          "iconTextColorHelp": "The color of the text for icons within the chat interface.",
          "iconTextColor": "Icon text color",
          "inputBackgroundColorHelp": "The background color for the input area where the user types messages.",
          "inputBackgroundColor": "Message input background color",
          "inputBorderColorHelp": "The border color for the input area in the chat interface.",
          "inputBorderColor": "Message input border color",
          "inputTextColorHelp": "The color of the text that the user types into the input area.",
          "inputTextColor": "Message input text color",
          "minimizedIconBackgroundColorHelp": "The background color for the icon when the chat widget is minimized.",
          "minimizedIconBackgroundColor": "Minimized chat icon background color",
          "minimizedIconColorHelp": "The color of the minimized chat widget icon itself.",
          "minimizedIconColor": "Minimized chat icon color",
          "primaryHoverColorHelp": "The color that appears when the user hovers over interactive elements in the chat interface.",
          "primaryHoverColor": "Hover color for interactive elements",
          "primaryTextColorHelp": "The primary text color used throughout the chat interface.",
          "primaryTextColor": "Primary text color in chat",
          "processingBubbleBackgroundColorHelp": "The background color for message bubbles that indicate processing is occurring.",
          "processingBubbleBackgroundColor": "Processing bubble background color",
          "processingBubbleTextColorHelp": "The text color within processing message bubbles in the chat.",
          "processingBubbleTextColor": "Processing bubble text color",
          "topBackgroundColorHelp": "The background color for the top area of the chat interface, usually containing the title or header.",
          "topBackgroundColor": "Top area background color",
          "topTextColorHelp": "The color of the text at the top area of the chat interface.",
          "topTextColor": "Top area text color",
          "advancedMode": "Advanced mode",
          "simpleMode": "Simple mode",
          "advancedModeHelp": "If you want to change the colors of individual components, click @:settings.channels.chat.theme.advancedMode below."
        },
        "tabs": {
          "installation": "Installation",
          "appearance": "Appearance",
          "text": "Text",
          "preChatSurvey": "Pre-chat survey",
          "behaviour": "Behaviour"
        },
        "widgetPreview": {
          "placeholderDomain": "yoursite.com"
        }
      }
    },
    "navigation": {
      "preferences": "Preferences",
      "privacyAndSecurity": "Privacy and Security",
      "notifications": "Notifications",
      "workspace": "Workspace",
      "snippets": "Snippets",
      "conversationAssist": "Conversation assist",
      "knowledgeBase": "Knowledge base",
      "channels": "Channels",
      "webChat": "Web chat",
      "facebookMessenger": "Facebook Messenger",
      "agents": "Agents",
      "teams": "Teams",
      "security": "Security",
      "securitySettings": "Security settings",
      "auditLogs": "Audit logs",
      "apiTokens": "API tokens",
      "integrations": "Integrations",
      "notion": "Notion",
      "email": "E-mail",
      "sms": "SMS"
    }
  },
  "agent": {
    "awayStatus": {
      "setYourselfAsAway": "Set yourself as away",
      "setYourselfAsAvailable": "Set yourself as available",
      "setAsAway": "Set as away",
      "setAsAvailable": "Set as available",
      "isSettingAwayProgressText": "Setting as away...",
      "isSettingAvailableProgressText": "Setting as available..."
    },
    "roleChip": {
      "admin": "Admin",
      "agent": "Agent",
      "manager": "Manager",
      "unknown": "Unknown"
    },
    "statusChip": {
      "active": "Active",
      "deleted": "Deleted",
      "disabled": "Disabled",
      "invited": "Invited",
      "unknown": "Unknown"
    },
    "management": {
      "confirmDelete": "Are you sure you wish to delete {name}?",
      "deleteSuccessful": "{name} was deleted successfully.",
      "invited": "invited",
      "languageLabel": "Language",
      "languagePlaceholder": "Select a language",
      "languageHelp": "Choose the language you wish the app to use",
      "preferencesPageTitle": "Preferences",
      "autoAdvance": {
        "workflowLabel": "Workflow",
        "workflowHelp": "After snoozing or marking a conversation as done, what would you like to do?",
        "workflowPlaceholder": "Select a workflow",
        "stayInConversation": "Stay in conversation",
        "returnToConversationList": "Return to conversation list"
      }
    },
    "loggedInAs": "Logged in as {0}"
  },
  "dateDifference": {
    "days": {
      "monday": "Monday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "thursday": "Thursday",
      "friday": "Friday",
      "saturday": "Saturday",
      "sunday": "Sunday"
    },
    "inMinute": "In {number} minute",
    "inMinutes": "In {number} minutes",
    "inHour": "In {number} hour",
    "inHours": "In {number} hours",
    "inDay": "In {number} day",
    "inDays": "In {number} days",
    "inMonth": "In {number} month",
    "inMonths": "In {number} months",
    "inYear": "In {number} year",
    "inYears": "In {number} years",
    "onDay": "On {day}",
    "onDayNextWeek": "On {day} next week",
    "onDayInWeek": "On {day} in {number} week",
    "onDayInWeeks": "On {day} in {number} weeks"
  },
  "contact": {
    "contactInfoPanel": {
      "latestConversations": "Latest conversations",
      "noKnownAddresses": "No known addresses.",
      "addresses": "Addresses",
      "noRecentConversations": "No recent conversations"
    }
  },
  "channels": {
    "SMS": "SMS",
    "EMAIL": "email",
    "WHATS_APP": "WhatsApp",
    "FACEBOOK_MESSENGER": "Facebook Messenger",
    "EMBEDDED_CHAT": "chat"
  },
  "kb": {
    "shared": {
      "objectiveLabel": "Objective",
      "titleLabel": "Title",
      "solutionLabel": "Solution",
      "questionsLabel": "Questions",
      "unsavedChanges": "Unsaved changes",
      "draft": "Draft",
      "audience": "Article audience",
      "articleIsPublishing": "The article is being published.",
      "saveDraftButtonText": "Save draft",
      "saveInternalArticleButtonText": "Publish article internally",
      "savePublicArticleButtonText": "Publish article publicly",
      "confirmDelete": "Are you sure you wish to delete this article?",
      "deleteToastProgress": "The article is being deleted...",
      "deleteToastSuccessful": "The article was deleted successfully.",
      "deleteToastError": "An error occured while deleting the article. Please try again later.",
      "theMainContentsOfTheArticle": "The main contents of the article.",
      "addAQuestion": "Add a question",
      "titleHelp": "Make a suitable title for the article",
      "questionsHelp": "Legg til ett eller flere spørsmål som denne artikkelen besvarer. Ved å gjøre dette øker du nøyaktigheten til AI-motoren.",
      "questionInputPlaceholder": "Hva betyr...?",
      "audienceHelp": "Select the intended audience for the information given in the article.",
      "draftHelp": "Enable if the article is a work-in-progress, and not yet ready to be used.",
      "knowledgeBaseArticle": "Knowledge base article",
      "objectiveHelp": "Describe the objective of the article",
      "addAnotherQuestion": "Add another question",
      "removeThisQuestion": "Remove this question"
    },
    "editorPlugin": {
      "noArticleFound": "No articles found",
      "typeToSearchForArticle": "Start typing to search for an article",
      "insertArticle": "Insert article"
    },
    "showArticle": "Show article",
    "previewArticle": "Preview article",
    "articleList": {
      "goToNextConversation": "Go to next article",
      "goToPreviousConversation": "Go to previous article",
      "selectAll": "Select all articles",
      "newKbArticle": "Create new article",
      "toggleSelect": "Select/deselect article"
    },
    "create": {
      "createArticle": "Create article",
      "saveButtonLabel": "Save article",
      "publicLabel": "Public article",
      "draft": "Publish article immediately",
      "saveToastProgress": "Your article is being created...",
      "saveToastSuccessful": "Your article was created successfully!",
      "saveToastError": "Sorry, an error occured while saving the article. Please try again."
    },
    "modify": {
      "modifyArticle": "Edit article",
      "saveButtonLabel": "Save article",
      "publicLabel": "Public article",
      "publish": "Published",
      "saveToastProgress": "Your article is being updated...",
      "saveToastSuccessful": "Your article was updated successfully!",
      "saveToastError": "Sorry, an error occured while updating the article. Please try again.",
      "isPublishingError": "Your article is in the process of being published. Please allow the existing process to complete and try again in a moment."
    },
    "conversationSuggestion": {
      "successToastMessage": "Unfortunately, we couldn't find any potential articles in this conversation. | Great news, we found one potential article in this conversation! | Great news, we found {count} potential articles in this conversation!",
      "progressToastMessage": "The conversation is being inspected for potential knowledge base articles. This might take a while.",
      "errorToastMessage": "Unfortunately, we could not find any relevant knowledge base articles at this time. Please try again later."
    },
    "status": {
      "published": "Published",
      "drafts": "Drafts",
      "suggestions": "Suggestions"
    },
    "settings": {
      "approvalFlowHelp": "When enabled, articles will have to be approved by a manager or admin before they are published. Managers or admins will be allowed to publish without approval.",
      "approvalFlowLabel": "Articles must receive approval",
      "articleLanguageHelp": "The language you use in your knowledge base articles. Avoid changing this if you've already created articles.",
      "articleLanguageLabel": "Article language",
      "header": "Knowledge base settings"
    },
    "audience": {
      "internal": "Internal",
      "public": "Public"
    }
  },
  "languages": {
    "aa": "Afar",
    "ab": "Abkhazian",
    "af": "Afrikaans",
    "ak": "Akan",
    "am": "Amharic",
    "an": "Aragonese",
    "ar": "Arabic",
    "as": "Assamese",
    "av": "Avar",
    "ay": "Aymara",
    "az": "Azerbaijani",
    "ba": "Bashkir",
    "be": "Belarusian",
    "bg": "Bulgarian",
    "bh": "Bihari",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Bengali",
    "bo": "Tibetan",
    "br": "Breton",
    "bs": "Bosnian",
    "ca": "Catalan",
    "ce": "Chechen",
    "ch": "Chamorro",
    "co": "Corsican",
    "cr": "Cree",
    "cs": "Czech",
    "cu": "Old Church Slavonic / Old Bulgarian",
    "cv": "Chuvash",
    "cy": "Welsh",
    "da": "Danish",
    "de": "German",
    "dv": "Divehi",
    "dz": "Dzongkha",
    "ee": "Ewe",
    "el": "Greek",
    "en": "English",
    "eo": "Esperanto",
    "es": "Spanish",
    "et": "Estonian",
    "eu": "Basque",
    "fa": "Persian",
    "ff": "Peul",
    "fi": "Finnish",
    "fj": "Fijian",
    "fo": "Faroese",
    "fr": "French",
    "fy": "West Frisian",
    "ga": "Irish",
    "gd": "Scottish Gaelic",
    "gl": "Galician",
    "gn": "Guarani",
    "gu": "Gujarati",
    "gv": "Manx",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Croatian",
    "ht": "Haitian",
    "hu": "Hungarian",
    "hy": "Armenian",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indonesian",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiak",
    "io": "Ido",
    "is": "Icelandic",
    "it": "Italian",
    "iu": "Inuktitut",
    "ja": "Japanese",
    "jv": "Javanese",
    "ka": "Georgian",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazakh",
    "kl": "Greenlandic",
    "km": "Cambodian",
    "kn": "Kannada",
    "ko": "Korean",
    "kr": "Kanuri",
    "ks": "Kashmiri",
    "ku": "Kurdish",
    "kv": "Komi",
    "kw": "Cornish",
    "ky": "Kirghiz",
    "la": "Latin",
    "lb": "Luxembourgish",
    "lg": "Ganda",
    "li": "Limburgian",
    "ln": "Lingala",
    "lo": "Laotian",
    "lt": "Lithuanian",
    "lv": "Latvian",
    "mg": "Malagasy",
    "mh": "Marshallese",
    "mi": "Maori",
    "mk": "Macedonian",
    "ml": "Malayalam",
    "mn": "Mongolian",
    "mo": "Moldovan",
    "mr": "Marathi",
    "ms": "Malay",
    "mt": "Maltese",
    "my": "Burmese",
    "na": "Nauruan",
    "nd": "North Ndebele",
    "ne": "Nepali",
    "ng": "Ndonga",
    "nl": "Dutch",
    "nn": "Norwegian Nynorsk",
    "nb": "Norwegian",
    "nr": "South Ndebele",
    "nv": "Navajo",
    "ny": "Chichewa",
    "oc": "Occitan",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossetian",
    "pa": "Punjabi",
    "pi": "Pali",
    "pl": "Polish",
    "ps": "Pashto",
    "pt": "Portuguese",
    "qu": "Quechua",
    "rm": "Raeto Romance",
    "rn": "Kirundi",
    "ro": "Romanian",
    "ru": "Russian",
    "rw": "Rwandi",
    "sa": "Sanskrit",
    "sc": "Sardinian",
    "sd": "Sindhi",
    "sg": "Sango",
    "sh": "Serbo-Croatian",
    "si": "Sinhalese",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sm": "Samoan",
    "sn": "Shona",
    "so": "Somalia",
    "sq": "Albanian",
    "sr": "Serbian",
    "ss": "Swati",
    "st": "Southern Sotho",
    "su": "Sundanese",
    "sv": "Swedish",
    "sw": "Swahili",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "tk": "Turkmen",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turkish",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahitian",
    "ug": "Uyghur",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "ve": "Venda",
    "vi": "Vietnamese",
    "vo": "Volapük",
    "wa": "Walloon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "Chinese",
    "zu": "Zulu"
  },
  "notifications": {
    "settings": {
      "events": {
        "conversationAgentMentioned": "When you're mentioned in a comment",
        "conversationAssignedToAgent": "When a conversation is reassigned to you",
        "conversationAssignedAgentUnSnoozed": "When a conversation assigned to you gets reactivated",
        "conversationAssignedAgentContactMessageCreated": "When a contact replies to a conversation assigned to you",
        "conversationAssignedTeamContactMessageCreated": "When a contact replies to a conversation assigned to one of your teams",
        "conversationAssignedTeamCreatedByContact": "When a contact starts a new conversation assigned to one of your teams",
        "conversationAssignedTeamUnSnoozed": "When a conversation assigned to one of your teams gets reactivated",
        "conversationAssignedToTeam": "When a conversation gets reassigned to one of your teams",
        "conversationUnassignedContactMessageCreated": "When a contact replies to an unassigned conversation",
        "conversationUnassignedCreatedByContact": "When a contact starts a new unassigned conversation",
        "conversationUnassignedUnSnoozed": "When an unassigned conversation gets reactivated"
      },
      "groups": {
        "commentMentions": "Comment mentions",
        "conversationReassignment": "Conversation reassignment",
        "snoozedConversations": "Snoozed conversations",
        "conversationReplies": "Conversation replies",
        "newConversations": "New conversations"
      },
      "pushNotifications": "Push notifications",
      "pushNotificationWhenAway": "Push notifications when away",
      "preferDesktop": "Prefer desktop",
      "inApp": "In app",
      "dontNotifyMe": "Don't notify me",
      "header": "Notification settings"
    },
    "conversationReminder": "Conversation reminder",
    "youWereMentionedByAgent": "You were mentioned by {0}",
    "conversationAssignedToYou": "Conversation assigned to you",
    "conversationAssignedToTeam": "Conversation assigned to {0}",
    "newConversation": "New conversation",
    "newReplyInConversation": "New reply in conversation",
    "enablePushNotificationsOnThisDevice": "Enable push notifications on this device",
    "disablePushNotificationsOnThisDevice": "Disable push notifications on this device",
    "pushNotificationsNotAvailable": "Push notifications not available",
    "pushNotificationsDisabledSuccessMessage": "Push notifications disabled successfully.",
    "pushNotAvailableInCurrentBrowserNotificationMessage": "Sorry, push notifications are not available in your current browser.",
    "pushNotificationsEnabledSuccessMessage": "Push notifications enabled successfully.",
    "pushPermissionsWereRejected": "Permissions were not granted by your browser."
  },
  "botAssistant": {
    "settings": {
      "defaultMode": "Default assistant mode",
      "defaultModeHelp": "Select the default assistant mode for new conversations across all channels."
    }
  },
  "eyeDropper": {
    "selectTooltip": "Select a color on your screen"
  },
  "search": {
    "contacts": "Contacts",
    "conversations": "Conversations",
    "messages": "Messages",
    "noResultsHeader": "No results found",
    "noResultsDescription": "Nothing matched your search filters. Please try again.",
    "noAddresses": "no addresses",
    "message": "Message",
    "kbArticle": "Knowledge base article",
    "conversation": "Conversation",
    "inputPlaceholder": "Search..."
  },
  "no-description": "no description",
  "knowledgeBase": {
    "showArticle": "Show article",
    "articleList": {
      "selectAll": "Select all articles",
      "newKbArticle": "Create new article",
      "goToPreviousConversation": "Go to previous article"
    }
  },
  "appInit": {
    "errorTitle": "An error occured",
    "errorMessage": "Sorry, the application could not be started at this time.",
    "loadingMessage": "Getting things ready..."
  },
  "activityFeed": {
    "eventLogHistoryTitle": "Event Log History",
    "activeConversationsTitle": "Active Conversations",
    "noActiveConversations": "Currently no conversations with activity",
    "aConversationHasActivity": "A conversation has activity",
    "customerIsTyping": "The customer is typing"
  }
}
