<template>
  <div class="sa-editor-popup">
    <q-list>
      <template v-if="popupItems?.length && !popupItems.some(i => i === null)">
        <q-item
          v-for="(item, index) in popupItems"
          :key="index"
          clickable
          class="sa-editor-popup__item"
          :class="{ 'sa-editor-popup__item--selected': index === selectedIndex }"
          @mouseenter="selectedIndex = index"
          @click="selectItem(index)"
        >
          <template-suggestion-item v-if="item" :title="item.document?.title!" :body="item.document?.solution!" />
        </q-item>
      </template>
      <template v-else-if="popupItems?.length && popupItems.some(i => i === null)">
        <div class="muted p-2">
          {{ $t("kb.editorPlugin.typeToSearchForArticle") }}
        </div>
      </template>
      <template v-else>
        <q-item>{{ $t("kb.editorPlugin.noArticleFound") }}</q-item>
      </template>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, toRefs } from "vue";
import { usePopup } from "src/features/tip-tap-editor/composables/usePopup";
import TemplateSuggestionItem from "src/features/tip-tap-editor/extensions/template/TemplateSuggestionItem.vue";
import { GSearchResultOfKnowledgeBaseIndex } from "src/generated/graphql";

const props = defineProps({
  items: {
    // eslint-disable-next-line
    type: Object as PropType<Array<GSearchResultOfKnowledgeBaseIndex | null>>,
    required: true,
  },

  command: {
    type: Function,
    required: true,
  },
});

const lastExpand = ref<number | null>(null);
const shiftKey = ref(false);
const showFull = ref(false);
const { items: popupItems } = toRefs(props);

const { onKeyDown, selectedIndex } = usePopup(popupItems, selectItem, otherKeyCallback);

defineExpose({ onKeyDown });

function selectItem(index: number) {
  const item = popupItems?.value[index];

  if (item) {
    const index = popupItems.value.findIndex(i => i?.document?.id === item.document?.id);
    if (index !== -1) {
      const template = popupItems.value[index];
      props.command({ id: template?.document?.id, content: template?.document?.solution });
    }
  }
}

function otherKeyCallback(event: KeyboardEvent) {
  if (event.shiftKey && selectedIndex.value !== lastExpand.value) {
    lastExpand.value = selectedIndex.value;
    shiftKey.value = true;
    showFull.value = true;
  }
}
</script>
