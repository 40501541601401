import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import {
  GAgentNotificationFragment,
  GOnAgentNotificationCreatedSubscription,
  GOnAgentNotificationCreatedSubscriptionVariables,
  GOnAgentNotificationModifiedSubscription,
  GOnAgentNotificationModifiedSubscriptionVariables,
  OnAgentNotificationCreatedDocument,
  OnAgentNotificationModifiedDocument,
} from "src/generated/graphql";
import { useGraphqlSdk } from "src/graphql/graphql-client";

export type AgentNotification = GAgentNotificationFragment & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AgentNotificationListData {}

export class AgentNotificationListStore extends Store2<AgentNotificationListData, AgentNotification> {
  protected data(): AgentNotificationListData {
    return {};
  }

  async load() {
    const sdk = useGraphqlSdk();

    const res = await sdk.GetAgentNotifications({
      take: 50,
      continuationToken: this.settings.continuationToken,
    });

    res.agentNotifications.items.forEach(i => {
      if (i) {
        this.push(i);
      }
    });

    this.setContinuationToken(res.agentNotifications.continuationToken);
    // agentNotificationListStore.loadWithData(res.agentNotifications.items as AgentNotification[]);
    this.loaded();

    this.setupSubscribe();
  }

  async loadMore(): Promise<void> {
    return new Promise(async resolved => {
      const sdk = useGraphqlSdk();

      if (!this.hasNextPage()) {
        return resolved();
      }

      sdk
        .GetAgentNotifications({
          take: 10,
          continuationToken: this.getContinuationToken(),
        })
        .then(res => {
          res.agentNotifications.items.forEach(data => {
            if (data) {
              this.push(data);

              this.list.sort((a, b) => {
                return a.timestamp > b.timestamp ? 1 : -1;
              });
            }
          });

          this.setContinuationToken(res.agentNotifications.continuationToken);
        })
        .catch(err => {
          console.warn("error getting notifications", err);
        })
        .finally(() => {
          return resolved();
        });
    });
  }

  protected setupSubscribe() {
    this.addSubscription<GOnAgentNotificationCreatedSubscription, GOnAgentNotificationCreatedSubscriptionVariables>(
      OnAgentNotificationCreatedDocument,
      {},
      res => {
        this.insert(0, res.onAgentNotificationCreated);
      }
    );

    this.addSubscription<GOnAgentNotificationModifiedSubscription, GOnAgentNotificationModifiedSubscriptionVariables>(
      OnAgentNotificationModifiedDocument,
      {},
      res => {
        const index = this.list.findIndex(i => i.id === res.onAgentNotificationModified.id);
        if (index !== -1) {
          this.list[index].seen = res.onAgentNotificationModified.seen;
        } else {
          this.insert(0, res.onAgentNotificationModified);
        }
      }
    );
  }

  // protected async load() {
  //   const sdk = useGraphqlSdk();
  //   sdk
  //     .GetAgentNotifications({
  //       take: 50,
  //       continuationToken: this.settings.continuationToken,
  //     })
  //     .then(res => {
  //       res.agentNotifications?.items.forEach(i => {
  //         this.push(i);
  //       });
  //
  //       this.loaded();
  //       this.selectFirst();
  //       this.setContinuationToken(res.agentNotifications.continuationToken);
  //     });
  // }
}

// export const agentNotificationListStore = new AgentNotificationListStore();
