import { Plugin, PluginKey } from "@tiptap/pm/state";
//import { EditorView } from "@tiptap/pm/view";
import type { ImageUploaderPluginOptions } from "./image-upload-extension";
import axios from "axios";
import { ContextHolder } from "@frontegg/vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { randomString } from "src/shared/random";
import { EditorView } from "@tiptap/pm/view";

let plugin: ImageUploaderPlugin | null = null;

// eslint-disable-next-line
async function upload(file: any, url: string, name: string) {
  console.log("Uploading", name, "to", url);
  const formData = new FormData();
  formData.append("file", file, name);

  const jwt = ContextHolder.getAccessToken();

  const response = await axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${jwt}` },
    onUploadProgress: progressEvent => {
      console.log("Upload progress", progressEvent);
    },
  });

  return response.data;
}

export function imageUploader(options: ImageUploaderPluginOptions) {
  plugin = new ImageUploaderPlugin(options);

  return new Plugin({
    key: new PluginKey("image-uploader"),

    props: {
      handlePaste(view, event) {
        return plugin?.setView(view).handlePaste(view, event) || false;
      },
    },
  });
}

export class ImageUploaderPlugin extends Plugin {
  public view!: EditorView;

  constructor(public config: ImageUploaderPluginOptions) {
    super(config);
  }

  public handlePaste(view: EditorView, event: ClipboardEvent) {
    if (event.clipboardData === null) {
      console.log("Empty clipboard");
      return false;
    }

    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.indexOf("image") === 0) {
        event.preventDefault();

        if (!this.config.acceptMimes.includes(item.type)) {
          console.log("Unsupported MIME type pasted:", item.type);
          continue;
        }

        const image = item.getAsFile();
        if (!isNullOrUndefined(image)) {
          const filename = [randomString(8), this.mimeToExt(image.type)].join("");
          this.uploadAndInsertImage(image, filename, view);
        }
      }
    }
  }

  private mimeToExt(mime: string) {
    if (mime === "image/jpeg" || mime === "image/jpg") {
      return ".jpg";
    }

    if (mime === "image/png") {
      return ".png";
    }

    return "";
  }

  private uploadAndInsertImage(file: File, filename: string, view: EditorView) {
    const { schema } = view.state;

    void upload(file, this.config.uploadUrl, filename).then((res: { id: string; previewUrl: string }) => {
      const img = new window.Image();

      img.onload = function () {
        const dpr = window.devicePixelRatio || 1;
        const width = Math.ceil(img.width / dpr);
        const height = Math.ceil(img.height / dpr);

        const node = schema.nodes.image.create({
          src: res.previewUrl,
          style: `max-width: ${width}px; max-height: ${height}px; width: 100%; height: 100%;`,
          azureblob: true,
          width: width,
          height: height,
        });

        const transaction = view.state.tr.replaceSelectionWith(node);
        view.dispatch(transaction);
      };

      img.src = res.previewUrl;
    });
  }

  public setView(view: EditorView): this {
    this.view = view;
    return this;
  }
}
