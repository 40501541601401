<template>
  <q-dialog ref="dialogRef" transition-duration="0" position="top" @hide="onDialogHide">
    <div
      class="mx-auto w-full mt-24 max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
    >
      <FocusTrap>
        <Combobox @update:modelValue="onSelect">
          <div class="relative border-b-gray-100 border flex flex-nowrap">
            <q-icon
              size="sm"
              name="fa-light fa-search"
              class="pointer-events-none absolute left-4 top-3 text-gray-400"
            />
            <ComboboxInput
              class="h-12 w-full border-0 outline-none bg-transparent pl-14 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              :placeholder="$t('conversations.assignDialog.inputPlaceholder')"
              @change="search = $event.target.value"
            />
          </div>

          <ComboboxOptions
            static
            class="max-h-96 transform-gpu scroll-py-4 overflow-y-auto p-3 option-container space-y-1"
          >
            <ComboboxOption
              v-for="item in list"
              :key="`palette--${item.ui.index}`"
              :value="item.ui.index"
              as="template"
              v-slot="{ active }"
            >
              <li
                :class="[
                  'flex flex-row flex-nowrap justify-between items-center cursor-pointer select-none rounded-xl p-3',
                  active && 'bg-gray-100',
                ]"
              >
                <div
                  class="flex flex-row items-center space-x-5 w-full py-2"
                  :id="`conversation-assign-${item.ui.index}`"
                >
                  <div class="w-6">
                    <team-avatar v-if="item.type === TeamOrAgentType.Team" size="sm" :id="item.id" />

                    <agent-avatar-with-status
                      v-else-if="item.type === TeamOrAgentType.Agent"
                      size="sm"
                      :agent-id="item.id"
                    />

                    <div v-else-if="item.type === TeamOrAgentType.None">
                      <q-avatar
                        dense
                        color="grey-4"
                        round
                        :size="'sm'"
                        text-color="black"
                        icon="fa-light fa-users"
                      ></q-avatar>
                    </div>
                  </div>

                  <div class="flex flex-grow justify-between">
                    <div>
                      {{ item.label }}
                    </div>

                    <div
                      v-if="
                        (item.type === TeamOrAgentType.Agent && item.id === currentAgentId) ||
                        (item.type === TeamOrAgentType.Team && item.id === currentTeamId) ||
                        (item.type === TeamOrAgentType.None && currentAgentId == null && currentTeamId == null)
                      "
                    >
                      <q-icon name="check" size="sm" />
                    </div>
                  </div>
                </div>
              </li>
            </ComboboxOption>
          </ComboboxOptions>

          <div v-if="search !== '' && !list.length" class="px-6 py-14 text-center text-sm sm:px-14">
            <!-- <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" /> -->
            <q-icon name="fa-light fa-exclamation-circle" size="md" class="mx-auto text-gray-400" />
            <p class="mt-4 font-semibold text-gray-900">{{ $t("search.noResultsHeader") }}</p>
            <p class="mt-2 text-gray-500">{{ $t("search.noResultsDescription") }}</p>
          </div>
        </Combobox>
      </FocusTrap>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref, PropType, computed } from "vue";
import { useShortcut } from "src/composables/useShortcut";
import { useI18n } from "vue-i18n";
import { conversationAssignStore } from "src/features/conversation/conversation-assign/conversation-assign-global-store";
import { TeamOrAgentType } from "src/shared/models/models";
import TeamAvatar from "src/features/team/team-avatar/TeamAvatar.vue";
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import { conversationAssignCommand } from "src/features/conversation/conversation-assign/api-commands";
import { useQuasar, useDialogPluginComponent } from "quasar";
import { Combobox, ComboboxInput, ComboboxOptions, FocusTrap, ComboboxOption } from "@headlessui/vue";

const props = defineProps({
  conversationIds: {
    type: Array as PropType<string[]>,
    required: true,
  },

  currentAgentId: {
    type: String,
    required: false,
    default: () => null,
  },

  currentTeamId: {
    type: String,
    required: false,
    default: () => null,
  },
});

const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
defineEmits([...useDialogPluginComponent.emits]);

const { t } = useI18n();
const $q = useQuasar();

const search = ref("");
const isSaving = ref(false);

const list = computed(() => {
  if (search.value != "") {
    return conversationAssignStore
      .getList()
      .filter(i => i.label && i.label.toLowerCase().indexOf(search.value.toLowerCase()) !== -1);
  } else {
    return conversationAssignStore.getList();
  }
});

async function onSelect(uiIndex: number) {
  if (isSaving.value) {
    console.log("Assignment already in progress");
    return;
  }

  const selected = list.value.find(i => i.ui.index == uiIndex);

  if (!selected) {
    console.log("Could not find uiIndex", uiIndex);
    $q.notify({
      message: t("conversations.assignDialog.couldNotAssignConversationTitle"),
      type: "negative",
    });

    return;
  }

  isSaving.value = true;

  const progress = $q.notify({
    type: "ongoing",
    message: t("conversation.actions.assign.assignProgressMessage"),
  });

  for (const conversationId of props.conversationIds) {
    try {
      await conversationAssignCommand(
        conversationId,
        selected.type === TeamOrAgentType.Agent ? selected.id : null,
        selected.type === TeamOrAgentType.Team ? selected.id : null,
      );

      progress({
        type: "positive",
        message: t("conversation.actions.assign.successToastMessage"),
        actions: [
          {
            label: t("common.undo"),
            color: "yellow",
            handler: async () => {
              await conversationAssignCommand(conversationId, props.currentAgentId, props.currentTeamId);
            },
          },
        ],
      });

      onDialogOK();
    } catch (err) {
      progress({
        message: t("conversations.assignDialog.couldNotAssignConversationTitle"),
        type: "negative",
      });
    } finally {
      isSaving.value = false;
    }
  }
}

useShortcut("conversationAssign", [], {
  newShortcutStack: true,
});
</script>

<style lang="sass" scoped></style>
