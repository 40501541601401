<template>
  <div class="flex flex-row justify-between items-center">
    <div class="flex flex-row items-center space-x-3">
      <div class="w-10 flex justify-center">
        <slot name="icon" />
      </div>

      <div>
        <slot name="body" />
      </div>
    </div>

    <div><slot name="ago" /></div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="sass"></style>
