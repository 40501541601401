import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import { agentWithStatusStore } from "src/features/agent/agent-avatar-with-status/agent-with-status-global-store";
import AgentSuggestionList from "src/features/tip-tap-editor/extensions/agent-mention/AgentSuggestionList.vue";
import { GAgentStatusItem } from "src/generated/graphql";
import { SuggestionKeyDownProps, SuggestionOptions, SuggestionProps } from "@tiptap/suggestion";
import { Instance, Props, GetReferenceClientRect } from "tippy.js";

const opts: Omit<SuggestionOptions<unknown>, "editor"> = {
  decorationTag: "div",

  items: ({ query }) => {
    const queryLc = query.toLowerCase();
    return agentWithStatusStore
      .getList()
      .filter(
        agent =>
          [GAgentStatusItem.Active].includes(agent.perTenant.status) &&
          (agent.lastName?.toLowerCase().indexOf(queryLc) !== -1 ||
            agent.firstName?.toLowerCase().indexOf(queryLc) !== -1),
      )
      .slice(0, 5)
      .map<{ id: string; name: string }>(i => {
        return {
          id: i.id,
          name: i.name,
        };
      });
  },

  render: () => {
    let component: VueRenderer;
    let popup: Instance<Props>[];

    return {
      onStart: (props: SuggestionProps<unknown>) => {
        component = new VueRenderer(AgentSuggestionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props: SuggestionProps<unknown>) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};

export default opts;
