<template>
  <div>
    <div v-if="showName" class="sa-conversation-timeline-agent-message__name mb-1 text-sm">
      {{ agentName }}
    </div>

    <div
      class="sa-conversation-timeline-agent-message__block cursor-pointer pl-5 pr-2 py-3 rounded-lg w-full flex justify-between items-center"
      :class="{
        'ring-2 ring-offset-2': selected,
      }"
      @click="onBlockClick"
    >
      <div class="sa-conversation-timeline-agent-message__block-content max-w-full leading-6 text-sm pr-4 space-y-1">
        <div v-html="agentMessage?.content" />

        <div v-if="agentMessage.contentIsTruncated" class="sa-conversation-timeline-agent-message__block-more">
          {{ $t("conversation.display.textIsTruncated") }}
        </div>
      </div>
      <div class="ml-auto flex flex-row items-center space-x-2">
        <div class="sa-conversation-timeline-agent-message__block-timestamp text-sm">
          <updated-ago :updated="timestamp" />
        </div>
        <div>
          <q-btn
            icon="more_vert"
            unelevated
            dense
            class="sa-conversation-timeline-agent-message__menu-button"
            @click="showBlockMenu"
          />
        </div>
      </div>

      <q-menu ref="menu" class="sa-conversation-display__menu" anchor="bottom right" self="top right" no-parent-event>
        <q-list>
          <q-item clickable @click="showCommentEditor">
            <q-item-section side>
              <q-icon name="o_comment" />
            </q-item-section>

            <q-item-section>{{ $t("conversations.singleView.addComment") }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </div>

    <conversation-attachment-grid :attachments="agentMessage.attachments" />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { GConversationAgentMessage } from "src/generated/graphql";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import { QMenu } from "quasar";
import { useBlockMenu } from "src/composables/useBlockMenu";
import ConversationAttachmentGrid from "src/features/conversation/conversation-display/ConversationAttachmentGrid.vue";
import { emitter } from "boot/mitt";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  timestamp: {
    type: String,
    required: true,
  },

  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },

  showName: {
    type: Boolean,
    required: true,
    default: () => true,
  },

  timelineId: {
    type: String,
    required: true,
  },

  agentMessage: {
    type: Object as PropType<GConversationAgentMessage>,
    required: true,
  },
});

const showCommentEditor = (e: Event) => {
  emitter.emit("conversation-timeline-item-comment", props.timelineId);
  showBlockMenu(e);
};

const agentName = computed(() => {
  if (props.agentMessage.agent) {
    return props.agentMessage.agent.name;
  }

  return t("conversation.display.systemAgentName");
});

const { menu, onBlockClick, showBlockMenu } = useBlockMenu();
</script>
