import { parse } from "date-fns";
import { isNullOrUndefined } from "src/shared/object-utils";
import { date } from "quasar";

type TimeKeyword = {
  next: string;
  minute: string;
  hour: string;
  day: string;
  week: string;
  month: string;
  year: string;
  minuteRe: string;
  hourRe: string;
  dayRe: string;
  weekRe: string;
  monthRe: string;
  yearRe: string;
  daysOfWeek: Record<string, string>;
  numbers: Record<string, string>;
};

const patterns: Record<string, TimeKeyword> = {
  en: {
    next: "next",
    minute: "minutes",
    hour: "hours",
    day: "days",
    week: "weeks",
    month: "months",
    year: "years",
    minuteRe: "(\\d+)\\s*minute(s)?",
    hourRe: "(\\d+)\\s*hour(s)?",
    dayRe: "(\\d+)\\s*day(s)?",
    weekRe: "(\\d+)\\s*week(s)?",
    monthRe: "(\\d+)\\s*month(s)?",
    yearRe: "(\\d+)\\s*year(s)?",
    daysOfWeek: {
      sunday: "sunday",
      monday: "monday",
      tuesday: "tuesday",
      wednesday: "wednesday",
      thursday: "thursday",
      friday: "friday",
      saturday: "saturday",
    },
    numbers: {
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
      7: "seven",
      8: "eight",
      9: "nine",
      10: "ten",
      11: "eleven",
      12: "twelve",
      13: "thirteen",
      14: "fourteen",
    },
  },
  no: {
    next: "neste",
    minute: "minutter",
    hour: "timer",
    day: "dager",
    week: "uker",
    month: "måneder",
    year: "år",
    minuteRe: "(\\d+)\\s*minutt(er)?",
    hourRe: "(\\d+)\\s*time(r)?",
    dayRe: "(\\d+)\\s*dag(er)?",
    weekRe: "(\\d+)\\s*uke(r)?",
    monthRe: "(\\d+)\\s*måned(er)?",
    yearRe: "(\\d+)\\s*år",
    daysOfWeek: {
      sunday: "søndag",
      monday: "mandag",
      tuesday: "tirsdag",
      wednesday: "onsdag",
      thursday: "torsdag",
      friday: "fredag",
      saturday: "lørdag",
    },
    numbers: {
      1: "en",
      2: "to",
      3: "tre",
      4: "fire",
      5: "fem",
      6: "seks",
      7: "syv",
      8: "åtte",
      9: "ni",
      10: "ti",
      11: "elleve",
      12: "tolv",
      13: "tretten",
      14: "fjorten",
    },
  },
  // ... Other languages here
};

const daysOfWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export function parseDateInput(dateStr: string): Date[] {
  const exactDateMatch = parseExactDate(dateStr);
  if (!isNullOrUndefined(exactDateMatch) && !isNaN(exactDateMatch.getTime())) {
    return [exactDateMatch];
  }

  const exactNaturalMatch = parseNaturalLanguageDate(dateStr);
  if (!isNullOrUndefined(exactNaturalMatch) && !isNaN(exactNaturalMatch.getTime())) {
    return [exactNaturalMatch];
  }

  return fuzzySearch(dateStr);
}

function parseExactDate(dateStr: string): Date | null {
  // List of possible formats
  const dateFormats = ["MMM dd yy", "dd MMM yy", "dd.MM.yy", "yyyy-MM-dd", "MMM dd yyyy", "dd MMM yyyy"];

  for (const format of dateFormats) {
    const parsedDate = parse(dateStr, format, new Date());
    if (!isNaN(parsedDate.getTime())) {
      parsedDate.setHours(9, 0, 0);
      return parsedDate;
    }
  }

  return null;
}

function parseNaturalLanguageDate(expression: string): Date | null {
  const today = new Date();

  for (const lang in patterns) {
    const minuteMatch = new RegExp(patterns[lang].minuteRe, "i").exec(expression);

    if (minuteMatch) {
      return addMinutes(today, parseInt(minuteMatch[1], 10));
    }

    const hourMatch = new RegExp(patterns[lang].hourRe, "i").exec(expression);

    if (hourMatch) {
      return addHours(today, parseInt(hourMatch[1], 10));
    }

    const dayMatch = new RegExp(patterns[lang].dayRe, "i").exec(expression);

    if (dayMatch) {
      return addDays(today, parseInt(dayMatch[1], 10));
    }

    const weekMatch = new RegExp(patterns[lang].weekRe, "i").exec(expression);

    if (weekMatch) {
      const weeks = parseInt(weekMatch[1], 10);
      return addDays(today, weeks * 7);
    }

    const monthMatch = new RegExp(patterns[lang].monthRe, "i").exec(expression);

    if (monthMatch) {
      return addMonths(today, parseInt(monthMatch[1], 10));
    }

    const yearMatch = new RegExp(patterns[lang].yearRe, "i").exec(expression);

    if (yearMatch) {
      return addYears(today, parseInt(yearMatch[1], 10));
    }

    // Check for days of the week
    for (const dayKey in patterns[lang].daysOfWeek) {
      const day = patterns[lang].daysOfWeek[dayKey];
      if (expression.toLowerCase().indexOf(day) !== -1) {
        // Check for "next"
        if (expression.toLowerCase().indexOf(patterns[lang].next) !== -1) {
          return getNextWeekday(dayKey, true);
        } else {
          return getNextWeekday(dayKey);
        }
      }
    }
  }

  return null;
}

function fuzzySearch(dateStr: string): Date[] {
  const today = new Date();

  const fuzzyNumber = fuzzyNumberSearch(today, dateStr);

  if (fuzzyNumber.length) {
    return fuzzyNumber;
  }

  const fuzzyNext = fuzzyNextSearch(today, dateStr);

  if (fuzzyNext.length) {
    return fuzzyNext;
  }

  return [];
}

function fuzzyNumberSearch(today: Date, dateStr: string): Date[] {
  const list: Date[] = [];
  let number: number | null = null;
  let numberStr = "";

  const numberMatch = new RegExp("(\\d+)\\s*(.*)", "i").exec(dateStr);

  if (numberMatch) {
    number = parseInt(numberMatch[1], 10);
    numberStr = numberMatch[2];
  } else {
    OUTER: for (const lang in patterns) {
      for (const numbersKey in patterns[lang].numbers) {
        const numberStrMatch = new RegExp(`(${patterns[lang].numbers[numbersKey]})\\s*(.*)`, "i").exec(dateStr);
        if (numberStrMatch) {
          number = parseInt(numbersKey, 10);
          numberStr = numberStrMatch[2];
          break OUTER;
        }
      }
    }
  }

  if (number !== null) {
    if (numberStr !== "") {
      for (const lang in patterns) {
        if (patterns[lang].minute.startsWith(numberStr)) {
          list.push(addMinutes(today, number));
        }

        if (patterns[lang].hour.startsWith(numberStr)) {
          list.push(addHours(today, number));
        }

        if (patterns[lang].day.startsWith(numberStr)) {
          list.push(addDays(today, number));
        }

        if (patterns[lang].week.startsWith(numberStr)) {
          list.push(addDays(today, number * 7));
        }

        if (patterns[lang].month.startsWith(numberStr)) {
          list.push(addMonths(today, number));
        }

        if (patterns[lang].year.startsWith(numberStr)) {
          list.push(addYears(today, number));
        }
      }
    } else {
      list.push(
        addMinutes(today, number),
        addHours(today, number),
        addDays(today, number),
        addDays(today, number * 7),
        addMonths(today, number),
        addYears(today, number),
      );
    }
  }

  return list.filter((date, index, self) => index === self.findIndex(d => d.getTime() === date.getTime()));
}

function fuzzyNextSearch(today: Date, dateStr: string): Date[] {
  const list: Date[] = [];
  for (const lang in patterns) {
    const nextMatch = new RegExp(`${patterns[lang].next}\\s*(.*)`, "i").exec(dateStr);

    if (nextMatch) {
      if (nextMatch[2] !== "") {
        const str = nextMatch[2];

        if (patterns[lang].day.startsWith(str)) {
          list.push(addDays(today, 1));
        }

        if (patterns[lang].week.startsWith(str)) {
          list.push(addDays(today, 7));
        }

        if (patterns[lang].month.startsWith(str)) {
          list.push(addMonths(today, 1));
        }

        if (patterns[lang].year.startsWith(str)) {
          list.push(addYears(today, 1));
        }
      } else {
        list.push(addDays(today, 1), addDays(today, 7), addMonths(today, 1), addYears(today, 1));
      }
    }
  }

  return list;
}

function addMinutes(d: Date, minutes: number): Date {
  return date.addToDate(d, { minutes: minutes });
}

function addHours(d: Date, hours: number): Date {
  return date.addToDate(d, { hours: hours });
}

function addDays(d: Date, days: number): Date {
  return date.addToDate(d, { days: days });
}

function addMonths(d: Date, months: number): Date {
  return date.addToDate(d, { months: months });
}

function addYears(d: Date, years: number): Date {
  return date.addToDate(d, { years: years });
}

function getNextWeekday(weekday: string, skipFirstOccurrence = false): Date {
  const date = new Date();
  let daysUntilNext = (7 + daysOfWeek[weekday as keyof typeof daysOfWeek] - date.getDay()) % 7;

  if (skipFirstOccurrence) {
    daysUntilNext += 7; // This will skip the first occurrence
  } else {
    daysUntilNext = daysUntilNext === 0 ? 7 : daysUntilNext; // This adjusts if today is the specified day
  }

  date.setDate(date.getDate() + daysUntilNext);
  date.setHours(9, 0, 0);
  return date;
}

// function getNextWeekday(weekday: string): Date {
//   const date = new Date();
//   let daysUntilNext = (7 + daysOfWeek[weekday as keyof typeof daysOfWeek] - date.getDay()) % 7;
//   daysUntilNext = daysUntilNext === 0 ? 7 : daysUntilNext;
//   date.setDate(date.getDate() + daysUntilNext);
//   return date;
// }
//
// function getThisWeekday(weekday: string): Date {
//   const date = new Date();
//   let daysUntilNext = daysOfWeek[weekday as keyof typeof daysOfWeek] - date.getDay();
//   if (daysUntilNext <= 0) {
//     daysUntilNext += 7;
//   }
//   date.setDate(date.getDate() + daysUntilNext);
//   return date;
// }
