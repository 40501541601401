<template>
  <div class="flex content-center items-center flex-col space-y-2" v-if="showSwitcher">
    <Popover class="relative">
      <PopoverButton :class="['outline-none flex flex-col items-center space-y-1']" class="">
        <q-avatar :class="['switcher-button h-10 w-10 rounded-xl transition-all']">
          <q-badge v-if="true" transparent floating rounded class="sa-main-nav__button--badge z-50">
            {{ combinedCountOtherTenants }}
          </q-badge>
          {{ currentTenantData?.name.substring(0, 1) }}
        </q-avatar>
        <span class="text-xs font-medium">{{ currentTenantData?.name }}</span>
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="fixed left-6 z-10 mt-3 max-w-xs transform px-4 sm:px-0 lg:max-w-3xl switcher-panel"
          v-slot="{ close }"
        >
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
            <div class="bg-gray-50 p-4 flex flex-col space-y-2">
              <router-link
                v-for="(tenant, idx) in tenants"
                :to="resolvedTenantUrl(tenant.tenantId)"
                :key="tenant.tenantId"
                v-bind="$props"
                custom
                v-slot="{ href }"
              >
                <a
                  :href="href"
                  class="cursor-pointer p-2 rounded-md"
                  @click.prevent="navigateToTenant(tenant.tenantId, close)"
                  :class="[
                    currentTenantId !== tenant.tenantId && 'border-transparent hover:border-slate-300',
                    currentTenantId === tenant.tenantId && 'bg-gray-100',
                    'hover:bg-gray-100',
                  ]"
                >
                  <div class="flex flex-row space-x-4 items-center flex-nowrap justify-between">
                    <div class="flex flex-row space-x-4 flex-nowrap items-center">
                      <q-avatar :class="[currentTenantId === tenant.tenantId && 'active', 'rounded-md']" square>
                        {{ tenant.name.substring(0, 1) }}
                        <q-badge
                          floating
                          rounded
                          class="sa-main-nav__button--badge"
                          v-if="activeConversationCountStore.countForTenant(tenant.tenantId) ?? 0 > 0"
                          >{{ activeConversationCountStore.countForTenant(tenant.tenantId) }}
                        </q-badge>
                      </q-avatar>
                      <div class="font-medium text-base place-items-start text-left">
                        {{ tenant.name }}
                      </div>
                    </div>
                    <div class="text-xs px-1 whitespace-nowrap">
                      <KeyboardShortcutDisplay color="light" :keys="`mod+${idx + 1}`" />
                    </div>
                  </div>
                </a>
              </router-link>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import { useFrontegg } from "@frontegg/vue";
import { computed, ref, watch, nextTick } from "vue";
import { activeConversationCountStore } from "../conversation/active-conversation-count/active-conversation-count-global-store";
import { useRouter } from "vue-router";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import KeyboardShortcutDisplay from "src/components/KeyboardShortcutDisplay.vue";
import { useCurrentTenant } from "src/composables/useCurrentTenant";

const hasRegisteredShortcuts = ref(false);
const showSwitcher = ref(false);
const router = useRouter();
//const route = useRoute();

const { authState: feAuthState } = useFrontegg();
const { currentTenantId } = useCurrentTenant();

// function parseJwt(token: string) {
//   var base64Url = token.split(".")[1];
//   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   var jsonPayload = decodeURIComponent(
//     window
//       .atob(base64)
//       .split("")
//       .map(function (c) {
//         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//       })
//       .join(""),
//   );

//   return JSON.parse(jsonPayload);
// }

// useIntervalFn(() => {
//   console.log(feAuthState.user?.tenantId);
//   console.log(ContextHolder.getUser()?.tenantId);

//   const parsed = parseJwt(ContextHolder.getAccessToken()!);
//   console.log("in jwt:", parsed.tenantId);
// }, 1000);

const currentTenantData = computed(() => tenants.value.find(i => i.tenantId == currentTenantId.value));

const combinedCountOtherTenants = computed(() => {
  if (tenants.value.length <= 1) {
    return null;
  }

  let i = 0;

  for (const tenant of tenants.value) {
    if (tenant.tenantId == currentTenantId.value) {
      continue;
    }

    i += activeConversationCountStore.countForTenant(tenant.tenantId) ?? 0;
  }

  return i;
});

const tenants = computed(() => {
  const tenantIds = feAuthState.user?.tenantIds;

  if (!tenantIds) {
    console.error("Failed to get tenantIds from auth state");
    return [];
  }

  return [...feAuthState.tenantsState.tenants]
    .filter(i => tenantIds?.includes(i.tenantId))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
});

const shortcuts = computed(() => {
  let i = 1;

  return tenants.value.map(tenant => {
    return {
      description: `Select tenant ${tenant.tenantId}`,
      key: `mod+${i++}`,
      callback: async () => {
        await navigateToTenant(tenant.tenantId);
      },
      shortcut: ShortcutItem.SwitchTenant,
      sendToCommandPalette: false,
      globalShortcut: true,
    };
  });
});

useShortcut("app", shortcuts, {
  includeGlobal: true,
});

const navigateToTenant = async (tenantId: string, panelCloseCallback?: () => void) => {
  if (panelCloseCallback) {
    panelCloseCallback();
  }
  console.log("navigating...");
  await router.push({
    name: "conversation-list",
    params: {
      tenantId: tenantId,
    },
  });
};

watch(
  tenants,
  () => {
    showSwitcher.value = tenants.value.length > 1;
  },
  {
    immediate: true,
  },
);

const resolvedTenantUrl = (newTenantId: string) => {
  return router.resolve({
    name: "conversation-list",
    params: {
      tenantId: newTenantId,
    },
  });
};
</script>

<style scoped lang="scss">
.switcher-button {
  color: var(--q-sa-main-navigation--tenantSwitcherTextColor);
  background: var(--q-sa-main-navigation--tenantSwitcherBackgroundColor);
  @apply hover:brightness-95;
}

.switcher-panel .q-avatar {
  color: var(--q-sa-main-navigation--buttonTextColor);
  background: var(--q-sa-main-navigation--buttonBackground);
  @apply ring-2 ring-gray-200;

  &.active {
    color: var(--q-sa-main-navigation--tenantSwitcherTextColor);
    background: var(--q-sa-main-navigation--tenantSwitcherBackgroundColor);
  }
}

.tenant-switcher-container {
  overflow: auto;
  // scrollbar firefox
  scrollbar-color: transparent transparent;
  // scrollbar chrome/edge
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    // scrollbar firefox
    scrollbar-color: initial;
    // scrollbar chrome/edge
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}
</style>
