import { boot } from "quasar/wrappers";
import mitt from "mitt";
import { MittEvents } from "src/shared/mitt-events";

const emitter = mitt<MittEvents>();

export default boot(({ app }) => {
  app.config.globalProperties.$mitt = emitter;
});

export { emitter };
