<template>
  <s-form @submit="onSubmit" ref="form" class="max-w-2xl">
    <q-banner v-if="conflicts.length" class="bg-red text-white rounded">
      <div v-for="(conflict, index) in conflicts" :key="index">
        {{ t("contactCreate.addressConflictError", { address: conflict }) }}
      </div>
    </q-banner>
    <q-banner v-else-if="error" class="bg-red text-white">{{ errorMessage }}</q-banner>

    <div class="space-y-1">
      <div class="sa-form__label">{{ t("contactCreate.firstName") }}</div>
      <q-input
        v-model="firstName"
        outlined
        class="sa-form__input"
        type="text"
        :placeholder="t('contactCreate.firstName')"
        autofocus
      />
    </div>

    <div class="space-y-1">
      <div class="sa-form__label">{{ t("contactCreate.lastName") }}</div>
      <q-input
        v-model="lastName"
        type="text"
        :placeholder="t('contactCreate.lastName')"
        outlined
        class="sa-form__input"
      />
    </div>

    <div class="space-y-1">
      <div class="sa-form__label">{{ t("contactCreate.emailAddresses") }}</div>
      <contact-email-address-input v-model:model-value="emailAddresses" />
    </div>

    <div class="space-y-1">
      <div class="sa-form__label">{{ t("contactCreate.phoneNumbers") }}</div>
      <contact-phone-number-input v-model:model-value="phoneNumbers" />
    </div>
  </s-form>
</template>

<script setup lang="ts">
import SForm from "components/SForm.vue";
import { ref } from "vue";
import { useFormSave } from "src/composables/useFormSave";
import { GraphQlUserError, useGraphqlSdk } from "src/graphql/graphql-client";
import ContactEmailAddressInput, {
  ContactEmailAddress,
} from "src/features/contact/contact-select/ContactEmailAddressInput.vue";
import ContactPhoneNumberInput, {
  ContactPhoneNumber,
} from "src/features/contact/contact-select/ContactPhoneNumberInput.vue";
import {
  GContactAddressConflictError,
  GCreateContactAddressInput,
  GInputContactAddressTypeItem,
  GUserErrorCode,
} from "src/generated/graphql";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["update:saving", "created"]);
const { t } = useI18n();
const { form, save, error, errorMessage, setError } = useFormSave();
defineExpose({ save });

const firstName = ref("");
const lastName = ref("");
const emailAddresses = ref<ContactEmailAddress[]>([]);
const phoneNumbers = ref<ContactPhoneNumber[]>([]);
const conflicts = ref<string[]>([]);

async function onSubmit() {
  const sdk = await useGraphqlSdk();
  emit("update:saving", true);

  const addresses: GCreateContactAddressInput[] = [];

  emailAddresses.value.forEach(i => {
    addresses.push({
      address: i.email,
      type: GInputContactAddressTypeItem.EmailAddress,
    });
  });

  phoneNumbers.value.forEach(i => {
    addresses.push({
      address: i.number,
      type: GInputContactAddressTypeItem.PhoneNumber,
    });
  });

  sdk
    .CreateContact({
      input: {
        firstName: firstName.value,
        lastName: lastName.value,
        tags: [],
        addresses: addresses,
      },
    })
    .then(res => {
      emit("created", res.createContact.contact);
    })
    .catch(ex => {
      if (ex instanceof GraphQlUserError) {
        if (ex.error.code === GUserErrorCode.ContactAddressConflict) {
          const contactAddressConflict = ex.error as GContactAddressConflictError;
          conflicts.value = contactAddressConflict.conflicts.map(i => i.address);
          return;
        }
      }

      setError(ex.message);
    })
    .finally(() => {
      emit("update:saving", false);
    });
}
</script>

<style lang="sass"></style>
