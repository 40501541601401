<template>
  <bubble-menu
    class="bg-white border p-2 space-x-1 text-black"
    :tippy-options="{ animation: false, hideOnClick: true }"
    :editor="editor"
  >
    <q-btn
      v-close-popup
      icon="format_bold"
      class="rounded-md"
      dense
      :outline="editor.isActive('bold')"
      :flat="!editor.isActive('bold')"
      @click="editor.chain().focus().toggleBold().run()"
    />

    <q-btn
      v-close-popup
      icon="format_italic"
      class="rounded-md"
      dense
      :outline="editor.isActive('italic')"
      :flat="!editor.isActive('italic')"
      @click="editor.chain().focus().toggleItalic().run()"
    />

    <q-btn
      v-close-popup
      icon="format_quote"
      class="rounded-md"
      dense
      :outline="editor.isActive('blockquote')"
      :flat="!editor.isActive('blockquote')"
      @click="editor.chain().focus().toggleBlockquote().run()"
    />

    <q-btn
      v-close-popup
      icon="link"
      class="rounded-md"
      dense
      :outline="editor.isActive('link')"
      :flat="!editor.isActive('link')"
      @click="setLink"
    />

    <q-btn
      v-close-popup
      icon="link_off"
      v-if="editor.isActive('link')"
      class="rounded-md"
      dense
      @click="editor.chain().focus().unsetLink().run()"
    />
  </bubble-menu>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { Editor, BubbleMenu } from "@tiptap/vue-3";
import { isNullOrUndefined } from "src/shared/object-utils";

const props = defineProps({
  editor: {
    type: Object as PropType<Editor>,
    required: true,
  },
});

function setLink() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const previousUrl = props.editor.getAttributes("link").href;
  const url = window.prompt("URL", previousUrl);

  // cancelled
  if (url == null) {
    return;
  }

  // empty
  if (url === "") {
    if (!isNullOrUndefined(props.editor)) {
      props.editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }
  }

  // update link
  if (!isNullOrUndefined(props.editor)) {
    props.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }
}
</script>
