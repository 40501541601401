import { isNullOrUndefined } from "src/shared/object-utils";
import { nextTick } from "process";

export function isBodyItemVisible(el: HTMLElement) {
  let headerHeight = 0;
  const header = getSiblingHeader(el);
  if (!isNullOrUndefined(header)) {
    const headerRect = header.getBoundingClientRect();
    headerHeight = headerRect.bottom;
  }

  let footerHeight = 0;
  const footer = getSiblingFooter(el);
  if (!isNullOrUndefined(footer)) {
    const footerRect = footer.getBoundingClientRect();
    footerHeight = window.innerHeight - footerRect.top;
  }

  const item4Rect = el.getBoundingClientRect();
  const visibleAreaTop = headerHeight;
  const visibleAreaBottom = window.innerHeight - footerHeight;

  // Check if the top of the item is below the header and if the bottom of the item is above the footer
  return item4Rect.top >= visibleAreaTop && item4Rect.bottom <= visibleAreaBottom;
}

export function isElementVisibleInContainer(container: HTMLElement, element: HTMLElement) {
  const containerTop = 0;
  const containerBottom = container.clientHeight;

  const elementTop = element.getBoundingClientRect().top - container.getBoundingClientRect().top;
  const elementBottom = elementTop + element.clientHeight;

  return elementTop < containerBottom && elementBottom > containerTop;
}

export function isInViewport(element: HTMLElement, container: HTMLElement) {
  const rect = element.getBoundingClientRect();
  element.getClientRects();

  if (!isNullOrUndefined(container)) {
    const rect2 = container.getBoundingClientRect();

    return (
      rect.top >= rect2.top &&
      // rect.right <= rect2.right &&
      rect.bottom <= rect2.bottom
      // rect.left >= rect2.left
    );
  }

  return false;
}

export function getSiblingHeader(el: HTMLElement) {
  const main = el.closest("main");

  if (main == null) {
    console.warn("Could not find sibling header. Unable to find closest <main>.");
    return null;
  }

  const header = main.previousElementSibling;

  if (header && header.tagName === "HEADER") {
    return header;
  }

  const headerDescendant = main.querySelector("header");

  if (headerDescendant != null) {
    return headerDescendant;
  }

  return null;

  // console.warn("Could not find sibbling header. Make sure there are no elements between <main> and <header>.");
}

export function getSiblingFooter(el: HTMLElement) {
  const main = el.closest("main");

  if (main == null) {
    console.warn("Could not find sibling header. Unable to find closest <main>.");
    return null;
  }

  const footer = main.nextElementSibling;

  if (footer && footer.tagName === "FOOTER") {
    return footer;
  }

  const footerDescendant = main.querySelector("footer");

  if (footerDescendant != null) {
    return footerDescendant;
  }

  return null;

  // console.warn("Could not find sibbling footer. Make sure there are no elements between <main> and <footer>.");
}

export function isDrawerVisible() {
  return isElementBySelectorVisible(".q-drawer");
}

export function getDrawerElement() {
  if (isElementByIdVisible("sa-drawer")) {
    return document.getElementById("sa-drawer");
  }

  return null;
}

export function isDialogVisible() {
  return document.querySelector(".q-dialog") !== null && isElementBySelectorVisible(".q-dialog");
}

export function isElementBySelectorVisible(selector: string) {
  const element = document.querySelector(selector) as HTMLElement;

  if (!element) {
    return false;
  }

  const style = window.getComputedStyle(element);
  return (
    style.display !== "none" && style.visibility !== "hidden" && element.offsetWidth > 0 && element.offsetHeight > 0
  );
}

export function isElementByIdVisible(id: string) {
  const element = document.getElementById(id) as HTMLElement;

  if (!element) {
    return false;
  }

  const style = window.getComputedStyle(element);
  return (
    style.display !== "none" && style.visibility !== "hidden" && element.offsetWidth > 0 && element.offsetHeight > 0
  );
}

export function scrollPageTo(y: number) {
  const target = getPageElement();
  target.scrollTo(0, y);
}

export function getScrollY(pageElement: HTMLElement | Window) {
  if (pageElement instanceof Window) {
    return pageElement.scrollY;
  } else {
    return (pageElement as HTMLElement).scrollTop;
  }
}

export function getPageElement() {
  const drawer = getDrawerElement();

  if (drawer !== null) {
    return drawer;
  }

  const mainContainer = document.getElementsByTagName("main");

  return mainContainer.length ? mainContainer[0] : window;
}

export function scrollToId(id: string) {
  console.log("Scrolling to", id);
  nextTick(() => {
    const el = document.getElementById(id);

    if (!isNullOrUndefined(el)) {
      const rect = el.getBoundingClientRect();

      // Scroll down
      if (rect.top >= 0) {
        el.scrollIntoView({
          block: "end",
          behavior: "auto",
        });

        nextTick(() => {
          const footer = getSiblingFooter(el);
          if (!isNullOrUndefined(footer) && footer.clientHeight > 0) {
            scrollPageBy(footer.clientHeight);
          }
        });
      }

      // Scroll up
      else {
        el.scrollIntoView({
          block: "start",
          behavior: "auto",
        });

        const header = getSiblingHeader(el);
        if (!isNullOrUndefined(header)) {
          scrollPageBy(-header.clientHeight);
        }
      }
    } else {
      console.log("Element with id", id, "not found");
    }
  });
}

export function getDistanceFromBottom(pageElement: HTMLElement | Window) {
  if (pageElement instanceof Window) {
    return document.body.scrollHeight - scrollY;
  } else {
    return pageElement.scrollHeight - scrollY;
  }
}

export function getScrollHeight(pageElement: HTMLElement | Window) {
  if (pageElement instanceof Window) {
    return document.body.scrollHeight;
  } else {
    return pageElement.scrollHeight;
  }
}

// export function isNearBottom(element: HTMLElement | Window): boolean {
//   const scrollHeight = getScrollHeight(element);
//
//   if (element instanceof Window) {
//     const scrollPosition = window.pageYOffset;
//     return scrollPosition + window.innerHeight >= scrollHeight - 10;
//   } else {
//     const { bottom } = element.getBoundingClientRect();
//     return bottom >= scrollHeight - 10;
//   }
// }

export function isNearBottom(pageElement: HTMLElement | Window, delta = 200): boolean {
  let scrollPos: number;
  let totalHeight: number;
  let viewportHeight: number;

  if (pageElement instanceof Window) {
    scrollPos = pageElement.scrollY;
    totalHeight = document.body.scrollHeight;
    viewportHeight = pageElement.innerHeight;
  } else {
    scrollPos = pageElement.scrollTop;
    totalHeight = pageElement.scrollHeight;
    viewportHeight = pageElement.clientHeight;
  }

  return scrollPos + viewportHeight >= totalHeight - delta;
}

export function isPageNearBottom(delta = 200): boolean {
  const pageElement = getPageElement();
  const bottom = isNearBottom(pageElement, delta);
  console.log("Is page near bottom?", bottom);
  return bottom;
}

export function getOffsetHeight(pageElement: HTMLElement | Window) {
  if (pageElement instanceof Window) {
    return document.body.offsetHeight;
  } else {
    return (pageElement as HTMLElement).offsetHeight;
  }
}

export function getInnerHeight(pageElement: HTMLElement | Window) {
  if (pageElement instanceof Window) {
    return pageElement.innerHeight;
  } else {
    return (pageElement as HTMLElement).clientHeight;
  }
}

export function scrollPageBy(y: number) {
  const target = getPageElement();
  target.scrollBy(0, y);
}

export function scrollToBottomInstant() {
  const element = getPageElement();

  if (element instanceof HTMLElement) {
    element.scrollTop = element.scrollHeight;
  } else {
    window.scrollTo(0, document.body.scrollHeight);
  }
}

export function scrollToBottomSmooth(timeout = 0) {
  const element = getPageElement();

  const func = () => {
    if (element instanceof HTMLElement) {
      console.log("Scrolling element to", element.scrollHeight);
      element.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    } else {
      console.log("Scrolling window to", document.body.scrollHeight);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  if (timeout === 0) {
    func();
  } else {
    setTimeout(() => func(), timeout);
  }
}

// export function getScrollbarWidth() {
//   const outer = document.createElement('div');
//   outer.style.visibility = 'hidden';
//   outer.style.overflow = 'scroll';
//   document.body.appendChild(outer);
//
//   const inner = document.createElement('div');
//   outer.appendChild(inner);
//
//   const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
//
//   if (!isNullOrUndefined(outer.parentNode)) {
//     outer.parentNode.removeChild(outer);
//   }
//
//   return scrollbarWidth;
// }

// export function isScrollbarVisible() {
//   const el = document.getElementById('foo')
//   console.log(el, el?.scrollHeight, el?.clientHeight, el)
//   if (!isNullOrUndefined(el)) {
//     return el.scrollHeight > el.clientHeight;
//   }
// }
export class scrollToBottom {}
