import { computed, reactive, readonly } from "vue";
import {
  SelectDirection,
  StoreWithSelectListData,
  StoreWithIdField,
} from "src/shared/models/store-list-with-select-data";
import { DocumentNode } from "graphql/language";
import { subscribe } from "src/graphql/subscription-client";
import { emitter } from "boot/mitt";
import { MittEvents } from "src/shared/mitt-events";

interface StoreListSettings {
  [key: string]: string | number | boolean | null;

  loaded: boolean;
  continuationToken: string | null;
  selectDirection: SelectDirection | null;
  lastScrollY: number;
  keyboardNavigationChange: number;
  selectChange: number;
  lastSelected: number;
  hoverChange: number;
  wrapSelection: boolean;
}

type EventType = keyof MittEvents;
type Callback = () => void;

interface RegisteredMittEvent<T extends EventType> {
  type: T;
  callback: (data: MittEvents[T]) => void;
}

export abstract class Store2<T extends object, TList extends StoreWithSelectListData & StoreWithIdField> {
  protected readonly state: T;
  protected readonly list: TList[];
  protected readonly settings: StoreListSettings;
  private subscriptions: Callback[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private registeredMittEvents: RegisteredMittEvent<any>[];

  constructor() {
    this.state = reactive(this.data()) as T;
    this.list = reactive(this.listData()) as TList[];
    this.settings = reactive(this.settingsData()) as StoreListSettings;
    this.subscriptions = [];
    this.registeredMittEvents = [];
    this.setup();
    // this.load();
  }

  private listData(): TList[] {
    return [];
  }

  private settingsData(): StoreListSettings {
    return {
      loaded: false,
      continuationToken: null,
      selectDirection: null,
      lastScrollY: 0,
      keyboardNavigationChange: 0,
      selectChange: 0,
      hoverChange: 0,
      lastSelected: -1,
      wrapSelection: this.wrapSelection(),
    };
  }

  protected abstract data(): T;

  // protected load() {
  //   //
  // }

  loadWithData(data: Omit<TList, keyof StoreWithSelectListData>[]) {
    void this.clearState();
    this.unsubscribeAll();

    data.forEach(i => {
      this.push(i);
    });

    this.setupEmitter();
    this.setupSubscribe();
    this.loaded();
  }

  protected setup(): void {
    //
  }

  protected setupEmitter(): void {
    //
  }

  protected setupSubscribe(): void {
    //
  }

  protected wrapSelection() {
    return false;
  }

  public getState(): T {
    return readonly(this.state) as T;
  }

  public getList() {
    return readonly(this.list) as Readonly<TList[]>;
  }

  public getSettings() {
    return readonly(this.settings) as StoreListSettings;
  }

  public isLoaded() {
    return computed(() => this.settings.loaded);
  }

  public selectChange() {
    return computed(() => this.settings.selectChange);
  }

  public keyboardNavigationChange() {
    return computed(() => this.settings.keyboardNavigationChange);
  }

  public hasNextPage() {
    return this.settings.continuationToken !== null && this.settings.continuationToken;
  }

  public unmount() {
    this.unsubscribeAll();
    void this.clearState();
  }

  protected async clearState() {
    const stateCopy = { ...this.state };

    const data = this.data();

    for (const key in data) {
      this.state[key] = data[key];
    }

    this.list.splice(0, this.list.length);

    const settings = this.settingsData();

    for (const key in settings) {
      this.settings[key as keyof StoreListSettings] = settings[key];
    }

    // this.unsubscribeAll()

    await this.onClearedState(stateCopy);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async onClearedState(data: T): Promise<void> {
    //
  }

  // protected mittEvents (): void {
  //   //
  // }

  select(uiIndex: number) {
    this.deselectAll();

    const index = this.list.findIndex(s => s.ui.index == uiIndex);

    if (index !== -1) {
      this.list[index].ui.selected = true;
      this.settings.lastSelected = index;
    }

    this.settings.selectChange++;
  }

  selectNext(startBottom?: boolean) {
    if (this.list.length === 0) {
      this.settings.selectDirection = null;
      return;
    }

    this.settings.selectDirection = SelectDirection.Down;

    let selectedIndex = this.settings.lastSelected;

    if (startBottom === true && selectedIndex == -1) {
      selectedIndex = this.list.length - 1;
    } else {
      if (this.settings.wrapSelection) {
        selectedIndex = selectedIndex === -1 ? 0 : (selectedIndex + 1) % this.list.length;
      } else {
        if (selectedIndex !== this.list.length - 1) {
          selectedIndex = selectedIndex === -1 ? 0 : selectedIndex + 1;
        }
      }
    }

    this.deselectAll();

    this.list[selectedIndex].ui.selected = true;
    this.settings.lastSelected = selectedIndex;
    this.settings.keyboardNavigationChange++;
    this.settings.selectChange++;
  }

  selectPrevious(startBottom?: boolean) {
    if (this.list.length === 0) {
      this.settings.selectDirection = null;
      return;
    }

    this.settings.selectDirection = SelectDirection.Up;

    let selectedIndex = this.settings.lastSelected;

    if (startBottom === true && selectedIndex == -1) {
      selectedIndex = this.list.length - 1;
    } else {
      if (this.settings.wrapSelection) {
        selectedIndex = selectedIndex === -1 ? 0 : (selectedIndex - 1 + this.list.length) % this.list.length;
      } else {
        if (selectedIndex !== 0) {
          selectedIndex = selectedIndex === -1 ? 0 : selectedIndex - 1;
        }
      }
    }

    this.deselectAll();

    this.list[selectedIndex].ui.selected = true;
    this.settings.lastSelected = selectedIndex;
    this.settings.keyboardNavigationChange++;
    this.settings.selectChange++;
  }

  toggleSelect(uiIndex: number) {
    const index = this.list.findIndex(s => s.ui.index === uiIndex);

    if (index !== -1) {
      this.list[index].ui.selected = !this.list[index].ui.selected;
      this.settings.lastSelected = index;
      this.settings.selectChange++;
    }
  }

  selectedCount() {
    return this.list.filter(i => i.ui.selected).length;
  }

  hover(index: number) {
    this.unhoverAll();

    this.list
      .filter(i => i.ui.index === index)
      .forEach(i => {
        i.ui.hover = true;
      });

    this.settings.hoverChange++;
  }

  unhover(index: number) {
    this.list
      .filter(i => i.ui.index === index)
      .forEach(i => {
        i.ui.hover = false;
      });

    this.settings.hoverChange++;
  }

  unhoverAll() {
    this.list
      .filter(i => i.ui.hover)
      .forEach(i => {
        i.ui.hover = false;
      });

    this.settings.hoverChange++;
  }

  isHover() {
    return this.list.filter(i => i.ui.hover).length >= 1;
  }

  getHoveredItem() {
    const item = this.list.find(s => s.ui.hover);

    if (item === undefined) {
      throw new Error("Nothing hovered");
    }

    return item;
  }

  // mark (index: number) {
  //   this.list.filter(i => i.ui.index === index).forEach(i => {
  //     i.ui.marked = true
  //   })
  // }

  setContinuationToken(token: string | null | undefined) {
    this.settings.continuationToken = token ?? null;
  }

  getContinuationToken() {
    return this.settings.continuationToken;
  }

  selectFirst() {
    if (this.list.length === 0) {
      return;
    }

    this.deselectAll();
    this.list[0].ui.selected = true;
    this.settings.lastSelected = 0;
  }

  selectLast() {
    if (this.list.length === 0) {
      return;
    }

    this.deselectAll();
    const index = this.list.length - 1;
    this.list[index].ui.selected = true;
    this.settings.lastSelected = index;
  }

  getSelectDirection() {
    if (this.settings.selectDirection == null) {
      return SelectDirection.Down;
    }

    return this.settings.selectDirection;
  }

  getSelected() {
    // if (this.state.selectedIndex === null) {
    //   return null;
    // }
    //
    // return this.state.selectedIndex;
    const item = this.list.find(s => s.ui.selected);

    if (item === undefined) {
      return null;
    }

    return item.ui.index;
  }

  getSelectedItems() {
    return this.list.filter(s => s.ui.selected);
  }

  getSelectedItem() {
    // if (this.state.selectedIndex === null) {
    //   return null;
    // }
    //
    // if (this.state.list.length-1 > this.state.selectedIndex) {
    //   return null;
    // }
    //
    // return this.state.list[this.state.selectedIndex]
    const item = this.list.find(s => s.ui.selected);

    if (item === undefined) {
      throw new Error("Nothing selected");
    }

    return item;
  }

  deselectAll() {
    // this.state.selectedIndex = null;
    this.list
      .filter(i => i.ui.selected)
      .forEach(i => {
        i.ui.selected = false;
      });
  }

  getSelectedIndex() {
    // return this.state.selectedIndex;
    return this.list.findIndex(s => s.ui.selected);
  }

  lastIndexSelected() {
    // return this.state.selectedIndex === this.state.list.length - 1;
    return this.list.findIndex(s => s.ui.selected) === this.list.length - 1;
  }

  saveScrollY(scrollY: number) {
    this.settings.lastScrollY = scrollY;
  }

  getLastScrollY() {
    return this.settings.lastScrollY;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected push(data: Omit<TList, keyof StoreWithSelectListData>) {
    const extra: StoreWithSelectListData = {
      ui: {
        index: this.nextUiIndex(),
        selected: false,
        hover: false,
        key: 1,
      },
    };

    const items = { ...data, ...extra } as TList;
    this.list.push(items);
    return items;
  }

  protected unshift(data: Omit<TList, keyof StoreWithSelectListData>) {
    const extra: StoreWithSelectListData = {
      ui: {
        index: this.nextUiIndex(),
        selected: false,
        hover: false,
        key: 1,
      },
    };

    const items = { ...data, ...extra } as TList;
    this.list.unshift(items);
    return items;
  }

  protected replaceById(id: string, data: Omit<TList, keyof StoreWithSelectListData>) {
    const objIndex = this.list.findIndex(i => i.id == id);

    if (objIndex === -1) {
      return;
    }

    const ui = this.list[objIndex].ui;
    const item = { ...data, ui } as TList;
    this.list[objIndex] = item;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected insert(index: number, data: any) {
    const extra = {
      ui: {
        index: this.nextUiIndex(),
        selected: false,
        hover: false,
      },
    } as StoreWithSelectListData;

    this.list.splice(index, 0, { ...data, ...extra });
  }

  protected remove(index: number) {
    this.list.splice(index, 1);
  }

  protected removeByFilter(predicate: (value: TList, index: number, obj: TList[]) => boolean) {
    const index = this.list.findIndex(predicate);
    // console.log('index', index)

    if (index !== -1) {
      // console.log('removing', index)
      // const newList = [...this.list]
      // newList.splice(index, 1)
      // console.log(newList)
      this.list.splice(index, 1);
    }
  }

  protected loaded() {
    this.settings.loaded = true;
  }

  protected nextUiIndex() {
    if (this.list.length === 0) {
      return 1;
    }

    const highestUi = this.list.reduce((prev, current) => {
      return prev.ui.index > current.ui.index ? prev : current;
    });

    return highestUi.ui.index + 1;
  }

  protected addSubscription<T, TVar extends Record<string, unknown> | undefined>(
    query: DocumentNode,
    variables: TVar,
    callback: (data: T) => void,
    onReconnected?: () => void,
  ) {
    console.log("Adding subscription");

    const unsubscribe = subscribe<T, TVar>(query, variables, callback, onReconnected);

    this.subscriptions.push(unsubscribe);
  }

  protected unsubscribeAll() {
    console.log(
      "Removing",
      this.subscriptions.length,
      "subscriptions and",
      this.registeredMittEvents.length,
      "mitt events",
    );

    this.subscriptions.forEach(callback => {
      console.log("Removing subscription", callback);
      callback();
    });

    this.subscriptions = [];

    this.registeredMittEvents.forEach(i => {
      emitter.off(i.type, i.callback);
    });

    this.registeredMittEvents = [];
  }

  protected addMittEvent<T extends keyof MittEvents>(type: T, callback: (data: MittEvents[T]) => void) {
    emitter.on(type, callback);
    this.registeredMittEvents.push({
      type: type,
      callback: callback,
    });
  }

  noop() {
    //
  }
}
