<template>
  <div
    :class="{
      'w-full': expectedBotProcessing.length === 2 || botSuggestions?.length === 2,
      'w-2/3': expectedBotProcessing.length === 1 || expectedBotProcessing.length === 0,
    }"
  >
    <div v-if="showName" class="sa-conversation-timeline-contact-message__name mb-1 text-sm">
      {{ contactName }}
    </div>

    <div class="sa-conversation-timeline-contact-message__block rounded-lg w-full">
      <div
        class="px-5 py-3 flex justify-between items-center"
        :class="{
          'ring-2 ring-offset-2': selected,
        }"
      >
        <div class="sa-conversation-timeline-contact-message__block-content leading-6 text-sm pr-4 space-y-1">
          <div v-html="contactMessage.content"></div>

          <div v-if="contactMessage.contentIsTruncated" class="sa-conversation-timeline-contact-message__block-more">
            {{ $t("conversation.display.textIsTruncated") }}
          </div>
        </div>

        <div
          class="sa-conversation-timeline-contact-message__block-timestamp ml-auto text-sm place-self-end flex flex-row items-center"
        >
          <q-btn
            v-if="hideBotSuggestion"
            :icon="showBotSuggestion ? 'fa-light fa-caret-up' : 'fa-light fa-caret-down'"
            dense
            class="mr-4 rounded-lg"
            @click="showBotSuggestion = !showBotSuggestion"
          >
            <s-tooltip>
              <template v-if="showBotSuggestion">{{ $t("conversation.assistant.hideAssistantDetails") }}</template>
              <template v-else>{{ $t("conversation.assistant.showAssistantDetails") }}</template>
            </s-tooltip>
          </q-btn>
          <updated-ago :updated="timestamp" />
        </div>
      </div>

      <div v-if="showBotSuggestion" class="flex flex-row bg-white rounded-b-lg">
        <div
          v-for="(botSuggestion, index) in suggestions"
          :key="`bot-suggestion-${index}`"
          :class="{
            'w-1/2 first:border-r-2 border-r-gray-100': suggestions.length === 2,
          }"
        >
          <div v-if="!isNullOrUndefined(botSuggestion.suggestion)" class="rounded-b-lg px-1">
            <div v-if="showAnalysis" class="px-6 py-2">
              <div
                v-for="(actionableTask, atIndex) in botSuggestion.suggestion.actionableTasks"
                :key="`task-${atIndex}`"
              >
                <div class="flex flex-row items-center">
                  <div>{{ actionableTask.task }}</div>
                  <q-chip dense square>
                    {{ actionableTask.specificity }}
                    <s-tooltip>Query specificity</s-tooltip>
                  </q-chip>

                  <q-chip dense square>
                    {{ actionableTask.answerResult }}
                    <s-tooltip>Answer category</s-tooltip>
                  </q-chip>
                </div>

                <div class="ml-4 space-y-1.5">
                  <div>
                    <div>Search queries:</div>
                    <ul>
                      <li
                        v-for="(query, qIndex) in actionableTask.queries"
                        :key="`query-${atIndex}-${qIndex}`"
                        class="list-disc ml-6"
                      >
                        {{ query }}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div>Knowledge base articles:</div>
                    <ul>
                      <li
                        v-for="(kbMatch, kbIndex) in actionableTask.kbMatches"
                        :key="`kb-match-${atIndex}-${kbIndex}`"
                        class="list-disc ml-6"
                      >
                        <div class="flex flex-row items-center">
                          <div>
                            {{ kbMatch.knowledgeBaseArticle.title }}
                            <q-chip dense square>
                              {{ kbMatch.vectorSearchScore }}
                              <s-tooltip>Vector search score</s-tooltip>
                            </q-chip>

                            <q-chip dense square>
                              {{ kbMatch.aiRelevanceCheck }}
                              <s-tooltip>Assistant ruled KB relevant?</s-tooltip>
                            </q-chip>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="botSuggestion.suggestion.botResponseResult === GBotResponseResultItem.Error" class="p-3">
              <div class="flex flex-row space-x-2">
                <q-icon
                  :name="findAiIcon(botSuggestion.aiModel)"
                  size="24px"
                  class="cursor-pointer"
                  @click="showAnalysis = !showAnalysis"
                />

                <div class="leading-6 text-sm text-gray-700">{{ $t("conversation.assistant.error") }}</div>
              </div>

              <div class="mt-2" v-html="botSuggestion.suggestion.content" />
            </div>

            <div
              v-else-if="botSuggestion.suggestion.botResponseResult === GBotResponseResultItem.AnswerNotFound"
              class="p-1 lg:p-3 rounded-b-lg text-gray-700"
            >
              <div class="flex flex-row items-center space-x-3">
                <div>
                  <q-icon
                    :name="findAiIcon(botSuggestion.aiModel)"
                    size="24px"
                    class="cursor-pointer"
                    @click="showAnalysis = !showAnalysis"
                  />
                </div>

                <div class="flex-1">{{ $t("conversation.assistant.noSuitableResponseCouldBeFound") }}</div>
              </div>
            </div>

            <div v-else class="p-1 lg:p-3 rounded-b-lg text-gray-700">
              <div class="flex flex-row items-center space-x-2">
                <div>
                  <q-icon
                    :name="findAiIcon(botSuggestion.aiModel)"
                    size="24px"
                    class="cursor-pointer"
                    @click="showAnalysis = !showAnalysis"
                  />
                </div>

                <div class="flex-1">
                  <div class="leading-6 text-sm text-gray-700">
                    {{ $t("conversation.assistant.suggestedReply") }}
                  </div>
                </div>
              </div>

              <div
                class="w-full flex flex-row items-center mt-2"
                :class="{
                  'rounded-lg ring-2 ring-offset-2': selected,
                }"
              >
                <div
                  class="leading-5 text-xs muted pr-4 max-w-full sa-conversation-timeline-bot-suggestion__block-content"
                  v-html="botSuggestion?.suggestion.content"
                />
              </div>
              <div
                v-if="!suggestionAlreadyUsed"
                class="w-full flex flex-col lg:flex-row items-start lg:justify-end mt-3 lg:space-x-2"
              >
                <q-btn
                  size="md"
                  :label="$t('common.edit')"
                  icon="fa-solid fa-user-pen"
                  @click="onEditClick(botSuggestion.suggestion)"
                />

                <q-btn
                  size="md"
                  :label="$t('conversation.assistant.sendSuggestionDirectly')"
                  icon="fa-solid fa-paper-plane-top"
                  @click="onSendDirectlyClick(botSuggestion.suggestion)"
                  :loading="isSending"
                />
              </div>
            </div>
          </div>
          <div v-else class="p-1 lg:p-3 rounded-b-lg text-gray-700 flex flex-row space-x-2 items-center">
            <q-icon
              :name="findAiIcon(botSuggestion.aiModel)"
              size="24px"
              class="cursor-pointer"
              @click="showAnalysis = !showAnalysis"
            />
            <div>{{ $t("conversation.assistant.assistantIsRunning") }}</div>
            <q-spinner-ios />
          </div>
        </div>
      </div>
    </div>

    <conversation-attachment-grid :attachments="contactMessage.attachments" />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import {
  GAiModelItem,
  GBotResponseResultItem,
  GConversationBotSuggestion,
  GConversationContactMessage,
  GInputContactAddressTypeItem,
} from "src/generated/graphql";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import ConversationAttachmentGrid from "src/features/conversation/conversation-display/ConversationAttachmentGrid.vue";
import STooltip from "components/STooltip.vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { emitter } from "boot/mitt";
import { createConversationAgentMessageCommand } from "src/features/conversation/shared-api-commands";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { TrackedEvent, useTracking } from "src/composables/useTracking";

const showAddressForContactAddressTypes: Array<GInputContactAddressTypeItem> = [
  GInputContactAddressTypeItem.EmailAddress,
  GInputContactAddressTypeItem.PhoneNumber,
];

const props = defineProps({
  timestamp: {
    type: String,
    required: true,
  },

  conversationId: {
    type: String,
    required: true,
  },

  timelineId: {
    type: String,
    required: true,
  },

  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },

  showName: {
    type: Boolean,
    required: true,
    default: () => true,
  },

  contactMessage: {
    type: Object as PropType<GConversationContactMessage>,
    required: true,
  },

  botSuggestions: {
    type: Array as PropType<GConversationBotSuggestion[]>,
    required: false,
  },

  hideBotSuggestion: {
    type: Boolean,
    required: true,
  },

  suggestionAlreadyUsed: {
    type: Boolean,
    required: true,
  },

  expectedBotProcessing: {
    type: Array as PropType<GAiModelItem[]>,
    required: true,
    default: () => [],
  },
});

const isSending = ref(false);
const showAnalysis = ref(false);

const { trackEvent } = useTracking();

const showBotSuggestion = ref(true);

if (props.hideBotSuggestion) {
  showBotSuggestion.value = false;
}

interface Suggestion {
  aiModel: GAiModelItem;
  suggestion: GConversationBotSuggestion | undefined;
}

const suggestions = computed(() => {
  const list: Suggestion[] = [];
  if (props.expectedBotProcessing?.length > 0) {
    props.expectedBotProcessing?.forEach(e => {
      const botSuggestion = props.botSuggestions?.find(i => i.aiModel === e);
      list.push({
        aiModel: e,
        suggestion: botSuggestion,
      });
    });
    return list;
  }

  // A workaround for conversations created before "AiModelsProcessing" was populated in conversation contact message
  if (!isNullOrUndefined(props.botSuggestions) && props.botSuggestions?.length > 0) {
    props.botSuggestions?.forEach(i => {
      list.push({
        aiModel: i.aiModel,
        suggestion: i,
      });
    });
    return list;
  }

  return list;
});

const contactName = computed(() => {
  if (!props.showName) {
    return null;
  }

  if (showAddressForContactAddressTypes.includes(props.contactMessage.contactAddress.addressType)) {
    return props.contactMessage.contactAddress.address;
  }

  return resolveDisplayName(props.contactMessage.contact.firstName, props.contactMessage.contact.lastName);
});

async function onSendDirectlyClick(botSuggestion: GConversationBotSuggestion) {
  isSending.value = true;
  await createConversationAgentMessageCommand(props.conversationId, botSuggestion.content ?? "", props.timelineId, [])
    .then(async () => {
      trackEvent(TrackedEvent.UsedBotReplySentDirectly, {
        aiModel: botSuggestion.aiModel,
      });
    })
    .catch(err => {
      console.error(err);
    })
    .finally(() => {
      isSending.value = false;
    });
}

function onEditClick(botSuggestion: GConversationBotSuggestion) {
  emitter.emit("bot-suggestion-agent-edit", {
    conversationId: props.conversationId,
    content: botSuggestion.content ?? "",
    linkedToTimelineId: props.timelineId ?? null,
  });
  trackEvent(TrackedEvent.UsedBotReplyWithEdit, {
    aiModel: botSuggestion.aiModel,
  });
}

const findAiIcon = (aiModel: GAiModelItem) => {
  const claudeModels: GAiModelItem[] = [
    GAiModelItem.Claude2,
    GAiModelItem.ClaudeInstant12,
    GAiModelItem.Claude3Opus,
    GAiModelItem.Claude3Sonnet,
    GAiModelItem.Claude3Haiku,
  ];

  const openaiModels = [GAiModelItem.Gpt35, GAiModelItem.Gpt4, GAiModelItem.Gpt4Turbo];

  if (openaiModels.includes(aiModel)) {
    return "img:/chatgpt-icon.svg";
  } else if (claudeModels.includes(aiModel)) {
    return "img:/claude-ai-icon.svg";
  }
};
</script>
