import { QMenu } from "quasar";
import { ref } from "vue";

export function useBlockMenu() {
  const menu = ref<QMenu>();

  function onBlockClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (window.getSelection()?.toString() === "") {
      menu.value?.toggle();
    }
  }

  function showBlockMenu(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    menu.value?.toggle();
  }

  return { menu, onBlockClick, showBlockMenu };
}
