import { useGraphqlSdk } from "src/graphql/graphql-client";
import { tagListStore } from "stores/tag-list-store";
import { agentWithStatusStore } from "src/features/agent/agent-avatar-with-status/agent-with-status-global-store";
import { connectedServiceStore } from "stores/connected-service-global-store";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import { tenantInfoStore } from "stores/tenant-info-global-store";
import { conversationSnippetStore } from "stores/conversation-snippets-global-store";
import {
  ConversationAssignItem,
  conversationAssignStore,
} from "src/features/conversation/conversation-assign/conversation-assign-global-store";
import { TeamOrAgentType } from "src/shared/models/models";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { activeConversationCountStore } from "src/features/conversation/active-conversation-count/active-conversation-count-global-store";
import { unseenAgentNotificationCountStore } from "src/features/agent-notification/unseen-agent-notification-count/unseen-agent-notification-count-global-store";
import { teamListGlobalStore } from "stores/team-list-global-store";
import { GAgentStatusItem } from "src/generated/graphql";
import { Composer } from "vue-i18n";
import { ContextHolder } from "@frontegg/vue";

export async function initializeApp($t: Composer["t"]) {
  let jwt = ContextHolder.getAccessToken();

  // Sometimes there is a race condition where jwt is not yet available.
  if (!jwt) {
    let i = 0;
    do {
      console.log("jwt unavailable. Waiting 500ms and retrying...");
      await new Promise(resolve => setTimeout(resolve, 500));
      jwt = ContextHolder.getAccessToken();
    } while (!jwt && ++i < 5);
  }

  const sdk = useGraphqlSdk();

  const res = await sdk.AppInitQuery();

  tagListStore.loadWithData(res.tags ?? []);
  agentWithStatusStore.loadWithData(res.agents);
  connectedServiceStore.loadWithData(res.channels ?? []);
  agentSettingsStore.loadWithData({
    ...res.me,
  });
  teamListGlobalStore.loadWithData(res.teams);

  const activeConversationCount = res.conversationCount.count.reduce((acc: { [key: string]: number }, i) => {
    acc[i.key] = i.value;
    return acc;
  }, {});

  activeConversationCountStore.loadWithData(activeConversationCount);

  unseenAgentNotificationCountStore.loadWithData({ count: res.unseenAgentNotificationCount.count });

  conversationSnippetStore.loadWithData({
    snippets: res.conversationSnippets.items,
  });

  if (!res.tenant) {
    throw new Error("Failed to get tenant info");
  }

  tenantInfoStore.loadWithData(res.tenant);

  if (res.tenant.partner?.whiteLabelProductName) {
    document.title = res.tenant.partner.whiteLabelProductName;
  }

  const conversationAssignData: ConversationAssignItem[] = [
    {
      id: "",
      label: $t("conversations.assignDialog.notAssigned"),
      type: TeamOrAgentType.None,
    },
  ];

  res.teams.forEach(i => {
    conversationAssignData.push({
      id: i.id,
      label: i.name,
      type: TeamOrAgentType.Team,
    } as ConversationAssignItem);
  });

  res.agents.forEach(i => {
    if (i.perTenant.status === GAgentStatusItem.Active) {
      conversationAssignData.push({
        id: i.id,
        label: resolveDisplayName(i.firstName, i.lastName),
        type: TeamOrAgentType.Agent,
      });
    }
  });

  conversationAssignStore.loadWithData(conversationAssignData);

  // Set locale
  // const localeItemToLocale: Record<GLocaleItem, (typeof SUPPORTED_LOCALES)[number]> = {
  //   [GLocaleItem.English]: "en",
  //   [GLocaleItem.Norwegian]: "nb",
  // };
}
