<template>
  <div
    class="sa-conversation-attachment"
    :class="{
      'sa-conversation-attachment--dense': dense,
    }"
    @mouseover="hoverAttachment = true"
    @mouseleave="hoverAttachment = false"
  >
    <template v-if="attachmentTypeStore.isImage(contentType)">
      <q-intersection v-if="!hoverAttachment">
        <q-img
          :src="previewUrl"
          class="rounded-lg"
          :style="`height: ${imgHeight}px; width: ${imgWidth}px`"
          no-spinner
          no-transition
        />
      </q-intersection>

      <div v-if="hoverAttachment" class="flex h-full w-full items-center justify-center" @click="onPreviewClick">
        <template v-if="dense">
          <div class="space-y-1 w-full">
            <div class="flex justify-center">
              <q-btn icon="get_app" @click="onDownloadClick" dense />
              <q-btn v-if="showDelete" icon="delete" @click="onDeleteClick" dense />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="space-y-1 w-full">
            <div class="flex justify-center">
              <q-btn icon="get_app" @click="onDownloadClick" />
              <q-btn v-if="showDelete" icon="delete" @click="onDeleteClick" />
            </div>

            <div class="flex justify-center px-1">
              <div class="ellipsis text-xs">
                {{ filename }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <template v-if="dense">
        <div class="flex flex-row h-full items-center px-2" @click="onPreviewClick">
          <template v-if="hoverAttachment">
            <div class="space-y-1 w-full">
              <div class="flex justify-center">
                <q-btn icon="get_app" @click="onDownloadClick" dense />
                <q-btn v-if="showDelete" icon="delete" @click="onDeleteClick" dense />
              </div>
            </div>
          </template>

          <template v-else>
            <div class="ellipsis text-xs">
              <q-icon :name="icon" />
              {{ filename }}
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="flex h-full w-full items-center justify-center" @click="onPreviewClick">
          <div class="space-y-1 w-full">
            <div class="flex justify-center">
              <template v-if="hoverAttachment">
                <q-btn icon="get_app" @click="onDownloadClick" />
                <q-btn v-if="showDelete" icon="delete" @click="onDeleteClick" />
              </template>
              <q-btn :icon="icon" v-else />
            </div>

            <div class="flex justify-center px-1">
              <div class="ellipsis text-xs">
                {{ filename }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { attachmentTypeStore } from "stores/attachment-type-global-store";
import { useQuasar } from "quasar";
import AttachmentPreviewDialog from "src/features/attachment/attachment-preview/AttachmentPreviewDialog.vue";

const props = defineProps({
  filename: {
    type: String,
    required: true,
  },

  contentType: {
    type: String,
    required: true,
  },

  previewUrl: {
    type: String,
    required: true,
  },

  downloadUrl: {
    type: String,
    required: true,
  },

  showDelete: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  dense: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const emit = defineEmits(["delete"]);

const $q = useQuasar();
const hoverAttachment = ref(false);
const icon = attachmentTypeStore.icon(props.contentType);

// const color = attachmentTypeStore.color(props.attachment.contentType);

const imgHeight = computed(() => {
  return props.dense ? 40 : 70;
});

const imgWidth = computed(() => {
  return props.dense ? 161 : 161;
});

function onPreviewClick() {
  $q.dialog({
    component: AttachmentPreviewDialog,
    componentProps: {
      filename: props.filename,
      contentType: props.contentType,
      previewUrl: props.previewUrl,
      downloadUrl: props.downloadUrl,
    },
  });
}

function onDownloadClick(e: Event) {
  e.stopPropagation();
  e.preventDefault();
  window.location.href = props.downloadUrl;
}

function onDeleteClick(e: Event) {
  e.stopPropagation();
  e.preventDefault();
  emit("delete");
}
</script>

<style lang="sass"></style>
