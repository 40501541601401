import { GraphQLClient } from "graphql-request";
import { SdkFunctionWrapper, getSdk, GUserErrorFragment } from "src/generated/graphql";
import { ContextHolder } from "@frontegg/vue";
import polly from "polly-js";
import { isNullOrUndefined } from "src/shared/object-utils";

export class GraphQlUserError extends Error {
  constructor(public error: GUserErrorFragment) {
    super(error.message);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function findErrors(obj: any): GUserErrorFragment[] {
  const errors: GUserErrorFragment[] = [];

  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (
        obj[key] &&
        "errors" in obj[key] &&
        !isNullOrUndefined(obj[key]["errors"]) &&
        Array.isArray(obj[key]["errors"])
      ) {
        errors.push(...obj[key]["errors"]);
      }
    }
  }

  return errors;
}

export function useGraphqlSdk() {
  if (!process.env.API) {
    throw new Error("Missing API in env");
  }

  const client = new GraphQLClient(process.env.API, {
    headers: () => {
      const jwt = ContextHolder.getAccessToken();
      // console.log("access token", jwt);
      // console.log(ContextHolder.getUser());

      if (!jwt) {
        throw new Error("Cannot use sdk without a jwt");
      }

      return { Authorization: `Bearer ${jwt}` };
    },
  });

  const errorsToRetry = ["Network request failed", "connect ETIMEDOUT"];

  const withRetries: SdkFunctionWrapper = <T>(action: () => Promise<T>) =>
    polly()
      .handle((err: Error) => {
        console.warn("GraphqlClient:NetworkError", err);
        return errorsToRetry.includes(err.message);
      })
      .waitAndRetry(3)
      .executeForPromise(async info => {
        if (info.count === 3) {
          console.error("GraphqlClient:MaxRetries", null, {
            ...info,
            action: action.toString(),
          });
        } else if (info.count > 0) {
          console.warn("GraphqlClient:RetryingCall", null, {
            ...info,
            action: action.toString(),
          });
        }

        const response = await action();
        const errors = findErrors(response);

        if (errors.length > 0) {
          throw new GraphQlUserError(errors[0]);
        }

        return response;
      });

  return getSdk(client, withRetries);
}
