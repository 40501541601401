import { onMounted, onBeforeUnmount } from "vue";

export function useTimeout(callback: () => void, intervalInSeconds: number) {
  let timeoutId: number;

  onMounted(() => {
    timeoutId = window.setTimeout(callback, intervalInSeconds * 1000);
  });

  onBeforeUnmount(() => {
    clearTimeout(timeoutId);
  });
}
