<template>
  <div v-if="uploadProgress.length" class="w-full h-full p-2">
    <div class="space-y-1">
      <template v-for="upload in uploadProgress" :key="upload.id">
        <template v-if="upload.progress !== 1">
          <q-linear-progress size="20px" :value="upload.progress" rounded color="orange-7">
            <div class="absolute-full flex flex-center text-xs text-white">
              {{ upload.fileName }}
            </div>
          </q-linear-progress>
        </template>
      </template>
    </div>
  </div>

  <div v-if="show" class="absolute top-0 bottom-0 left-0 right-0 p-1">
    <div
      class="w-full h-full bg-white border-2 border-dashed border-gray-700 rounded-lg text-black text-lg flex items-center justify-center opacity-40"
      @drop="handleFileDrop"
      @dragover.prevent
    >
      Drop your files here
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { useEmitterOn } from "src/composables/useEmitterOn";
import { ContextHolder } from "@frontegg/vue";
import axios from "axios";
import { useQuasar } from "quasar";
import { randomString } from "src/shared/random";

const $q = useQuasar();
const show = ref(false);
const dragCounter = ref(0);

interface UploadProgress {
  id: string;
  fileName: string;
  progress: number;
}

export interface UploadedFile {
  filename: string;
  size: number;
  previewUrl: string;
  downloadUrl: string;
  contentType: string;
}

const emit = defineEmits(["upload"]);

const uploadProgress = ref<UploadProgress[]>([]);

if (isNullOrUndefined(process.env.REST_API)) {
  throw new Error("REST_API is not defined in config");
}

const uploadUrl = `${process.env.REST_API}/upload`;

useEmitterOn("drop-files", async (files: File[]) => {
  console.log("Files dropped", files);

  for (const file of files) {
    void upload(file);
  }
});

async function upload(file: File) {
  const id = randomString(16);
  console.log("Uploading", file.name, "to", uploadUrl, "using id", id);
  const formData = new FormData();
  formData.append("file", file);

  const jwt = ContextHolder.getAccessToken();

  uploadProgress.value.push({
    id: id,
    fileName: file.name,
    progress: 0,
  });

  try {
    const response = await axios.post(uploadUrl as string, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwt}`,
      },
      onUploadProgress: progressEvent => {
        uploadProgress.value.forEach(i => {
          console.log("Upload progress", progressEvent);
          if (i.id === id) {
            if (!isNullOrUndefined(progressEvent.progress)) {
              i.progress = progressEvent.progress;
            }
          }
        });
      },
    });

    let attachment = {
      filename: response.data.filename,
      size: response.data.size,
      contentType: response.data.contentType,
      previewUrl: response.data.previewUrl,
      downloadUrl: response.data.downloadUrl,
    } as UploadedFile;

    emit("upload", attachment);
  } catch (error) {
    console.log(error);

    $q.notify({
      progress: true,
      message: "Error uploading file. Make sure the file is below 30 MB, and that you are connected to the internet.",
    });
  }

  const index = uploadProgress.value.findIndex(i => i.id === id);
  if (index !== -1) {
    uploadProgress.value.splice(index, 1);
  }
}

onMounted(() => {
  document.body.addEventListener("dragenter", handleDragEnter);
  document.body.addEventListener("dragleave", handleDragLeave);
  document.body.addEventListener("dragend", handleDragEnd);
  // window.addEventListener("dragover", preventDrop, false);
  window.addEventListener("dragover", preventDrop, false);
  window.addEventListener("drop", onDrop, false);
  // document.body.addEventListener("drop", handleFileDrop);
});

onBeforeUnmount(() => {
  document.body.removeEventListener("dragenter", handleDragEnter);
  document.body.removeEventListener("dragleave", handleDragLeave);
  document.body.removeEventListener("dragend", handleDragEnd);
  window.removeEventListener("dragover", preventDrop);
  window.removeEventListener("drop", onDrop);
  // document.body.removeEventListener("drop", handleFileDrop);
});

function preventDrop(event: Event) {
  event.preventDefault();
}

function onDrop(event: Event) {
  event.preventDefault();
  show.value = false;
  dragCounter.value = 0;
}

function handleDragEnter(event: DragEvent) {
  event.preventDefault();
  dragCounter.value++;
  show.value = true;
}

function handleDragLeave(event: DragEvent) {
  event.preventDefault();
  dragCounter.value--;

  if (dragCounter.value === 0) {
    console.log("Drag action ended/aborted");
    show.value = false;
  }
}

function handleDragEnd(event: DragEvent) {
  event.preventDefault();
  console.log("Drag action ended");
  dragCounter.value = 0;
  show.value = false;
}

async function handleFileDrop(event: DragEvent) {
  event.preventDefault();
  show.value = false;

  if (isNullOrUndefined(event) || isNullOrUndefined(event.dataTransfer)) {
    return;
  }

  if (event.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      if (event.dataTransfer.items[i].kind === "file") {
        const file = event.dataTransfer.items[i].getAsFile();
        if (!isNullOrUndefined(file)) {
          void upload(file);
        }
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      const file = event.dataTransfer.files[i];
      if (!isNullOrUndefined(file)) {
        void upload(file);
      }
    }
  }
}
</script>

<style lang="sass"></style>
