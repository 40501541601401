<template>
  <q-dialog ref="dialogRef" transition-duration="0" position="top" @hide="onDialogHide">
    <div
      class="mx-auto w-full mt-24 max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
    >
      <FocusTrap>
        <Combobox @update:modelValue="onSelect">
          <div class="relative border-b-gray-100 border flex flex-nowrap">
            <q-icon
              size="sm"
              name="fa-light fa-search"
              class="pointer-events-none absolute left-4 top-3 text-gray-400"
            />
            <ComboboxInput
              class="h-12 w-full border-0 outline-none bg-transparent pl-14 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              :placeholder="$t('commandPalette.commandInputPlaceholder')"
              @change="search = $event.target.value"
            />
          </div>

          <ComboboxOptions
            static
            class="max-h-96 transform-gpu scroll-py-4 overflow-y-auto p-3 option-container space-y-1"
          >
            <ComboboxOption
              v-for="shortcut in shortcuts"
              :key="`palette--${shortcut.ui.index}`"
              :value="shortcut.ui.index"
              as="template"
              v-slot="{ active }"
            >
              <li
                :class="[
                  'flex flex-row flex-nowrap justify-between content-center items-center cursor-pointer select-none rounded-xl p-3',
                  active && 'bg-gray-100',
                ]"
              >
                <span
                  :class="[
                    'text-sm font-medium overflow-hidden overflow-ellipsis whitespace-nowrap',
                    active ? 'text-gray-900' : 'text-gray-700',
                  ]"
                  v-html="shortcut.description"
                ></span>
                <div>
                  <KeyboardShortcutDisplay v-if="shortcut.key" :keys="shortcut.key" />
                  <!-- <q-chip :class="!shortcut.key && 'invisible'" :label="shortcut.key" square dense /> -->
                </div>
              </li>
            </ComboboxOption>
          </ComboboxOptions>

          <div v-if="search !== '' && !shortcuts.length" class="px-6 py-14 text-center text-sm sm:px-14">
            <!-- <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" /> -->
            <q-icon name="fa-light fa-exclamation-circle" size="md" class="mx-auto text-gray-400" />
            <p class="mt-4 font-semibold text-gray-900">{{ $t("search.noResultsHeader") }}</p>
            <p class="mt-2 text-gray-500">{{ $t("search.noResultsDescription") }}</p>
          </div>
        </Combobox>
      </FocusTrap>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { globalCommandPaletteStore } from "src/features/palette-global-command/global-command-palette-global-store";
import { computed, ref } from "vue";
import { emitter } from "boot/mitt";
import { useShortcut } from "src/composables/useShortcut";
import { isNullOrUndefined } from "src/shared/object-utils";
import { useDialogPluginComponent } from "quasar";
import { Combobox, ComboboxInput, ComboboxOptions, FocusTrap, ComboboxOption } from "@headlessui/vue";
import KeyboardShortcutDisplay from "src/components/KeyboardShortcutDisplay.vue";
import { useThrottleFn } from "@vueuse/core";

const { dialogRef, onDialogCancel, onDialogHide } = useDialogPluginComponent();
defineEmits([...useDialogPluginComponent.emits]);

const search = ref("");

const shortcuts = computed(() => {
  if (search.value.trim() != "") {
    return globalCommandPaletteStore
      .getList()
      .filter(i => i.description && i.description.toLowerCase().indexOf(search.value.toLowerCase()) !== -1);
  } else {
    return globalCommandPaletteStore.getList();
  }
});

// Throttling the function because `enter` seems to fire the callback twice (for unknown reasons)
const onSelect = useThrottleFn((uiIndex: number) => {
  const item = shortcuts.value.find(i => i.ui.index === uiIndex);

  if (item) {
    if (!isNullOrUndefined(item.shortcut)) {
      emitter.emit("trigger-shortcut", {
        shortcut: item.shortcut,
      });
    } else {
      console.error("No shortcut for", item.description);
    }

    onDialogCancel();
  }
}, 250);

useShortcut("global-command-palette", [], { suspendShortcuts: true });
</script>

<style lang="sass"></style>
