import { Store } from "./store";

export enum AttachmentType {
  Media,
  Image,
  Text,
  Pdf,
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface AttachmentTypeData extends Object {
  lookup: { [contentType: string]: AttachmentType };
}

export class AttachmentTypeGlobalStore extends Store<AttachmentTypeData> {
  protected data(): AttachmentTypeData {
    return {
      lookup: {},
    };
  }

  constructor() {
    super();
    this.add(AttachmentType.Image, ["image/png", "image/jpeg"]);
    this.add(AttachmentType.Pdf, ["application/pdf"]);
    this.add(AttachmentType.Media, ["video/mp4", "video/ogg", "video/webm"]);
    this.add(AttachmentType.Text, ["text/plain"]);
  }

  isSupported(contentType: string): boolean {
    return contentType in this.state.lookup;
  }

  isImage(contentType: string): boolean {
    return this.isOfType(contentType, AttachmentType.Image);
  }

  isMedia(contentType: string): boolean {
    return this.isOfType(contentType, AttachmentType.Media);
  }

  isPdf(contentType: string): boolean {
    return this.isOfType(contentType, AttachmentType.Pdf);
  }

  isText(contentType: string): boolean {
    return this.isOfType(contentType, AttachmentType.Text);
  }

  icon(contentType: string): string {
    if (this.isMedia(contentType)) {
      return "movie";
    } else if (this.isPdf(contentType)) {
      return "fas fa-file-pdf";
    } else if (this.isText(contentType)) {
      return "far fa-file-alt";
    } else if (contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return "far fa-file-word";
    } else if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return "far fa-file-excel";
    }

    return "far fa-file";
  }

  // color (contentType: string): string {
  //   if (this.isMedia(contentType)) {
  //     return 'orange-8'
  //   } else if (this.isPdf(contentType)) {
  //     return 'red-4'
  //   } else if (this.isText(contentType)) {
  //     return 'grey-8'
  //   } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
  //     return 'blue-8'
  //   } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
  //     return 'green-8'
  //   }
  //
  //   return 'grey-8'
  // }

  private isOfType(contentType: string, type: AttachmentType): boolean {
    if (!(contentType in this.state.lookup)) {
      return false;
    }

    return this.state.lookup[contentType] === type;
  }

  private add(type: AttachmentType, contentTypes: string[]) {
    contentTypes.forEach(contentType => {
      this.state.lookup[contentType] = type;
    });
  }
}

export const attachmentTypeStore = new AttachmentTypeGlobalStore();
