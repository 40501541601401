import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { Store2 } from "stores/store-2";
import { GContactAddressEntity } from "src/generated/graphql";

export type ConversationTransfer = GContactAddressEntity & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConversationTransferData {
  currentAddressId?: string;
}

export class ConversationTransferAddressStore extends Store2<ConversationTransferData, ConversationTransfer> {
  protected data(): ConversationTransferData {
    return {};
  }

  public setState(state: ConversationTransferData) {
    Object.assign(this.state, state);
  }
}

//export const conversationAssignStore = new ConversationAssignGlobalStore();
