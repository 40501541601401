<template>
  <q-dialog v-model="show" transition-duration="0">
    <div class="sa-dialog w-full">
      <div class="sa-dialog__header px-4 py-4">
        <div class="sa-dialog__header-title">{{ $t("conversation.assistant.mode.changeModeHeader") }}</div>

        <q-input
          v-model="search"
          outlined
          class="sa-dialog__header-input"
          :placeholder="$t('conversation.assistant.mode.inputPlaceholder')"
          autofocus
          @keydown.up.prevent.stop="selectPrevious"
          @keydown.down.prevent.stop="selectNext"
          @keydown.enter.prevent.stop="onEnter"
        >
          <template v-slot:prepend>
            <q-icon name="search" class="sa-dialog__header-input-icon" />
          </template>
        </q-input>
      </div>

      <div ref="itemContainer">
        <div class="sa-dialog__content scroll" style="max-height: 50vh">
          <div
            v-for="mode in list"
            :key="mode.ui.index"
            class="sa-dialog__content-row px-4 py-2 cursor-pointer"
            :class="{
              'sa-dialog__content-row--selected': mode.ui.selected,
            }"
            @mouseover="onSelect(mode.ui.index)"
            @click="onClick(mode.ui.index)"
          >
            <div class="flex flex-row items-center space-x-5 w-full" :id="`bot-assistant-mode-${mode.ui.index}`">
              <template v-if="mode.mode === GConversationBotAssistantModeItem.Disconnected">
                <q-icon name="o_visibility_off" size="sm" />
                <div class="flex-grow flex flex-col">
                  <div>{{ $t("conversation.assistant.mode.disconnected") }}</div>
                  <div class="muted text-xs">{{ $t("conversation.assistant.mode.disconnectedDescription") }}</div>
                </div>
                <div v-if="mode.mode === currentMode">
                  <q-icon name="checked" />
                </div>
              </template>

              <template v-if="mode.mode === GConversationBotAssistantModeItem.SuggestReply">
                <q-icon name="o_edit" size="sm" />
                <div class="flex-grow flex flex-col">
                  <div>{{ $t("conversation.assistant.mode.suggest") }}</div>
                  <div class="sa-dialog__content-row--faded text-xs">
                    {{ $t("conversation.assistant.mode.suggestDescription") }}
                  </div>
                </div>
                <div v-if="mode.mode === currentMode">
                  <q-icon name="checked" />
                </div>
              </template>

              <template v-if="mode.mode === GConversationBotAssistantModeItem.CreateReply">
                <q-icon name="o_send" size="sm" />
                <div class="flex-grow flex flex-col">
                  <div>{{ $t("conversation.assistant.mode.replyDirectly") }}</div>
                  <div class="sa-dialog__content-row--faded text-xs">
                    {{ $t("conversation.assistant.mode.replyDirectlyDescription") }}
                  </div>
                </div>
                <div v-if="mode.mode === currentMode">
                  <q-icon name="checked" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { useShortcut } from "src/composables/useShortcut";
import { GConversationBotAssistantModeItem } from "src/generated/graphql";
import { modifyConversationBotAssistantModeCommand } from "src/features/conversation/bot-assistant-mode/api-commands";
import { useQuasar } from "quasar";
import { useStore2 } from "src/composables/useStore2";
import { ConversationBotAssistantModeStore } from "src/features/conversation/bot-assistant-mode/conversation-bot-assistant-mode-store";
import { useI18n } from "vue-i18n";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },
  currentMode: {
    type: String as PropType<GConversationBotAssistantModeItem>,
    required: true,
  },
});

const { store } = useStore2(ConversationBotAssistantModeStore);
store.load();

const $q = useQuasar();
const { t } = useI18n();
const itemContainer = ref<HTMLElement>();
const search = ref("");
const show = ref(false);

async function onEnter() {
  const selected = store.getSelectedItem();

  modifyConversationBotAssistantModeCommand(props.conversationId, selected.mode)
    .then(() => {
      $q.notify({
        progress: true,
        message: t("conversation.assistant.mode.modeChangedSuccessMessage"),
        // actions: [
        //   {
        //     label: 'Undo',
        //     color: 'yellow',
        //     handler: () => {
        //       conversationAssignCommand(
        //         props.conversationId,
        //         props.currentAgentId,
        //         props.currentTeamId
        //       )
        //     }
        //   }
        // ]
      });

      show.value = false;
    })
    .catch(err => {
      $q.dialog({
        title: t("conversation.assistant.mode.modeChangeErrorMessage"),
        message: err.message,
      });
    });
}

function selectPrevious() {
  store.selectPrevious();
}

function selectNext() {
  store.selectNext();
}

function onSelect(uiIndex: number) {
  store.select(uiIndex);
}

async function onClick(uiIndex: number) {
  store.select(uiIndex);
  await onEnter();
}

const list = store.getList();

useShortcut(
  "conversationBotAssistantMode",
  [
    {
      key: "down",
      callback: () => selectNext(),
      sendToCommandPalette: false,
    },
    {
      key: "up",
      callback: () => selectPrevious(),
      sendToCommandPalette: false,
    },
    { key: "enter", callback: async () => await onEnter(), sendToCommandPalette: false },
  ],
  {
    newShortcutStack: true,
  },
);
</script>

<style lang="sass"></style>
