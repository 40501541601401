import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { ShortcutItem } from "src/shared/shortcut-item";
import { Store2 } from "stores/store-2";
import { useShortcut } from "src/composables/useShortcut";
import { watch } from "vue";
import { v4 as uuidv4 } from "uuid";

export interface GlobalCommandPaletteItem {
  id: string;
  description?: string;
  key?: string;
  shortcut: ShortcutItem | undefined;
}

export type GlobalCommandPalette = GlobalCommandPaletteItem & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GlobalCommandPaletteData {}

export class GlobalCommandPaletteGlobalStore extends Store2<GlobalCommandPaletteData, GlobalCommandPalette> {
  protected data(): GlobalCommandPaletteData {
    return {};
  }

  protected setup() {
    super.setup();

    const { activeShortcuts } = useShortcut("store", [], { ignoreMount: true });

    watch(activeShortcuts, () => {
      this.list.splice(0, this.list.length);

      activeShortcuts.value.forEach(s => {
        if (s.sendToCommandPalette === undefined || s.sendToCommandPalette) {
          this.unshift({
            id: uuidv4(),
            key: s.key,
            description: s.description,
            shortcut: s.shortcut,
          });
        }
      });
    });
  }

  protected wrapSelection(): boolean {
    return true;
  }
}

export const globalCommandPaletteStore = new GlobalCommandPaletteGlobalStore();
