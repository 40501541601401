<template>
  <div class="space-x-1 flex flex-row justify-start">
    <main-nav-btn size="small" :disabled="!hasBack" icon="fa-caret-left" @click="router.back()" />

    <main-nav-btn size="small" :disabled="!hasForward" icon="fa-caret-right" @click="router.forward()" />
  </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import MainNavBtn from "components/MainNavBtn.vue";
import { useRouter, useRoute } from "vue-router";
import { watch, ref } from "vue";
const router = useRouter();
const route = useRoute();

const hasBack = ref((window as any).navigation?.canGoBack);
const hasForward = ref((window as any).navigation?.canGoForward);

watch(route, () => {
  hasBack.value = !!((window as any).navigation?.canGoBack || window.history.state?.back);
  hasForward.value = !!((window as any).navigation?.canGoForward || window.history.state?.forward);
});
</script>

<style scoped lang="scss"></style>
