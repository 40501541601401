<template>
  <div>
    <div class="px-6 mt-6">
      <TeamModifyForm ref="form" :id="id" @created="onUpdated" v-model:saving="saving" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <s-drawer-btn :label="$t('common.cancel')" @click="closeDrawer" />
      <s-drawer-btn :label="$t('common.save')" primary @click="submit" :loading="saving" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import TeamModifyForm from "./TeamModifyForm.vue";
import { ref } from "vue";
import SDrawerBtn from "src/components/SDrawerBtn.vue";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import { emitter } from "src/boot/mitt";

const saving = ref(false);
const form = ref();

defineProps({
  id: {
    type: String,
    required: true,
  },
});

const submit = () => {
  form?.value.submitForm();
};

function onUpdated() {
  emitter.emit("team-updated");
  closeDrawer();
}

// useShortcut('contact-create-drawer', [
//   {key: 'esc', callback: () => onEscape(), description: 'Close contact drawer', shortcut: ShortcutItem.ConversationHideContact},
//   {key: 'm', callback: () => placeholder(), description: 'Merge'},
//   {key: 'enter', callback: () => placeholder(), description: 'Show profile'}
// ], {
//   pauseAllShortcuts: true,
//   pauseAllGlobalPalettes: true
// })
//
// function onEscape () {
//   drawerStore.hideContactDisplay()
// }
//
// function onShow () {
//   console.log('show')
//   // bind()
// }
//
// function onHide () {
//   console.log('hide')
//   // unbind()
// }
//
// function placeholder () {
//   //
// }
</script>

<style lang="sass"></style>
