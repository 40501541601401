<template>
  <div class="q-pa-md">
    <q-btn-dropdown
      :loading="loading"
      :tooltip="label"
      flat
      :icon="`fa-regular ${icon}`"
      split
      @click="emit(defaultMode)"
    >
      <template #label>
        <STooltip
          >{{ label }}
          <KeyboardShortcutDisplay :keys="shortcut.join('+')" />
        </STooltip>
      </template>
      <q-list>
        <q-item clickable v-close-popup @click="selectedFromDropdown('send')">
          <q-item-section side>
            <q-icon name="fa-light fa-send" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ $t("conversation.actions.send.sendOnly") }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <KeyboardShortcutDisplay keys="mod+enter" :color="'light'" />
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="selectedFromDropdown('send-and-mark-done')">
          <q-item-section side>
            <q-icon name="fa-light fa-message-check" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ $t("conversation.actions.send.sendAndMarkDone") }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <KeyboardShortcutDisplay keys="mod+shift+enter" :color="'light'" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import STooltip from "components/STooltip.vue";
import KeyboardShortcutDisplay from "src/components/KeyboardShortcutDisplay.vue";

type SendMode = "send" | "send-and-mark-done";

defineProps({
  loading: {
    type: Boolean,
  },
});

const emit = defineEmits(["send", "send-and-mark-done"]);
const { t } = useI18n();

const defaultMode = ref<SendMode>(
  localStorage.getItem("send-button-mode") === "send-and-mark-done" ? "send-and-mark-done" : "send",
);

const selectedFromDropdown = (type: SendMode) => {
  localStorage.setItem("send-button-mode", type);
  defaultMode.value = type;

  if (type === "send") {
    emit("send");
  } else {
    emit("send-and-mark-done");
  }
};

const label = computed(() =>
  defaultMode.value === "send-and-mark-done"
    ? t("conversation.actions.send.sendAndMarkDone")
    : t("conversation.actions.send.sendOnly"),
);

const icon = computed(() => (defaultMode.value === "send-and-mark-done" ? "fa-message-check" : "fa-send"));
const shortcut = computed(() =>
  defaultMode.value === "send-and-mark-done" ? ["mod", "shift", "enter"] : ["mod", "enter"],
);
</script>

<style scoped lang="scss"></style>
