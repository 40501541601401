<template>
  <slot />
</template>

<script setup lang="ts">
import { useIdle } from "@vueuse/core";

// import { ContextHolder } from "@frontegg/vue";
// import { Remote } from "comlink";
// import { reportActive } from "src/workers/report-activity";
import { watch, onBeforeUnmount } from "vue";
import { subscribe } from "src/graphql/subscription-client";
import { AgentPingDocument, GAgentPingSubscription, GAgentPingSubscriptionVariables } from "../../../generated/graphql";

// let instance: Remote<typeof import("src/workers/report-activity")> | typeof import("src/workers/report-activity");

// if (typeof SharedWorker !== "undefined") {
//   instance = new ComlinkSharedWorker<typeof import("src/workers/report-activity")>(
//     new URL("../workers/report-activity", import.meta.url),
//     {
//       /* normal Worker options*/
//     },
//   );
// } else if (typeof Worker !== "undefined") {
//   instance = new ComlinkWorker<typeof import("src/workers/report-activity")>(
//     new URL("../workers/report-activity", import.meta.url),
//     {
//       /* normal Worker options*/
//     },
//   );
// } else {
//   console.log("Workers not supported in browser");

//   instance = {
//     reportActive: reportActive,
//   };
// }

const { idle } = useIdle(30 * 60 * 1000); // 30 min
const unsubscribeFns: (() => void)[] = [];

const unsubscribe = () => {
  for (const fn of unsubscribeFns) {
    fn();
  }
};

watch(
  idle,
  idleValue => {
    if (idleValue) {
      console.log("Is idle, unsubscribing to ping");
      unsubscribe();
    } else {
      console.log("Not idle, subscribing to ping");
      unsubscribeFns.push(
        subscribe<GAgentPingSubscription, GAgentPingSubscriptionVariables>(AgentPingDocument, {}, () => {}),
      );
    }
  },
  {
    immediate: true,
  },
);

onBeforeUnmount(() => {
  unsubscribe();
});
</script>

<style scoped lang="scss"></style>
