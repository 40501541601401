<template>
  <div />
</template>

<script setup lang="ts">
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import GlobalCommandPaletteDialog from "src/features/palette-global-command/GlobalCommandPaletteDialog.vue";
import { useQuasar } from "quasar";
const $q = useQuasar();

function showGlobalPaletteDialog() {
  $q.dialog({
    component: GlobalCommandPaletteDialog,
  });
}

useShortcut("global-shortcut", [
  {
    key: "mod+k",
    shortcut: ShortcutItem.ShowGlobalCommandPalette,
    callback: showGlobalPaletteDialog,
    sendToCommandPalette: false,
    useGlobalBind: true,
    globalShortcut: true,
  },
  // {
  //   key: "g+i",
  //   shortcut: ShortcutItem.NavigateToConversations,
  //   callback: () => $router.push({ name: "conversation-list" }),
  //   description: "Go to active conversations",
  //   sendToCommandPalette: true,
  //   globalShortcut: true,
  // },
  // {
  //   key: "g+c",
  //   shortcut: ShortcutItem.NavigateToContacts,
  //   callback: () => $router.push({ name: "contact-list" }),
  //   description: "Go to contacts",
  //   sendToCommandPalette: true,
  //   globalShortcut: true,
  // },
]);
</script>

<style lang="sass"></style>
