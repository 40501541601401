<template>
  <q-select
    class="sa-form__input"
    popup-content-class="sa-select-popup"
    options-selected-class="sa-select-popup__row--selected"
    v-model="list"
    ref="select"
    multiple
    outlined
    use-input
    new-value-mode="add"
    @new-value="onNew"
    @add="onAdd"
    @remove="onRemove"
    @popup-hide="onPopupHide"
    :input-debounce="0"
    @input-value="onInputValue"
    hide-dropdown-icon
    hide-bottom-space
    @blur="onBlur"
    :error="hasError"
    error-message="Invalid email address"
  >
    <template v-slot:selected-item="scope">
      <q-chip
        removable
        @remove="scope.removeAtIndex(scope.index)"
        :tabindex="scope.tabindex"
        square
        :class="{
          'sa-select-popup__chip--warning': scope.opt.warning === true,
          'sa-select-popup__chip': scope.opt.warning === false,
        }"
      >
        {{ scope.opt.email }}

        <s-tooltip v-if="scope.opt.warning === true">
          This isn't a valid email address. Sending to this recipient will probably fail.
        </s-tooltip>
      </q-chip>
    </template>
  </q-select>
</template>

<script lang="ts" setup>
import { ref, PropType, nextTick, watch, computed } from "vue";
import EmailAddresses, { ParsedMailbox } from "email-addresses";
import { QSelect } from "quasar";
import { isNullOrUndefined } from "src/shared/object-utils";
import STooltip from "components/STooltip.vue";
import { useFormChild } from "quasar";

interface RecipientOption {
  name: string | null;
  nameHighlighted: string | null;
  email: string;
  emailHighlighted: string | null;
  domain: string | null;
  warning: boolean;
}

interface RecipientModel {
  name: string | null;
  email: string;
  domain: string | null;
  warning: boolean;
}

export interface ContactEmailAddress {
  name: string;
  email: string;
}

const props = defineProps({
  modelValue: {
    type: Array as PropType<ContactEmailAddress[]>,
    required: true,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: () => true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const list = ref<RecipientModel[]>([]);
const hasError = computed(() => list.value.filter(i => i.warning).length > 0);
const select = ref<QSelect>();
let inputValue = "";

list.value.push(
  ...props.modelValue.map(r => {
    const emailAddress = EmailAddresses.parseOneAddress(r.email) as ParsedMailbox;

    return {
      name: r.name === null || r.name === "" ? null : r.name,
      email: r.email,
      domain: emailAddress !== null ? emailAddress.domain : null,
      warning: emailAddress === null,
    } as RecipientModel;
  })
);

async function emitInput() {
  await nextTick();
  emit(
    "update:modelValue",
    list.value.map(i => {
      return {
        name: i.name,
        email: i.email,
      } as ContactEmailAddress;
    })
  );
}

function clearLastSearch() {
  // lastFilter = ''
  // lastSearchHits = 0
}

function onNew(value: string, done: (recipientData: RecipientModel) => void) {
  const emailAddress = EmailAddresses.parseOneAddress(value) as ParsedMailbox;

  if (emailAddress === null) {
    done({
      email: value,
      warning: true,
    } as RecipientModel);
  } else {
    done({
      email: emailAddress.address,
      warning: false,
    } as RecipientOption);
  }
}

function clearInput() {
  if (!isNullOrUndefined(select?.value)) {
    select.value.updateInputValue("", true);
    select.value.hidePopup();
  }
}

function onAdd() {
  clearLastSearch();
  void emitInput();
  clearInput();
}

function onRemove() {
  clearLastSearch();
  void emitInput();
  clearInput();
}

function onPopupHide() {
  clearLastSearch();
}

function onBlur() {
  if (inputValue !== "") {
    const emailAddress = EmailAddresses.parseOneAddress(inputValue) as ParsedMailbox;

    if (emailAddress === null) {
      list.value.push({
        email: inputValue,
        warning: true,
      } as RecipientModel);
    } else {
      list.value.push({
        email: inputValue,
        warning: false,
      } as RecipientModel);
    }
  }

  inputValue = "";
  void emitInput();
}

function onInputValue(value: string) {
  inputValue = value;
}

watch(
  () => props.autofocus,
  async () => {
    if (props.autofocus) {
      await nextTick();
      select.value?.focus();
    }
  }
);

function validate() {
  return !hasError.value;
}

function resetValidation() {
  //
}

useFormChild({
  validate,
  resetValidation,
  requiresQForm: true,
});
</script>

<style lang="sass"></style>
