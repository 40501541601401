import { date } from "quasar";

import { i18n } from "boot/i18n";

export function humanFriendlyDateDifference(targetDate: Date): string | null {
  const currentDate = new Date();

  if (targetDate <= currentDate) {
    return null;
  }

  const minuteDifference = date.getDateDiff(targetDate, currentDate, "minutes");

  if (minuteDifference < 60) {
    return i18n.global.t(minuteDifference === 1 ? "dateDifference.inMinute" : "dateDifference.inMinutes", {
      number: minuteDifference,
    });
  }

  const hourDifference = date.getDateDiff(targetDate, currentDate, "hours");

  if (hourDifference < 24) {
    return i18n.global.t(hourDifference === 1 ? "dateDifference.inHour" : "dateDifference.inHours", {
      number: hourDifference,
    });
  }

  const dayDifference = date.getDateDiff(targetDate, currentDate, "days");
  const daysLeftThisWeek = 7 - currentDate.getDay();

  const dayLongLc = targetDate.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
  const dayName = i18n.global.t(`dateDifference.days.${dayLongLc}`);

  if (dayDifference <= daysLeftThisWeek) {
    return i18n.global.t("dateDifference.onDay", { day: dayName });
  }

  if (dayDifference <= daysLeftThisWeek + 7) {
    return i18n.global.t("dateDifference.onDayNextWeek", { day: dayName });
  }

  if (dayDifference <= 7 * 4) {
    const weeks = Math.floor(dayDifference / 7);
    return i18n.global.t(weeks === 1 ? "dateDifference.onDayInWeek" : "dateDifference.onDayInWeeks", {
      day: dayName,
      number: weeks,
    });
  }

  const monthDifference = date.getDateDiff(targetDate, currentDate, "months");

  if (monthDifference < 12) {
    return i18n.global.t(monthDifference === 1 ? "dateDifference.inMonth" : "dateDifference.inMonths", {
      number: monthDifference,
    });
  }

  const yearDifference = date.getDateDiff(targetDate, currentDate, "years");
  return i18n.global.t(yearDifference === 1 ? "dateDifference.inYear" : "dateDifference.inYears", {
    number: yearDifference,
  });
}
