<template>
  <q-btn no-caps :ripple="false" :class="[active && 'active', 'sa__button']" flat>
    <s-tooltip v-if="tooltip && !$q.platform.has.touch" :position="tooltipPosition">
      <div>{{ tooltip }}</div>
      <div v-if="shortcuts.length > 0">
        <KeyboardShortcutDisplay :keys="shortcuts.join(' ')" />
      </div>
    </s-tooltip>
    <slot />
  </q-btn>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import STooltip from "components/STooltip.vue";
import { useQuasar } from "quasar";
import KeyboardShortcutDisplay from "src/components/KeyboardShortcutDisplay.vue";

const $q = useQuasar();

defineProps({
  tooltip: {
    type: String,
    required: false,
  },
  tooltipPosition: {
    type: String as PropType<"left" | "right" | "top" | "bottom">,
    required: false,
  },
  shortcuts: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [],
  },
  active: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});
</script>

<style lang="sass"></style>
