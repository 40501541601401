import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import {
  GGlobalTeamListFragment,
  GOnTeamCreatedSubscription,
  GOnTeamCreatedSubscriptionVariables,
  OnTeamCreatedDocument,
  GOnTeamModifiedSubscription,
  GOnTeamModifiedSubscriptionVariables,
  OnTeamModifiedDocument,
} from "src/generated/graphql";
import { computed } from "vue";

export type Team = GGlobalTeamListFragment & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TeamListData {
  //
}

export class TeamListGlobalStore extends Store2<TeamListData, Team> {
  protected data(): TeamListData {
    return {};
  }

  protected async load() {
    //
  }

  protected setupSubscribe() {
    this.addSubscription<GOnTeamCreatedSubscription, GOnTeamCreatedSubscriptionVariables>(
      OnTeamCreatedDocument,
      {},
      res => {
        if (res.onTeamCreated) {
          this.push(res.onTeamCreated);
        }
      },
    );

    this.addSubscription<GOnTeamModifiedSubscription, GOnTeamModifiedSubscriptionVariables>(
      OnTeamModifiedDocument,
      {},
      res => {
        if (res.onTeamModified) {
          this.replaceById(res.onTeamModified.id, res.onTeamModified);
        }
      },
    );
  }

  protected setupEmitter() {
    //
  }

  getById = (id: string) => computed(() => this.getList().find(i => i.id === id));
}

export const teamListGlobalStore = new TeamListGlobalStore();
