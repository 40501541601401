<template>
  <div
    class="py-2 px-4 rounded-lg"
    :class="{
      'ring-2 ring-offset-2': selected,
    }"
  >
    <div class="text-center text-xs">
      <template v-if="event.eventType === GAppEventType.ConversationArchived">
        <q-icon name="fa-solid fa-check" class="muted" />
        <div>
          {{ $t("conversation.event.archived") }}
        </div>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationUnArchived">
        <q-icon name="fa-solid fa-backward" class="muted" />
        <div>
          {{ $t("conversation.event.unArchived") }}
        </div>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationBotAssistantDisconnected">
        <q-icon name="fa-regular fa-message-slash" class="muted" />
        <div>
          {{ $t("conversation.event.botAssistantDisconnected") }}
        </div>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationBotAssistantSuggesting">
        <q-icon name="fa-regular fa-message-bot" class="muted" />
        <div>
          {{ $t("conversation.event.botAssistantSuggesting") }}
        </div>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationBotAssistantReplying">
        <q-icon name="fa-regular fa-message-bot" class="muted" />
        <div>
          {{ $t("conversation.event.botAssistantReplying") }}
        </div>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationSnoozed">
        <q-icon name="fa-solid fa-bell" class="muted" />
        <i18n-t keypath="conversation.event.snoozed" tag="div" scope="global">
          <template #date>
            {{ quasarDate.formatDate(snoozedMetadata.remindAt, "DD MMM YYYY HH:mm") }}
          </template>
        </i18n-t>
      </template>

      <template v-else-if="event.eventType === GAppEventType.ConversationUnSnoozed">
        <i18n-t keypath="conversation.event.unSnoozed" tag="div" scope="global"></i18n-t>
      </template>

      <div v-else-if="event.eventType === GAppEventType.ConversationAssigned">
        <q-icon name="fa-solid fa-people-arrows" class="muted" />
        <i18n-t
          v-if="assignMetadata.newAssignee?.id === event.executingAgent?.id"
          keypath="conversation.event.assignedSelf"
          tag="div"
          scope="global"
        >
          <template #agent>
            <agent-avatar-with-status
              v-if="event.executingAgent !== null"
              :agentId="event.executingAgent?.id as string"
              size="xs"
              hide-avatar
              include-name
            />
          </template>
        </i18n-t>

        <template v-else-if="!isNullOrUndefined(assignMetadata.newAssignee?.id)">
          <i18n-t keypath="conversation.event.assignedAgent" tag="div" scope="global">
            <template #agent>
              <agent-avatar-with-status
                v-if="event.executingAgent !== null"
                :agentId="assignMetadata.newAssignee?.id as string"
                hide-avatar
                include-name
                size="xs"
              />
            </template>
          </i18n-t>
        </template>

        <template v-else-if="!isNullOrUndefined(assignMetadata.newTeam?.id)">
          <i18n-t keypath="conversation.event.assignedTeam" tag="div" scope="global">
            <template #team>
              <team-avatar :id="assignMetadata.newTeam?.id ?? ''" :tooltip="true" size="xs" />
            </template>
          </i18n-t>
        </template>

        <template v-else-if="isNullOrUndefined(assignMetadata.newAssignee)">
          <i18n-t keypath="conversation.event.unassigned" tag="div" scope="global" />
        </template>
      </div>

      <div v-else-if="event.eventType === GAppEventType.ConversationTransferred">
        <q-icon name="fa-solid fa-comments" class="muted" />
        <i18n-t
          v-if="transferMetadata.fromChannel !== transferMetadata.toChannel"
          keypath="conversation.event.transferredNewChannel"
          tag="div"
          scope="global"
        >
          <template #fromChannel>
            {{ $t(`channels.${transferMetadata.fromChannel}`) }}
          </template>

          <template #toChannel>
            {{ $t(`channels.${transferMetadata.toChannel}`) }}
          </template>

          <template #toAddress>
            {{ transferMetadata.toContactAddress?.address }}
          </template>
        </i18n-t>

        <i18n-t
          v-else-if="
            transferMetadata.fromChannel === transferMetadata.toChannel ||
            transferMetadata.fromContactAddress?.id != transferMetadata.toContactAddress?.id
          "
          keypath="conversation.event.transferredNewAddress"
          tag="div"
          scope="global"
        >
          <template #fromAddress>
            {{ transferMetadata.fromContactAddress?.address }}
          </template>

          <template #toAddress>
            {{ transferMetadata.toContactAddress?.address }}
          </template>
        </i18n-t>

        <template v-else>
          {{ transferMetadata }}
        </template>
      </div>

      <div v-else class="text-red">
        {{ event }}
      </div>
    </div>

    <div class="muted text-center text-xs">
      <i18n-t v-if="event.executingAgent !== null" keypath="conversation.event.byAgent" tag="div" scope="global">
        <template #ago>
          <updated-ago :updated="timestamp" />
        </template>
        <template #agent>
          <agent-avatar-with-status :agentId="event.executingAgent?.id as string" size="xs" hide-avatar include-name />
        </template>
      </i18n-t>

      <i18n-t v-else keypath="conversation.event.bySystem" tag="div" scope="global">
        <template #ago>
          <updated-ago :updated="timestamp" />
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import {
  GAppEventType,
  GConversationAssignedMetaData,
  GConversationEvent,
  GConversationSnoozedMetaData,
  GConversationTransferredMetaData,
} from "src/generated/graphql";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import TeamAvatar from "src/features/team/team-avatar/TeamAvatar.vue";
import { date as quasarDate } from "quasar";

const props = defineProps({
  timestamp: {
    type: String,
    required: true,
  },

  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },

  event: {
    type: Object as PropType<GConversationEvent>,
    required: true,
  },
});

const assignMetadata = computed(() => props.event.metadata as GConversationAssignedMetaData);
const snoozedMetadata = computed(() => props.event.metadata as GConversationSnoozedMetaData);
const transferMetadata = computed(() => props.event.metadata as GConversationTransferredMetaData);
</script>
