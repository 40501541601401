import { useGraphqlSdk } from "src/graphql/graphql-client";
import { InternalError, UserError } from "src/shared/models/user-error";
import { isNullOrUndefined } from "src/shared/object-utils";
import { emitter } from "boot/mitt";
import { GConversationStatusItem } from "src/generated/graphql";

export async function modifyConversationStatusCommand(conversationId: string, status: GConversationStatusItem) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .ModifyConversationStatus({
      conversationId: conversationId,
      status: status,
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (
    isNullOrUndefined(res) ||
    isNullOrUndefined(res.modifyConversation) ||
    isNullOrUndefined(res.modifyConversation.conversation)
  ) {
    throw new InternalError(new Error("Conversation not found"));
  }

  if (res.modifyConversation.errors?.length) {
    throw new UserError(res.modifyConversation.errors);
  }

  res.modifyConversation.events?.forEach(i => {
    emitter.emit("conversation-timeline-created", {
      timeline: i,
      scrollTo: true,
    });
  });

  const conversation = res.modifyConversation.conversation;
  emitter.emit("conversation-modified", conversation);
  return conversation;
}

export async function startWatchConversation(conversationId: string) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .AgentWatchConversation({
      conversationId,
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (isNullOrUndefined(res) || isNullOrUndefined(res.agentWatchConversation)) {
    throw new InternalError(new Error("Unable to watch conversation"));
  }

  return res.agentWatchConversation.success;
}

export async function stopWatchConversation(conversationId: string) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .AgentStopWatchConversation({
      conversationId,
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (isNullOrUndefined(res) || isNullOrUndefined(res.agentStopWatchConversation)) {
    throw new InternalError(new Error("Unable to stop watch conversation"));
  }

  return res.agentStopWatchConversation.success;
}
