<template>
  <q-form @submit="onSubmit" greedy ref="form" class="sa-form">
    <template v-if="loading">
      <q-spinner />
    </template>

    <div v-else class="space-y-5">
      <slot />
    </div>
  </q-form>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { QForm } from "quasar";

const form = ref<QForm>();

export interface ISForm {
  save(): void;
}

defineProps({
  loading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const emit = defineEmits(["submit"]);

function onSubmit() {
  emit("submit");
}

const save = () => {
  console.log("saving in sform");

  if (form.value) {
    form.value.submit();
  }
};

defineExpose({ save });
</script>

<style lang="sass"></style>
