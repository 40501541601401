export enum TeamOrAgentType {
  None = 0,
  Agent = 1,
  Team = 2,
  All = 3,
}

export enum ConversationListFilterType {
  Active = 1,
  Snoozed = 2,
  Archived = 3,
}
