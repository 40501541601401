import { onBeforeUnmount } from "vue";
import { subscribe } from "src/graphql/subscription-client";
import { DocumentNode } from "graphql/language";

export function useSubscribe<T, TVar extends Record<string, unknown> | undefined>(
  query: DocumentNode,
  variables: TVar,
  callback: (data: T) => void
) {
  const unsubscribe = subscribe<T, TVar>(query, variables, callback);

  onBeforeUnmount(() => {
    unsubscribe();
  });
}
