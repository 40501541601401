import { isDialogVisible } from "src/shared/dom-utils";
import { Store2 } from "stores/store-2";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSaveSelectionToStore(store: Store2<any, any>) {
  function onMouseOver(uiIndex: number) {
    store.hover(uiIndex);
  }

  function onMouseLeave(uiIndex: number) {
    if (isDialogVisible()) {
      return;
    }

    store.unhover(uiIndex);
  }

  function onCtrlClick(uiIndex: number, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    store.toggleSelect(uiIndex);
  }

  return { onMouseOver, onMouseLeave, onCtrlClick };
}
