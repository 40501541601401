<template>
  <div>
    <div class="space-y-2 px-4 py-4">
      <div class="sa-kb-display text-sm leading-5" v-html="snippet.conversationSnippetById?.content" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <ActionBtn label="Avbryt" @click="onCancel" />
      <ActionBtn label="Slett" @click="onDelete" />
      <ActionBtn label="Edit" primary @click="onEdit" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useShortcut } from "src/composables/useShortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { useRouter } from "vue-router";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import ActionBtn from "src/components/ActionBtn.vue";
import { useI18n } from "vue-i18n";
import { emitter } from "src/boot/mitt";
import { useQuasar } from "quasar";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const sdk = useGraphqlSdk();
const { t } = useI18n();
const $q = useQuasar();

const snippet = await sdk.GetSnippetById({
  id: props.id,
});

useShortcut(
  "knowledgeBaseDisplayDrawer",
  [
    {
      key: "esc",
      callback: () => onCancel(),
      description: "Close drawer",
      shortcut: ShortcutItem.Escape,
    },
  ],
  {
    newShortcutStack: true,
    includeGlobal: true,
  }
);

function onEdit() {
  onHide();
  void router.push({ name: "settings-snippet-modify", params: { id: props.id } });
}

function onCancel() {
  closeDrawer();
}

function onDelete() {
  $q.dialog({
    title: "Confirm",
    message: t("conversation.snippets.shared.confirmDelete"),
    cancel: true,
  }).onOk(async () => {
    const progress = $q.notify({
      type: "ongoing",
      message: t("conversation.snippets.shared.deleteToastProgress"),
    });

    try {
      await sdk.DeleteConversationSnippet({
        input: {
          id: props.id,
        },
      });

      progress({
        type: "positive",
        message: t("conversation.snippets.shared.deleteToastSuccessful"),
      });
      emitter.emit("conversation-snippet-deleted");
      closeDrawer();
      await router.push({ name: "settings-snippets" });
    } catch (err) {
      progress({
        type: "negative",
        message: t("conversation.snippets.shared.deleteToastError"),
      });
    }
  });
}

// function onShow() {
//   bind();
// }

function onHide() {
  // closeDrawer();
}
</script>

<style lang="sass"></style>
