<template>
  <q-btn square :class="buttonClass" v-bind="$attrs">
    <slot />
  </q-btn>
</template>

<script setup lang="ts">
import { PropType, toRefs, computed } from "vue";

const props = defineProps({
  kind: {
    type: String as PropType<"primary" | "secondary">,
    default: "primary",
  },
});

const { kind } = toRefs(props);

const buttonClass = computed(() => {
  const baseClass = "sa-action-button";

  switch (kind?.value) {
    case "primary":
      return baseClass + " sa-action-button__primary";

    case "secondary":
      return baseClass + " sa-action-button__secondary";
  }

  return baseClass;
});
</script>

<style scoped lang="scss"></style>
