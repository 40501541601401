import { Editor } from "@tiptap/vue-3";
import { diffChars } from "diff";
import { onBeforeUnmount } from "vue";
import { TextPatch } from "src/shared/models/text-patch";

export function useChangeWatcher(
  editor: Editor,
  diffCallback: (formattedDiffs: TextPatch[], sequence: number) => void,
  snapshotCallback: (text: string, sequence: number) => void
) {
  let previousText = "";
  let sequence = 0;
  let previousSnapshot = new Date().getTime();

  const refreshTimerId = setInterval(() => {
    const currentText = editor.getHTML();
    const differences = formatDifferences(previousText, currentText);

    if (differences.length) {
      sequence++;
      console.log(sequence, differences);
      previousText = currentText;

      if (new Date().getTime() - previousSnapshot >= 10 * 1000) {
        console.log("Full snapshot");
        previousSnapshot = new Date().getTime();
        snapshotCallback(currentText, sequence);
      } else {
        diffCallback(differences, sequence);
      }
    }
  }, 2000);

  function formatDifferences(previousText: string, currentText: string) {
    const differences = diffChars(previousText, currentText);

    let offset = 0;
    const formattedDiffs: TextPatch[] = [];

    for (const part of differences) {
      if (part.added) {
        formattedDiffs.push({ start: offset, del: 0, insert: part.value });
        offset += part.value.length;
      } else if (part.removed) {
        formattedDiffs.push({ start: offset, del: part.value.length, insert: "" });
      } else {
        offset += part.value.length;
      }
    }

    return formattedDiffs;
  }

  onBeforeUnmount(() => {
    console.log("Cleared interval", refreshTimerId);
    clearInterval(refreshTimerId);
  });

  return {};
}
