<template>
  <div class="inline-flex flex-row items-center space-x-1">
    <q-avatar
      v-if="!hideAvatar"
      dense
      :color="inactive ? 'sa-agent-away' : 'sa-agent-active'"
      :text-color="inactive ? 'sa-agent-away' : 'sa-agent-active'"
      round
      :size="size"
      class="mr-1"
    >
      {{ initials }}
      <q-badge
        v-if="!hideStatus"
        :color="inactive ? 'sa-agent-away-badge' : 'sa-agent-active-badge'"
        floating
        rounded
      />
      <s-tooltip v-if="tooltip" class="text-base">{{ name }}</s-tooltip>
    </q-avatar>
    <div v-if="includeName">{{ name }}</div>
    <div v-if="extraText !== ''">{{ extraText }}</div>
  </div>
</template>

<script setup lang="ts">
import { agentWithStatusStore } from "src/features/agent/agent-avatar-with-status/agent-with-status-global-store";
import { isNullOrUndefined } from "src/shared/object-utils";
import { ref, watch, computed } from "vue";
import { resolveNameInitials, resolveDisplayName } from "src/shared/display-name-helper";
import STooltip from "components/STooltip.vue";
import { GAgentStatusItem } from "src/generated/graphql";

const props = defineProps({
  agentId: {
    type: String,
    required: true,
  },

  size: {
    type: String,
    required: false,
    default: () => "md",
  },

  tooltip: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  includeName: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  extraText: {
    type: String,
    required: false,
    default: () => "",
  },

  hideAvatar: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  hideStatus: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const initials = ref("");
const name = ref("");
const away = ref(false);
const idle = ref(false);

const inactive = computed(() => idle.value || away.value);

function setData() {
  const list = agentWithStatusStore.getList();

  const agent = list.find(s => s.id === props.agentId);

  if (!isNullOrUndefined(agent)) {
    initials.value = resolveNameInitials(agent.firstName, agent.lastName);
    away.value = agent.away;
    idle.value = agent.idle;
    name.value = resolveDisplayName(agent.firstName, agent.lastName);

    if (
      [
        GAgentStatusItem.Deleted,
        GAgentStatusItem.Invited,
        GAgentStatusItem.Disabled,
        GAgentStatusItem.Unknown,
      ].includes(agent.perTenant.status)
    ) {
      away.value = true;
    }
  } else {
    initials.value = "?";
    away.value = true;
    idle.value = false;
    name.value = "?";
  }
}

setData();
watch(props, () => setData());
watch(agentWithStatusStore.getList(), () => setData());
</script>

<style lang="sass"></style>
