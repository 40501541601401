<template>
  <slot />
</template>

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { useFrontegg, useAuthState, ContextHolder } from "@frontegg/vue";
import { watch } from "vue";
import { useCurrentTenant } from "src/composables/useCurrentTenant";

const router = useRouter();
const route = useRoute();

await router.isReady();

const authState = useAuthState();
const { currentTenantId, setTenantId, reset } = useCurrentTenant();
const { fronteggAuth: feAuth, authState: loginState } = useFrontegg();

// TODO Remove
if (route.name === "legacy-conversation") {
  if (authState.isAuthenticated) {
    console.log("redirecting to new conversation");
    await router.push({
      name: "conversation",
      params: {
        tenantId: loginState.user?.tenantId,
        id: route.params.id,
      },
    });
  }
}

const isAuthRoute = (path: string) => {
  const { routes } = authState;
  return Object.values(routes)
    .filter(path => path !== routes.authenticatedUrl)
    .includes(path);
};

const checkTenantInUrl = () => {
  return new Promise(async resolve => {
    if (isAuthRoute(route.path)) {
      resolve(true);
      return;
    }

    if (!authState.isAuthenticated) {
      console.log("Not authenticated...");
      reset();
      resolve(true);
      return;
    }

    if (!loginState.user?.tenantId) {
      throw new Error("No tenantId in frontegg state");
    }

    // No tenant ID in URL -- insert the current tenant from the frontegg state
    if (!route.params.tenantId) {
      console.log("No tenant in url, redirecting to tenant from session:", loginState.user.tenantId);
      await router.push({
        name: "conversation-list",
        params: {
          tenantId: loginState.user.tenantId,
        },
      });
    }

    // Accessed another tenant by URL -- need to trigger switchTenant
    // Using ContextHolder instead of loginState, because loginState sometimes falls out of sync after some switches (don't know why)
    else if (
      route.params.tenantId !== ContextHolder.getUser()?.tenantId ||
      route.params.tenantId !== currentTenantId.value
    ) {
      // Does user have access to the given tenant?
      if (!loginState.tenantsState.tenants.find(i => i.tenantId === route.params.tenantId)) {
        console.log("No access to tenant", route.params.tenantId);

        await router.push({
          name: "conversation-list",
          params: {
            tenantId: loginState.user.tenantId,
          },
        });
        resolve(true);
        return;
      }

      // Switch to the given tenant
      await new Promise(r => {
        if (ContextHolder.getUser()?.tenantId === (route.params.tenantId as string)) {
          setTenantId(route.params.tenantId as string);
          r(true);
        } else {
          feAuth.tenantsActions.switchTenant({
            tenantId: route.params.tenantId as string,
            callback: () => {
              setTenantId(route.params.tenantId as string);
              r(true);
            },
          });
        }
      });
    }

    // A-OK
    else {
      setTenantId(route.params.tenantId as string);
    }

    resolve(true);
  });
};

watch(route, async () => {
  await checkTenantInUrl();
});

watch(
  () => loginState.user?.tenantId,
  async () => {
    if (!loginState.user?.tenantId) {
      return;
    }
    if (route.params.tenantId !== loginState.user?.tenantId) {
      await router.push({
        name: "conversation-list",
        params: {
          tenantId: loginState.user?.tenantId,
        },
      });
    }
  },
);

await checkTenantInUrl();
</script>

<style scoped lang="scss"></style>
