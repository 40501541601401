import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import {
  GGlobalAgentWithStatusListFragment,
  GOnAgentCreatedSubscription,
  GOnAgentCreatedSubscriptionVariables,
  GOnAgentModifiedSubscription,
  GOnAgentModifiedSubscriptionVariables,
  OnAgentCreatedDocument,
  OnAgentModifiedDocument,
} from "src/generated/graphql";

export type Agent = GGlobalAgentWithStatusListFragment & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AgentWithStatusData {
  //
}

export class AgentWithStatusGlobalStore extends Store2<AgentWithStatusData, Agent> {
  protected data(): AgentWithStatusData {
    return {};
  }

  // protected async load() {
  //   //
  // }

  protected setupSubscribe() {
    this.addSubscription<GOnAgentModifiedSubscription, GOnAgentModifiedSubscriptionVariables>(
      OnAgentModifiedDocument,
      {},
      res => {
        if (res.onAgentModified) {
          this.replaceById(res.onAgentModified.id, res.onAgentModified);
        }
      }
    );

    this.addSubscription<GOnAgentCreatedSubscription, GOnAgentCreatedSubscriptionVariables>(
      OnAgentCreatedDocument,
      {},
      res => {
        if (res.onAgentCreated) {
          this.push(res.onAgentCreated);
        }
      }
    );
  }

  protected setupEmitter() {
    //
  }
}

export const agentWithStatusStore = new AgentWithStatusGlobalStore();
