<template>
  <template v-if="activeList.length">
    <div class="space-y-4 w-full flex justify-end">
      <div
        v-for="agentIsTyping in activeList"
        :key="agentIsTyping.typingId"
        class="w-2/3"
        :id="`agent-typing-${agentIsTyping.typingId}`"
      >
        <div class="sa-conversation-timeline-agent-typing-message__name mb-1 text-sm flex flex-row items-end space-x-2">
          <agent-avatar-with-status :agent-id="agentIsTyping.agentId" size="sm" include-name extra-text="is typing" />
          <q-spinner-dots size="xs" />
        </div>

        <div
          class="sa-conversation-timeline-agent-typing-message__block cursor-pointer pl-5 pr-2 py-3 rounded-lg w-full flex justify-between items-center"
        >
          <div class="sa-conversation-timeline-agent-typing-message__block-content leading-6 text-sm pr-4 space-y-1">
            <template v-if="agentIsTyping.content === ''">
              <q-spinner-dots />
            </template>
            <div v-else v-html="agentIsTyping.content" />
          </div>
        </div>

        <placeholder-component @mounted="onAgentTypingMounted(agentIsTyping.typingId)" />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import {
  GOnAgentStoppedTypingInConversationByIdSubscription,
  GOnAgentStoppedTypingInConversationByIdSubscriptionVariables,
  GOnAgentTypingInConversationByIdSubscription,
  GOnAgentTypingInConversationByIdSubscriptionVariables,
  OnAgentStoppedTypingInConversationByIdDocument,
  OnAgentTypingInConversationByIdDocument,
} from "src/generated/graphql";
import { useSubscribe } from "src/composables/useSubscribe";
import { TextSynchronizationClient } from "src/shared/text-synchronization-client";
import { ref } from "vue";
import AgentAvatarWithStatus from "src/features/agent/agent-avatar-with-status/AgentAvatarWithStatus.vue";
import { useInterval } from "src/composables/useInterval";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import PlaceholderComponent from "components/PlaceholderComponent.vue";
import { isPageNearBottom, scrollToBottomSmooth } from "src/shared/dom-utils";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },
});

interface AgentIsTypingInConversation {
  typingId: string;
  agentId: string;
  content: string;
  text: TextSynchronizationClient;
  lastUpdated: Date;
}

const list: AgentIsTypingInConversation[] = [];
const activeList = ref<AgentIsTypingInConversation[]>([]);
const agentSettingsStoreState = agentSettingsStore.getState();

function onAgentTypingMounted(typingId: string) {
  const elementId = `agent-typing-${typingId}`;
  const el = document.getElementById(elementId);

  if (el) {
    // The element is already visible, so we need to include the height when checking if we are near the bottom.
    if (isPageNearBottom(el.clientHeight + 50)) {
      scrollToBottomSmooth();
    }
  } else {
    console.log("Element", elementId, "not found");
  }
}

useInterval(() => {
  const delta = new Date().getTime() - 30 * 1000; // 30 seconds
  activeList.value = list.filter(
    i => i.lastUpdated.getTime() > delta && i.content !== "" && i.content !== "<div></div>"
  );
}, 1);

useSubscribe<GOnAgentTypingInConversationByIdSubscription, GOnAgentTypingInConversationByIdSubscriptionVariables>(
  OnAgentTypingInConversationByIdDocument,
  {
    conversationId: props.conversationId ?? "",
  },
  res => {
    if (res.onAgentTypingInConversationById.agentId === agentSettingsStoreState.id) {
      return;
    }

    let agentIsTyping: AgentIsTypingInConversation;
    const isNearBottom = isPageNearBottom();

    const agentIsTypingIndex = list.findIndex(i => i.typingId === res.onAgentTypingInConversationById.typingId);
    if (agentIsTypingIndex !== -1) {
      agentIsTyping = list[agentIsTypingIndex] as AgentIsTypingInConversation;
    } else {
      agentIsTyping = {
        agentId: res.onAgentTypingInConversationById.agentId,
        content: "",
        typingId: res.onAgentTypingInConversationById.typingId,
        text: new TextSynchronizationClient(),
      } as AgentIsTypingInConversation;

      list.push(agentIsTyping);
    }

    agentIsTyping.content = agentIsTyping.text.applyPatch(
      res.onAgentTypingInConversationById.content,
      res.onAgentTypingInConversationById.isFullContent,
      res.onAgentTypingInConversationById.sequence
    );

    agentIsTyping.lastUpdated = new Date();

    if (isNearBottom) {
      scrollToBottomSmooth(500);
    }
  }
);

useSubscribe<
  GOnAgentStoppedTypingInConversationByIdSubscription,
  GOnAgentStoppedTypingInConversationByIdSubscriptionVariables
>(
  OnAgentStoppedTypingInConversationByIdDocument,
  {
    conversationId: props.conversationId ?? "",
  },
  res => {
    const agentIsTypingIndex = list.findIndex(i => i.typingId === res.onAgentStoppedTypingInConversationById.typingId);
    if (agentIsTypingIndex !== -1) {
      list.splice(agentIsTypingIndex, 1);
    }
  }
);
</script>
