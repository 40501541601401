import { useGraphqlSdk } from "src/graphql/graphql-client";
import { InternalError, UserError } from "src/shared/models/user-error";
import { isNullOrUndefined } from "src/shared/object-utils";
import { emitter } from "boot/mitt";

export async function createConversationAgentMessageCommand(
  conversationId: string,
  content: string,
  linkedToTimelineId: string | null,
  attachments: string[]
) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .CreateConversationAgentMessage({
      conversationId: conversationId,
      content: content,
      linkedToTimelineId: linkedToTimelineId,
      attachments: attachments,
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (isNullOrUndefined(res.createConversationAgentMessage.conversationTimeline)) {
    throw new InternalError(new Error("Conversation not found"));
  }

  if (res.createConversationAgentMessage.errors?.length) {
    throw new UserError(res.createConversationAgentMessage.errors);
  }

  const timeline = res.createConversationAgentMessage.conversationTimeline;

  emitter.emit("conversation-timeline-created", {
    timeline: timeline,
    scrollTo: true,
  });

  return timeline;
}
