import { GTenantEntity } from "src/generated/graphql";
import { Store } from "stores/store";

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface TenantInfoStoreData extends GTenantEntity {}

export class TenantInfoGlobalStore extends Store<TenantInfoStoreData> {
  protected data(): TenantInfoStoreData {
    return {
      createdAt: null,
      emailChannelDomain: "",
      id: "",
      name: "",
      subdomain: "",
      partner: null,
      onboardingStatus: {
        hasAtLeastOneConversation: false,
        hasInstalledWidgetOnWebsite: false,
      },
    };
  }
}

export const tenantInfoStore = new TenantInfoGlobalStore();
