import { scrollToBottomInstant } from "src/shared/dom-utils";
import { nextTick, onMounted } from "vue";
import { onBeforeUnmount } from "vue";

export function useScrollToBottom(delay?: number) {
  let timeoutId: number;

  onMounted(async () => {
    await nextTick();
    scrollToBottomInstant();

    if (delay != null) {
      timeoutId = window.setTimeout(() => {
        scrollToBottomInstant();
      }, delay);
    }
  });

  onBeforeUnmount(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  });
}
