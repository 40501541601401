<template>
  <div v-if="!$q.platform.has.touch">
    <p class="inline-flex px-1.5 text-xs leading-5" v-if="addDivider">or</p>
    <template v-for="(keyItem, index) in splitKeys" :key="index">
      <p class="inline-flex pl-1 dark:text-zinc-400 text-xs leading-5" v-if="isSequence && index > 0">
        {{ separator }}
      </p>
      <p
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-md"
        :class="[colorClasses, index !== 0 && 'ml-1']"
      >
        {{ getSymbolIcon(keyItem, $q.platform.is.win ?? false) }}
      </p>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { getSymbolIcon } from "src/shared/keyHelper";
import { useQuasar } from "quasar";

const { t } = useI18n();
const $q = useQuasar();

const isSequence = computed(() => props.keys.includes(" "));

const props = defineProps({
  keys: {
    type: String,
    required: true,
  },
  color: {
    type: String as PropType<"dark" | "light" | "indigo">,
    default: "dark",
  },
});

const separator = computed(() => {
  return props.keys.includes(" ") ? t("common.keyboardShortcutSequenceSeparator") : null;
});

const splitKeys = computed(() => {
  if (props.keys.includes("|")) {
    return props.keys.split("|").map(i => i.trim());
  }

  if (props.keys.includes("+")) {
    return props.keys.split("+").map(i => i.trim());
  }

  return props.keys
    .trim()
    .split(" ")
    .map(i => i.trim());
});

const addDivider = computed(() => props.keys.includes("|"));

const colorClasses = computed(() => {
  if (props.color === "light") {
    return "bg-gray-200 text-gray-500 dark:bg-zinc-600 dark:text-zinc-200";
  } else if (props.color === "indigo") {
    return "bg-wh-teal text-wh-black";
  } else {
    return "bg-gray-800 dark:bg-zinc-800 dark:border-zinc-700 dark:text-zinc-100 text-white border-gray-600 border-2";
  }
});
</script>
