import { StorePersistent } from "stores/store-persistant";
import { GGlobalSnippetListFragment } from "src/generated/graphql";

export interface ConversationSnippetStoreData {
  snippets: GGlobalSnippetListFragment[] | null;
}

export class ConversationSnippetsGlobalStore extends StorePersistent<ConversationSnippetStoreData> {
  protected data(): ConversationSnippetStoreData {
    return {
      snippets: null,
    };
  }
}

export const conversationSnippetStore = new ConversationSnippetsGlobalStore("conversation-snippets");
