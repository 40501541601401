<template>
  <main-nav-btn
    icon="fa-rectangle-terminal"
    @click="onClick"
    :tooltip="$t('commandPalette.title')"
    size="small"
    :shortcuts="['mod+K']"
  />
</template>

<script setup lang="ts">
import { useQuasar } from "quasar";
import GlobalCommandPaletteDialog from "src/features/palette-global-command/GlobalCommandPaletteDialog.vue";
import MainNavBtn from "components/MainNavBtn.vue";
const $q = useQuasar();

function onClick() {
  $q.dialog({
    component: GlobalCommandPaletteDialog,
  });
}
</script>

<style lang="sass"></style>
