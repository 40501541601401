import { emitter } from "src/boot/mitt";
import { MittEvents } from "src/shared/mitt-events";
import { isNullOrUndefined } from "src/shared/object-utils";
import { onBeforeUnmount } from "vue";

type EventType = keyof MittEvents;

interface RegisteredMittEvent<T extends EventType> {
  type: T;
  callback: (data: MittEvents[T]) => void;
}

export function useEmitterOn<T extends keyof MittEvents>(type: T | T[], callback: (data: MittEvents[T]) => void) {
  const temp = Array.isArray(type) ? type : [type];

  for (const t of temp) {
    emitter.on(t, callback);

    const registeredMittEvent: RegisteredMittEvent<T> = {
      type: t,
      callback: callback,
    };

    onBeforeUnmount(() => {
      if (!isNullOrUndefined(registeredMittEvent)) {
        console.log("Removing mitt event", registeredMittEvent.type);
        emitter.off(registeredMittEvent.type, registeredMittEvent.callback);
      }
    });
  }
}
