import { GTenantRoleItem } from "src/generated/graphql";

export function hasRequiredRole(currentRole: GTenantRoleItem, requiredRole: GTenantRoleItem) {
  if (requiredRole === GTenantRoleItem.Manager) {
    return [GTenantRoleItem.Manager, GTenantRoleItem.Admin].includes(currentRole);
  }

  if (requiredRole === GTenantRoleItem.Admin) {
    return currentRole === GTenantRoleItem.Admin;
  }

  return true;
}
