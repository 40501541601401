import { nextTick, onMounted, onBeforeUnmount } from "vue";
import { debounce } from "quasar";
import {
  getDistanceFromBottom,
  getPageElement,
  getScrollHeight,
  getScrollY,
  isDialogVisible,
  isDrawerVisible,
  scrollPageBy,
} from "src/shared/dom-utils";

export function useScrollTopListener(drawerMode: boolean, callback: (scrollToPreviousLocation: () => void) => void) {
  const debouncedHandleScroll = debounce(handleScroll, 100);
  let distanceFromBottom = 0;
  let pageElement: HTMLElement | Window;

  async function handleScroll() {
    const scrollY = getScrollY(pageElement);

    if (scrollY <= 20) {
      //console.log("User is at the top of the page", drawerMode);

      // Not the best Vue way, but it works...
      // If a q-dialog or q-drawer is showing then we don't trigger the callback.
      // Could check drawerStore instead of .q-drawer, but there is no store for dialogs.
      if (!drawerMode && (isDrawerVisible() || isDialogVisible())) {
        console.log("A q-dialog or q-drawer is showing. Skipping");
        return;
      }

      distanceFromBottom = getDistanceFromBottom(pageElement);
      callback(scrollToPreviousLocation);
    }
  }

  async function scrollToPreviousLocation() {
    await nextTick(() => {
      const newScrollY = getScrollHeight(pageElement) - distanceFromBottom;
      scrollPageBy(newScrollY);
    });
  }

  onMounted(() => {
    pageElement = getPageElement();
    pageElement.addEventListener("scroll", debouncedHandleScroll);
  });

  onBeforeUnmount(() => {
    pageElement.removeEventListener("scroll", debouncedHandleScroll);
    debouncedHandleScroll.cancel();
  });
}
