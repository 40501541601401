import { GAutoAdvanceOptionItem, GGlobalMeFragment, GLocaleItem, GTenantRoleItem } from "src/generated/graphql";
import { Store } from "stores/store";

export interface AgentSettingStoreData extends GGlobalMeFragment {}

export class AgentSettingsGlobalStore extends Store<AgentSettingStoreData> {
  protected data(): AgentSettingStoreData {
    return {
      id: "",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      locale: GLocaleItem.English,
      //theme: "",
      perTenant: {
        role: GTenantRoleItem.Unknown,
        teams: [],
        isFirstAgentInTenant: false,
      },
      settings: {
        autoAdvance: {
          action: GAutoAdvanceOptionItem.StayInConversation,
        },
      },
    };
  }
}

export const agentSettingsStore = new AgentSettingsGlobalStore();
