<template>
  <div class="flex flex-row items-center justify-between pt-4 lg:pt-0">
    <div class="flex flex-row space-x-5 items-center">
      <div class="flex flex-col space-y-1">
        <div class="text-xl space-x-3">
          <span v-if="conversation.description">{{ conversation.description }}</span
          ><span v-else class="muted"> &lt;{{ $t("conversation.common.noSubject").toLowerCase() }}&gt; </span>
          <conversation-status-chip-display :status="conversation.status" />
        </div>

        <div class="flex flex-row space-x-5 items-center">
          <div class="sa-conversation-display__contact">
            <span class="cursor-pointer" @click="showContactDrawer">{{ contactName }}</span>
            <span class="sa-conversation-display__channel">
              (<conversation-channel-display :channel="conversation.currentChannel" />)
            </span>
          </div>

          <div class="muted">
            {{ $t("conversation.display.toolbar.assignedLabel") }}
            <team-or-agent-avatar
              class="cursor-pointer"
              @click="emitShortcut(ShortcutItem.ConversationAssign)"
              :agent-id="conversation.assignee?.id"
              :team-id="conversation.team?.id"
              :team-name="conversation.team?.name"
              size="sm"
            />
          </div>
        </div>
      </div>

      <div></div>
    </div>

    <div class="space-x-2 flex flex-row justify-end">
      <ActionButtonCollection :actions="toolbarButtons" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ShortcutItem } from "src/shared/shortcut-item";
// import { conversationStore } from "src/features/conversation/conversation-display/conversation-store";
import { GConversationByIdFragment, GConversationStatusItem } from "src/generated/graphql";
import { resolveDisplayName } from "src/shared/display-name-helper";
import ConversationChannelDisplay from "src/features/conversation/conversation-display/ConversationChannelDisplay.vue";
import ConversationStatusChipDisplay from "src/features/conversation/conversation-display/ConversationStatusChipDisplay.vue";
import TeamOrAgentAvatar from "src/features/conversation/team-or-agent-avatar/TeamOrAgentAvatar.vue";
import emitShortcut from "src/shared/emit-shortcut";
import { useI18n } from "vue-i18n";
import { useDrawer } from "src/composables/useDrawers";
import { ConversationStore } from "src/features/conversation/conversation-display/conversation-store";
import { PropType, computed, toRefs, unref } from "vue";
import ActionButtonCollection from "src/components/ActionButtonCollection.vue";
import { useFeatureEntitlements } from "@frontegg/vue";
import { Entitlements } from "src/shared/entitlements";
import { useRouter } from "vue-router";
import { ActionButtonCollectionActionProp } from "src/components/props";

const aiEntitlement = useFeatureEntitlements(Entitlements.AiAssistant);

const props = defineProps({
  drawerMode: {
    type: Boolean,
    required: false,
  },

  conversationStore: {
    type: Object as PropType<ConversationStore>,
    required: true,
  },
});

const { conversationStore } = toRefs(props);
const router = useRouter();

const { t } = useI18n();
const { showContactDisplayDrawer } = useDrawer(t);

const conversation = conversationStore.value.getState().conversation as GConversationByIdFragment;

const showContactDrawer = () => {
  showContactDisplayDrawer(conversation.contact.id);
};

const contactName = computed(() => {
  const resolved = resolveDisplayName(conversation.contact.firstName, conversation.contact.lastName);

  if (!resolved || resolved === "") return t("contacts.unknownContact");

  return resolved;
});

const toolbarButtons = computed(() => {
  let buttons: ActionButtonCollectionActionProp[] = [
    {
      icon: "o_reply",
      action: () => emitShortcut(ShortcutItem.CreateConversationAgentMessage),
      description: t("conversations.singleView.reply"),
      shortcut: ["enter"],
    },
    {
      icon: "o_edit",
      action: () => emitShortcut(ShortcutItem.CreateConversationAgentComment),
      description: t("conversations.singleView.addComment"),
      shortcut: ["C"],
    },
    {
      icon: "o_person_add",
      action: () => emitShortcut(ShortcutItem.ConversationAssign),
      description: t("conversations.singleView.assign"),
      shortcut: ["A"],
    },
  ];

  if (unref(aiEntitlement).isEntitled) {
    buttons.unshift({
      icon: "o_smart_toy",
      action: () => emitShortcut(ShortcutItem.ConversationShowBotAssistantMode),
      description: t("conversations.singleView.botAssistantMode"),
      shortcut: ["B"],
    });
  }

  if (props.drawerMode) {
    buttons.unshift({
      icon: "fa-light fa-expand",
      description: t("conversations.singleView.preview.openConversation"),
      route: router.resolve({ name: "conversation", params: { id: conversation.id } }),
    });
  }

  if (
    conversation.status === GConversationStatusItem.Active ||
    conversation.status === GConversationStatusItem.Snoozed
  ) {
    buttons.push({
      icon: "o_done",
      action: () => emitShortcut(ShortcutItem.ConversationArchive),
      description: t("conversations.singleView.markAsDone"),
      shortcut: ["D"],
    });
  }

  if (conversation.status === GConversationStatusItem.Archived) {
    buttons.push({
      icon: "o_fast_rewind",
      action: () => emitShortcut(ShortcutItem.ConversationUnArchive),
      description: t("conversations.singleView.markAsNotDone"),
      shortcut: ["D"],
    });
  }

  if (conversation.status === GConversationStatusItem.Active) {
    buttons.push({
      icon: "o_alarm",
      action: () => emitShortcut(ShortcutItem.ConversationSnooze),
      description: t("conversations.singleView.snooze"),
      shortcut: ["Z"],
    });
  }

  buttons.push({
    icon: "o_forum",
    action: () => emitShortcut(ShortcutItem.ConversationChangeChannel),
    description: t("conversations.singleView.transfer"),
    shortcut: ["F"],
  });

  // if (!props.drawerMode) {
  //   buttons.push({
  //     icon: "add",
  //     action: () => emitShortcut(ShortcutItem.CreateNewConversation),
  //     description: t("conversations.singleView.newConversation"),
  //   });
  // }

  return buttons;
});

// useEmitterOn("conversation-count", count => {
//   console.log("yep", count);
// });
</script>

<style lang="sass"></style>
