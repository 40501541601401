// import { GConversationChannelItem } from "src/generated/graphql";
// import { Composer } from 'vue-i18n';

import { GConversationChannelItem } from "src/generated/graphql";

// export function humanConversationChannel(channel: GConversationChannelItem, t: Composer["t"], capitalize = false) {
//   let ret = "";

//   switch (channel) {
//     case GConversationChannelItem.EmbeddedChat:
//       ret = t("conversation.channels.chat");
//       break;

//     case GConversationChannelItem.Email:
//       ret = t("conversation.channels.email");
//       break;

//     case GConversationChannelItem.Sms:
//       ret = t("conversation.channels.sms");
//       break;

//     case GConversationChannelItem.FacebookMessenger:
//       ret = t("conversation.channels.facebookMessenger");
//       break;

//     case GConversationChannelItem.WhatsApp:
//       ret = t("conversation.channels.whatsapp");
//       break;
//   }

//   return capitalize ? ret.charAt(0).toUpperCase() + ret.slice(1) : ret;
// }

export const channelIcons: Record<GConversationChannelItem, string> = {
  EMAIL: "fa-light fa-envelope",
  EMBEDDED_CHAT: "fa-light fa-comments",
  FACEBOOK_MESSENGER: "fa-brands fa-facebook-messenger",
  SMS: "fa-light fa-comment-sms",
  UNKNOWN: "fa-light fa-circle-question",
  VOICE_CALL: "fa-regular fa-phone",
  WHATS_APP: "fa-brands fa-whatsapp",
};
