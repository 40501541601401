import { GLocaleItem } from "src/generated/graphql";
//import { computed, ref } from "vue";
import { SUPPORTED_LOCALES } from "src/boot/i18n";
// import type { Locale } from "vue-i18n";

// const localeToLocaleItem: Record<(typeof SUPPORTED_LOCALES)[number], GLocaleItem> = {
//   en: GLocaleItem.English,
//   no: GLocaleItem.Norwegian,
// };

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];

const localeItemToLocale: Record<GLocaleItem, (typeof SUPPORTED_LOCALES)[number]> = {
  [GLocaleItem.English]: "en",
  [GLocaleItem.Norwegian]: "no",
};

//const localeItem = ref(GLocaleItem.English);

export function useLocaleHelpers() {
  // const onLocaleUpdated = (locale: Locale) => {
  //   if (!SUPPORTED_LOCALES.some(i => i === locale)) {
  //     console.warn("Update to unsupported locale", locale);
  //     return;
  //   }

  //   localeItem.value = localeToLocaleItem[locale as (typeof SUPPORTED_LOCALES)[number]];
  // };

  return {
    // onLocaleUpdated,
    // localeItem: computed(() => localeItem.value),
    localeItemToLocale,
  };
}
