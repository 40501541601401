<template>
  <q-select
    class="sa-form__input"
    popup-content-class="sa-select-popup"
    options-selected-class="sa-select-popup__row--selected"
    v-model="list"
    ref="select"
    multiple
    outlined
    use-input
    new-value-mode="add"
    @new-value="onNew"
    @add="onAdd"
    @remove="onRemove"
    @popup-hide="onPopupHide"
    :input-debounce="0"
    @input-value="onInputValue"
    hide-dropdown-icon
    hide-bottom-space
    @blur="onBlur"
    :error="hasError"
    error-message="Invalid phone number"
  >
    <template v-slot:selected-item="scope">
      <q-chip
        removable
        @remove="scope.removeAtIndex(scope.index)"
        :tabindex="scope.tabindex"
        square
        :class="{
          'sa-select-popup__chip--warning': scope.opt.warning === true,
          'sa-select-popup__chip': scope.opt.warning === false,
        }"
      >
        {{ scope.opt.number }}

        <s-tooltip v-if="scope.opt.warning === true">
          This isn't a valid phone number. Sending to this recipient will probably fail.
        </s-tooltip>
      </q-chip>
    </template>
  </q-select>
</template>

<script lang="ts" setup>
import { ref, PropType, nextTick, watch, computed } from "vue";
import parsePhoneNumber from "libphonenumber-js";
import { QSelect, useFormChild } from "quasar";
import { isNullOrUndefined } from "src/shared/object-utils";
import STooltip from "components/STooltip.vue";

interface RecipientModel {
  number: string;
  warning: boolean;
}

export interface ContactPhoneNumber {
  number: string;
  ext: string;
}

const props = defineProps({
  modelValue: {
    type: Array as PropType<ContactPhoneNumber[]>,
    required: true,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: () => true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const list = ref<RecipientModel[]>([]);
const hasError = computed(() => list.value.filter(i => i.warning).length > 0);
const select = ref<QSelect>();
let inputValue = "";

list.value.push(
  ...props.modelValue.map(r => {
    const phoneNumber = parsePhoneNumber(r.number, "NO");

    if (phoneNumber) {
      return {
        number: r.number,
        warning: !phoneNumber.isValid(),
      } as RecipientModel;
    } else {
      return {
        number: r.number,
        warning: true,
      } as RecipientModel;
    }
  })
);

async function emitInput() {
  await nextTick();
  emit(
    "update:modelValue",
    list.value.map(i => {
      return {
        number: i.number,
        ext: "",
      } as ContactPhoneNumber;
    })
  );
}

function clearLastSearch() {
  // lastFilter = ''
  // lastSearchHits = 0
}

function onNew(value: string, done: (recipientData: RecipientModel) => void) {
  const phoneNumber = parsePhoneNumber(value, "NO");

  if (phoneNumber) {
    console.log(phoneNumber.country, phoneNumber.number, phoneNumber.isPossible(), phoneNumber.isValid());

    done({
      number: phoneNumber.isValid() ? phoneNumber.number : value,
      warning: !phoneNumber.isValid(),
    });
  } else {
    console.log("Not a number");
    done({
      number: value,
      warning: true,
    });
  }
}

function clearInput() {
  if (!isNullOrUndefined(select?.value)) {
    select.value.updateInputValue("", true);
    select.value.hidePopup();
  }
}

function onAdd() {
  clearLastSearch();
  void emitInput();
  clearInput();
}

function onRemove() {
  clearLastSearch();
  void emitInput();
  clearInput();
}

function onPopupHide() {
  clearLastSearch();
}

function onBlur() {
  if (inputValue !== "") {
    const phoneNumber = parsePhoneNumber(inputValue, "NO");

    if (!phoneNumber) {
      list.value.push({
        number: inputValue,
        warning: true,
      } as RecipientModel);
    } else {
      list.value.push({
        number: inputValue,
        warning: false,
      } as RecipientModel);
    }
  }

  inputValue = "";
  void emitInput();
}

function onInputValue(value: string) {
  inputValue = value;
}

watch(
  () => props.autofocus,
  async () => {
    if (props.autofocus) {
      await nextTick();
      select.value?.focus();
    }
  }
);

function validate() {
  return !hasError.value;
}

function resetValidation() {
  //
}

useFormChild({
  validate,
  resetValidation,
  requiresQForm: true,
});
</script>

<style lang="sass">
.ticket-recipient-select
  .q-chip
    font-weight: 500

div.ticket-recipient-select-popup
  span.highlight
    font-weight: bold
</style>
