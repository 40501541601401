<template>
  <Popover v-slot="{ open }">
    <Float
      strategy="fixed"
      placement="left-start"
      :offset="20"
      :shift="6"
      auto-placement
      :arrow="5"
      enter="transition duration-200 ease-out"
      enter-from="opacity-0 -translate-y-1"
      enter-to="opacity-100 translate-y-0"
      leave="transition duration-150 ease-in"
      leave-from="opacity-100 translate-y-0"
      leave-to="opacity-0 -translate-y-1"
    >
      <PopoverButton
        :class="open ? 'text-white' : 'text-white/70'"
        class="group inline-flex items-center rounded-md pr-2 py-2 text-xl font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
      >
        <span>{{ emoji }}</span>
      </PopoverButton>
      <PopoverPanel
        class="bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none p-1"
        v-slot="{ close }"
      >
        <emoji-picker @emoji-click="onEmojiSelect($event, close)" class="light" draggable="false"></emoji-picker>
      </PopoverPanel>
    </Float>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { Float } from "@headlessui-float/vue";
import { EmojiClickEvent } from "emoji-picker-element/shared";
import "emoji-picker-element";
import { computed } from "vue";

const emit = defineEmits(["select"]);

const props = defineProps({
  currentEmoji: {
    type: String,
    required: false,
  },
});

const emoji = computed(() => props.currentEmoji?.trim() || "🫥");

const onEmojiSelect = (event: EmojiClickEvent, closePopover: () => void) => {
  if (!event.detail.unicode) return;

  emit("select", event.detail.unicode);
  closePopover();
};
</script>

<style scoped lang="scss"></style>
