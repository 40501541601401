<template>
  <s-btn-with-tooltip
    :unelevated="unelevated"
    :glossy="glossy"
    :round="round"
    :rounded="rounded"
    :ripple="ripple"
    :push="push"
    :dense="dense"
    :outline="outline"
    size="md"
    :class="primary ? 'sa-drawer__button--primary' : 'sa-drawer__button--secondary'"
    :tooltip="tooltip"
    :shortcuts="shortcuts"
  >
    <slot />
  </s-btn-with-tooltip>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import SBtnWithTooltip from "components/SBtnWithTooltip.vue";

defineProps({
  tooltip: {
    type: String,
    required: false,
  },
  shortcuts: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [],
  },
  primary: {
    type: Boolean,
    required: false,
    default: () => false,
  },
});

const unelevated = ref(true);
const glossy = ref(false);
const round = ref(false);
const rounded = ref(false);
const ripple = ref(false);
const push = ref(false);
const dense = ref(false);
const outline = ref(false);
</script>

<style lang="sass"></style>
