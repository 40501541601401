<template>
  <template v-if="isLoaded">
    <header class="sticky top-0 z-10 py-4 sa-conversation-display__toolbar">
      <KnowledgeBaseArticleCreateToolbar :store="store" :drawerMode="drawerMode" />
    </header>
    <main class="mt-4">
      <KnowledgeBaseArticleCreateForm :store="store" :title="title" :solution="solution" @created="onCreated" />
    </main>
  </template>
</template>

<script setup lang="ts">
import KnowledgeBaseArticleCreateForm from "src/features/knowledgebase/kb-article-create/KnowledgeBaseArticleCreateForm.vue";
import KnowledgeBaseArticleCreateToolbar from "src/features/knowledgebase/kb-article-create/KnowledgeBaseArticleCreateToolbar.vue";
import { useStore } from "src/composables/useStore";
import { KnowledgeBaseArticleCreateStore } from "src/features/knowledgebase/kb-article-create/knowledge-base-article-create-store";
import { useRouter } from "vue-router";
import { closeDrawer } from "src/composables/useGlobalDrawer";

const { store, isLoaded } = useStore(KnowledgeBaseArticleCreateStore);
const router = useRouter();

// onUnmounted(() => {
//   knowledgeBaseArticleModifyStore.clearState();
// });

const props = defineProps({
  drawerMode: {
    type: Boolean,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  solution: {
    type: String,
    required: false,
  },
});

const onCreated = async () => {
  if (props.drawerMode) {
    closeDrawer();
  } else {
    await router.push({ name: "kb-list" });
  }
};
</script>

<style lang="sass"></style>
