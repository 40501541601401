<template>
  <FormKit id="agent-form" type="form" @submit="onSubmit" :actions="false">
    <FormKit
      type="text"
      v-model="firstName"
      validation="required"
      :label="$t('settings.agents.shared.firstNameInputLabel')"
      :placeholder="$t('settings.agents.shared.firstNameInputLabel')"
    >
    </FormKit>
    <FormKit
      type="text"
      v-model="lastName"
      validation="required"
      :label="$t('settings.agents.shared.lastNameInputLabel')"
      :placeholder="$t('settings.agents.shared.lastNameInputLabel')"
    >
    </FormKit>

    <FormKit
      type="email"
      v-model="email"
      readonly
      disabled
      :label="$t('settings.agents.shared.emailInputLabel')"
      :placeholder="$t('settings.agents.shared.emailInputLabel')"
    >
    </FormKit>

    <FormKit
      type="autocomplete"
      clear-search-on-open
      :close-on-select="true"
      :selection-removable="false"
      open-on-focus
      v-model="role"
      :label="$t('settings.agents.shared.roleInputLabel')"
      :options="roleOptions"
      validation="required"
    >
      <template #option="{ option, classes }">
        <div :class="classes.option">
          <div>
            {{ option.label }}
          </div>
          <div class="text-xs muted">
            {{ option.description }}
          </div>
        </div>
      </template>
    </FormKit>
  </FormKit>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { GTenantRoleItem } from "src/generated/graphql";
import { useI18n } from "vue-i18n";
import { GLocaleItem } from "src/generated/graphql";
import { getNode, FormKitNode, FormKitGroupValue } from "@formkit/core";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();

const roleOptions = [
  {
    label: t("settings.agents.shared.roles.agentLabel"),
    value: GTenantRoleItem.Agent,
    description: t("settings.agents.shared.roles.agentDescription"),
  },
  {
    label: t("settings.agents.shared.roles.managerLabel"),
    value: GTenantRoleItem.Manager,
    description: t("settings.agents.shared.roles.managerDescription"),
  },
  {
    label: t("settings.agents.shared.roles.adminLabel"),
    value: GTenantRoleItem.Admin,
    description: t("settings.agents.shared.roles.adminDescription"),
  },
];

const emit = defineEmits(["update:saving", "updated"]);

const submitForm = () => {
  getNode("agent-form")?.submit();
};

defineExpose({ submitForm });

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const role = ref<GTenantRoleItem>(GTenantRoleItem.Admin);
const locale = ref<GLocaleItem>(GLocaleItem.English);

const sdk = useGraphqlSdk();

try {
  const agentData = await sdk.AgentById({
    id: props.id,
  });

  firstName.value = agentData.agentById?.firstName ?? "";
  lastName.value = agentData.agentById?.lastName ?? "";
  email.value = agentData.agentById?.email ?? "";
  role.value = agentData.agentById?.perTenant.role;
  locale.value = agentData.agentById?.locale;
} catch (err) {
  console.warn("AGENT NOT FOUND");
}

async function onSubmit(form: FormKitGroupValue, node?: FormKitNode) {
  try {
    emit("update:saving", true);

    await sdk.ModifyAgent({
      input: {
        agentId: props.id,
        firstName: firstName.value,
        lastName: lastName.value,
        role: role.value,
        //teamIds: [],
        //locale:
      },
    });

    emit("updated");
  } catch (err) {
    node?.setErrors([`Sorry, the agent could not be updated.`]);
  } finally {
    emit("update:saving", false);
  }
}
</script>

<style lang="sass"></style>
