import { Store } from "stores/store";
import { ComputedRef, computed } from "vue";

export interface KnowledgeBaseArticleCreateData {
  title: string;
  objective: string | null | undefined;
  solution: string;
  isPublished: boolean;
  isPublic: boolean;
  isDraft: boolean;
  questions: string[];
  unsavedChanged: ComputedRef<boolean>;
}

export class KnowledgeBaseArticleCreateStore extends Store<KnowledgeBaseArticleCreateData> {
  constructor() {
    super();
    this.settings.loaded = true;
  }

  protected data(): KnowledgeBaseArticleCreateData {
    return {
      title: "",
      objective: "",
      solution: "",
      isPublished: false,
      isPublic: false,
      isDraft: false,
      questions: [],
      unsavedChanged: computed(
        () =>
          !!this.state.title ||
          !!this.state.objective ||
          (!!this.state.solution && this.state.solution !== "<p></p>") ||
          !!(this.state.questions?.length ?? false)
      ),
    };
  }

  // public addEmptyQuestion() {
  //   this.state.questions.push("");
  // }
}

// export const knowledgeBaseArticleModifyStore = new KnowledgeBaseArticleModifyStore();
