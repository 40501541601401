import { useGraphqlSdk } from "src/graphql/graphql-client";
import { InternalError, UserError } from "src/shared/models/user-error";
import { isNullOrUndefined } from "src/shared/object-utils";

export async function agentIsTypingInConversationCommand(
  typingId: string | null,
  accessKey: string | null,
  sequence: number,
  conversationId: string,
  content: string,
  isFullContent: boolean
) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .AgentIsTypingInConversation({
      input: {
        typingId: typingId,
        accessKey: accessKey,
        conversationId: conversationId,
        sequence: sequence,
        content: content,
        isFullContent: isFullContent,
      },
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (isNullOrUndefined(res.agentTypingInConversation)) {
    throw new InternalError(new Error("Unable to set agent is typing"));
  }

  if (res.agentTypingInConversation.errors?.length) {
    throw new UserError(res.agentTypingInConversation.errors);
  }

  return res.agentTypingInConversation;
}

export async function agentStoppedTypingInConversationCommand(
  typingId: string,
  accessKey: string,
  conversationId: string
) {
  const sdk = await useGraphqlSdk();

  const res = await sdk
    .AgentStoppedTypingInConversation({
      input: {
        typingId: typingId,
        conversationId: conversationId,
        accessKey: accessKey,
      },
    })
    .catch(err => {
      console.warn(err);
      throw new InternalError(err);
    });

  if (isNullOrUndefined(res.agentStoppedTypingInConversation)) {
    throw new InternalError(new Error("Unable to set agent stopped typing"));
  }

  if (res.agentStoppedTypingInConversation.errors?.length) {
    throw new UserError(res.agentStoppedTypingInConversation.errors);
  }

  return res.agentStoppedTypingInConversation;
}
