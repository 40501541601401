<template>
  <div @click="copyToClipboard(summary?.content)" class="cursor-pointer">
    <div class="sa-conversation-timeline-summary__name mb-1 text-sm">
      {{
        $t("conversations.summary.summaryDisplayDescription", {
          agent: resolveDisplayName(summary.createdByAgent?.firstName, summary.createdByAgent?.lastName),
        })
      }}
    </div>

    <div
      class="sa-conversation-timeline-summary__block px-5 py-3 rounded-lg w-full flex justify-between items-center"
      :class="{
        'ring-2 ring-offset-2': selected,
      }"
    >
      <div class="sa-conversation-timeline-summary__block-content leading-6 text-sm pr-4 space-y-1">
        <div v-html="summary?.content" />
      </div>

      <div class="sa-conversation-timeline-summary__block-timestamp ml-auto text-sm">
        <updated-ago :updated="timestamp" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { GConversationSummary } from "src/generated/graphql";
import UpdatedAgo from "src/shared/components/UpdatedAgo.vue";
import { resolveDisplayName } from "src/shared/display-name-helper";
import { useClipboard } from "src/composables/useClipboard";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { copyToClipboard } = useClipboard(t);

defineProps({
  timestamp: {
    type: String,
    required: true,
  },

  selected: {
    type: Boolean,
    required: true,
    default: () => false,
  },

  summary: {
    type: Object as PropType<GConversationSummary>,
    required: true,
  },
});
</script>
