<template>
  <div class="w-full space-y-6">
    <q-banner class="bg-primary text-white rounded-md mt-4" v-if="conversationState?.conversation?.isDemoContent"
      >{{ $t("conversations.demoContentBannerText") }}
    </q-banner>
    <div class="w-full flex justify-center mb-3" v-if="isLoadingMore">
      <q-spinner />
    </div>
    <div class="w-full flex justify-center mb-3" v-if="conversationStore.hasNextPage()">
      <ActionBtn
        :loading="isLoadingMore"
        icon="fa-light fa-plus"
        kind="secondary"
        @click="conversationStore.loadMore()"
        >{{ $t("common.loadMore") }}</ActionBtn
      >
    </div>
    <transition-group name="conversation-timeline-transition" tag="div">
      <div
        v-for="(timeline, index) in list"
        :key="timeline.ui.index"
        :id="`conversation-timeline-${timeline.ui.index}`"
        class="space-y-4"
      >
        <template
          v-if="
            timeline.data.__typename === 'ConversationAgentComment' ||
            timeline.data.__typename === 'ConversationAgentMessage' ||
            timeline.data.__typename === 'ConversationBotMessage' ||
            timeline.data.__typename === 'ConversationContactMessage' ||
            timeline.data.__typename === 'ConversationEvent' ||
            timeline.data.__typename === 'ConversationSummary'
          "
        >
          <div v-if="isContactMessageWithBotSuggestion(index)" class="w-full flex justify-start">
            <conversation-contact-message-with-bot-suggestion
              :key="`${timeline.id}-${timeline.ui.key}`"
              :contact-message="timeline.data as GConversationContactMessage"
              :bot-suggestions="getContactMessageBotSuggestions(index) as GConversationBotSuggestion[]"
              :expected-bot-processing="getAiModelsProcessing(index)"
              :hide-bot-suggestion="hideBotSuggestion(index) || isSuggestionUsed(index)"
              :suggestion-already-used="isSuggestionUsed(index)"
              :timestamp="timeline.timestamp"
              :timeline-id="timeline.id"
              :conversation-id="timeline.conversationId"
              :selected="timeline.ui.selected"
              :show-name="showContactMessageName(index)"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationAgentComment'" class="w-full flex justify-center">
            <conversation-agent-comment
              :timestamp="timeline.timestamp"
              :agent-comment="timeline.data as GConversationAgentComment"
              :selected="timeline.ui.selected"
              :show-name="showAgentCommentName(index)"
              class="w-2/3"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationAgentMessage'" class="w-full flex justify-end">
            <conversation-agent-message
              :timestamp="timeline.timestamp"
              :agent-message="timeline.data as GConversationAgentMessage"
              :selected="timeline.ui.selected"
              :show-name="showAgentMessageName(index)"
              :timeline-id="timeline.id"
              class="w-2/3"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationContactMessage'" class="w-full flex justify-start">
            <conversation-contact-message
              :timestamp="timeline.timestamp"
              :contact-message="timeline.data as GConversationContactMessage"
              :selected="timeline.ui.selected"
              :show-name="showContactMessageName(index)"
              class="w-2/3"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationBotMessage'" class="w-full flex justify-end">
            <conversation-bot-message
              :timestamp="timeline.timestamp"
              :bot-message="timeline.data as GConversationBotMessage"
              :selected="timeline.ui.selected"
              class="w-2/3"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationEvent'" class="w-full flex justify-center">
            <conversation-event
              :event="timeline.data as GConversationEvent"
              :selected="timeline.ui.selected"
              :timestamp="timeline.timestamp"
              class="w-2/3"
            />
          </div>

          <div v-else-if="timeline.data.__typename === 'ConversationSummary'" class="w-full flex justify-center">
            <ConversationSummary
              :summary="timeline.data as GConversationSummary"
              :selected="timeline.ui.selected"
              :timestamp="timeline.timestamp"
              class="w-2/3"
            />
          </div>

          <q-intersection once @visibility="onTimelineAppear(timeline)" />
        </template>
      </div>
    </transition-group>
  </div>

  <q-page-sticky v-if="unseenTimeline > 0" position="bottom" :offset="[0, 0]">
    <q-btn-group class="mb-1">
      <q-btn
        fab-mini
        icon="keyboard_arrow_down"
        :label="`${unseenTimeline} new messages`"
        color="white"
        class="bg-green-900"
        @click="onUnseenGotoClick"
      />
      <q-btn fab-mini icon="close" color="white" class="bg-green-800" @click="onUnseenCloseClick" />
    </q-btn-group>
  </q-page-sticky>
</template>

<script setup lang="ts">
import ConversationAgentComment from "src/features/conversation/conversation-display/ConversationAgentComment.vue";
import ConversationAgentMessage from "src/features/conversation/conversation-display/ConversationAgentMessage.vue";
import ConversationContactMessage from "src/features/conversation/conversation-display/ConversationContactMessage.vue";
import {
  ConversationStore,
  ConversationTimeline,
} from "src/features/conversation/conversation-display/conversation-store";
import { useScrollToStoreSelected } from "src/composables/useScrollToStoreSelected";
import {
  GBotResponseResultItem,
  GConversationAgentComment,
  GConversationAgentMessage,
  GConversationBotMessage,
  GConversationBotSuggestion,
  GConversationContactMessage,
  GConversationEvent,
  GConversationSummary,
} from "src/generated/graphql";
import ConversationBotMessage from "src/features/conversation/conversation-display/ConversationBotMessage.vue";
import { useScrollTopListener } from "src/composables/useScrollTopListeners";
import { useScrollToBottom } from "src/composables/useScrollToBottom";
import { useEmitterOn } from "src/composables/useEmitterOn";
import ConversationEvent from "src/features/conversation/conversation-display/ConversationEvent.vue";
import { isPageNearBottom, scrollToBottomSmooth } from "src/shared/dom-utils";
import { computed, ref, toRefs } from "vue";
import { PropType } from "vue/dist/vue";
import ConversationContactMessageWithBotSuggestion from "src/features/conversation/conversation-display/ConversationContactMessageWithBotSuggestion.vue";
import ConversationSummary from "./ConversationSummary.vue";
import { useQuasar } from "quasar";
import ActionBtn from "src/components/ActionBtn.vue";

const props = defineProps({
  drawerMode: {
    type: Boolean,
    required: false,
    default: () => false,
  },

  conversationStore: {
    type: Object as PropType<ConversationStore>,
    required: true,
  },
});

const { conversationStore } = toRefs(props);
const list = conversationStore.value.getList();
const unseenUiIndex = ref<number[]>([]);
const unseenTimeline = computed(() => unseenUiIndex.value.length);
const isLoadingMore = ref(false);
const $q = useQuasar();

const conversationState = conversationStore.value.getState();

useScrollToBottom(250);

const { scrollToId } = useScrollToStoreSelected(conversationStore.value, "conversation-timeline");

useScrollTopListener(props.drawerMode, async scrollToPreviousLocation => {
  try {
    isLoadingMore.value = true;
    await conversationStore.value.loadMore();
    scrollToPreviousLocation();
  } catch (err) {
    console.warn("Error loading more", err);
  } finally {
    isLoadingMore.value = false;
  }
});

useEmitterOn("conversation-timeline-scroll-to", (uiIndex: number) => {
  scrollToId(uiIndex, 15);
});

useEmitterOn("conversation-timeline-optional-scroll-to", (uiIndex: number) => {
  const position = list.findIndex(i => i.ui.index === uiIndex);

  // It's the last item in the timeline
  if (position === list.length - 1) {
    if (isPageNearBottom()) {
      scrollToBottomSmooth(500);
    } else {
      unseenUiIndex.value.push(uiIndex);
    }
  }
});

function onTimelineAppear(timeline: ConversationTimeline) {
  const index = unseenUiIndex.value.indexOf(timeline.ui.index);
  if (index !== -1) {
    unseenUiIndex.value.splice(index, 1);
  }
}

function onUnseenGotoClick() {
  if (unseenUiIndex.value.length) {
    scrollToId(unseenUiIndex.value[unseenUiIndex.value.length - 1], 10);
  }

  unseenUiIndex.value = [];
}

function onUnseenCloseClick() {
  unseenUiIndex.value = [];
}

function showAgentCommentName(index: number) {
  if (index === 0) {
    return true;
  }

  if (list[index - 1].data.__typename !== "ConversationAgentComment") {
    return true;
  }

  const previousTimelineData = list[index - 1].data as GConversationAgentComment;
  const currentTimelineData = list[index].data as GConversationAgentComment;
  return previousTimelineData.agent?.id != currentTimelineData.agent?.id;
}

function isContactMessageWithBotSuggestion(index: number) {
  const data = list[index].data;

  if (data.__typename === "ConversationContactMessage") {
    if (data.botIsProcessing) {
      return true;
    }
  }

  if (index === list.length - 1) {
    console.log("false, index === list.length - 1");
    return false;
  }

  const linked = list.filter(s => s.linkedToTimelineId == list[index].id);

  // console.log("1", list[index].data.__typename === "ConversationContactMessage");
  // console.log(
  //   "2",
  //   linked.some(s => s.data.__typename === "ConversationBotSuggestion"),
  // );
  return (
    list[index].data.__typename === "ConversationContactMessage" &&
    linked.some(s => s.data.__typename === "ConversationBotSuggestion")
  );
}

function hideBotSuggestion(index: number) {
  if ($q.platform.is.mobile) {
    return true;
  }

  const ignoredResults = [
    GBotResponseResultItem.AnswerNotFound,
    GBotResponseResultItem.LowSpecificity,
    GBotResponseResultItem.NoQuestion,
    GBotResponseResultItem.Error,
  ];

  const hasNoSolution = getContactMessageBotSuggestions(index).every(i => ignoredResults.includes(i.botResponseResult));

  if (hasNoSolution) {
    return true;
  }

  // Check if we have suggestions for later messages -- if so, only display the last
  const laterMessages = list.slice(index + 1);

  for (const message of laterMessages) {
    if (
      message.linkedToTimelineId != list[index].id &&
      message.data.__typename === "ConversationBotSuggestion" &&
      !ignoredResults.includes(message.data.botResponseResult)
    ) {
      return true;
    }
  }

  return false;
}

function isSuggestionUsed(index: number) {
  const linked = list.filter(s => s.linkedToTimelineId == list[index].id);

  return (
    list[index].data.__typename === "ConversationContactMessage" &&
    linked.some(s => s.data.__typename === "ConversationAgentMessage")
  );
}

function getContactMessageBotSuggestions(index: number) {
  const item = list.filter(
    s => s.linkedToTimelineId == list[index].id && s.data.__typename === "ConversationBotSuggestion",
  );

  return item.map(i => i?.data as GConversationBotSuggestion);
}

function getAiModelsProcessing(index: number) {
  const data = list[index].data;
  return data.__typename === "ConversationContactMessage" ? data.aiModelsProcessing : [];
}

function showAgentMessageName(index: number) {
  if (index === 0) {
    return true;
  }

  if (list[index - 1].data.__typename !== "ConversationAgentMessage") {
    return true;
  }

  const previousTimelineData = list[index - 1].data as GConversationAgentMessage;
  const currentTimelineData = list[index].data as GConversationAgentMessage;
  return previousTimelineData.agent?.id != currentTimelineData.agent?.id;
}

function showContactMessageName(index: number) {
  if (index === 0) {
    return true;
  }
  return true;

  if (list[index - 1].data.__typename !== "ConversationContactMessage") {
    return true;
  }

  const previousTimelineData = list[index - 1].data as GConversationContactMessage;
  const currentTimelineData = list[index].data as GConversationContactMessage;
  return previousTimelineData.contact.id != currentTimelineData.contact.id;
}
</script>

<style lang="sass" scoped></style>
