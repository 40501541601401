<template>
  <sa-editor class="form-editor" ref="editor" :content="editorContent" text-bubble-menu paragraph-as-div emoji />
</template>

<script setup lang="ts">
import SaEditor, { ISaEditor } from "src/features/tip-tap-editor/SaEditor.vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { ref } from "vue";

const props = defineProps({
  body: {
    type: String,
    required: false,
    default: () => "",
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const editor = ref<ISaEditor>();
const editorContent = props.body.toString();

defineExpose({ getHTML });

function getHTML() {
  if (isNullOrUndefined(editor)) {
    throw new Error("Editor not defined");
  }

  return editor.value?.getHTML();
}
</script>
