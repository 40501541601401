/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComputedRef, onBeforeUnmount } from "vue";
import { Store2 } from "stores/store-2";

export function useStore2<T extends Store2<any, any>>(storeType: {
  new (...args: any[]): T;
}): { store: T; isLoaded: ComputedRef<boolean> } {
  const store = new storeType();

  onBeforeUnmount(() => {
    store.unmount();
  });

  return { store, isLoaded: store.isLoaded() };
}
