<template>
  <div>
    <div class="px-6 mt-6">
      <agent-create-form ref="form" @created="onCreated" v-model:saving="saving" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <s-drawer-btn :label="$t('common.cancel')" @click="closeDrawer" />
      <s-drawer-btn :label="$t('common.create')" primary @click="submit" :loading="saving" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import AgentCreateForm from "./AgentCreateForm.vue";
import { ref } from "vue";
import SDrawerBtn from "components/SDrawerBtn.vue";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import { emitter } from "boot/mitt";
import { GAgentEntity } from "src/generated/graphql";

const saving = ref(false);
const form = ref();

const submit = () => {
  form?.value.submitForm();
};

function onCreated(agent: GAgentEntity) {
  emitter.emit("agent-created", {
    agent: agent,
  });
  closeDrawer();
}

// useShortcut('contact-create-drawer', [
//   {key: 'esc', callback: () => onEscape(), description: 'Close contact drawer', shortcut: ShortcutItem.ConversationHideContact},
//   {key: 'm', callback: () => placeholder(), description: 'Merge'},
//   {key: 'enter', callback: () => placeholder(), description: 'Show profile'}
// ], {
//   pauseAllShortcuts: true,
//   pauseAllGlobalPalettes: true
// })
//
// function onEscape () {
//   drawerStore.hideContactDisplay()
// }
//
// function onShow () {
//   console.log('show')
//   // bind()
// }
//
// function onHide () {
//   console.log('hide')
//   // unbind()
// }
//
// function placeholder () {
//   //
// }
</script>

<style lang="sass"></style>
