/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import TemplateSuggestionList from "src/features/tip-tap-editor/extensions/template/TemplateSuggestionList.vue";
import { conversationSnippetStore } from "stores/conversation-snippets-global-store";
import { TemplatePlaceholder } from "./template-placeholder";
import { GGlobalSnippetListFragment } from "src/generated/graphql";
import { isNullOrUndefined } from "src/shared/object-utils";

export type PlaceholderReplacementData = Record<TemplatePlaceholder, string>;

const fillTemplatePlaceholders = (
  templates: GGlobalSnippetListFragment[],
  placeholderData: PlaceholderReplacementData,
): GGlobalSnippetListFragment[] => {
  return templates.map<GGlobalSnippetListFragment>(template => {
    return {
      ...template,

      content: template.content
        .replaceAll(TemplatePlaceholder.AgentName, placeholderData[TemplatePlaceholder.AgentName])
        .replaceAll(TemplatePlaceholder.ContactName, placeholderData[TemplatePlaceholder.ContactName]),

      title: template.title
        .replaceAll(TemplatePlaceholder.AgentName, placeholderData[TemplatePlaceholder.AgentName])
        .replaceAll(TemplatePlaceholder.ContactName, placeholderData[TemplatePlaceholder.ContactName]),
    };
  });
};

export default function (placeholderContent: PlaceholderReplacementData) {
  const { snippets: snippetsRaw } = conversationSnippetStore.getState();

  const snippets = !isNullOrUndefined(snippetsRaw) ? fillTemplatePlaceholders(snippetsRaw, placeholderContent) : [];

  return {
    //char: "!!",

    items: ({ query }: { query: string }) => {
      if (!snippets) {
        return [];
      }

      const queryLc = query.toLowerCase();
      return snippets
        .filter(
          template =>
            template.title.toLowerCase().indexOf(queryLc) !== -1 ||
            template.content.toLowerCase().indexOf(queryLc) !== -1,
        )
        .slice(0, 5);
    },

    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          component = new VueRenderer(TemplateSuggestionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            maxWidth: 520,
          });
        },

        onUpdate(props: any) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return false;
          }

          return component.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
}
