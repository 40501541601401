<template>
  <div class="flex flex-row items-center justify-between">
    <div class="flex flex-row space-x-5 items-center">
      <router-link :to="{ name: 'kb-list' }" @click="onBackClick">
        <s-nav-back-btn tooltip="Go back" />
      </router-link>

      <div>
        <div class="text-lg">{{ $t("kb.create.createArticle") }}</div>
      </div>
    </div>

    <div class="space-x-2 flex flex-row">
      <!-- <conversation-toolbar-btn
        icon="question_mark"
        @click="emitShortcut(ShortcutItem.ConversationShowBotAssistantMode)"
        tooltip="Update questions"
      />

      <conversation-toolbar-btn
        icon="o_smart_toy"
        @click="emitShortcut(ShortcutItem.CreateConversationAgentMessage)"
        tooltip="Make changes with assistant"
      />

      <conversation-toolbar-btn
        v-if="!state.isPublished"
        icon="o_publish"
        @click="emitShortcut(ShortcutItem.CreateConversationAgentMessage)"
        tooltip="Publish"
      />

      <conversation-toolbar-btn
        v-if="state.isPublished"
        icon="o_unpublished"
        @click="emitShortcut(ShortcutItem.CreateConversationAgentMessage)"
        tooltip="Unpublish"
      /> -->

      <div v-if="!drawerMode" class="pl-8 flex justify-end">
        <q-btn icon="menu" text-color="white" color="grey-9" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ShortcutItem } from "src/shared/shortcut-item";
import SNavBackBtn from "components/SNavBackBtn.vue";
import { useI18n } from "vue-i18n";
import { useShortcut } from "src/composables/useShortcut";
import { useRouter } from "vue-router";
import { closeDrawer } from "src/composables/useGlobalDrawer";

const props = defineProps({
  drawerMode: {
    type: Boolean,
    required: false,
  },
});

const { t } = useI18n();
const router = useRouter();

const goBack = async () => {
  if (props.drawerMode) {
    closeDrawer();
  } else {
    await router.push({ name: "kb-list" });
  }
};

useShortcut(
  "kb-article-create",
  [
    {
      key: "escape",
      callback: async () => {
        await goBack();
      },
      description: t("conversations.singleView.goBack"),
      sendToCommandPalette: true,
      shortcut: ShortcutItem.ConversationGoBack,
    },
  ],
  {
    newShortcutStack: props.drawerMode,
    includeGlobal: true,
  }
);

const onBackClick = async (event: Event) => {
  if (props.drawerMode) {
    event.preventDefault();
  }

  await goBack();
};
</script>

<style lang="sass"></style>
