<template>
  <div class="space-y-4 flex flex-col justify-center content-center items-center">
    <RouterLink :to="{ name: 'conversation-list' }" v-slot="{ isActive }">
      <main-nav-btn
        :active="isActive"
        icon="fa-comments"
        :label="t('mainNavigation.conversations')"
        :badge="activeConversationCountStore.isLoaded() ? activeConversationCount?.toString() : undefined"
      >
      </main-nav-btn>
    </RouterLink>

    <RouterLink :to="{ name: 'app-event-list' }" v-slot="{ isActive }">
      <main-nav-btn :active="isActive" icon="fa-eye" :label="t('mainNavigation.activityFeed')" />
    </RouterLink>

    <!-- <main-nav-btn icon="fa-light fa-chart-simple" :tooltip="t('mainNavigation.reports')" /> -->

    <!-- <RouterLink :to="{ name: 'contact-list' }" v-slot="{ isActive }">
              <main-nav-btn :active="isActive" icon="fa-light fa-users" :tooltip="t('mainNavigation.contacts')" />
            </RouterLink> -->

    <RouterLink :to="{ name: 'kb-list' }" v-slot="{ isActive }">
      <main-nav-btn :active="isActive" icon="fa-book-open" :label="t('mainNavigation.knowledgeBase')" />
    </RouterLink>

    <RouterLink :to="{ name: 'settings-index' }" v-slot="{ isActive }">
      <main-nav-btn :active="isActive" icon="fa-gear" :label="t('mainNavigation.settings')" />
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useFrontegg } from "@frontegg/vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { activeConversationCountStore } from "src/features/conversation/active-conversation-count/active-conversation-count-global-store";
import { useI18n } from "vue-i18n";
import MainNavBtn from "components/MainNavBtn.vue";

const { t } = useI18n();
const { authState: feAuthState } = useFrontegg();

const activeConversationCount = computed(() => {
  const currentTenant = feAuthState.user?.tenantId;
  if (isNullOrUndefined(currentTenant)) {
    return undefined;
  } else {
    return (activeConversationCountStore.countForTenant(currentTenant) ?? 0) > 99
      ? "99+"
      : activeConversationCountStore.countForTenant(currentTenant);
  }
});
</script>

<style scoped lang="scss"></style>
