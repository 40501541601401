<template>
  <div>
    <div class="px-6 mt-6">
      <contact-create-form ref="form" @created="onCreated" v-model:saving="saving" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <s-drawer-btn label="Cancel" @click="closeDrawer" />
      <s-drawer-btn label="Create" primary @click="save" :loading="saving" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import ContactCreateForm from "src/features/contact/contact-create/ContactCreateForm.vue";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import { useFormSave } from "src/composables/useFormSave";
import { ref } from "vue";
import { GContactEntityFragment } from "src/generated/graphql";
import { emitter } from "boot/mitt";
import SDrawerBtn from "components/SDrawerBtn.vue";
const { form, save } = useFormSave();
const saving = ref(false);

function onCreated(contact: GContactEntityFragment) {
  console.log("contact-created-in-drawer", contact);
  emitter.emit("contact-created-in-drawer", {
    contact: contact,
  });

  closeDrawer();
}

// useShortcut('contact-create-drawer', [
//   {key: 'esc', callback: () => onEscape(), description: 'Close contact drawer', shortcut: ShortcutItem.ConversationHideContact},
//   {key: 'm', callback: () => placeholder(), description: 'Merge'},
//   {key: 'enter', callback: () => placeholder(), description: 'Show profile'}
// ], {
//   pauseAllShortcuts: true,
//   pauseAllGlobalPalettes: true
// })
//
// function onEscape () {
//   drawerStore.hideContactDisplay()
// }
//
// function onShow () {
//   console.log('show')
//   // bind()
// }
//
// function onHide () {
//   console.log('hide')
//   // unbind()
// }
//
// function placeholder () {
//   //
// }
</script>

<style lang="sass"></style>
