<template>
  <slot />
</template>

<script setup lang="ts">
import { useAuthState } from "@frontegg/vue";
import { onMounted, onBeforeUnmount } from "vue";
import * as Sentry from "@sentry/vue";

const authState = useAuthState();

const identifyUser = () => {
  if (!authState.user) {
    console.error("No user data, skipping Gleap identify");
    return;
  }

  Sentry.setUser({
    email: authState.user?.email,
    id: authState.user?.id,
  });
};

onMounted(() => {
  if (!authState.user) {
    console.error("No user data, skipping Sentry init");
    return;
  }

  identifyUser();
});

onBeforeUnmount(() => {
  Sentry.setUser(null);
});
</script>

<style scoped lang="scss"></style>
