import { isNullOrUndefined } from "src/shared/object-utils";

export const resolveDisplayName = (firstName: string | undefined | null, lastName: string | undefined | null) => {
  if (!isNullOrUndefined(firstName) && !isNullOrUndefined(lastName)) {
    return `${firstName} ${lastName}`;
  }

  if (!isNullOrUndefined(firstName)) {
    return firstName;
  }

  if (!isNullOrUndefined(lastName)) {
    return lastName;
  }

  return "";
};

export const resolveNameInitials = (firstName: string | undefined | null, lastName: string | undefined | null) => {
  if (!isNullOrUndefined(firstName) && !isNullOrUndefined(lastName)) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }

  if (!isNullOrUndefined(firstName)) {
    return `${firstName.charAt(0).toUpperCase()}`;
  }

  if (!isNullOrUndefined(lastName)) {
    return `${lastName.charAt(0).toUpperCase()}`;
  }

  return "?";
};

export const resolveTeamInitials = (teamName: string | undefined | null) => {
  if (!isNullOrUndefined(teamName)) {
    return `${teamName.charAt(0).toUpperCase()}`;
  }

  return "?";
};
