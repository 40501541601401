import { ContextHolder } from "@frontegg/vue";
import Gleap from "gleap";

export enum TrackedEvent {
  EnabledPushNotifications = "Enabled push notifications",
  CreatedComment = "Created comment",
  UsedKeyboardShortcut = "Used keyboard shortcut",
  RequestedConversationSummary = "Requested conversation summary",
  UsedBotReplySentDirectly = "Used assistant suggestion (sent directly)",
  UsedBotReplyWithEdit = "Used assistant suggestion (with edit)",
  AssistantTranslatedText = "Translated text using assistant",
  AssistantImprovedText = "Improved text using assistant",
  AssistantFixedGrammar = "Fixed grammar using assistant",
  InsertedSnippetInReply = "Used snippet in a reply",
  InsertedKbInReply = "Inserted KB article in a reply",
  SnoozedConversation = "Snoozed a conversation",
}

export function useTracking() {
  const trackEvent = (type: TrackedEvent, data?: Record<string, unknown>) => {
    try {
      Gleap.trackEvent(type, data);
    } catch (err) {
      console.log("Error tracking Gleap event", err);
    }

    if (window.juneAnalytics) {
      try {
        void window.juneAnalytics.track(type, data, {
          context: { groupId: ContextHolder.getUser()?.tenantId },
        });
      } catch (err) {
        console.log("Error tracking June event", err);
      }
    }
  };

  return { trackEvent };
}
