import { Composer } from "vue-i18n";
import { copyToClipboard as qCopyToClipboard, Notify } from "quasar";

export function useClipboard($t: Composer["t"]) {
  const copyToClipboard = async (stringToCopy: string) => {
    try {
      await qCopyToClipboard(stringToCopy);

      Notify.create({
        type: "positive",
        message: $t("common.copiedToClipboard"),
        caption: stringToCopy,
        icon: "fa-light fa-clipboard-check",
      });
    } catch (e) {
      Notify.create({
        type: "negative",
        message: $t("common.copyToClipboardError"),
        caption: stringToCopy,
        icon: "fa-light fa-clipboard",
      });
    }
  };

  return {
    copyToClipboard,
  };
}
