import { emitter } from "boot/mitt";
import {
  GConversationByIdFragment,
  GConversationsFragment,
  GConversationTimelineFragment,
  GOnConversationModifiedSubscription,
  GOnConversationModifiedSubscriptionVariables,
  GOnConversationTimelineCreatedSubscription,
  GOnConversationTimelineCreatedSubscriptionVariables,
  GOnConversationTimelineModifiedSubscription,
  GOnConversationTimelineModifiedSubscriptionVariables,
  OnConversationModifiedDocument,
  OnConversationTimelineCreatedDocument,
  OnConversationTimelineModifiedDocument,
} from "src/generated/graphql";
import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { isNullOrUndefined } from "src/shared/object-utils";
import { useThrottleFn } from "@vueuse/core";

// export interface ConversationTimelineReRender {
//   key: number;
// }

export type ConversationTimeline = GConversationTimelineFragment & StoreWithSelectListData;

export interface ConversationData {
  conversationId: string | null;
  conversation: GConversationByIdFragment | null;
}

export class ConversationStore extends Store2<ConversationData, ConversationTimeline> {
  protected data(): ConversationData {
    return {
      conversationId: null,
      // botAssistantMode: null,
      conversation: null,
    };
  }

  protected wrapSelection() {
    return false;
  }

  async load(conversationId: string) {
    console.log("Fetching", conversationId);
    this.state.conversationId = conversationId;

    const sdk = useGraphqlSdk();

    const res = await sdk.ConversationById({
      id: conversationId,

      // Make sure this is high enough. If we fetch too few, and they all fit into the viewport,
      // there will never be a scroll event that triggers loadMore().
      take: 50,
    });

    if (!res.conversationById) {
      throw new Error(`Couldn't find conversation id ${conversationId}`);
    }

    this.state.conversation = res.conversationById;

    res.conversationById.timeline.items.forEach(timeline => {
      if (timeline) {
        if (this.list.findIndex(s => s.id == timeline.id) !== -1) {
          this.replaceById(timeline.id, timeline);
        } else {
          this.push(timeline);

          this.list.sort((a, b) => {
            return a.position > b.position ? 1 : -1;
          });
        }
      }
    });

    this.list.sort((a, b) => {
      return a.position > b.position ? 1 : -1;
    });

    // this.selectLast()
    this.setContinuationToken(res.conversationById.timeline.continuationToken);
    this.loaded();

    this.setupEmitter();
    this.setupSubscribe();
  }

  async loadMore(): Promise<void> {
    return new Promise(async resolved => {
      const sdk = useGraphqlSdk();

      if (this.state.conversationId === null) {
        return;
      }

      if (!this.hasNextPage()) {
        return resolved();
      }

      const res = await sdk.ConversationByIdLoadMore({
        id: this.state.conversationId,
        take: 50,
        continuationToken: this.getContinuationToken(),
      });

      res.conversationById.timeline.items.forEach(data => {
        if (data) {
          this.push(data);

          this.list.sort((a, b) => {
            return a.position > b.position ? 1 : -1;
          });
        }
      });

      this.setContinuationToken(res.conversationById.timeline.continuationToken);

      return resolved();
    });
  }

  // getTimelineById(id: string) {
  //   return this.list.find(s => s.id === id);
  // }

  setupEmitter() {
    this.addMittEvent("conversation-timeline-created", data => {
      if (data.timeline.conversationId !== this.state.conversationId) {
        return;
      }

      if (this.list.findIndex(s => s.id == data.timeline.id) !== -1) {
        this.replaceById(data.timeline.id, data.timeline);
      } else {
        this.push(data.timeline);

        this.list.sort((a, b) => {
          return a.position > b.position ? 1 : -1;
        });
      }

      if (data.scrollTo) {
        const added = this.list.find(s => s.id === data.timeline.id);

        if (added !== undefined) {
          emitter.emit("conversation-timeline-scroll-to", added.ui.index);
        }
      }
    });

    this.addMittEvent("conversation-modified", data => {
      this.onConversationModified(data);
    });
  }

  setupSubscribe() {
    const onReconnected = useThrottleFn(async () => {
      if (this.state.conversationId) {
        console.log("Reloading conversation store due to reconnection");
        await this.load(this.state.conversationId);
      }
    }, 10_000);

    this.addSubscription<
      GOnConversationTimelineCreatedSubscription,
      GOnConversationTimelineCreatedSubscriptionVariables
    >(
      OnConversationTimelineCreatedDocument,
      {
        conversationId: this.state.conversationId ?? "",
      },
      res => {
        if (
          res.onConversationTimelineCreated.conversationId !== this.state.conversationId ||
          this.state.conversation === null
        ) {
          return;
        }

        const addedIndex = this.list.findIndex(s => s.id == res.onConversationTimelineCreated.id);
        if (addedIndex !== -1) {
          emitter.emit("conversation-timeline-optional-scroll-to", this.list[addedIndex].ui.index);
          // TODO: patch?
          return;
        }

        const added = this.push(res.onConversationTimelineCreated);

        this.list.sort((a, b) => {
          return a.position > b.position ? 1 : -1;
        });

        // Force a re-render of the timeline that this is linked to (used to re-render contact with bot suggestion)
        this.list.filter(i => i.id === res.onConversationTimelineCreated.linkedToTimelineId).forEach(i => i.ui.key++);

        emitter.emit("conversation-timeline-optional-scroll-to", added.ui.index);
      },
      onReconnected,
    );

    this.addSubscription<
      GOnConversationTimelineModifiedSubscription,
      GOnConversationTimelineModifiedSubscriptionVariables
    >(
      OnConversationTimelineModifiedDocument,
      {
        conversationId: this.state.conversationId ?? "",
      },
      res => {
        if (
          res.onConversationTimelineModified.conversationId !== this.state.conversationId ||
          this.state.conversation === null
        ) {
          return;
        }

        const index = this.list.findIndex(s => s.id == res.onConversationTimelineModified.id);

        if (index !== -1) {
          this.list[index].data = res.onConversationTimelineModified.data;
          emitter.emit("conversation-timeline-optional-scroll-to", this.list[index].ui.index);
        }
      },
      onReconnected,
    );

    this.addSubscription<GOnConversationModifiedSubscription, GOnConversationModifiedSubscriptionVariables>(
      OnConversationModifiedDocument,
      {},
      res => {
        this.onConversationModified(res.onConversationModified);
      },
      onReconnected,
    );
  }

  onConversationModified(data: GConversationsFragment) {
    if (data.id !== this.state.conversationId || this.state.conversation === null) {
      return;
    }

    this.state.conversation.botAssistantMode = data.botAssistantMode;
    this.state.conversation.status = data.status;

    if (isNullOrUndefined(data.assignee)) {
      this.state.conversation.assignee = null;
    } else {
      this.state.conversation.assignee = {
        id: data.assignee.id,
        firstName: data.assignee.firstName,
        lastName: data.assignee.lastName,
      };
    }

    if (isNullOrUndefined(data.team)) {
      this.state.conversation.team = null;
    } else {
      this.state.conversation.team = {
        id: data.team.id,
        name: data.team.name,
      };
    }
  }
}

// export const conversationStore = new ConversationStore();
