import { isNullOrUndefined } from "src/shared/object-utils";
import {
  getPageElement,
  getSiblingFooter,
  getSiblingHeader,
  isBodyItemVisible,
  scrollPageBy,
} from "src/shared/dom-utils";
import { onMounted, watch } from "vue";
import { Store2 } from "stores/store-2";
import { SelectDirection } from "src/shared/models/store-list-with-select-data";
import { nextTick } from "process";

// eslint-disable-next-line
export function useScrollToStoreSelected(store: Store2<any, any>, template: string) {
  function scroll() {
    if (store.selectedCount() !== 1) {
      return;
    }

    const selected = store.getSelected();
    const el = document.getElementById(`${template}-${selected}`);

    if (!isNullOrUndefined(el)) {
      // We blur active element to fix edge case where clicking a button in navigation
      // will select that button, and enter shortcut doesn't work as the button has focus
      // and has it its own enter code. May have to move this to a more general layer,
      // but a temp (lol) hack for now.
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }

      if (!isBodyItemVisible(el)) {
        el.scrollIntoView({
          block: store.getSelectDirection() == SelectDirection.Up ? "start" : "end",
          behavior: "auto",
        });

        if (store.getSelectDirection() == SelectDirection.Up) {
          scrollPageBy(-10);

          if (!store.lastIndexSelected()) {
            const header = getSiblingHeader(el);
            if (!isNullOrUndefined(header)) {
              scrollPageBy(-header.getBoundingClientRect().bottom);
            }
          }
        }

        if (store.getSelectDirection() == SelectDirection.Down && store.getSelectedIndex() !== 0) {
          scrollPageBy(20);

          const footer = getSiblingFooter(el);
          if (!isNullOrUndefined(footer) && footer.clientHeight > 0) {
            scrollPageBy(window.innerHeight - footer.getBoundingClientRect().top);
          }
        }
      }
    }
  }

  function scrollToId(uiIndex: number, scrollExtra: number) {
    nextTick(() => {
      const el = document.getElementById(`${template}-${uiIndex}`);

      if (!isNullOrUndefined(el)) {
        el.scrollIntoView({
          block: "end",
          behavior: "auto",
        });

        const pageElement = getPageElement();
        pageElement.scrollBy(0, scrollExtra);
      }
    });
  }

  onMounted(() => {
    watch(store.keyboardNavigationChange(), () => {
      scroll();
    });

    scroll();
  });

  return { scrollToId };
}
