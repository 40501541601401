<template>
  <div>
    <div class="px-6 mt-6">
      <EmailCreateForm ref="form" @created="onCreated" v-model:saving="saving" />
    </div>

    <Teleport :to="`#drawer-button-container-${drawerRef?.wrapper}`">
      <s-drawer-btn :label="$t('common.cancel')" @click="closeDrawer" />
      <s-drawer-btn :label="$t('common.create')" primary @click="save" :loading="saving" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import EmailCreateForm from "./EmailCreateForm.vue";
import { useFormSave } from "src/composables/useFormSave";
import { ref } from "vue";
import SDrawerBtn from "components/SDrawerBtn.vue";
import { closeDrawer, drawerRef } from "src/composables/useGlobalDrawer";
import { GConnectedServiceEmailEntity } from "src/generated/graphql";
import { emitter } from "boot/mitt";

const { form, save } = useFormSave();
const saving = ref(false);

function onCreated(email: GConnectedServiceEmailEntity) {
  emitter.emit("email-channel-created-in-drawer", {
    channel: email,
  });

  closeDrawer();
}

// useShortcut('contact-create-drawer', [
//   {key: 'esc', callback: () => onEscape(), description: 'Close contact drawer', shortcut: ShortcutItem.ConversationHideContact},
//   {key: 'm', callback: () => placeholder(), description: 'Merge'},
//   {key: 'enter', callback: () => placeholder(), description: 'Show profile'}
// ], {
//   pauseAllShortcuts: true,
//   pauseAllGlobalPalettes: true
// })
//
// function onEscape () {
//   drawerStore.hideContactDisplay()
// }
//
// function onShow () {
//   console.log('show')
//   // bind()
// }
//
// function onHide () {
//   console.log('hide')
//   // unbind()
// }
//
// function placeholder () {
//   //
// }
</script>

<style lang="sass"></style>
