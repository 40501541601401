<template>
  <q-drawer
    id="sa-drawer"
    v-model="showDrawer"
    side="right"
    ref="drawer"
    overlay
    no-swipe-open
    no-swipe-close
    behavior="mobile"
    :width="drawerWidth"
    @hide="onHide"
    @before-show="onShow"
    @update:modelValue="onChange"
    class="sa-drawer"
  >
    <q-layout view="lHr lpR lFr">
      <q-header v-if="title" class="sa-drawer__header">
        <q-toolbar class="py-3">
          <q-toolbar-title class="lg:px-2">
            {{ title }}
          </q-toolbar-title>

          <q-btn icon="close" class="sa-drawer__header-button" flat @click="onHide" />
        </q-toolbar>
      </q-header>

      <q-page-container class="sa-drawer__content">
        <Suspense timeout="0">
          <component
            :is="drawerRef.drawer"
            v-if="drawerRef && drawerRef.wrapper === name"
            v-bind="drawerRef.props"
            :isVisible="showDrawer"
            ref="drawerInstance"
          />

          <template #fallback>
            <q-inner-loading :showing="true">
              <q-spinner size="50px" />
            </q-inner-loading>
          </template>
        </Suspense>
      </q-page-container>

      <q-footer :class="showFooter ? 'sa-drawer__footer' : 'hidden'">
        <div
          class="space-x-2 py-3 px-4 flex justify-end"
          :id="`drawer-button-container-${name}`"
          ref="buttonContainer"
        ></div>
      </q-footer>
    </q-layout>
  </q-drawer>
</template>

<script setup lang="ts">
import { computed, ComponentPublicInstance, ref, watch } from "vue";
import { QDrawer, useQuasar } from "quasar";
import { drawerRef, DrawerInstance, closeDrawer } from "src/composables/useGlobalDrawer";
import { onBeforeRouteLeave } from "vue-router";
import { useMutationObserver } from "@vueuse/core";
import { emitter } from "src/boot/mitt";

const drawerInstance = ref<ComponentPublicInstance<DrawerInstance>>();
const buttonContainer = ref<HTMLDivElement>();
const drawer = ref<QDrawer>();

const showFooter = ref(false);

useMutationObserver(
  buttonContainer,
  () => {
    if (buttonContainer.value?.hasChildNodes() ?? false) {
      showFooter.value = true;
    } else {
      showFooter.value = false;
    }
  },
  {
    childList: true,
  },
);

watch([drawerInstance, drawerRef], () => {
  if (drawerRef.value) {
    drawerRef.value.comp = drawerInstance.value;
    showDrawer.value = true;
  } else {
    showDrawer.value = false;
  }
});

defineProps({
  name: {
    type: String,
    default: "default",
  },
});

const emits = defineEmits(["show", "hide"]);
const showDrawer = ref(false);

const title = computed(() => drawerRef.value?.title);
const $q = useQuasar();

const drawerWidth = computed(() => {
  let w = 500;
  if (drawerRef.value?.width === "xl") {
    w = 1024;
  } else if (drawerRef.value?.width === "lg") {
    w = 728;
  } else if (drawerRef.value?.width === "md") {
    w = 600;
  } else if (drawerRef.value?.width === "sm") {
    console.log("SMALL");
    w = 400;
  }

  if (w > $q.screen.width) {
    w = $q.screen.width - 50;
  }

  return w;
});

onBeforeRouteLeave(() => {
  closeDrawer();
});

//const timeouts = ref<Array<NodeJS.Timeout>>([]);

function onShow() {
  // while (timeouts.value.length) {
  //   const timeout = timeouts.value.pop();
  //   clearTimeout(timeout);
  // }

  emits("show");
  showDrawer.value = true;
}

function onHide() {
  emits("hide");
  emitter.emit("drawer-closed");
  showDrawer.value = false;

  // Allow close transition to finish before killing the component
  // timeouts.value.push(
  //   setTimeout(() => {
  //     drawerRef.value = null;
  //   }, 250)
  // );
  drawerRef.value = null;
}

function onChange(value: boolean) {
  if (!value) {
    onHide();
  }
}
</script>

<style lang="sass"></style>
