import { route } from "quasar/wrappers";
import { createMemoryHistory, createRouter, createWebHistory } from "vue-router";

import routes from "./routes";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER ? createMemoryHistory : createWebHistory;

  const Router = createRouter({
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return false;
      }
    },

    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.onError(error => {
    console.log("Router error", error);
    // if (
    //   error.message.includes("Failed to fetch dynamically imported module") ||
    //   error.message.includes("Importing a module script failed")
    // ) {
    //   console.log("Reloading due to module import error ...");

    //   if (!to?.fullPath) {
    //     window.location.reload();
    //   } else {
    //     window.location.assign(to.fullPath);
    //   }
    // }
  });

  // Router.beforeEach((to, from, next) => {
  //   if (!isLoggedIn("agent") && to.name !== "login") {
  //     next({ name: "login" });
  //   } else {
  //     next();
  //   }
  // });

  Router.onError(err => {
    console.log(err);
    console.log("router error");
  });

  return Router;
});
