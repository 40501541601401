import { onMounted, onBeforeUnmount } from "vue";

export function useInterval(callback: () => void, intervalInSeconds: number) {
  let refreshTimerId: number;

  onMounted(() => {
    refreshTimerId = window.setInterval(callback, intervalInSeconds * 1000);
    // console.log('Started interval', refreshTimerId)
  });

  onBeforeUnmount(() => {
    // console.log('Cleared interval', refreshTimerId)
    clearInterval(refreshTimerId);
  });
}
