<template>
  <sa-editor
    ref="editor"
    class="border-gray-400 ring ring-blue-200 pr-1"
    :content="editorContent"
    :autofocus="editorContent === '' ? 'start' : 'end'"
    @escape="onEscape"
    @mod+enter="onCtrlCenter(false)"
    @mod+shift+enter="onCtrlCenter(true)"
    @upload="onUpload"
    @change-diff="changeDiffHandler"
    @change-snapshot="changeSnapshotHandler"
    text-bubble-menu
    :file-upload="enableAttachments"
    paragraph-as-div
    :image-paste="enableAttachments"
    intro-template
    knowledge-base-suggestions
    :intro-template-placeholder-content="introTemplatePlaceholderContent"
    body-scroll-lock
    change-watch
    emoji
  >
    <template #bottom-buttons>
      <div class="flex flex-row items-center space-x-4 pb-2 text-gray-600 pt-1 justify-between">
        <div class="flex flex-row">
          <div>
            <q-btn icon="fa-light fa-sparkles" label="AI" class="rounded-md">
              <q-menu>
                <q-list>
                  <q-item v-ripple clickable v-close-popup @click="onImproveClick">
                    <q-item-section side>
                      <q-icon name="fa-regular fa-pen-to-square" />
                    </q-item-section>
                    <q-item-section>{{ $t("conversation.editor.assistant.improveWriting") }}</q-item-section>
                  </q-item>

                  <q-item v-ripple clickable v-close-popup @click="onFixGrammarClick">
                    <q-item-section side>
                      <q-icon name="fa-regular fa-spell-check" />
                    </q-item-section>
                    <q-item-section>{{ $t("conversation.editor.assistant.fixSpellingAndGrammar") }}</q-item-section>
                  </q-item>

                  <q-item v-ripple clickable>
                    <q-item-section side>
                      <q-icon name="fa-regular fa-language" />
                    </q-item-section>
                    <q-item-section>{{ $t("conversation.editor.assistant.translate") }}</q-item-section>
                    <q-item-section side>
                      <q-icon name="keyboard_arrow_right" />
                    </q-item-section>
                  </q-item>

                  <q-menu anchor="top end" self="top start">
                    <q-list>
                      <q-item v-ripple clickable v-close-popup @click="onTranslateClick('English')">
                        <q-item-section>{{ $t("languages.en") }}</q-item-section>
                      </q-item>

                      <q-item v-ripple clickable v-close-popup @click="onTranslateClick('Norwegian')">
                        <q-item-section>{{ $t("languages.nb") }}</q-item-section>
                      </q-item>

                      <q-item v-ripple clickable v-close-popup @click="showAskTranslateLanguageDialog">
                        <q-item-section>{{ $t("common.other") }}</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-list>
              </q-menu>
            </q-btn>
          </div>

          <div v-if="enableAttachments">
            <q-btn icon="attach_file" flat @click="onAttach" class="rounded-md" />
          </div>

          <!-- <div>
            <q-btn icon="delete" class="rounded-md" />
          </div> -->
        </div>

        <div class="flex items-center">
          <div class="pr-3 hidden lg:block">
            <span>{{ $t("kb.editorPlugin.insertArticle") }}</span>
            <q-chip square dense>??</q-chip>
          </div>
          <div class="pr-3 hidden lg:block">
            <span>{{ $t("conversation.snippets.editorPlugin.insertSnippet") }}</span>
            <q-chip square dense>!!</q-chip>
          </div>
          <MessageSubmitButton
            :loading="isSending"
            @send="onCtrlCenter(false)"
            @send-and-mark-done="onCtrlCenter(true)"
          />
        </div>
      </div>
    </template>
  </sa-editor>

  <div class="flex flex-row items-center pt-1.5">
    <AttachmentThumbnail
      v-for="(attachment, index) in attachments"
      :key="`attachment-${index}`"
      :filename="attachment.filename"
      :content-type="attachment.contentType"
      :preview-url="attachment.previewUrl"
      :download-url="attachment.downloadUrl"
      show-delete
      dense
      @delete="onAttachmentDelete(index)"
    />
  </div>
</template>

<script setup lang="ts">
import SaEditor, { ISaEditor } from "src/features/tip-tap-editor/SaEditor.vue";
import { agentIsTypingInConversationCommand, agentStoppedTypingInConversationCommand } from "./api-commands";
import { onBeforeUnmount, ref, PropType, computed } from "vue";
import { isNullOrUndefined } from "src/shared/object-utils";
import { UploadedFile } from "src/features/tip-tap-editor/SaEditorDropTarget.vue";
import AttachmentThumbnail from "src/features/attachment/attachment-thumbnail/AttachmentThumbnail.vue";
import { TextPatch } from "src/shared/models/text-patch";
import { createConversationAgentMessageCommand } from "src/features/conversation/shared-api-commands";
import { PlaceholderReplacementData } from "src/features/tip-tap-editor/extensions/template/template-intro-suggestion";
import { GConversationChannelItem } from "src/generated/graphql";
import emitShortcut from "src/shared/emit-shortcut";
import { ShortcutItem } from "src/shared/shortcut-item";
import MessageSubmitButton from "../shared/MessageSubmitButton.vue";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const props = defineProps({
  conversationId: {
    type: String,
    required: true,
  },

  linkedToTimelineId: {
    type: String,
    required: false,
  },

  content: {
    type: String,
    required: false,
    default: () => "",
  },

  introTemplatePlaceholderContent: {
    type: Object as PropType<PlaceholderReplacementData>,
    required: false,
  },

  currentChannel: {
    type: String as PropType<GConversationChannelItem>,
  },
});

const enableAttachments = computed(() => {
  if (props.currentChannel === GConversationChannelItem.Sms) {
    return false;
  }

  return true;
});

const emits = defineEmits(["close"]);

const editor = ref<ISaEditor>();
const attachments = ref<UploadedFile[]>([]);
const isSending = ref(false);
const $q = useQuasar();
const { t } = useI18n();

const editorContent = props.content.toString();

let typingId: null | string = null;
let accessKey: null | string = null;

function onUpload(attachment: UploadedFile) {
  attachments.value.push(attachment);
}

function onEscape() {
  emits("close");
  // e.stopPropagation();
}

async function onCtrlCenter(shift: boolean) {
  if (isSending.value) {
    console.log("A message is already sending, skipping...");
    return;
  }

  const attachmentList = attachments.value.map(i => i.downloadUrl);

  if (editor.value?.isEmpty() && attachmentList.length === 0) {
    $q.notify({
      type: "warning",
      icon: "fa-regular fa-face-confused",
      message: t("conversation.actions.send.nothingToSendNotification"),
    });
    return;
  }

  console.log("sending", editor.value?.getHTML(), attachmentList, editor.value?.isEmpty());

  isSending.value = true;

  await createConversationAgentMessageCommand(
    props.conversationId,
    editor.value?.getHTML() ?? "",
    props.linkedToTimelineId ?? null,
    attachmentList,
  )
    .then(async () => {
      if (shift) {
        emitShortcut(ShortcutItem.ConversationArchive);
      }

      emits("close");
    })
    .catch(err => {
      console.error(err);
    })
    .finally(() => {
      isSending.value = false;
    });
}

function onTranslateClick(language: string) {
  editor.value?.translate(language);
}

function onImproveClick() {
  editor.value?.improveLanguage();
}

function onFixGrammarClick() {
  editor.value?.fixGrammar();
}

function onAttach() {
  //
}

function onAttachmentDelete(index: number) {
  attachments.value.splice(index, 1);
}

function showAskTranslateLanguageDialog() {
  editor.value?.showAskTranslateLanguageDialog();
}

onBeforeUnmount(async () => {
  if (!isNullOrUndefined(typingId) && !isNullOrUndefined(accessKey)) {
    await agentStoppedTypingInConversationCommand(typingId, accessKey, props.conversationId);
  }
});

async function changeDiffHandler({ diffs, sequence }: { diffs: TextPatch[]; sequence: number }) {
  await agentIsTypingInConversationCommand(
    typingId,
    accessKey,
    sequence,
    props.conversationId,
    JSON.stringify(diffs),
    false,
  )
    .then(res => {
      typingId = res.typingId ?? "";
      accessKey = res.accessKey ?? "";
    })
    .catch(err => {
      console.warn("Unable to set typing", err);
    });
}

async function changeSnapshotHandler({ text, sequence }: { text: string; sequence: number }) {
  await agentIsTypingInConversationCommand(typingId, accessKey, sequence, props.conversationId, text, true)
    .then(res => {
      typingId = res.typingId ?? "";
      accessKey = res.accessKey ?? "";
    })
    .catch(err => {
      console.warn("Unable to set typing", err);
    });
}
</script>

<style lang="sass"></style>
