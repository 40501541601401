import { Store2 } from "stores/store-2";
import { StoreWithSelectListData } from "src/shared/models/store-list-with-select-data";
import { GConnectedServiceCapabilityItem, GGlobalConnectedServiceListFragment } from "src/generated/graphql";

export type ConnectedService = GGlobalConnectedServiceListFragment & StoreWithSelectListData;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConnectedServiceData {
  //
}

export class ConnectedServiceGlobalStore extends Store2<ConnectedServiceData, ConnectedService> {
  protected data(): ConnectedServiceData {
    return {};
  }

  protected async load() {
    //
  }

  getConnectedEmailServices() {
    return this.getConnectedServicesByType(GConnectedServiceCapabilityItem.Email);
  }

  private getConnectedServicesByType(type: GConnectedServiceCapabilityItem) {
    const connectedServices: ConnectedService[] = [];

    this.list.forEach(i => {
      if (i.capabilities.indexOf(type) !== -1) {
        connectedServices.push(i);
      }
    });

    return connectedServices;
  }

  protected setupSubscribe() {
    //
  }

  protected setupEmitter() {
    //
  }
}

export const connectedServiceStore = new ConnectedServiceGlobalStore();
