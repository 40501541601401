<template>
  <s-form @submit="onSubmit" ref="form" class="max-w-2xl">
    <q-banner v-if="error" class="bg-red text-white">{{ errorMessage }}</q-banner>

    <div class="space-y-1">
      <div class="sa-form__label">Email address</div>
      <q-input
        v-model="emailLocalPart"
        outlined
        class="sa-form__input"
        type="text"
        :suffix="`@${tenantEmailDomain}`"
        autofocus
        :rules="[
          val => !!val || 'Field is required',
          val => val.match(/^[a-zA-Z\-\_0-9.]*$/) || 'Please use only letters a-z, hyphens, underscores or numbers 0-9',
          val => val.length > 2 || 'Please use min 3 characters',
          val => val.length < 30 || 'Please use max 30 characters',
        ]"
      />
    </div>

    <div class="space-y-1">
      <div class="sa-form__label">Team</div>
      <q-select
        hint="Optionally assign new conversations to this team"
        v-model="teamId"
        :options="teamOptions"
        emit-value
        map-options
        outlined
        clearable
        class="sa-form__input"
        :placeholder="'No '"
        :disable="!teamOptions.length"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.label }}</q-item-label>
              <q-item-label caption>{{ scope.opt.description }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <div class="text-xs ml-3" v-if="!teamOptions.length">
        You haven't created any teams yet. Would you like to
        <router-link :to="{ name: 'settings-teams' }">create one?</router-link>
      </div>
    </div>
  </s-form>
</template>

<script setup lang="ts">
import SForm from "components/SForm.vue";
import { ref, computed } from "vue";
import { useFormSave } from "src/composables/useFormSave";
import { useGraphqlSdk } from "src/graphql/graphql-client";
import { isNullOrUndefined } from "src/shared/object-utils";
import { tenantInfoStore } from "stores/tenant-info-global-store";
import { conversationAssignStore } from "src/features/conversation/conversation-assign/conversation-assign-global-store";
import { TeamOrAgentType } from "src/shared/models/models";

const tenantEmailDomain = computed(() => tenantInfoStore.getState().emailChannelDomain);

const teamOptions = computed(() => {
  return conversationAssignStore
    .getList()
    .filter(i => i.type == TeamOrAgentType.Team)
    .map(team => {
      return {
        label: team.label,
        value: team.id,
      };
    });
});

const emit = defineEmits(["update:saving", "created"]);

const { form, save, error, errorMessage, setError } = useFormSave();
defineExpose({ save });

const teamId = ref<string>();
const emailLocalPart = ref<string>("");

async function onSubmit() {
  const sdk = useGraphqlSdk();
  emit("update:saving", true);

  sdk
    .CreateEmailChannel({
      input: {
        emailLocalPart: emailLocalPart.value,
        teamId: teamId.value,
      },
    })
    .then(res => {
      if (!isNullOrUndefined(res.createEmailChannel.channel)) {
        emit("created", res.createEmailChannel.channel);
      }
    })
    .catch(err => {
      setError(err.message);
    })
    .finally(() => {
      emit("update:saving", false);
    });
}
</script>

<style lang="sass"></style>
